import { useEffect, useState } from "react";
import { filter, find } from "lodash";
import { connect } from "react-redux";
import Alert from "../../../components/Alert";
import Table from "../../../components/Table";
import Edit from "../../../assets/svgs/edit.svg";
import Delete from "../../../assets/svgs/delete.svg";
import DeleteItem from "../../../components/DeleteItem";
import { Actions } from "../../../internals/app/Actions";
import AddDiscount from "../../../components/AddDiscount";
import DiscountHeader from "../../../components/DiscountHeader";
import { formatPrice } from "../../../internals/managers/PaymentManager";

const tableHeader = [
  "DISCOUNT TITLE",
  "DISCOUNT TYPE",
  "INTERNAL ID",
  "DESCRIPTION",
  "DISCOUNT",
  "ACTIONS",
];

const DiscountView = (props: any) => {
  const { alertStatus, openAlertBox, discount, updateDiscount, selectedShop } =
    props;
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [selctedDiscount, setSelctedDiscount] = useState(null);

  useEffect(() => {
    if (discount.discounts) {
      const tableData = getData(discount.discounts);
      setTableData(tableData);
    }
  }, [discount]);

  const getData = (data: any) => {
    return data.map((item: any) => {
      const discountValue =
        item.amountType === "PERCENTAGE"
          ? `${item.amount}%`
          : formatPrice(item.amount, selectedShop.currency);
      return {
        id: item.discId,
        DESCRIPTION: item.desc,
        DISCOUNT: discountValue,
        "INTERNAL ID": item.inId,
        "DISCOUNT TITLE": item.title,
        "DISCOUNT TYPE": item.discType,
        ACTIONS: [Edit, Delete],
      };
    });
  };

  const onClickItem = (data: any, index: number) => {
    const selectedData = find(
      discount.discounts,
      (item) => item.discId === data.id
    );
    setSelctedDiscount(selectedData);
    if (index === 0) {
      toggleDiscount();
    } else if (index === 1) {
      toggleDelete();
    }
  };

  const toggleDelete = () => {
    setIsOpenDelete(!isOpenDelete);
  };

  const toggleDiscount = () => {
    setIsOpen(!isOpen);
  };

  const onDeleteDiscount = (data: any) => {
    const values = filter(
      discount.discounts,
      (discount: any) => discount.discId !== data.discId
    );
    discount.discounts = [...values];
    updateDiscount(discount, "DELETE");
    toggleDelete();
  };

  return (
    <div className="flex flex-col w-full p-6 justify-start">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="pb-6">
        <DiscountHeader
          openDiscount={() => {
            setSelctedDiscount(null);
            toggleDiscount();
          }}
        />
      </div>
      <Table {...{ tableHeader, tableData }} onClickItem={onClickItem} />
      <AddDiscount
        isOpen={isOpen}
        selctedDiscount={selctedDiscount}
        closeModal={() => toggleDiscount()}
      />
      <DeleteItem
        isOpen={isOpenDelete}
        closeModal={() => toggleDelete()}
        onDelete={() => onDeleteDiscount(selctedDiscount)}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    discount: state.discount.get("discount"),
    categories: state.product.get("categories"),
    alertStatus: state.common.get("alertStatus"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    openAlertBox: Actions.common.openAlertBox,
    updateDiscount: Actions.discount.updateDiscount,
  }
)(DiscountView);
