import CloseCashRepository from "../../internals/repository/CloseCashRepository";
import TransactionRepository from "../../internals/repository/TransactionRepository";

export const ModuleEvents = {
  SET_SELECTED_SCREEN: "SET_SELECTED_SCREEN",
  FETCH_BATCH_SUMMARY: "FETCH_BATCH_SUMMARY",
  SET_SELECTED_DATERANGE: 'SET_SELECTED_DATERANGE',
  FETCH_ORDER_TRANSACTIONS: "FETCH_ORDER_TRANSACTIONS",
  FETCH_BATCH_SUMMARY_BY_ID: "FETCH_BATCH_SUMMARY_BY_ID",
  FETCH_BATCH_ORDER_SUMMARY: "FETCH_BATCH_ORDER_SUMMARY",
  SEARCH_ORDER_TRANSACTIONS: "SEARCH_ORDER_TRANSACTIONS",
  COMPLETED_CLOSE_CASH_BY_ID: "COMPLETED_CLOSE_CASH_BY_ID",
  FETCH_PAGINATE_BATCH_SUMMARY: "FETCH_PAGINATE_BATCH_SUMMARY",
  FETCH_CLOSECASH_FOR_DATERANGE: 'FETCH_CLOSECASH_FOR_DATERANGE',
  FETCH_PAGINATE_ORDER_TRANSACTION: "FETCH_PAGINATE_ORDER_TRANSACTION",
  FETCH_PAGINATE_CLOSECASH_FOR_DATERANGE: 'FETCH_PAGINATE_CLOSECASH_FOR_DATERANGE'
};

const createAction =
  (type, action = () => { }, meta) =>
    (...args) => ({
      type,
      payload: action(...args),
      meta,
    });

const fetchOrderTransactions = createAction(
  ModuleEvents.FETCH_ORDER_TRANSACTIONS,
  async (shopId, limit = 30, lastKey = null) => {
    const result = await TransactionRepository.fetchOrderTransactions(
      shopId,
      limit,
      lastKey
    );
    return result;
  },
  { loading: true }
);


const fetchPaginateOrderTransactions = createAction(
  ModuleEvents.FETCH_PAGINATE_ORDER_TRANSACTION,
  async (salonId, limit = 30, lastKey = null) => {
    const result = await TransactionRepository.fetchOrderTransactions(
      salonId,
      limit,
      lastKey
    );
    return result;
  },
  { loading: true }
);

const searchOrderTransactions = createAction(
  ModuleEvents.SEARCH_ORDER_TRANSACTIONS,
  async (
    shopId,
    searchText = null,
    startDate = null,
    endDate = null,
    limit = 30,
    lastKey = null
  ) => {
    const result = await TransactionRepository.searchOrderTransactions(
      shopId,
      searchText,
      endDate,
      startDate,
      limit,
      lastKey
    );
    return result;
  },
  { loading: true }
);

const fetchBatchSummary = createAction(
  ModuleEvents.FETCH_BATCH_SUMMARY,
  async (shopId, userId, limit = 30, lastKey = null) => {
    const result = await CloseCashRepository.fetchBatchSummary(
      shopId,
      userId,
      limit,
      lastKey
    );
    return result;
  },
  { loading: true }
);

const fetchPaginateBatchSummary = createAction(
  ModuleEvents.FETCH_PAGINATE_BATCH_SUMMARY,
  async (salonId, userId, limit = 30, lastKey = null) => {
    const result = await CloseCashRepository.fetchBatchSummary(
      salonId,
      userId,
      limit,
      lastKey
    );
    return result;
  },
  { loading: true }
);

const fetchBatchSummaryById = createAction(
  ModuleEvents.FETCH_BATCH_SUMMARY_BY_ID,
  async (params) => {
    const result = await CloseCashRepository.fetchBatchSummaryById(
      params.batchId
    );
    return { params, result };
  },
  { loading: true }
);

const completedCloseCashById = createAction(
  ModuleEvents.COMPLETED_CLOSE_CASH_BY_ID,
  async (batchId, shopId, limit = 100, lastKey = null) => {
    const result = await CloseCashRepository.completedCloseCashById(
      batchId,
      shopId,
      limit,
      lastKey
    );
    return result;
  },
  { loading: true }
);

const fetchBatchOrderSummary = createAction(
  ModuleEvents.FETCH_BATCH_ORDER_SUMMARY,
  async (batchId, shopId) => {
    const result = await CloseCashRepository.fetchBatchOrderSummary(
      batchId,
      shopId
    );
    return result;
  },
  { loading: true }
);

const setSelectedScreen = createAction(
  ModuleEvents.SET_SELECTED_SCREEN,
  async (screen) => {
    return screen;
  }
);

const fetchCloseCashForDateRange = createAction(
  ModuleEvents.FETCH_CLOSECASH_FOR_DATERANGE,
  async (params, limit = 30, lastKey = null) => {
    const result = await TransactionRepository.fetchCloseCashForDateRange(
      params,
      limit,
      lastKey
    );
    return result;
  },
  { loading: true }
);

const fetchPaginateCloseCashForDateRange = createAction(
  ModuleEvents.FETCH_PAGINATE_CLOSECASH_FOR_DATERANGE,
  async (params, limit = 30, lastKey = null) => {
    const result = await TransactionRepository.fetchCloseCashForDateRange(
      params,
      limit,
      lastKey
    );
    return result;
  },
  { loading: true }
);

const onSelectedDateRange = createAction(
  ModuleEvents.SET_SELECTED_DATERANGE,
  async (status) => {
    return status;
  },
);

const exportedFuction = {
  fetchBatchSummary,
  setSelectedScreen,
  onSelectedDateRange,
  fetchBatchSummaryById,
  completedCloseCashById,
  fetchOrderTransactions,
  fetchBatchOrderSummary,
  searchOrderTransactions,
  fetchPaginateBatchSummary,
  fetchCloseCashForDateRange,
  fetchPaginateOrderTransactions,
  fetchPaginateCloseCashForDateRange,
};

export default exportedFuction;
