import * as Yup from "yup";

export const Schema = Yup.object({
  sendToBar: Yup.boolean(),
  sendToKitchen: Yup.boolean(),
  productName: Yup.string()
    .min(1, "At least 1 characters or more")
    .required("Please enter product name"),
  itemPrice: Yup.number()
    .typeError("Please enter a valid price")
    .required("Please enter item price")
    .positive("Amount must be more than 0"),
  actualPrice: Yup.number()
    .typeError("Please enter a valid price")
    .positive("Amount must be more than 0"),
  brand: Yup.string().required("Please select your brand"),
  category: Yup.string().required("Please select your category"),
  productOptions: Yup.array().of(
    Yup.object().shape({
      setName: Yup.string()
        .min(1, "At least 1 characters or more")
        .required("Please enter name"),
      setDisplayName: Yup.string()
        .min(1, "At least 1 characters or more")
        .required("Please enter display name"),
      options: Yup.array().of(
        Yup.object().shape({
          optionName: Yup.string()
            .min(1, "At least 1 characters or more")
            .required("Please enter option name"),
        })
      ),
    })
  ),
  priceVariants: Yup.array().of(
    Yup.object().shape({
      optionUnit: Yup.string()
        .min(1, "Must be 1 characters or more")
        .required("Please enter option unit"),
      price: Yup.number()
        .typeError("Please enter a valid price")
        .required("Please enter item price")
        .positive("Amount must be more than 0"),
    })
  ),
  modifiers: Yup.array().of(
    Yup.object().shape({
      setName: Yup.string()
        .min(1, "At least 1 characters or more")
        .required("Please enter modifier set name"),
      title: Yup.string()
        .min(1, "At least 1 characters or more")
        .required("Please enter modifier title"),
      isMandatory: Yup.boolean(),
      items: Yup.array().of(
        Yup.object().shape({
          itemName: Yup.string()
            .min(1, "At least 1 characters or more")
            .required("Please enter modifier item name"),
        })
      ),
    })
  ),
});
