import { useEffect, useState } from "react";
import moment from "moment";
import { nanoid } from "nanoid";
import Select from "react-select";
import { connect } from "react-redux";
import AddStockItem from "./AddStockItem";
import {
  mapStockItem,
  mapStockItemLabel,
} from "../../../internals/managers/StockManager";
import Delete from "../../../assets/svgs/delete.svg";
import { CircularProgress } from "@material-ui/core";
import { Actions } from "../../../internals/app/Actions";
import { formatPrice } from "../../../internals/managers/PaymentManager";
import { cloneDeep, findIndex, get, capitalize, find, filter } from "lodash";

const tableHeader = ["STOCK ITEM", "QTY", "UNIT PRICE", "TOTAL PRICE"];

const customStyles = {
  control: (base: any) => ({
    ...base,
    height: "36px",
    minHeight: "36px",
    minWidth: "240px",
    boxShadow: "none",
    borderColor: "#D1D5DB",
    "&:hover": {
      borderColor: "#D1D5DB",
    },
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    padding: 0,
  }),
};

const StockTable = (props: any) => {
  const { shop, stocks, selectedstock, setStockRecord, loadingAction } = props;
  const { action, loading } = loadingAction;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tableItems, setTableItems] = useState<Array<any>>([]);

  const getData = (data: any) => {
    return data?.map((item: any) => {
      const stockItem = get(item, "itemData.itemName", item.itemData?.value);
      const metricValue = get(item, "itemData.metricValue", "");
      return {
        id: item.id,
        QTY: item.qty,
        metric: metricValue,
        "STOCK ITEM": stockItem,
        "UNIT PRICE": item.unitPrice,
        "TOTAL PRICE": item.totalPrice,
      };
    });
  };

  const setTableData = (stocks: any) => {
    const tableData = getData(stocks);
    setTableItems(tableData);
  };
  useEffect(() => {
    if (selectedstock) {
      setTableData(selectedstock.stockRecords);
    }
  }, [selectedstock]);

  const updateStockItem = (option: any, record: any) => {
    const selectedItem = find(
      stocks,
      (stock: any) => stock.stockId === option.value
    );
    record.itemData = mapStockItem(selectedItem);
    const newStock = cloneDeep(selectedstock);
    const index = findIndex(
      selectedstock.stockRecords,
      (stock: any) => stock.id === record.id
    );
    newStock.stockRecords[index] = record;
    if (index == selectedstock.stockRecords.length - 1) {
      newStock.stockRecords.push({
        qty: 0,
        unitPrice: 0,
        id: nanoid(),
        totalPrice: 0,
        itemData: null,
      });
    }
    setStockRecord({ record: newStock, navigation: false });
  };

  const updateStockQTY = (event: any, record: any) => {
    if (event.target.value > -1) {
      const newStock = cloneDeep(selectedstock);

      record.qty = event.target.value;
      record.totalPrice =
        event.target.value > 0
          ? parseFloat(record.unitPrice) * event.target.value
          : 0;

      const index = findIndex(
        selectedstock.stockRecords,
        (stock: any) => stock.id === record.id
      );
      newStock.stockRecords[index] = record;
      setStockRecord({ record: newStock, navigation: false });
    }
  };

  const updateStockPrice = (event: any, record: any) => {
    if (event.target.value > -1) {
      const newStock = cloneDeep(selectedstock);
      record.unitPrice = parseFloat(event.target.value);
      record.totalPrice =
        record.unitPrice > 0 ? parseFloat(event.target.value) * record.qty : 0;

      const index = findIndex(
        selectedstock.stockRecords,
        (stock: any) => stock.id === record.id
      );
      newStock.stockRecords[index] = record;
      setStockRecord({ record: newStock, navigation: false });
    }
  };

  const removeStockItem = (record: any) => {
    const newStock = cloneDeep(selectedstock);
    const stockRecords = filter(
      selectedstock.stockRecords,
      (stock: any) => stock.id !== record.id
    );
    newStock["stockRecords"] = stockRecords;
    setTableData(newStock.stockRecords);
    setStockRecord({ record: newStock, navigation: false });
  };

  const toggleStock = () => {
    setIsOpen(!isOpen);
  };

  const renderTableData = (item: string, data: any, pIndex: number) => {
    const metric = data.metric;
    const isDate = item === "CREATED DATE";
    const options = mapStockItemLabel(stocks);

    const value = isDate
      ? moment(data[item]).format("DD/MM/YYYY - hh:mm A")
      : data[item];
    const isLastIndex = selectedstock?.stockRecords.length - 1 === pIndex;
    const record = find(
      selectedstock?.stockRecords,
      (stock: any) => stock.id === data.id
    );

    const selctedValue = {
      label: get(record, "itemData.value", ""),
      value: get(record, "itemData.stockId", ""),
    };

    switch (item) {
      case "TOTAL PRICE": {
        return (
          <div
            className={`flex flex-row min-w-[140px] justify-end h-9 text-xs text-end items-center text-gray-700`}
          >
            {formatPrice(value, shop.currency)}
          </div>
        );
      }
      case "STOCK ITEM": {
        return (
          <div className="flex flex-row gap-3 justify-start items-center">
            {!isLastIndex ? (
              <img
                src={Delete}
                alt="actions"
                className="w-4 cursor-pointer"
                onClick={() => removeStockItem(record)}
              />
            ) : (
              <div className="w-4" />
            )}
            <Select
              options={options}
              value={selctedValue}
              styles={customStyles}
              placeholder={"Stock Item"}
              onChange={(option) => updateStockItem(option, record)}
              className={
                "flex h-9 w-[240px] text-start items-center rounded-md outline-none text-sm text-valhalla"
              }
            />
            {isLastIndex ? (
              <div
                onClick={() => toggleStock()}
                className="cursor-pointer min-w-fit text-xs text-start text-medium-slate-blue"
              >
                {"Add New"}
              </div>
            ) : null}
          </div>
        );
      }
      case "QTY": {
        return (
          <div className="flex flex-row gap-3 justify-end items-center">
            <input
              type="number"
              value={value}
              placeholder="Please enter qty"
              onChange={(e) => updateStockQTY(e, record)}
              className="flex p-2 max-w-[150px] min-w-[90px] h-9 w-full justify-end rounded-md outline-none border-gray-300 border shadow sm:text-sm text-end"
            />
            <div className={"w-6 text-xs text-start text-gray-700"}>
              {metric}
            </div>
          </div>
        );
      }
      case "UNIT PRICE": {
        return (
          <div className="flex flex-row justify-end items-center">
            <input
              type="number"
              value={value}
              placeholder="Please enter unit price"
              onChange={(e) => updateStockPrice(e, record)}
              className="flex p-2 max-w-[190px] min-w-[90px] h-9 w-full justify-end rounded-md outline-none border-gray-300 border shadow sm:text-sm text-end"
            />
          </div>
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className="w-full relative rounded">
      <table id="tableToPrint" className="w-full">
        <thead className="w-full bg-lavender rounded-t-lg">
          <tr>
            {tableHeader?.map((item: string, index: number) => {
              const isItemEnd =
                item === "UNIT PRICE" ||
                item === "TOTAL PRICE" ||
                item === "QTY";
              const isItemQty = item === "QTY";
              const isStockItem = item === "STOCK ITEM";

              return (
                <th key={index} scope="col" className="px-6 py-3">
                  <div
                    className={`flex text-xs font-medium text-start items-center text-valhalla justify-start cursor-pointer 
                    ${isItemEnd ? "justify-end" : "justify-start"} 
                    ${isItemQty ? "mr-9" : ""} ${isStockItem ? "ml-7" : ""}`}
                  >
                    {item !== "ACTIONS" ? capitalize(item) : null}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="overflow-scroll w-full">
          {loading && action.type === "SET_STOCK_RECORD" ? (
            <CircularProgress size={50} style={{ color: "#6D71F9" }} />
          ) : null}
          {tableItems &&
            tableItems?.map((data: any, pIndex: number) => {
              return (
                <>
                  <tr className="h-4 bg-white border-b hover:bg-gray-50">
                    {tableHeader?.map((item: any) => {
                      return (
                        <td className="px-6 py-4">
                          {renderTableData(item, data, pIndex)}
                        </td>
                      );
                    })}
                  </tr>
                </>
              );
            })}
        </tbody>
      </table>
      <AddStockItem isOpen={isOpen} closeModal={() => toggleStock()} />
    </div>
  );
};
export default connect(
  (state: any) => ({
    stocks: state.stock.get("stocks"),
    shop: state.dashboard.get("selectedShop"),
    stockRecords: state.stock.get("stockRecords"),
    loadingAction: state.common.get("loadingAction"),
    selectedstock: state.stock.get("selectedstockRecord"),
  }),
  {
    setStockRecord: Actions.stock.setStockRecord,
  }
)(StockTable);
