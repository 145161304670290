import { Map } from "immutable";
import { ModuleEvents } from "./Actions";
import { get, orderBy, filter } from "lodash";

const initialState = Map({
  stocks: [],
  stockRecords: [],
  selectedstockRecord: {},
  selectedScreen: 'SUPPLIER'
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (type) {
    case ModuleEvents.FETCH_STOCKS: {
      if (payload && !payload.error) {
        const stocks = get(payload, "Items", []);
        const sortedStocks = orderBy(stocks, ['updatedTime'], ['desc']);
        return state
          .set("stocks", [...sortedStocks])
      }
      return state;
    }
    case ModuleEvents.CREATE_STOCK: {
      if (payload && !payload.error) {
        const stocks = state.get('stocks');
        stocks.push(payload);
        const sortedStocks = orderBy(stocks, ['updatedTime'], ['desc']);
        return state.set('stocks', [...sortedStocks])
      }
      return state;
    }
    case ModuleEvents.FETCH_STOCK_RECORDS: {
      if (payload && !payload.error) {
        const stocks = get(payload, 'Items', []);
        const sortedStocks = orderBy(stocks, ['updatedTime'], ['desc']);
        return state.set('stockRecords', [...sortedStocks]);
      }
      return state;
    }
    case ModuleEvents.DELETE_STOCK_RECORD: {
      if (payload && !payload.error) {
        const stockResult = payload?.stockResult
        const stockRecords = state.get('stockRecords');
        const filterRecords = filter(stockRecords, ({ id }) => id !== stockResult?.Key.id)
        const sortedRecords = orderBy(filterRecords, ['updatedTime'], ['desc']);
        return state.set('stockRecords', [...sortedRecords])
      }
      return state;
    }

    case ModuleEvents.SET_STOCK_RECORD: {
      if (payload) {
        return state.set('selectedstockRecord', { ...payload.record });
      }
      return state;
    }
    case ModuleEvents.SET_SELECTED_SCREEN: {
      return state.set("selectedScreen", payload);
    }

    default:
      return state;
  }
};

export default Reducer;