import { appHistory } from "../app/Store";
let _navigation = null;

export const setNavigation = (navigation) => {
  _navigation = navigation;
};

export const navigate = (path) => {
  console.log(_navigation);
  appHistory.push(path);
  return path;
};