import { get } from "lodash";
import Repository from "./Repository";

class ReportRepository extends Repository {
  init(store, client) {
    this.store = store;
    this.client = client;
    this.initialized = true;
  }

  fetchProductReportSummaryForMonthRange = async (shopId, startDate, endDate) => {
    try {
      const result = await this.apiGet({
        apiName: this.API_REPORTS,
        path: `/reports/product?shopId=${shopId}&startDate=${startDate}&endDate=${endDate}`,
      });
      return get(result, 'reportsResult.Items', []);
    } catch (error) {
      return { error };
    }
  };

  fetchSummaryForMonthRange = async (shopId, startDate, endDate) => {
    try {
      const result = await this.apiGet({
        apiName: this.API_REPORTS,
        path: `/reports?shopId=${shopId}&startDate=${startDate}&endDate=${endDate}`,
      });
      return result.reportsResult.Items;
    } catch (error) {
      return { error };
    }
  };


  fetchSummaryForDate = async (shopId, startDate) => {
    try {
      const message = this.buildMessage({
        shopId,
        startDate,
      });
      const result = await this.apiPost({
        apiName: this.API_REPORTS,
        path: `/reports/summary`,
        message,
      });
      return result?.data.report;
    } catch (error) {
      return { error };
    }
  };

  fetchSummaryForMonth = async (shopId, reportMonth) => {
    const result = await this.apiGet({
      apiName: this.API_REPORTS,
      path: `/report?shopId=${shopId}&reportMonth=${reportMonth}`,
    });
    return result?.reportsSummary;
  };

  fetchOrdersForDate = async (shopId, orderDate) => {
    const message = this.buildMessage({
      shopId,
      orderDate,
      endpointType: "ALL_ORDERS",
    });
    try {
      const result = await this.apiPost({
        apiName: this.API_ADMIN_DASHBOARD,
        path: `/orders`,
        message,
      });
      return get(result, "data.orderList.Items", []);
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  };



  fetchSummaryForMonth = async (shopId, reportMonth) => {
    try {
      const result = await this.apiGet({
        apiName: this.API_REPORTS,
        path: `/report?shopId=${shopId}&reportMonth=${reportMonth}`,
      });
      return result.reportsSummary.Items;
    } catch (error) {
      return { error };
    }
  };

  filterSummaryForDateRange = async (param) => {
    try {
      const message = this.buildMessage(param);
      const result = await this.apiPost({
        apiName: this.API_REPORTS,
        path: `/reports/summary/range`,
        message,
      });

      return result.data.reportsSummary;
    } catch (error) {
      return { error };
    }
  }
}
export default new ReportRepository();
