import Switch from "react-switch";

const SwitchInput = ({label, value, onChange}: any) => {
  return (
    <div className="flex flex-row items-center col-span-6 sm:col-span-6 mb-4 mt-4">
      <label
        htmlFor={'showMenu'}
        className="block text-sm font-medium text-gray-700 mr-4 "
      >
        {label}
      </label>
      <Switch
        width={48}
        height={20}
        id="showMenu"
        onColor="#6D71F9"
        checkedIcon={false}
        handleDiameter={30}
        uncheckedIcon={false}
        className="react-switch"
        onHandleColor="#F8F9FD"
        checked={value}
        boxShadow="0px 1px 5px rgba(109, 113, 249, 0.8)"
        activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
        onChange={onChange}
      />
    </div>
  );
};

export default SwitchInput;