import LineChart from './LineChart';
import {connect} from 'react-redux';
import DoughnutChart from './DoughnutChart';
import HelpGuide from '../../modules/help/HelpGuide';

const ServicesGrowthWidget = () => {
  return (
    <div className="overflow-visible h-full">
      <div className="shadow-lg rounded p-6">
        <h2 className="text-base mb-1 font-semibold text-valhalla">Today</h2>
        <h4 className="text-sm mb-4 font-normal text-valhalla">
          Sales by hour
        </h4>
        <div className="h-[400px]">
          <LineChart />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="items-start justify-start">
          <div className="col-start-1 col-span-6 lg:col-span-3 shadow-lg rounded p-6">
            <h2 className="text-base mb-1 font-semibold text-valhalla">
              Orders
            </h2>
            <h4 className="text-sm mb-4 font-normal text-valhalla">
              Orders of the day
            </h4>
            <div className="h-[300px]">
              <DoughnutChart />
            </div>
          </div>
        </div>
        <HelpGuide />
      </div>
    </div>
  );
};
export default connect((state: any) => ({}), {})(ServicesGrowthWidget);
