import gql from 'graphql-tag';

export const GetShop = gql`
  query getShop($shopId: String!) {
    getShop(shopId: $shopId) {
      shopId
      shopName
      email
      image
      phoneNumber
      shopStatus
      address
      claimStatus
      paymentMethod
      pin
      billImage
      menuImage
      token
      local
      currency
      shopType
      createdBy
      receiptEnd
      description
      shortCode
      shopCategory
    }
  }
`;
export const CreateShop = gql`
  mutation createShop($input: CreateShopInput!) {
    createShop(input: $input) {
      shopId
      shopName
      email
      image
      phoneNumber
      shopStatus
      address
      claimStatus
      paymentMethod
      pin
      local
      billImage
      menuImage
      token
      currency
      shopType
      createdBy
      receiptEnd
      description
      shortCode
      shopCategory
    }
  }
`;

export const UpdateShop = gql`
  mutation updateShop($input: UpdateShopInput!) {
    updateShop(input: $input) {
      shopId
      shopName
      email
      image
      phoneNumber
      billImage
      menuImage
      shopStatus
      address
      claimStatus
      paymentMethod
      pin
      local
      token
      currency
      shopType
      createdBy
      receiptEnd
      description
      shortCode
      shopCategory
    }
  }
`;

export const CreateShopClaim = gql`
  mutation createShopClaim($input: CreateShopClaimInput!) {
    createShopClaim(input: $input) {
      shopId
      requestedUserId
      claimStatus
      placeId
      lat
      lon
      createdTime
      updatedTime
      image
      role
      email
      phoneNumber
      firstName
      lastName
      claimType
    }
  }
`;

export const UpdateShopClaim = gql`
  mutation updateShopClaim($input: UpdateShopClaimInput!) {
    updateShopClaim(input: $input) {
      shopId
      requestedUserId
      claimStatus
      placeId
      lat
      lon
      createdTime
      updatedTime
      image
      role
      email
      phoneNumber
      firstName
      lastName
      claimType
    }
  }
`;

export const DeleteShopClaim = gql`
  mutation deleteShopClaim($input: DeleteShopClaimInput!) {
    deleteShopClaim(input: $input) {
      shopId
      requestedUserId
    }
  }
`;

export const QueryShopClaims = gql`
  query queryShopClaims($shopId: String!, $limit: Int, $nextToken: String) {
    queryShopClaims(shopId: $shopId, limit: $limit, nextToken: $nextToken) {
      items {
        shopId
        requestedUserId
        claimStatus
        placeId
        lat
        lon
        createdTime
        updatedTime
        image
        role
        email
        phoneNumber
        firstName
        lastName
        claimType
      }
      nextToken
    }
  }
`;

export const QueryShopClaimsById = gql`
  query queryShopClaimsByRequestedUserIdIndex($requestedUserId: String!, $first: Int, $after: String) {
    queryShopClaimsByRequestedUserIdIndex(requestedUserId: $requestedUserId, first: $first, after: $after) {
      items {
        shopId
        requestedUserId
        claimStatus
        placeId
        lat
        lon
        createdTime
        updatedTime
        image
        role
        email
        phoneNumber
        firstName
        lastName
        claimType
      }
      nextToken
    }
  }
`;

