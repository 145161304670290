import _, {
  get,
  sumBy,
  concat,
  groupBy,
  orderBy,
  capitalize,
  flattenDeep,
} from "lodash";
import { formatPrice } from "./PaymentManager";
import Dots from "../../assets/svgs/dots.svg";

export const getServiceSummary = (service, shop) => {
  const title = service[0].title || service[0].serviceTitle;
  const total = service
    .map((service) => service.totalPrice)
    .reduce((prev, curr) => prev + curr, 0);
  const count = service
    .map((service) => service.quantity)
    .reduce((prev, curr) => prev + curr, 0);
  const price = formatPrice(total, shop.currency);
  return {
    count,
    price,
    service: title,
  };
};

export const getSalesSummary = (summary, shop) => {
  const total =
    get(summary, "drawerSummary.cashIn/cashOut", 0) +
    get(summary, "drawerSummary.totalCashSalesPrice", 0) -
    get(summary, "drawerSummary.totalRefundPrice", 0);
  const cardTotalSale = get(summary, "drawerSummary.totalCardSalesPrice", 0);

  const data = [
    {
      header: "Drawer summary",
      details: [
        {
          title: "Starting Cash",
          value: formatPrice(
            get(summary, "drawerSummary.startingCash", 0),
            shop.currency
          ),
        },
        {
          title: "Cash Sales",
          value: formatPrice(
            get(summary, "drawerSummary.totalCashSalesPrice", 0),
            shop.currency
          ),
        },
        {
          title: "Card Sales",
          value: formatPrice(
            get(summary, "drawerSummary.totalCardSalesPrice", 0),
            shop.currency
          ),
        },
        {
          title: "Refunds",
          value: formatPrice(
            get(summary, "drawerSummary.totalRefundPrice", 0),
            shop.currency
          ),
        },
        {
          title: "Total Sale",
          value: formatPrice(
            get(summary, "drawerSummary.startingCash", 0),
            shop.currency
          ),
        },
        {
          title: "Cash In/Out",
          value: formatPrice(
            get(summary, "drawerSummary.cashIn/cashOut", 0),
            shop.currency
          ),
        },
        {
          title: "Drawer Expected Amount",
          value: formatPrice(total, shop.currency),
        },
        {
          title: "Drawer Actual Amount",
          value: formatPrice(total, shop.currency),
        },
        {
          title: "Net Amount",
          value: formatPrice(total + cardTotalSale, shop.currency),
        },
      ],
    },
    {
      header: "Sales Summary",
      details: [
        {
          title: `Sales x ${get(summary, "salesSummary.salesCount", 0)}`,
          value: formatPrice(
            get(summary, "salesSummary.totalSales", 0),
            shop.currency
          ),
        },
        {
          title: `Products x ${get(summary, "salesSummary.productCounts", 0)}`,
          value: formatPrice(
            get(summary, "salesSummary.totalProducts", 0),
            shop.currency
          ),
        },
        {
          title: "Commission",
          value: 0,
        },
        {
          title: "Gross Sales",
          value: formatPrice(
            get(summary, "salesSummary.grossSales", 0),
            shop.currency
          ),
        },
        {
          title: "Cash Payments",
          value: formatPrice(
            get(summary, "drawerSummary.totalCashSalesPrice", 0),
            shop.currency
          ),
        },
        {
          title: "Card Payments",
          value: formatPrice(
            get(summary, "drawerSummary.totalCardSalesPrice", 0),
            shop.currency
          ),
        },
      ],
    },
  ];
  return data;


};

export const getOrderDetails = (orders) => {
  let productList = [];
  let productArray = [];
  let serviceList = [];
  let serviceArray = [];

  orders.forEach((appointment) => {
    if (appointment.productDetails) {
      appointment.productDetails.forEach((product) => {
        productList.push(product);
      });
    }
    if (appointment.serviceDetails) {
      appointment.serviceDetails.forEach((service) => {
        serviceList.push(service);
      });
    }
  });
  const groupProducts = groupBy(flattenDeep(productList), "id");
  const groupServices = groupBy(flattenDeep(serviceList), "id");

  Object.keys(groupProducts).map((key) => {
    productArray.push({
      name: capitalize(get(groupProducts[key], "0.productName")),
      quantity: sumBy(groupProducts[key], "quantity"),
      totalPrice: sumBy(groupProducts[key], "totalPrice"),
    });
    return key;
  });
  Object.keys(groupServices).map((key) => {
    serviceArray.push({
      name: capitalize(get(groupServices[key], "0.title")),
      quantity: sumBy(groupServices[key], "quantity"),
      totalPrice: sumBy(groupServices[key], "totalPrice"),
    });
    return key;
  });
  const ordersArray = concat(serviceArray, productArray);
  return orderBy(ordersArray, ["totalPrice"], ["asc"]);
};

export const getCloseCashTableData = (data, shop) => {
  return data.map((item) => {
    const productCount = get(
      item,
      "batchSummary.salesSummary.productCounts",
      0
    );
    const serviceCount = get(item, "batchSummary.salesSummary.salesCount", 0);
    const totalItem = productCount + serviceCount;
    const name = `${get(item, "user.firstName", "")} ${get(
      item,
      "user.lastName",
      ""
    )}`;
    const total =
      get(item, "batchSummary.drawerSummary.cashIn/cashOut", 0) +
      get(item, "batchSummary.drawerSummary.totalCashSalesPrice", 0) -
      get(item, "batchSummary.drawerSummary.totalRefundPrice", 0);
    const cardTotalSale = get(
      item,
      "batchSummary.drawerSummary.totalCardSalesPrice",
      0
    );
    const netTotal = formatPrice(
      total + cardTotalSale,
      shop.currency
    );
    return {
      ACTIONS: [Dots],
      id: item.batchId,
      "NET TOTAL": netTotal,
      "CLOSE CASH BY": name,
      "TOTAL ITEMS": totalItem,
      "END DATE": item.updatedTime,
      "START DATE": item.createdTime,
      "TOTAL PRODUCTS": productCount,
      "TOTAL SERVICES": serviceCount,
    };
  });
};


export const getDateRangeTableData = (data) => {
  return data.map((item) => {
    const productCount = get(
      item,
      "salesSummary.productCounts",
      0
    );
    const serviceCount = get(item, "salesSummary.salesCount", 0);
    const totalItem = productCount + serviceCount;
    const name = `${get(item, "user.firstName", "")} ${get(
      item,
      "user.lastName",
      ""
    )}`;
    const total =
      get(item, "drawerSummary.cashIn/cashOut", 0) +
      get(item, "drawerSummary.totalCashSalesPrice", 0) -
      get(item, "drawerSummary.totalRefundPrice", 0);
    const cardTotalSale = get(
      item,
      "drawerSummary.totalCardSalesPrice",
      0
    );
    const netTotal = total + cardTotalSale;

    return {
      id: item.batchId,
      "CLOSE CASH BY": name,
      "NET AMOUNT": netTotal,
      "DRAWER AMOUNT": total,
      "END DATE": item.updatedTime,
      "START DATE": item.createdTime,
      "TOTAL PRODUCTS": productCount,
      "REFUNDS": get(item, "drawerSummary.totalRefundPrice", 0),
      "CASH IN/OUT": get(item, "drawerSummary.cashIn/cashOut", 0),
      "STARTING CASH": get(item, "drawerSummary.startingCash", 0),
      "CASH SALES": get(item, "drawerSummary.totalCashSalesPrice", 0),
      "CARD SALES": get(item, "drawerSummary.totalCardSalesPrice", 0),
    };
  });
};

export const getIsAmount = (item) => {
  const isAmount =
    item === "COST" ||
    item === "PRICE" ||
    item === "TOTAL" ||
    item === "AMOUNT" ||
    item === "REFUNDS" ||
    item === "WHL PRICE" ||
    item === "MIN PRICE" ||
    item === "CARD SALES" ||
    item === "CASH SALES" ||
    item === "NET AMOUNT" ||
    item === "CASH IN/OUT" ||
    item === "STARTING CASH" ||
    item === "DRAWER AMOUNT";
  return isAmount;

}
