import { useEffect, useState } from "react";
import StockTable from "./StockTable";
import { router } from "../../../App";
import { connect } from "react-redux";
import { headerTabs } from "../Constant";
import Alert from "../../../components/Alert";
import SupplierHeader from "./SupplierHeader";
import DeleteItem from "../../../components/DeleteItem";
import { Actions } from "../../../internals/app/Actions";
import { cloneDeep, filter, isEmpty, findIndex } from "lodash";

const PurchaseStockView = (props: any) => {
  const {
    shop,
    alertStatus,
    stockRecords,
    openAlertBox,
    selectedstock,
    selectedScreen,
    setSelectedScreen,
    deleteStockRecord,
    createStockRecord,
    updateStockRecord,
  } = props;
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    if (stockRecords) {
      const update =
        findIndex(
          stockRecords,
          (record: any) => record?.id === selectedstock?.id
        ) > -1;
      setIsUpdate(update);
    }
  }, [stockRecords, selectedstock]);

  const onCreateStock = () => {
    const newStock = cloneDeep(selectedstock);
    const validList = filter(
      selectedstock.stockRecords,
      (item) =>
        item.qty &&
        item.unitPrice > 0 &&
        item.totalPrice > 0 &&
        !isEmpty(item.itemData)
    );
    newStock.stockRecords = validList;
    if (isUpdate) {
      updateStockRecord(newStock);
    } else {
      createStockRecord(newStock);
    }
  };

  const toggleDelete = () => {
    setIsOpenDelete(!isOpenDelete);
  };

  const onDeleteStockRecord = () => {
    deleteStockRecord({ id: selectedstock.id, shopId: shop.shopId });
    toggleDelete();
    router.navigate("/stock");
  };

  const renderHeaderTab = () => {
    return (
      <div className="flex flex-row gap-6 border-b-2 min-w-[690px]">
        {headerTabs.map((tab: any) => {
          const isSelected = selectedScreen === tab.screen;
          return (
            <div
              onClick={() => setSelectedScreen(tab.screen)}
              className={`${
                isSelected
                  ? "text-midnight-express border-b-2 border-medium-slate-blue"
                  : "text-echo-Blue"
              } + cursor-pointer text-md font-semibold `}
            >
              {tab.label}
            </div>
          );
        })}
      </div>
    );
  };

  const renderBottomScreen = () => {
    switch (selectedScreen) {
      case "SUPPLIER": {
        return (
          <>
            <SupplierHeader />
            <StockTable />
          </>
        );
      }
    }
  };

  return (
    <div className="flex flex-col min-w-full p-6 justify-start">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="flex flex-row justify-between gap-4 pb-6 overflow-x-auto">
        {renderHeaderTab()}
        <div className="flex flex-row justify-end gap-4 min-w-[250px]">
          {isUpdate && (
            <button
              type="button"
              onClick={() => toggleDelete()}
              className="flex justify-center rounded-md border border-transparent bg-deactivate py-2 px-4 text-sm font-medium text-white shadow hover:bg-deactivate-focus"
            >
              {"Delete Stock"}
            </button>
          )}
          <button
            type="button"
            onClick={() => onCreateStock()}
            className="flex justify-center rounded-md border border-transparent bg-medium-slate-blue py-2 px-4 text-sm font-medium text-white shadow hover:bg-slate-blue"
          >
            {"Save Stock"}
          </button>
        </div>
      </div>
      {renderBottomScreen()}
      <DeleteItem
        isOpen={isOpenDelete}
        closeModal={() => toggleDelete()}
        onDelete={() => onDeleteStockRecord()}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    shop: state.dashboard.get("selectedShop"),
    alertStatus: state.common.get("alertStatus"),
    stockRecords: state.stock.get("stockRecords"),
    selectedScreen: state.stock.get("selectedScreen"),
    selectedstock: state.stock.get("selectedstockRecord"),
  }),
  {
    fetchStocks: Actions.stock.fetchStocks,
    openAlertBox: Actions.common.openAlertBox,
    setStockRecord: Actions.stock.setStockRecord,
    setSelectedScreen: Actions.stock.setSelectedScreen,
    deleteStockRecord: Actions.stock.deleteStockRecord,
    createStockRecord: Actions.stock.createStockRecord,
    updateStockRecord: Actions.stock.updateStockRecord,
    fetchStockRecords: Actions.stock.fetchStockRecords,
  }
)(PurchaseStockView);
