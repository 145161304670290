import { useEffect, useState } from "react";
import { find } from "lodash";
import { connect } from "react-redux";
import Alert from "../../../components/Alert";
import Table from "../../../components/Table";
import Edit from "../../../assets/svgs/edit.svg";
import Delete from "../../../assets/svgs/delete.svg";
import DeleteItem from "../../../components/DeleteItem";
import { Actions } from "../../../internals/app/Actions";
import AddCustomer from "../../../components/AddCustomer";
import CustomerHeader from "../../../components/CustomerHeader";

const tableHeader = [
  "CLIENT NAME",
  "CLIENT EMAIL",
  "PHONE NUMBER",
  "APPOINTMENTS	",
  "ACTIONS",
];

const CustomerView = (props: any) => {
  const { alertStatus, openAlertBox, clientList, deleteClient } = props;
  const [customer, setCustomer] = useState(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    if (clientList.clients) {
      const tableData = getData(clientList.clients);
      setTableData(tableData);
    }
  }, [clientList]);

  const getData = (data: any) => {
    return data.map((item: any) => {
      return {
        id: item.id,
        APPOINTMENTS: "",
        ACTIONS: [Edit, Delete],
        "CLIENT EMAIL": item.email,
        "CLIENT NAME": `${item.firstName} ${item.lastName}`,
        "PHONE NUMBER": item.mobileNumber.length > 7 ? item.mobileNumber : "",
      };
    });
  };

  const onClickItem = (data: any, index: number) => {
    const client = find(clientList.clients, (item) => item.id === data.id);
    setCustomer(client);
    if (index === 0) {
      toggleCustomer();
    } else if (index === 1) {
      toggleDelete();
    }
  };

  const toggleDelete = () => {
    setIsOpenDelete(!isOpenDelete);
  };

  const toggleCustomer = () => {
    setIsOpen(!isOpen);
  };

  const onDeleteClient = (data: any) => {
    deleteClient(data.id, data.shopId);
    toggleDelete();
  };

  return (
    <div className="flex flex-col w-full p-6 justify-start">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="pb-6">
        <CustomerHeader
          openCustomer={() => {
            setCustomer(null);
            toggleCustomer();
          }}
        />
      </div>
      <Table {...{ tableHeader, tableData }} onClickItem={onClickItem} />
      <AddCustomer
        isOpen={isOpen}
        customer={customer}
        closeModal={() => toggleCustomer()}
      />
      <DeleteItem
        isOpen={isOpenDelete}
        closeModal={() => toggleDelete()}
        onDelete={() => onDeleteClient(customer)}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    alertStatus: state.common.get("alertStatus"),
    clientList: state.customer.get("clientList"),
  }),
  {
    openAlertBox: Actions.common.openAlertBox,
    deleteClient: Actions.customer.deleteClient,
  }
)(CustomerView);
