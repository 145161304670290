import Modal from "react-modal";
import { router } from "../../App";
import { connect } from "react-redux";
import { find, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Actions } from "../../internals/app/Actions";
import success from "../../assets/svgs/subscription/success.svg";
import unsuccess from "../../assets/svgs/subscription/unsuccess.svg";

const customStyles: any = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    height: "auto",
    padding: 0,
    overflow: "auto",
    maxWidth: "374px",
    maxHeight: "349px",
    background: "#fff",
    borderRadius: "8px",
    position: "absolute",
    top: "calc(50vh - 20rem)",
    left: "calc(50vw - 170px)",
    WebkitOverflowScrolling: "touch",
    boxShadow: "0 4px 8px 1px rgb(0 0 0 / 0.8)",
  },
};

type CategoryProps = {
  modalStatus: string;
  onCloseModal: (status: string) => void;
};

const modalComponents = [
  {
    image: success,
    type: "SUCCESS",
    title: "Successful",
    button: "Manage Location",
    desc: "Your payment is successful",
  },
  {
    type: "FAILED",
    image: unsuccess,
    button: "Try Again",
    title: "Your payment failed",
    desc: "Something went to wrong!",
  },
  {
    type: "ERROR",
    image: unsuccess,
    button: "Try Again",
    title: "Unexpected result",
    desc: "Something went to wrong!",
  },
];

const AlertModal = (props: CategoryProps) => {
  const { modalStatus, onCloseModal } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<any>({});

  useEffect(() => {
    const findData = find(modalComponents, (item) => item.type === modalStatus);
    setData(findData);
    setIsOpen(!isEmpty(modalStatus));
  }, [modalStatus]);

  const closeModal = () => {
    onCloseModal("");
  };

  const onClickButton = () => {
    if (modalStatus === "SUCCESS") {
      router.navigate("/subscription-plans");
    } else {
      router.navigate("/subscription");
    }
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Category Modal"
      onRequestClose={() => closeModal()}
    >
      <div className="flex flex-col w-full h-full p-6 selection:overflow-hidden shadow rounded-lg items-center justify-center">
        <img src={data.image} alt="altimage" />
        <p className="mt-7 text-lg font-bold text-paua">{data.title}</p>
        <p className="mt-2 text-xs text-waikawa-grey">{data.desc}</p>
        <button
          type="button"
          onClick={() => onClickButton()}
          className="mt-7 w-[175px] text-white bg-medium-slate-blue hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5  focus:outline-none"
        >
          {data.button}
        </button>
      </div>
    </Modal>
  );
};

export default connect(
  (state: any) => ({
    modalStatus: state.setting.get("modalStatus"),
  }),
  {
    setSelectedPlan: Actions.setting.setSelectedPlan,
    onCloseModal: Actions.setting.openRecurringStatusModal,
  }
)(AlertModal);
