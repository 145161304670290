import { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../Button";
import Modal from "react-modal";
import { nanoid } from "nanoid";
import { isEmpty } from "lodash";
import "../../layouts/Styles.css";
import { connect } from "react-redux";
import { CgClose } from "react-icons/cg";
import FormInput from "../inputs/FormInput";
import Delete from "../../assets/svgs/delete.svg";
import { Formik, useFormikContext } from "formik";
import { Actions } from "../../internals/app/Actions";

const customStyles: any = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    padding: 0,
    height: "auto",
    border: "none",
    overflow: "auto",
    maxWidth: "624px",
    background: "none",
    marginLeft: "20px",
    marginRight: "20px",
    left: "calc(50vw - 312px)",
    WebkitOverflowScrolling: "touch",
  },
};

type ServiceProps = {
  category?: any;
  isOpen: boolean;
  isEdit?: boolean;
  currentUser: any;
  selectedShop: any;
  selectedService: any;
  selectedCategory: any;
  closeModal: () => void;
  createCategory: (params: any) => void;
  updateCategory: (params: any, status: string) => void;
};

const AddService = (props: ServiceProps) => {
  const {
    isOpen,
    category,
    closeModal,
    updateCategory,
    selectedService,
    selectedCategory,
  } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    setIsEdit(!isEmpty(selectedService));
  }, [selectedService]);

  const onSaveService = (values: any) => {
    const index = category.categories.findIndex(
      (category: any) => category.id === selectedCategory.id
    );
    const filterServices = category.categories[index].services;
    const data = {
      duration: values.duration,
      serviceTitle: values.service,
      price: parseFloat(values.price),
      id: isEdit ? selectedService.id : nanoid(),
    };

    if (isEmpty(selectedCategory.services)) {
      category.categories[index]["services"] = [data];
      updateCategory(category, "CREATE");
    } else {
      const childIndex = selectedCategory.services.findIndex(
        (service: any) => service.id === selectedService?.id
      );
      if (childIndex > -1) {
        filterServices[childIndex] = data;
        category.categories[index]["services"] = filterServices;
        updateCategory(category, "UPDATE");
      } else {
        category.categories[index]["services"] = [...filterServices, data];
        updateCategory(category, "CREATE");
      }
    }
    closeModal();
  };

  const validationSchema = Yup.object({
    category: Yup.string()
      .min(2, "Must be 2 characters or more")
      .required("Please enter category title"),
    service: Yup.string()
      .min(2, "Must be 2 characters or more")
      .required("Please enter service title"),
    price: Yup.number()
      .typeError("Please enter a valid price")
      .positive("Item price must be more than 0")
      .required("Please enter item price"),
    duration: Yup.string().required("Please enter service duration"),
  });

  const initialValues = {
    price: "",
    service: "",
    category: "",
    duration: "",
  };

  const onDeleteService = () => {
    const index = category.categories.findIndex(
      (category: any) => category.id === selectedCategory.id
    );
    const services = category.categories[index].services;
    const filterServices = services.filter(
      (service: any) => service.id !== selectedService.id
    );
    category.categories[index].services = filterServices;
    updateCategory(category, "DELETE");
    setIsOpenDelete(false);
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Category Modal"
      onRequestClose={() => closeModal()}
    >
      <div className=" bg-white shadow rounded-lg">
        <div className="flex flex-row h-14 justify-between items-center bg-gray-50 px-4 py-3 text-right text-xl font-semibold sm:px-6 rounded-t-lg">
          <h3>{isEdit ? "Edit Service" : "Add Service"}</h3>
          <button
            type="button"
            className="top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
            onClick={() => closeModal()}
          >
            <CgClose size={20} />
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => onSaveService(values)}
        >
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="h-auto bg-white px-4 py-5 sm:p-6 rounded-lg">
                  <RenderInput
                    category={selectedCategory}
                    service={selectedService}
                  />
                </div>
                <div className="flex h-16 flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
                  {isEdit ? (
                    <button
                      type="button"
                      onClick={() => setIsOpenDelete(true)}
                      className="inline-flex outline-none justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-red-700"
                    >
                      {"Delete Service"}
                    </button>
                  ) : (
                    <div />
                  )}
                  <Button
                    type="submit"
                    label={isEdit ? "Update Service" : "Add Service"}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
        {isOpenDelete ? (
          <div className="absolute inset-14 w-full max-w-lg z-50 justify-center items-center">
            <div className="relative h-full md:h-auto">
              <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                <button
                  type="button"
                  onClick={() => setIsOpenDelete(false)}
                  className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <CgClose size={20} />
                  <span className="sr-only">Close modal</span>
                </button>
                <img
                  className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                  src={Delete}
                  alt="actions"
                />

                <p className="mb-4 text-gray-500 dark:text-gray-300">
                  Are you sure you want to delete this discount?
                </p>
                <div className="flex justify-center items-center space-x-4">
                  <button
                    type="button"
                    onClick={() => setIsOpenDelete(false)}
                    className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:outline-none hover:text-gray-900"
                  >
                    No, cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => onDeleteService()}
                    className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none  "
                  >
                    Yes, I'm sure
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default connect(
  (state: any) => ({
    category: state.service.get("category"),
    currentUser: state.setting.get("currentUser"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    createCategory: Actions.service.createCategory,
    updateCategory: Actions.service.updateCategory,
  }
)(AddService);

const RenderInput = ({ category, service }: any) => {
  const { values, errors, touched, handleChange, setFieldValue } =
    useFormikContext<{
      price: string;
      service: string;
      category: string;
      duration: string;
    }>();

  useEffect(() => {
    if (!isEmpty(category)) {
      setFieldValue("category", category.category);
    }
    if (!isEmpty(service)) {
      setFieldValue("service", service.serviceTitle);
      setFieldValue("price", `${service.price}`);
      setFieldValue("duration", service.duration);
    }
  }, [category, service]);

  return (
    <div className="grid grid-cols-6 gap-6">
      <div className="col-span-6">
        <FormInput
          id="category"
          label="Selected Category"
          error={errors.category}
          value={values.category}
          touched={touched.category}
          onChange={handleChange("category")}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <FormInput
          id="service"
          label="Service Title"
          error={errors.service}
          value={values.service}
          touched={touched.service}
          onChange={handleChange("service")}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <FormInput
          type="time"
          id="duration"
          label="Duration"
          error={errors.duration}
          value={values.duration}
          touched={touched.duration}
          onChange={handleChange("duration")}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <FormInput
          id="price"
          label="Price"
          error={errors.price}
          value={values.price}
          touched={touched.price}
          onChange={handleChange("price")}
        />
      </div>
    </div>
  );
};
