import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import millify from "millify";
import "../../layouts/Styles.css";
import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";
import { capitalize, isEmpty } from "lodash";
import { tableColors } from "../../internals/managers/ProductManager";
import zoomPlugin from "chartjs-plugin-zoom";

ChartJS.register(
  zoomPlugin,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarCharProduct = (props: any) => {
  const { productReportSummary, products } = props;
  const [tableLabels, setTableLabels] = useState<Array<string>>([]);
  const [productDetails, setProductDetails] = useState<any>([]);

  const getAllReportsSummary = (summary: any) => {
    let data: any = [];
    let label: any = [];
    let color: any = [];

    Object.keys(summary).map((key: any, index: number) => {
      data.push(summary[key]?.qty);
      label.push(capitalize(data[key]?.name));
      color.push(tableColors[index % 15]);
    });
    return { label, data, backgroundColor: color };
  };

  useEffect(() => {
    const newLabel: any = [];
    if (!isEmpty(productReportSummary)) {
      const data = productReportSummary[0]?.summary;
      Object.keys(data).map((key: any) => {
        newLabel.push(capitalize(data[key].name));
      });
      const details = getAllReportsSummary(productReportSummary[0]?.summary);
      setProductDetails(details);
    } else {
      products?.products?.map((product: any) => {
        newLabel.push(capitalize(product.productName));
      });
      setProductDetails({ data: null });
    }
    setTableLabels([...newLabel]);
  }, [productReportSummary, products]);

  const options: any = {
    legend: {
      display: false,
    },
    responsive: true,
    barPercentage: 1,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 30,
          color: "#9BACCB",
          crossAlign: "start",
          callback: (v: any) => `${millify(v).toLowerCase()}`,
        },
      },
      x: {
        ticks: {
          minRotation: 90,
          maxRotation: 90,
          color: "#343951",
          textAlign: "start",
        },
        grid: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "x",
          threshold: 5,
        },
      },
    },
  };

  const chartData = {
    labels: tableLabels,
    datasets: [
      {
        precision: 0,
        barThickness: 15,
        data: productDetails?.data,
        backgroundColor: productDetails?.backgroundColor,
      },
    ],
  };

  return (
    <div className="flex flex-row items-start gap-6 mb-4">
      <Bar height={"140"} options={options as any} data={chartData as any} />
    </div>
  );
};

export default connect(
  (state: any) => ({
    products: state.product.get("products"),
    productReportSummary: state.report.get("productReportSummary"),
  }),
  () => ({})
)(BarCharProduct);
