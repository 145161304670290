import { v4 as uuid } from "uuid";
import _, { filter, isEmpty, compact, values, sortBy, isEqual } from "lodash";

export const mapCategory = (categories) => {
  const result = categories.map((category) => ({
    label: category.categoryName,
    value: category.categoryId,
  }));
  return result;
};

export const mapBrand = (brands) => {
  const result = brands.map((brand) => ({
    label: brand.brandName,
    value: brand.id,
  }));
  return result;
};

export const mapEmployee = (employees) => {
  const result = employees.map((employee) => ({
    label: `${employee.firstName} ${employee.lastName}`,
    value: employee.empId,
  }));
  return result;
};

export const mapWarranty = (warranties) => {
  const result = warranties.map((warranty) => ({
    label: warranty.title,
    value: warranty.wrid,
  }));
  return result;
};

export const productOptions = [
  { label: "Per Item", value: "PER_ITEM" },
  { label: "Per Hour", value: "PER_HOUR" },
  { label: "Per Liter", value: "PER_LITER" },
  { label: "Per Meter", value: "PER_METER" },
  { label: "Per Milliliter", value: "PER_MILILITER" },
];

export const rewardOptions = [
  { label: "Discount on the Appointment", value: "APPOINTMENT" },
];

export const mapProductOptions = (
  productOptionsList,
  optionUnit,
  optionPrice,
  productName
) => {
  let productList = [];
  if (productOptionsList.length > 0) {
    productOptionsList.forEach((option) => {
      if (option.price && option.unit && option.name) {
        productList.push({
          id: option.id,
          optionName: option.name,
          optionUnit: option.unit,
          optionPrice: option.price,
        });
      }
    });
  } else {
    productList.push({
      optionName: productName,
      optionUnit: _.get(optionUnit, "0.name", ""),
      optionPrice: optionPrice,
      id: uuid(),
    });
  }

  return productList;
};

export const mapEditProductOptions = (optionSet) => {

  optionSet.map((option) => {

    option.options.map((item) => {
      return {

      }
    })
    const productOptionSet = {
      id: option.id,
      shopId: option.shopId,
      setName: option.setName,
      setDisplayName: option.setDisplayName,
      options: [],
    }

  });

}


const getMonthName = (monthName) => {
  const splitMonth = monthName.split("_");
  return splitMonth[1];
};

export const getAllReportsSummary = (reportList) => {
  const netDataList = [];
  const productDataList = [];
  const list = reportList.map((report) => {
    const summary = _.get(report, 'summary', []);
    const month = report.reportMonth;
    const monthName = getMonthName(month).substring(0, 3).toUpperCase();
    const totalProductPrice = _.sumBy(summary, 'totalProductPrice');
    const totalPrice = _.sumBy(summary, 'totalPrice');
    return {
      label: monthName,
      totalPrice,
      totalProductPrice
    };
  });

  monthLabels.forEach((label) => {
    const findData = _.find(list, item => item.label === label);
    const net = findData?.totalPrice > 0 ? findData.totalPrice : 0;
    const product = findData?.totalProductPrice > 0 ? findData.totalProductPrice : 0;
    netDataList.push(net);
    productDataList.push(product);
  });

  return { netDataList, productDataList }
};

export const monthLabels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];


export const getMonthSummaryReport = (reports) => {
  let orderCount = 0;
  let revenue = 0;
  const summary = _.get(reports, '0.summary', []);
  if (summary?.length > 0) {
    orderCount = _.sumBy(summary, 'total');
    revenue = _.sumBy(summary, 'totalPrice');
  }

  return { orderCount, revenue };
};

export const mapTableOptions = (areas, qrs) => {
  const result = areas.tables.map((table) => {
    const filterQr = filter(
      qrs,
      ({ tblId }) => tblId === table.tbId
    );
    if (isEmpty(filterQr)) {
      return {
        label: table.tbNumber,
        value: table.tbId,
      }
    }
    return null
  });
  return compact(result);
};


export const tableColors = [
  "#7dcea0",
  "#935116",
  "#21618c",
  "#b3b6b7",
  "#cb4335",
  "#f4d03f",
  "#fae5d3",
  "#f8c471",
  "#616a6b",
  "#a93226",
  "#2980b9",
  "#17a589",
  "#7fb3d5",
  "#5d6d7e",
  "#f4d03f",
  "#bfc9ca",
  "#82e0aa",
  "#dc7633",
  "#d35400",
];

export const getUserColor = (length = 0) => {
  return tableColors[length + 1 % 20];
};

export const getProductOptionSets = async (data) => {
  const productOptions = data.map((option) => {
    const options = option.options.map((item) => {
      return {
        id: item.id,
        optionName: item.optionName,
      };
    });
    option.options = options;
    return option;
  });
  return productOptions;
};

export const mapProductOffers = (products) => {
  const result = products.map((product) => {
    return {
      label: product.productName,
      value: product.pid,
    }
  });
  return compact(result);
}