import { useState } from "react";
import { connect } from "react-redux";
import { GoSearch } from "react-icons/go";
import { AiOutlinePlus } from "react-icons/ai";
import { CircularProgress } from "@material-ui/core";
import { Actions } from "../../internals/app/Actions";

const CustomerHeader = (props: any) => {
  const {
    openCustomer,
    loadingAction,
    selectedShop,
    fetchClients,
    searchClient,
  } = props;
  const { action, loading } = loadingAction;
  const [searchText, setSearchText] = useState<string>("");

  const loadingSearch = loading && action.type === "SEARCH_CLIENT";

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      onSearchClients(event.target.value);
    }
  };

  const onSearchClients = (text: string) => {
    if (text && text.length > 0) {
      searchClient(selectedShop.shopId, text);
    }
  };

  const onChangeText = (text: string) => {
    setSearchText(text);
    if (text.length === 0) {
      fetchClients(selectedShop.shopId);
    }
  };

  return (
    <div className="flex w-full justify-between">
      <div
        className="flex max-[768px]:flex-col gap-4 flex-row w-full justify-between rounded-md"
        role="group"
      >
        <div className="flex max-w-lg	items-end">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <GoSearch size={20} />
            </div>
            <input
              required
              type="text"
              id="simple-search"
              onKeyDown={handleKeyDown}
              placeholder="Search here.."
              onChange={(event) => onChangeText(event.target.value)}
              className="h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-2.5 focus:outline-none"
            />
          </div>
          <button
            onClick={() => onSearchClients(searchText)}
            className="flex items-center justify-center h-[38px] w-[47px] ml-4 text-sm font-medium text-white bg-medium-slate-blue rounded-md border border-medium-slate-blue  focus:outline-none"
          >
            {loadingSearch ? (
              <CircularProgress size={15} style={{ color: "#fff" }} />
            ) : (
              <GoSearch size={20} />
            )}
          </button>
        </div>
        <button
          type="button"
          onClick={() => openCustomer()}
          className="inline-flex gap-2 items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-md hover:bg-gray-100 hover:text-medium-slate-blue"
        >
          <AiOutlinePlus size={20} />
          Add Customer
        </button>
      </div>
    </div>
  );
};
export default connect(
  (state: any) => ({
    clientList: state.customer.get("clientList"),
    loadingAction: state.common.get("loadingAction"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    fetchClients: Actions.customer.fetchClients,
    searchClient: Actions.customer.searchClient,
  }
)(CustomerHeader);
