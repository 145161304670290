import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { find, isEmpty } from "lodash";
import Alert from "../../../components/Alert";
import Table from "../../../components/Table";
import Edit from "../../../assets/svgs/edit.svg";
import AddOffer from "../../../components/AddOffer";
import Delete from "../../../assets/svgs/delete.svg";
import DeleteItem from "../../../components/DeleteItem";
import { Actions } from "../../../internals/app/Actions";
import ScreenHeader from "../../../components/ScreenHeader";
import { formatPrice } from "../../../internals/managers/PaymentManager";

const tableHeader = [
  "OFFER TITLE",
  "PRODUCT",
  "TIME PERIODS",
  "OFFER",
  "ACTIONS",
];

const OfferView = (props: any) => {
  const {
    alertStatus,
    openAlertBox,
    offer,
    selectedShop,
    fetchOffers,
    deleteOffer,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [selectedOffer, setSelectedOffer] = useState<any>(null);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    if (selectedShop) {
      fetchOffers(selectedShop.shopId);
    }
  }, []);

  useEffect(() => {
    if (offer?.offers) {
      const tableData = getData(offer.offers);
      setTableData(tableData);
    }
  }, [offer]);

  const getData = (data: any) => {
    return data.map((item: any) => {
      let period = "";
      const product = item.offerItems.map((item: any) => item.name);
      if (item.isEveryDay) {
        period = `Every Day ${item.timePeriod.startTime} - ${item.timePeriod.endTime}`;
      } else {
        if (!isEmpty(item.timePeriods)) {
          period = `${item.timePeriods[0].date} ${
            item.timePeriods[0].startTime
          } - ${item.timePeriods[0].endTime} ${
            item.timePeriods.length > 1 ? "..." : ""
          }`;
        }
      }

      const offerItem = item.offerItems.map((item: any) => {
        if (item.type === "DISCOUNT") {
          if (item.discount.amountType === "FIXED_AMOUNT") {
            return formatPrice(item.discount.amount);
          } else {
            return `${item.discount.amount}%`;
          }
        } else {
          if (item?.products) {
            return item?.products.map((data: any) => data.name);
          }
        }
      });
      return {
        id: item.id,
        "TIME PERIODS": period,
        "OFFER TITLE": item.title,
        PRODUCT: product.join(" / "),
        OFFER: offerItem.join(" / "),
        ACTIONS: [Edit, Delete],
      };
    });
  };

  const onClickItem = (data: any, index: number) => {
    const value = find(offer.offers, ({ id }) => id === data.id);
    setSelectedOffer(value);
    if (index === 0) {
      toggleOffer();
    } else if (index === 1) {
      toggleDelete();
    }
  };

  const toggleDelete = () => {
    setIsOpenDelete(!isOpenDelete);
  };

  const toggleOffer = () => {
    setIsOpen(!isOpen);
  };

  const onDelete = (data: any) => {
    deleteOffer(data.shopId, data.id);
    toggleDelete();
  };

  return (
    <div className="flex flex-col w-full p-6 justify-start">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="pb-6">
        <ScreenHeader
          addTitle={"Add Offer"}
          openModal={() => {
            setSelectedOffer(null);
            toggleOffer();
          }}
        />
      </div>
      <Table {...{ tableHeader, tableData }} onClickItem={onClickItem} />
      <AddOffer
        isOpen={isOpen}
        offer={selectedOffer}
        closeModal={() => toggleOffer()}
      />
      <DeleteItem
        isOpen={isOpenDelete}
        closeModal={() => toggleDelete()}
        onDelete={() => onDelete(selectedOffer)}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    offer: state.offer.get("offer"),
    categories: state.product.get("categories"),
    alertStatus: state.common.get("alertStatus"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    fetchOffers: Actions.offer.fetchOffers,
    deleteOffer: Actions.offer.deleteOffer,
    openAlertBox: Actions.common.openAlertBox,
  }
)(OfferView);
