import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import millify from "millify";
import "../../layouts/Styles.css";
import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";
import {
  monthLabels,
  getAllReportsSummary,
} from "../../internals/managers/ProductManager";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = (props: any) => {
  const { monthsReports } = props;
  const options: any = {
    legend: {
      display: false,
    },
    responsive: true,
    barPercentage: 1,
    scales: {
      y: {
        ticks: {
          crossAlign: "start",
          callback: (v: any) => `${millify(v).toLowerCase()}`,
          fontSize: "12px",
          color: "#9BACCB",
          fontFamily: "Inter",
          fontWeight: 400,
        },
      },
      x: {
        ticks: {
          fontSize: "16px",
          color: "#343951",
          fontFamily: "Inter",
          fontWeight: 400,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
  };
  const mapReports = getAllReportsSummary(monthsReports);

  const data = {
    labels: monthLabels,
    datasets: [
      {
        label: "Net Total",
        data: mapReports.netDataList,
        backgroundColor: "#6D71F9",
      },
      {
        label: "Products",
        data: mapReports.productDataList,
        backgroundColor: "#54C1FB",
      },
    ],
  };

  return (
    <div className="flex flex-row items-start gap-6 mb-4">
      <Bar height={"100px"} options={options} data={data} />
    </div>
  );
};
export default connect(
  (state: any) => ({
    monthsReports: state.report.get("monthsReports"),
  }),
  () => ({})
)(BarChart);
