import Swal from "sweetalert2";
import { router } from "../../App";
import { ModuleEvents } from "./Actions";
import { LOGIN_ERROR } from "./Constants";
import { delay, get, isNull } from "lodash";
import { Actions } from "../../internals/app/Actions";
import withReactContent from "sweetalert2-react-content";

const Handler = {
  [ModuleEvents.LOGIN]: ({ dispatch, payload, appState }) => {
    const userId = get(payload, "signInUserSession.idToken.payload[custom:id]");

    if (payload && !payload.error && userId) {
      dispatch(Actions.setting.getCurrentUser(userId));
    } else if (
      get(payload, "error.code") === "UserNotFoundException" ||
      get(payload, "error.code") === "NotAuthorizedException"
    ) {
      return warning(payload.error.message);
    } else if (get(payload, "error.code") === LOGIN_ERROR.USER_NOT_CONFIRMED) {
      dispatch(Actions.login.resendAuthCode(payload.username))
      // return warning(payload.error.message);
    } else if (
      get(payload, "error.code") === "PasswordResetRequiredException"
    ) {
      return warning("Password reset required for the user");
    } else {
      return warning("Catched error when user login!");
    }
  },

  [ModuleEvents.AUTHENTICATED_USER]: ({ dispatch, payload, appState, pathname }) => {
    if (payload && !payload.error) {
      const userId = get(
        payload,
        "signInUserSession.idToken.payload.[custom:id]",
        null
      );
      if (userId) {
        dispatch(Actions.setting.getCurrentUser(userId));
        return;
      }
    }
    if (pathname !== "/signup") {
      router.navigate("/signin");
    }
  },

  [ModuleEvents.SIGNUP_USER]: ({ dispatch, payload, appState }) => {
    if (payload) {
      router.navigate("/business-manage");
    }
  },

  [ModuleEvents.RESEND_CODE]: ({ dispatch, payload, appState }) => {
    router.navigate("/otp-screen");
  },

  [ModuleEvents.SIGNUP]: ({ dispatch, payload, appState }) => {
    const { result, phone, userResult } = payload;
    if (userResult && userResult.UserStatus === "CONFIRMED") {
      return warning("User aleady exist. Please signin using this email");
    } else if (result && !isNull(get(result, "user.username", null))) {
      dispatch(Actions.login.navigateOtpView({ type: "", error: "" }));
    } else if (result && result.error) {
      if (result.error.code === LOGIN_ERROR.USER_NAME_EXISTS) {
        router.navigate("/signin");
        return warning("User aleady exist. Please signin using this email");
      } else {
        return warning("Error occurred while sending verification code!");
      }
    } else {
      return warning("Error occurred while sending verification code!");
    }
  },

  [ModuleEvents.NAVIGATE_OTP_VIEW]: ({ dispatch, payload, appState }) => {
    router.navigate("/otp-screen");
  },

  [ModuleEvents.CONFIRM_SIGN_UP]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      const email = appState.login.get("email");
      const password = appState.login.get("password");
      dispatch(Actions.login.login(email, password));
    } else if (payload.error) {
      if (payload.error.code === "CodeMismatchException") {
        dispatch(
          Actions.login.navigateOtpView({
            type: "",
            error: "CodeMismatchException",
          })
        );
      } else if (payload.error.code === "InvalidParameterException") {
        return warning("Please enter the correct number!");
      } else if (payload.error.code === "LimitExceededException") {
        return warning(payload.error.message);
      } else if (payload.error.code === "NotAuthorizedException") {
        router.navigate("/signin");
        return warning(
          "User already created. Please login using this username!"
        );
      }
    }
  },
  [ModuleEvents.LOG_OUT]: ({ dispatch, payload, appState }) => {
    router.navigate("/signin");
  },
  [ModuleEvents.FORGET_PASSWORD]: ({ dispatch, payload, appState }) => {
    if (payload && payload.code === "LimitExceededException") {
      dispatch(
        Actions.common.openAlertBox("WARNING", true, "", payload.message)
      );
      delay(function (text) {
        dispatch(
          Actions.common.openAlertBox("WARNING", false, "", payload.message)
        );
      }, 3000);
    } else if (
      payload &&
      payload.data &&
      payload.data.code === "UserNotFoundException"
    ) {
      dispatch(
        Actions.common.openAlertBox(
          "WARNING",
          true,
          "",
          "User not found, please enter correct email."
        )
      );
      delay(function (text) {
        dispatch(
          Actions.common.openAlertBox(
            "WARNING",
            false,
            "",
            "User not found, please enter correct email."
          )
        );
      }, 3000);
    }
  },
  [ModuleEvents.FORGET_PASSWORD_SUBMIT]: ({ dispatch, payload, appState }) => {
    const { data, new_password } = payload;
    if (data && !data.error) {
      const email = appState.login.get("email");
      dispatch(Actions.login.login(email, new_password));
    } else if (payload && payload.code === "LimitExceededException") {
      dispatch(
        Actions.common.openAlertBox("WARNING", true, "", payload.message)
      );
      delay(function (text) {
        dispatch(
          Actions.common.openAlertBox("WARNING", false, "", payload.message)
        );
      }, 3000);
    }
  },
};

const warning = (alert) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    icon: "error",
    text: alert,
    title: "Oops...",
    showCancelButton: true,
    showConfirmButton: false,
    cancelButtonColor: "#FF3333",
  });
};

export default Handler;