import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import IosIcon from '../../../assets/svgs/ios-icon.svg';
import {Actions} from '../../../internals/app/Actions';
import WindowsIcon from '../../../assets/svgs/windows-icon.svg';
import {useSearchParams} from 'react-router-dom';
import Tooltip from 'react-power-tooltip';

const DownloadView = (props: any) => {
  const {updateUser, currentUser} = props;
  const [searchParams] = useSearchParams();
  const [showGuide, setGuide] = useState<boolean>(false);

  const onPressDownload = async () => {
    setGuide(false);
    if (!currentUser?.didDownload) {
      updateUser({
        uid: currentUser.uid,
        didDownload: true,
      });
    }
  };

  useEffect(() => {
    const guide = searchParams.get('guide');
    if (guide) {
      setGuide(true);
    }
  }, [searchParams]);

  return (
    <div className="flex flex-col w-full p-6 justify-start">
      <h3 className={`text-valhalla cursor-pointer text-md font-semibold mb-8`}>
        {'Download Software Platform'}
      </h3>
      <div className="flex flex-row gap-6">
        <div className="flex flex-col text-center items-center gap-5 relative	w-[200px]">
          <div className="flex items-center justify-center border border-genteel-lavender rounded-lg	w-full h-[165px] bg-[#F8FBFF]">
            <img
              alt="windows"
              className="w-[120px] h-[120px]"
              src={WindowsIcon}
            />
          </div>
          <a
            onClick={onPressDownload}
            href="https://dvmpvm7pyvujs.cloudfront.net/public/Netlise-POS-2.0.1.exe"
            target="_blank"
            className={`text-valhalla underline cursor-pointer text-sm font-normal `}
          >
            {'Download Netlise Cloud POS Windows 64x'}
          </a>
          {/* @ts-ignore */}
          <Tooltip textBoxWidth="200px"
            show={showGuide}
            backgroundColor="#6D71F9"
          >
            <p className="w-[200px] text-sm font-normal text-white">
              Download your Netlise app and process orders
            </p>
          </Tooltip>
        </div>
        <div className="flex flex-col text-center items-center gap-5	w-[200px]">
          <div className="flex border items-center justify-center border-genteel-lavender rounded-lg	w-full h-[165px] bg-[#F8FBFF]">
            <img alt="windows" className="w-[120px] h-[120px]" src={IosIcon} />
          </div>
          <a
            onClick={onPressDownload}
            target="_blank"
            href="https://apps.apple.com/us/app/netlise-handpad/id1673360746"
            className={`text-valhalla underline cursor-pointer text-sm font-normal `}
          >
            {'Download HandPad iPad App'}
          </a>
        </div>
        <div className="flex flex-col text-center items-center gap-5	w-[200px]">
          <div className="flex border items-center justify-center border-genteel-lavender rounded-lg	w-full h-[165px] bg-[#F8FBFF]">
            <img
              alt="windows"
              className="w-[80px] h-[80px]"
              src={'/images/apple-logo.png'}
            />
          </div>
          <a
            onClick={onPressDownload}
            target="_blank"
            href="https://dvmpvm7pyvujs.cloudfront.net/public/Netlise-POS-2.0.1.dmg"
            className={`text-valhalla underline cursor-pointer text-sm font-normal `}
          >
            {'Download Netlise Cloud POS MacOS 86x'}
          </a>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state: any) => ({
    alertStatus: state.common.get('alertStatus'),
    currentUser: state.setting.get('currentUser'),
  }),
  {
    updateUser: Actions.setting.updateUser,
  }
)(DownloadView);
