import { get } from "lodash";
import {
  CreateWarranty,
  DeleteWarranty,
  UpdateWarranty,
  FetchWarranties,
} from "./schema/Warranty.schema";
import Repository from "./Repository";
import { graphqlOperation } from "@aws-amplify/api-graphql";

class WarrantyRepository extends Repository {
  async fetchWarranties(shopId: string, limit: number, nextToken: any) {
    try {
      const params = {
        shopId,
        limit,
        nextToken,
      };
      const result: any = await this.API.graphql(
        graphqlOperation(FetchWarranties, params)
      );
      return get(result, "data.fetchWarranties", {});
    } catch (error) {
      console.warn("fetch warranties error", error);
      return { error };
    }
  }

  createWarranty = async (params: any) => {
    try {
      const result = await this.API.graphql(
        graphqlOperation(CreateWarranty, { input: params })
      );
      return get(result, "data.createWarranty", []);
    } catch (error) {
      console.warn("error", error);
      console.warn("create warranty error", error);
      return { error };
    }
  };

  updateWarranty = async (params: any) => {
    try {
      const result = await this.API.graphql(
        graphqlOperation(UpdateWarranty, { input: params })
      );
      return get(result, "data.updateWarranty", []);
    } catch (error) {
      console.warn("update warranty error", error);
      return { error };
    }
  };

  deleteWarranty = async (params: any) => {
    try {
      const result = await this.API.graphql(
        graphqlOperation(DeleteWarranty, { input: params })
      );
      return get(result, "data.deleteWarranty", []);
    } catch (error) {
      console.warn("delete warranty error", error);
      return { error };
    }
  };
}

export default new WarrantyRepository();
