import Repository from "./Repository";
import { isNetworkError } from "../managers/SecurityManager";
import { get } from "lodash";

class StockRepository extends Repository {
  async fetchStocks(shopId, retryCount = 1) {
    try {
      const result = await this.apiGet({
        apiName: this.API_NAME,
        path: `/stocks?shopId=${shopId}`,
      });
      return result.stockResult;
    } catch (error) {
      console.warn("fetch stock error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchStocks(shopId, ++retryCount);
      }
      return { error };
    }
  }
  async createStock(param, retryCount = 1) {
    try {
      const message = this.buildMessage(param);
      const result = await this.apiPost({
        apiName: this.API_NAME,
        path: `/stock`,
        message,
      });
      return get(result, 'data.stockResult', {});
    } catch (error) {
      console.warn("create stock error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.createStock(param, ++retryCount);
      }
      return { error };
    }
  }
  async deleteStock(param, retryCount = 1) {
    try {
      const message = this.buildMessage(param);
      const result = await this.apiDelete({
        apiName: this.API_NAME,
        path: `/stock`,
        message,
      });
      return result.stockResult;
    } catch (error) {
      console.warn("delete product menu error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.deleteStock(param, ++retryCount);
      }
      return { error };
    }
  }


  async createStockRecord(param, retryCount = 1) {
    try {
      const message = this.buildMessage(param);
      const result = await this.apiPost({
        apiName: this.API_NAME,
        path: `/stock-record`,
        message,
      });
      return result.data;
    } catch (error) {
      console.warn("create stock record error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.createStockRecord(param, ++retryCount);
      }
      return { error };
    }
  }

  async updateStockRecord(param, retryCount = 1) {
    try {
      const message = this.buildMessage(param);
      const result = await this.apiPut({
        apiName: this.API_NAME,
        path: `/stock-record`,
        message,
      });
      return result.data;
    } catch (error) {
      console.warn("update stock record error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.updateStockRecord(param, ++retryCount);
      }
      return { error };
    }
  }

  async fetchStockRecords(shopId, retryCount = 1) {
    try {
      const result = await this.apiGet({
        apiName: this.API_NAME,
        path: `/stock-records?shopId=${shopId}`,
      });
      return result.stockResult;
    } catch (error) {
      console.warn("fetch stock records error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchStockRecords(shopId, ++retryCount);
      }
      return { error };
    }
  }

  async deleteStockRecord(param, retryCount = 1) {
    try {
      const message = this.buildMessage(param);
      const result = await this.apiDelete({
        apiName: this.API_NAME,
        path: `/stock-record`,
        message,
      });
      return result.data;
    } catch (error) {
      console.warn("delete stock record error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.deleteStockRecord(param, ++retryCount);
      }
      return { error };
    }
  }
}
export default new StockRepository();
