import TableRepository from "../../internals/repository/TableRepository";

export const ModuleEvents = {
  FETCH_TABLES: "FETCH_TABLES",
  FETCH_QR_TABLES: 'FETCH_QR_TABLES',
  CREATE_QR_FOR_TABLE: "CREATE_QR_FOR_TABLE",
};

const createAction =
  (type, action = () => {}, meta) =>
  (...args) => ({
    type,
    payload: action(...args),
    meta,
  });

const fetchTables = createAction(
  ModuleEvents.FETCH_TABLES,
  async (shopId) => {
    const result = await TableRepository.fetchTables(shopId);
    return result;
  },
  { loading: true }
);

const fetchQRTables = createAction(
  ModuleEvents.FETCH_QR_TABLES,
  async (shopId) => {
    const result = await TableRepository.fetchQRTables(shopId);
    return result;
  },
  { loading: true }
);

const createQRForTable = createAction(
  ModuleEvents.CREATE_QR_FOR_TABLE,
  async (params) => {
    const result = await TableRepository.createQRForTable(params);
    return result;
  },
  { loading: true }
);

const exportedFuction = {
  fetchTables,
  fetchQRTables,
  createQRForTable,
};

export default exportedFuction;
