import { get } from "lodash";
import Repository from "./Repository";

class TransactionRepository extends Repository {
  async fetchOrderTransactions(shopId, limit, lastKey) {
    const message = this.buildMessage({ shopId, limit, lastKey });
    try {
      const result = await this.apiPost({
        apiName: this.API_ADMIN_DASHBOARD,
        path: "/orders/completed",
        message,
      });
      return result.data.orderResult;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async searchOrderTransactions(
    shopId,
    searchText,
    endDate,
    startDate,
    limit,
    lastKey
  ) {
    const message = this.buildMessage({
      shopId,
      searchText,
      endDate,
      startDate,
      limit,
      lastKey,
    });
    try {
      const result = await this.apiPost({
        apiName: this.API_ADMIN_DASHBOARD,
        path: "/search/completed-orders",
        message,
      });
      return result.data.orderResult;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async fetchCloseCashForDateRange(params, limit, lastKey) {
    const message = this.buildMessage({ ...params, limit, lastKey });
    try {
      const result = await this.apiPost({
        apiName: this.API_ADMIN_DASHBOARD,
        path: "/close-cash/date",
        message,
      });
      return get(result, 'data.summaryList', {})
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }
}

export default new TransactionRepository();
