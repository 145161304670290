import { useState } from "react";
import { connect } from "react-redux";
import { GoSearch } from "react-icons/go";
import { AiOutlinePlus } from "react-icons/ai";
import { CircularProgress } from "@material-ui/core";
import { filter, includes, lowerCase } from "lodash";
import { Actions } from "../../internals/app/Actions";

const ServiceHeader = (props: any) => {
  const {
    category,
    openCategory,
    selectedShop,
    loadingAction,
    fetchCategories,
    searchCategories,
  } = props;
  const { action, loading } = loadingAction;
  const [searchText, setSearchText] = useState<string>("");
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      onSearchCategories(event.target.value);
    }
  };

  const onChangeText = (text: string) => {
    setSearchText(text);
    if (text.length === 0) {
      fetchCategories(selectedShop.shopId);
    }
  };

  const onSearchCategories = (text: string) => {
    if (text && text.length > 0) {
      setLoadingData(true);
      const filterCategories = filter(category.categories, (item: any) =>
        includes(lowerCase(item.category), lowerCase(text))
      );
      category.categories = filterCategories;
      searchCategories(category);
      setLoadingData(false);
    }
  };
  const loadingSearch =
    (loading && action.type === "FETCH_SERVICE_CATEGORIES") || loadingData;

  return (
    <div className="flex w-full justify-between">
      <div
        className="flex max-[768px]:flex-col gap-4 flex-row w-full justify-between rounded-md"
        role="group"
      >
        <div className="flex max-w-lg	items-end">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <GoSearch size={20} />
            </div>
            <input
              required
              type="text"
              id="simple-search"
              onKeyDown={handleKeyDown}
              placeholder="Search here.."
              onChange={(event) => onChangeText(event.target.value)}
              className="h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-2.5 focus:outline-none"
            />
          </div>
          <button
            onClick={() => onSearchCategories(searchText)}
            className="flex items-center justify-center h-[38px] w-[47px] ml-4 text-sm font-medium text-white bg-medium-slate-blue rounded-md border border-medium-slate-blue  focus:outline-none"
          >
            {loadingSearch ? (
              <CircularProgress size={15} style={{ color: "#fff" }} />
            ) : (
              <GoSearch size={20} />
            )}
          </button>
        </div>

        <button
          type="button"
          onClick={() => openCategory()}
          className="flex w-[165px] gap-2 items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-md hover:bg-gray-100 hover:text-medium-slate-blue"
        >
          <AiOutlinePlus size={20} />
          Add Category
        </button>
      </div>
    </div>
  );
};
export default connect(
  (state: any) => ({
    category: state.service.get("category"),
    loadingAction: state.common.get("loadingAction"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    fetchCategories: Actions.service.fetchCategories,
    searchCategories: Actions.service.searchCategories,
  }
)(ServiceHeader);
