export const AppModules = [
  "user",
  "stock",
  "menu",
  "table",
  "offer",
  "login",
  "common",
  "report",
  "setting",
  "product",
  "service",
  "warranty",
  "modifier",
  "customer",
  "employee",
  "discount",
  "dashboard",
  "transaction",
];

export default AppModules;
