import Swal from "sweetalert2";
import { delay } from "lodash";
import { get, isEmpty, findIndex } from 'lodash';
import { ModuleEvents } from "./Actions";
import { Actions } from "../../internals/app/Actions";
import withReactContent from "sweetalert2-react-content";

export default {
  [ModuleEvents.CREATE_CATEGORY]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      dispatch(Actions.common.openAlertBox("CREATE", true, "SUCCESS"));
      delay(function (text) {
        dispatch(Actions.common.openAlertBox("CREATE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning("Error occurred while creating the product category");
    }
  },

  [ModuleEvents.CREATE_PRODUCT]: async ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      const modifiers = appState.modifier.get("modifiers");
      const currentUser = appState.setting.get("currentUser");
      const selectedShop = appState.dashboard.get("selectedShop");
      dispatch(Actions.product.fetchProducts(selectedShop.shopId));
      if (!isEmpty(get(payload, "productOptions", null))) {
        dispatch(Actions.product.createProductOptions(payload.productOptions));
      }
      if (!isEmpty(get(payload, "modifiers", null))) {
        await Promise.all(
          payload.modifiers.map(async (modifier) => {
            const index = findIndex(modifiers,
              (item) => item.id === modifier.id
            )
            const params = {
              ...modifier,
              createdTime: Date.now(),
              updatedTime: Date.now(),
              shopId: selectedShop.shopId,
              createdUser: currentUser.uid,
            }
            if (index === -1) {
              dispatch(Actions.modifier.createModifier(params));
            }
            // if (index > -1) {
            //   params.createdTime = modifiers[index].createdTime,
            //     dispatch(Actions.modifier.updateModifier(params));
            // } else {
            //   dispatch(Actions.modifier.createModifier(params));
            // }
          })
        );
      }
      dispatch(Actions.common.openAlertBox("CREATE", true, "SUCCESS"));
      delay(function (text) {
        dispatch(Actions.common.openAlertBox("CREATE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning("Error occurred while creating the product");
    }
  },

  [ModuleEvents.UPDATE_PRODUCT]: async ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      const modifiers = appState.modifier.get("modifiers");
      const currentUser = appState.setting.get("currentUser");
      const selectedShop = appState.dashboard.get("selectedShop");
      dispatch(Actions.product.fetchProducts(selectedShop.shopId));
      if (!isEmpty(get(payload, "productOptions", null))) {
        dispatch(Actions.product.createProductOptions(payload.productOptions));
      }
      if (!isEmpty(get(payload, "modifiers", null))) {
        await Promise.all(
          payload.modifiers.map(async (modifier) => {
            const index = findIndex(modifiers,
              (item) => item.id === modifier.id
            )
            const params = {
              ...modifier,
              createdTime: Date.now(),
              updatedTime: Date.now(),
              shopId: selectedShop.shopId,
              createdUser: currentUser.uid,
            }
            if (index === -1) {
              dispatch(Actions.modifier.createModifier(params));
            }
            // if (index > -1) {
            //   params.createdTime = modifiers[index].createdTime,
            //     dispatch(Actions.modifier.updateModifier(params));
            // } else {
            //   dispatch(Actions.modifier.createModifier(params));
            // }
          })
        );
      }
      dispatch(Actions.common.openAlertBox("UPDATE", true, "SUCCESS"));
      delay(function (text) {
        dispatch(Actions.common.openAlertBox("UPDATE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning("Error occurred while updating the product");
    }
  },

  [ModuleEvents.UPDATE_CATEGORY]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      const selectedShop = appState.dashboard.get("selectedShop");
      dispatch(Actions.product.fetchCategories(selectedShop.shopId));
      dispatch(Actions.common.openAlertBox("UPDATE", true, "SUCCESS"));
      delay(function (text) {
        dispatch(Actions.common.openAlertBox("UPDATE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning("Error occurred while updating the category");
    }
  },

  [ModuleEvents.DELETE_PRODUCT]: ({ dispatch, payload, appState }) => {
    if (payload === "SUCCESS") {
      const selectedShop = appState.dashboard.get("selectedShop");
      dispatch(Actions.product.fetchProducts(selectedShop.shopId));
      dispatch(Actions.common.openAlertBox("DELETE", true, "SUCCESS"));
      delay(function (text) {
        dispatch(Actions.common.openAlertBox("DELETE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning("Product deleted unsuccessfully");
    }
  },

  [ModuleEvents.DELETE_CATEGORY]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      const selectedShop = appState.dashboard.get("selectedShop");
      dispatch(Actions.product.fetchCategories(selectedShop.shopId));
      dispatch(Actions.common.openAlertBox("DELETE", true, "SUCCESS"));
      delay(function (text) {
        dispatch(Actions.common.openAlertBox("DELETE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning("Error occurred while deleting the product category");
    }
  },

  [ModuleEvents.CREATE_BRAND]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      dispatch(Actions.common.openAlertBox("CREATE", true, "SUCCESS"));
      delay(function (text) {
        dispatch(Actions.common.openAlertBox("CREATE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning("Error occurred while creating the product brand");
    }
  },
  [ModuleEvents.UPDATE_BRAND]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      dispatch(Actions.common.openAlertBox("UPDATE", true, "SUCCESS"));
      delay(function (text) {
        dispatch(Actions.common.openAlertBox("UPDATE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning("Error occurred while updating the brand");
    }
  },
  [ModuleEvents.DELETE_BRAND]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      dispatch(Actions.common.openAlertBox("DELETE", true, "SUCCESS"));
      delay(function (text) {
        dispatch(Actions.common.openAlertBox("DELETE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning("Error occurred while deleting the product brand");
    }
  },
};

const warning = (alert) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    icon: "error",
    text: alert,
    title: "Oops...",
    showCancelButton: true,
    showConfirmButton: false,
    cancelButtonColor: "#FF3333",
  });
};
