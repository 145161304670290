const ReportHeaderTabs = (props: any) => {
  const { onChange, selectedTab } = props;
  const reportTypes = [
    {
      title: "Sales Summary",
      value: "SALES_SUMMARY",
    },
    {
      title: "Revenue",
      value: "REVENUE",
    },
    {
      title: "Product Report",
      value: "PRODUCT",
    },
  ];

  return (
    <div className="flex flex-row w-full justify-between mb-[24px]">
      <div className="flex flex-row flex-wrap gap-8 rounded-md" role="group">
        {
          reportTypes.map((type: any) => {
            const isSelected = selectedTab  === type.value;
            return(
              <div
              onClick={() => onChange(type.value)}
              className={`text-[18px] font-inter font-semibold cursor-pointer ${
                isSelected
                  ? "text-liberty-blue"
                  : "text-echo-blue"
              }`}
            >
              {type.title}
            </div>
            )
          })
        }
      </div>
    </div>
  );
};
export default ReportHeaderTabs;
