import gql from 'graphql-tag';

export const GetTableAreas = gql`
  query GetTableAreas($shopId: String!) {
    getTableAreas(shopId: $shopId) {
      shopId
      tableAreas {
        areaName
        areaId
        tables {
          tbId
          tbNumber
          tbType
          tbShape
          x
          y
          h
          w
        }
      }
    }
  }
`;

export const createQrTable = gql`
  mutation CreateQrTable($input: CreateQrTableInput!) {
    createQrTable(input: $input) {
      qrId
      tblNo
      tblId
      shopId
      createdTime
      updatedTime
    }
  }
`;