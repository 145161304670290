import { connect } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";

type ProductProps = {
  title: string;
  closeModal: () => void;
};

const CreateModalHeader = (props: ProductProps) => {
  const { title, closeModal } = props;

  return (
    <div className="flex flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
      <h3 className="text-lg text-speech-blue font-semibold">{title}</h3>
      <button
        type="button"
        onClick={() => closeModal()}
        className="top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-1.5 ml-auto inline-flex items-center"
      >
        <AiOutlineClose size={20} />
        <span className="sr-only">Close modal</span>
      </button>
    </div>
  );
};
export default connect((state: any) => ({}), {})(CreateModalHeader);
