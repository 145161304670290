import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Alert from "../../../components/Alert";
import Table from "../../../components/Table";
import Edit from "../../../assets/svgs/edit.svg";
import AddBrand from "../../../components/AddBrand";
import Delete from "../../../assets/svgs/delete.svg";
import { find, get, isEmpty, orderBy } from "lodash";
import DeleteItem from "../../../components/DeleteItem";
import { Actions } from "../../../internals/app/Actions";
import BrandHeader from "../../../components/BrandHeader";

const BrandView = (props: any) => {
  const { alertStatus, openAlertBox, brands, deleteBrand } = props;

  const [tableData, setTableData] = useState<Array<any>>([]);
  const [selectedBrand, setSelectedBrand] = useState<any>({});
  const [tableHeader, setTableHeader] = useState<Array<any>>([]);
  const [isOpenBrand, setIsOpenBrand] = useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    if (brands) {
      const headers = getHeader();
      const tableData = getData(brands);
      setTableHeader(headers);
      setTableData(tableData);
    }
  }, [brands]);

  const getHeader = () => {
    return ["BRAND NAME", "DESCRIPTION", "TOTAL PRODUCT", "ACTIONS"];
  };

  const getData = (data: any) => {
    const tableData = data.map((item: any) => {
      return {
        id: item.id,
        TIME: item.updatedTime,
        "BRAND NAME": item.brandName,
        DESCRIPTION: !isEmpty(item.description) ? item.description : "---",
        "TOTAL PRODUCT": get(item, "totalProductCount", 0),
        ACTIONS: [Edit, Delete],
      };
    });
    return orderBy(tableData, ["TIME"], ["desc"]);
  };

  const toggleBrand = () => {
    setIsOpenBrand(!isOpenBrand);
  };

  const toggleDelete = () => {
    setIsOpenDelete(!isOpenDelete);
  };

  const onClickItem = (data: any, index: number) => {
    const brand = find(brands, ({ id }) => id === data.id);
    setSelectedBrand(brand);
    if (index === 0) {
      toggleBrand();
    } else if (index === 1) {
      toggleDelete();
    }
  };

  const onDeleteBrand = (data: any) => {
    deleteBrand(data.shopId, data.id);
    toggleDelete();
  };

  return (
    <div className="flex flex-col w-full p-6 justify-start">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="pb-6">
        <BrandHeader />
      </div>
      <Table
        {...{ tableHeader, tableData }}
        onClickItem={(data: any, index: number) => onClickItem(data, index)}
      />
      <AddBrand
        isOpen={isOpenBrand}
        brand={selectedBrand}
        closeModal={() => toggleBrand()}
      />
      <DeleteItem
        isOpen={isOpenDelete}
        closeModal={() => toggleDelete()}
        onDelete={() => onDeleteBrand(selectedBrand)}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    brands: state.product.get("brands"),
    alertStatus: state.common.get("alertStatus"),
  }),
  {
    deleteBrand: Actions.product.deleteBrand,
    openAlertBox: Actions.common.openAlertBox,
  }
)(BrandView);
