import { useEffect, useState } from "react";
import * as Yup from "yup";
import Modal from "react-modal";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import FormInput from "../inputs/FormInput";
import { AiOutlineClose } from "react-icons/ai";
import Delete from "../../assets/svgs/delete.svg";
import CustomSelect from "../inputs/CustomSelect";
import CreateModalHeader from "../CreateModalHeader";
import { Actions } from "../../internals/app/Actions";
import { Field, Formik, useFormikContext } from "formik";
import { BsCurrencyDollar, BsPercent } from "react-icons/bs";
import { rewardOptions } from "../../internals/managers/ProductManager";

const customStyles: any = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    padding: 0,
    height: "auto",
    border: "none",
    overflow: "auto",
    maxWidth: "624px",
    background: "none",
    marginLeft: "20px",
    marginRight: "20px",
    left: "calc(50vw - 292px)",
    WebkitOverflowScrolling: "touch",
  },
};

type ProductProps = {
  reward: any;
  isOpen: boolean;
  isEdit?: boolean;
  currentUser: any;
  selectedShop: any;
  loadingAction: any;
  closeModal: () => void;
  updateReward: (params: any) => void;
  createReward: (params: any) => void;
  deleteReward: (productId: string, shopId: string) => void;
};

const AddReward = (props: ProductProps) => {
  const {
    reward,
    isOpen,
    closeModal,
    currentUser,
    selectedShop,
    updateReward,
    createReward,
    deleteReward,
    loadingAction,
  } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    setIsEdit(!isEmpty(reward));
  }, [isOpen]);

  const formData = (values: any) => {
    return {
      type: values.type,
      totalStars: values.totalStars,
      amountType: values.amountType,
      minimumSpend: values.minimumSpend,
      amount: parseFloat(values.amount),
      maximumAmount: values.maximumAmount,
    };
  };

  const onCreateReward = (values: any) => {
    const shopId = selectedShop.shopId;
    const formValues = formData(values);
    const param = {
      shopId,
      ...formValues,
      createdTime: Date.now(),
      updatedTime: Date.now(),
      createdUserId: currentUser.uid,
    };
    // createReward(param);
    closeModal();
  };

  const onUpdateReward = (values: any) => {
    const formValues = formData(values);
    const param = {
      ...formValues,
      updatedTime: Date.now(),
    };
    // updateReward(param);
    closeModal();
  };

  const validationSchema = Yup.object({
    totalStars: Yup.number()
      .typeError("Please enter a valid stars")
      .positive("stars must be more than 0")
      .required("Please enter total stars for a reward"),
    amount: Yup.number()
      .typeError("Please enter a valid reward amount")
      .positive("Reward amount must be more than 0")
      .required("Please enter reward amount"),
    minimumSpend: Yup.number()
      .typeError("Please enter a valid amount")
      .positive("Amount must be more than 0")
      .required("Please enter Amount"),
    maximumAmount: Yup.number()
      .typeError("Please enter a valid amount")
      .positive("Amount must be more than 0")
      .required("Please enter Amount"),
    type: Yup.string().required("Please select your reward type"),
  });

  const initialValues = {
    type: "",
    amount: "",
    totalStars: "",
    minimumSpend: "",
    maximumAmount: "",
    amountType: "PERCENTAGE",
  };

  const onProductDelete = () => {
    // deleteReward();
    setIsOpenDelete(false);
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Reward Modal"
      onRequestClose={() => closeModal()}
    >
      <div className="bg-white shadow rounded-lg">
        <CreateModalHeader
          title={isEdit ? "Edit Reward" : "Add Reward"}
          closeModal={() => closeModal()}
        />
        <Formik
          onSubmit={(values) => {
            if (!isEdit) {
              onCreateReward(values);
            } else {
              onUpdateReward(values);
            }
          }}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="overflow-auto bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <RenderInput reward={reward} typeList={rewardOptions} />
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
                  {isEdit ? (
                    <button
                      type="button"
                      onClick={() => setIsOpenDelete(true)}
                      className="inline-flex outline-none justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-red-700"
                    >
                      {"Delete Reward"}
                    </button>
                  ) : (
                    <div />
                  )}
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-indigo-700"
                  >
                    {isEdit ? "Update Reward" : "Add Reward"}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
        {isOpenDelete ? (
          <div className="absolute top-1/4 inset-14 w-full max-w-lg z-50 justify-center items-center">
            <div className="relative h-full md:h-auto">
              <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                <button
                  type="button"
                  onClick={() => setIsOpenDelete(false)}
                  className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <AiOutlineClose size={20} />
                  <span className="sr-only">Close modal</span>
                </button>
                <img
                  className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                  src={Delete}
                  alt="actions"
                />

                <p className="mb-4 text-gray-500 dark:text-gray-300">
                  Are you sure you want to delete this item?
                </p>
                <div className="flex justify-center items-center space-x-4">
                  <button
                    type="button"
                    onClick={() => setIsOpenDelete(false)}
                    className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:outline-none hover:text-gray-900"
                  >
                    No, cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => onProductDelete()}
                    className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none  "
                  >
                    Yes, I'm sure
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};
export default connect(
  (state: any) => ({
    currentUser: state.setting.get("currentUser"),
    loadingAction: state.common.get("loadingAction"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    createReward: Actions.product.createReward,
    updateReward: Actions.product.updateReward,
    deleteReward: Actions.product.deleteReward,
  }
)(AddReward);

const RenderInput = ({ reward, typeList }: any) => {
  const { values, errors, touched, handleChange, setFieldValue } =
    useFormikContext<{
      type: string;
      amount: string;
      amountType: string;
      totalStars: string;
      minimumSpend: string;
      maximumAmount: string;
    }>();

  useEffect(() => {
    if (!isEmpty(reward)) {
      // setFieldValue("brand", product.brandId);
      // setFieldValue("category", product.categoryId);
      // setFieldValue("itemPrice", product.itemPrice);
      // setFieldValue("productName", product.productName);
      // setFieldValue("description", product.description);
    }
  }, [reward]);

  return (
    <>
      <div className="col-span-6 sm:col-span-3">
        <div className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 ">
          <h5 className="mb-2 text-md text-center font-bold tracking-tight text-valhalla">
            By Visits
          </h5>
          <p className="font-normal text-center text-sm text-valhalla">
            Reward users for number of visits to your location
          </p>
        </div>
      </div>
      <div className="col-span-6 sm:col-span-3">
        <div className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 ">
          <h5 className="mb-2 text-md text-center font-bold tracking-tight text-valhalla">
            By Amount Spent
          </h5>
          <p className="font-normal text-center text-sm text-valhalla">
            Reward users for amount they spent at your location
          </p>
        </div>
      </div>
      <div className="col-span-6 sm:col-span-3">
        <FormInput
          id="minimumSpend"
          label="Minimum spend per order"
          error={errors.minimumSpend}
          value={values.minimumSpend}
          touched={touched.minimumSpend}
          onChange={handleChange("minimumSpend")}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <FormInput
          id="totalStars"
          label="Total Stars for a Reward"
          error={errors.totalStars}
          value={values.totalStars}
          touched={touched.totalStars}
          onChange={handleChange("totalStars")}
        />
      </div>
      <div className="col-span-6">
        <label
          htmlFor={"amountType"}
          className="block text-md font-medium text-gray-700"
        >
          Customize Reward
        </label>
      </div>
      <div className="col-span-6 sm:col-span-3">
        <Field
          id="type"
          name="type"
          isMulti={false}
          options={typeList}
          label="Reward Type"
          error={errors.type}
          touched={touched.type}
          component={CustomSelect}
          placeholder="Reward Type"
        />
      </div>
      <div className="col-span-6 sm:col-span-3" />
      <div className="flex flex-row gap-4 col-span-6 sm:col-span-3">
        <FormInput
          id="amount"
          label="Discount Amount"
          error={errors.amount}
          value={values.amount}
          touched={touched.amount}
          onChange={handleChange("amount")}
        />
        <div className="flex flex-row justify-between items-center sm:w-16 w-20 mt-7">
          <BsPercent
            size={16}
            color={values.amountType === "PERCENTAGE" ? "#6D71F9" : "black"}
            onClick={() => setFieldValue("amountType", "PERCENTAGE")}
          />
          <div className="w-0.5 h-9 bg-gray-300" />
          <BsCurrencyDollar
            size={16}
            color={values.amountType === "FIXED_AMOUNT" ? "#6D71F9" : "black"}
            onClick={() => setFieldValue("amountType", "FIXED_AMOUNT")}
          />
        </div>
      </div>
      <div className="col-span-6 sm:col-span-3">
        <FormInput
          id="maximumAmount"
          error={errors.maximumAmount}
          value={values.maximumAmount}
          touched={touched.maximumAmount}
          label="Maximum Discount Amount"
          onChange={handleChange("maximumAmount")}
        />
      </div>
    </>
  );
};
