import { useEffect, useState } from "react";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Actions } from "../../../internals/app/Actions";
import { Formik, Field, useFormikContext } from "formik";
import FormInput from "../../../components/inputs/FormInput";
import CustomSelect from "../../../components/inputs/CustomSelect";
import { formatPrice } from "../../../internals/managers/PaymentManager";

const options: any = [];

const SupplierHeader = (props: any) => {
  const { shop, selectedstock } = props;
  const [total, setTotal] = useState<number>(0);

  const Schema = Yup.object({
    supplier: Yup.string()
      .min(2, "Must be 2 characters or more")
      .required("First name is required"),
  });

  const initialValues = {
    date: "",
    supplier: "",
    invoiceId: "",
    orderType: "",
  };

  useEffect(() => {
    if (selectedstock) {
      const totalValue = selectedstock.stockRecords
        .map((record: any) => record.totalPrice)
        .reduce((prev: any, curr: any) => prev + curr, 0);
      setTotal(totalValue);
    }
  }, [selectedstock]);

  const renderCharges = (title: string, total: number) => {
    const totalPrice = formatPrice(total, shop.currency);
    return (
      <div className="flex w-full items-center justify-between">
        <div className="text-sm	font-normal text-ship-cove">{title}</div>
        <div className="text-sm	font-normal text-ship-cove">{totalPrice}</div>
      </div>
    );
  };

  return (
    <div className="flex flex-row justify-between gap-4 pb-6">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={(values) => {
          
        }}
      >
        {({ handleSubmit }) => {
          return (
            <form
              className="w-[calc(100%-280px)] bg-dismiss rounded-md"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-row z-20 gap-4 justify-between items-center p-4 overflow-x-auto bg-dismiss rounded-md">
                <RenderComponent />
                <button
                  type="submit"
                  className="inline-flex min-w-[130px] mt-6 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-indigo-700"
                >
                  {"Add Supplier"}
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
      <div className="flex flex-col gap-2 justify-start items-center p-4 w-[280px] bg-dismiss rounded-md">
        {renderCharges("Sub Total", total)}
        {renderCharges("Charges", 0)}
        <div className="flex w-full items-center justify-between">
          <div className="text-sm	font-bold text-paua">{"Total"}</div>
          <div className="text-sm	font-bold text-paua">
            {formatPrice(total, shop.currency)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state: any) => ({
    shop: state.dashboard.get("selectedShop"),
    selectedstock: state.stock.get("selectedstockRecord"),
  }),
  {}
)(SupplierHeader);

const RenderComponent = ({ user }: any) => {
  const { values, errors, touched, handleChange, setFieldValue } =
    useFormikContext<{
      date: string;
      supplier: string;
      invoiceId: string;
      orderType: string;
    }>();

  return (
    <div className="flex flex-row gap-4">
      <div className="min-w-[190px]">
        <FormInput
          id="supplier"
          label="Search Supplier"
          value={values.supplier}
          error={errors.supplier}
          touched={touched.supplier}
          placeholder="Search Supplier"
          onChange={handleChange("supplier")}
        />
      </div>
      <div className="min-w-[140px]">
        <FormInput
          id="date"
          label="Date"
          value={values.date}
          error={errors.date}
          placeholder="Date"
          touched={touched.date}
          onChange={handleChange("date")}
        />
      </div>
      <div className="min-w-[140px]">
        <FormInput
          id="Invoice ID"
          label="Invoice ID"
          value={values.invoiceId}
          error={errors.invoiceId}
          touched={touched.invoiceId}
          placeholder="Invoice ID"
          onChange={handleChange("invoiceId")}
        />
      </div>
      <div className="min-w-[160px]">
        <Field
          isMulti={false}
          id="orderType"
          options={options}
          name="orderType"
          label="Order Type"
          error={errors.orderType}
          component={CustomSelect}
          touched={touched.orderType}
          placeholder="Order Type"
        />
      </div>
    </div>
  );
};
