import UserRepository from "../../internals/repository/UserRepository";
import ShopRepository from "../../internals/repository/ShopRepository";
import PaymentRepository from "../../internals/repository/PaymentRepository";

export const ModuleEvents = {
  UPDATE_USER: "UPDATE_USER",
  FETCH_TRIAL: "FETCH_TRIAL",
  CREATE_TRIAL: "CREATE_TRIAL",
  FETCH_PACKAGES: "FETCH_PACKAGES",
  CHANGE_PAYMENT: "CHANGE_PAYMENT",
  CREATE_CUSTOMER: "CREATE_CUSTOMER",
  GET_CURRENT_USER: "GET_CURRENT_USER",
  SET_SELECTED_PLAN: "SET_SELECTED_PLAN",
  UPLOAD_USER_IMAGE: "UPLOAD_USER_IMAGE",
  UPLOAD_SHOP_IMAGE: "UPLOAD_SHOP_IMAGE",
  UPDATE_CHECK_STATUS: "UPDATE_CHECK_STATUS",
  FETCH_SHOP_PAYMENT: "FETCH_SHOP_PAYMENT",
  CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
  FETCH_SUBSCRIPTIONS: "FETCH_SUBSCRIPTIONS",
  SET_SELECTED_LOCATION: "SET_SELECTED_LOCATION",
  UPDATE_DOWNGRADE_PACKAGE: "UPDATE_DOWNGRADE_PACKAGE",
  CREATE_HOSTED_SUBSCRIPTION: "CREATE_HOSTED_SUBSCRIPTION",
  FETCH_ACTIVE_SUBSCRIPTIONS: "FETCH_ACTIVE_SUBSCRIPTIONS",
  OPEN_RECURRING_STATUS_MODAL: "OPEN_RECURRING_STATUS_MODAL",
  CHECK_SUCCESS_RECURRING_PAYMENT: "CHECK_SUCCESS_RECURRING_PAYMENT",
};

const createAction =
  (type, action = () => { }, meta) =>
    (...args) => ({
      type,
      payload: action(...args),
      meta,
    });

const getCurrentUser = createAction(
  ModuleEvents.GET_CURRENT_USER,
  async (userId) => {
    const result = await UserRepository.getUserForId(userId);
    return result;
  }
);

const setSelectedPlan = createAction(
  ModuleEvents.SET_SELECTED_PLAN,
  async (plan) => {
    return plan;
  }
);

const setSelectedLocation = createAction(
  ModuleEvents.SET_SELECTED_LOCATION,
  async (location) => {
    return location;
  }
);

const createCustomer = createAction(
  ModuleEvents.CREATE_CUSTOMER,
  async (params) => {
    const result = await PaymentRepository.createCustomer(params);
    return result;
  }
);

const createHostedSubscription = createAction(
  ModuleEvents.CREATE_HOSTED_SUBSCRIPTION,
  async (params) => {
    const result = await PaymentRepository.createHostedSubscription(params);
    return result;
  }
);

const changePayment = createAction(
  ModuleEvents.CHANGE_PAYMENT,
  async (params) => {
    const result = await PaymentRepository.changePayment(params);
    return result;
  }
);

const updateDowngradePackage = createAction(
  ModuleEvents.UPDATE_DOWNGRADE_PACKAGE,
  async (params) => {
    const result = await PaymentRepository.updateDowngradePackage(params);
    return result;
  }
);

const cancelSubscription = createAction(
  ModuleEvents.CANCEL_SUBSCRIPTION,
  async (params) => {
    const result = await PaymentRepository.cancelSubscription(params);
    return result;
  }
);

const fetchActiveSubscriptions = createAction(
  ModuleEvents.FETCH_ACTIVE_SUBSCRIPTIONS,
  async (userId) => {
    const result = await PaymentRepository.fetchActiveSubscriptions(userId);
    return result;
  }
);

const fetchSubscriptions = createAction(
  ModuleEvents.FETCH_SUBSCRIPTIONS,
  async (userId, limit = 30, lastKey = null) => {
    const result = await PaymentRepository.fetchSubscriptions({
      userId,
      limit,
      lastKey,
    });
    return result;
  }
);

const fetchShopPayments = createAction(
  ModuleEvents.FETCH_SHOP_PAYMENT,
  async (shopId) => {
    const result = await PaymentRepository.fetchShopPayments(shopId);
    return result?.items;
  }
);

const checkSuccessRecurringPayment = createAction(
  ModuleEvents.CHECK_SUCCESS_RECURRING_PAYMENT,
  async (refId, isUpdate) => {
    return { refId, isUpdate };
  }
);

const openRecurringStatusModal = createAction(
  ModuleEvents.OPEN_RECURRING_STATUS_MODAL,
  (status) => {
    return status;
  }
);

const updateCheckStatus = createAction(
  ModuleEvents.UPDATE_CHECK_STATUS,
  async (params) => {
    const result = await PaymentRepository.updateRecurringCheckStatus(params);
    return result;
  }
);

const uploadShopImage = createAction(
  ModuleEvents.UPLOAD_SHOP_IMAGE,
  async (file, shopId, type) => {
    const { key } = await ShopRepository.uploadToS3Image(file, type === 'menuImage' ? 'netlise-web-menu' : null);
    const param = {
      shopId,
    };
    param[type] = key;
    const result = await ShopRepository.updateShop(param);
    return result;
  },
  { loading: true }
);

const removeShopImage = createAction(
  ModuleEvents.UPLOAD_SHOP_IMAGE,
  async (shopId, type) => {
    const param = {
      shopId,
    };
    param[type] = "";
    const result = await ShopRepository.updateShop(param);
    return result;
  },
  { loading: true }
);

const uploadUserImage = createAction(
  ModuleEvents.UPLOAD_USER_IMAGE,
  async (file, uid) => {
    const { key } = await ShopRepository.uploadToS3Image(file);
    const param = {
      uid,
      image: key,
    };
    const result = await UserRepository.updateUser(param);
    return result;
  },
  { loading: true }
);

const removeUserImage = createAction(
  ModuleEvents.UPLOAD_USER_IMAGE,
  async (uid) => {
    const param = {
      uid,
      image: "",
    };
    const result = await UserRepository.updateUser(param);
    return result;
  },
  { loading: true }
);

const updateUser = createAction(
  ModuleEvents.UPDATE_USER,
  async (param) => {
    const result = await UserRepository.updateUser(param);
    return result;
  },
);

const fetchPackages = createAction(
  ModuleEvents.FETCH_PACKAGES,
  async () => {
    const result = await PaymentRepository.fetchPackages();
    return result;
  },
);

const fetchTrial = createAction(
  ModuleEvents.FETCH_TRIAL,
  async (shopId) => {
    const result = await PaymentRepository.fetchTrial(shopId);
    return result;
  }, { loading: true }
);

const createTrial = createAction(
  ModuleEvents.CREATE_TRIAL,
  async ({ shopId, createdBy }) => {
    const result = await PaymentRepository.createTrial({ shopId, createdBy });
    return result;
  },
);

const exportedFuction = {
  updateUser,
  changePayment,
  getCurrentUser,
  uploadShopImage,
  removeUserImage,
  uploadUserImage,
  createCustomer,
  setSelectedPlan,
  removeShopImage,
  updateCheckStatus,
  fetchShopPayments,
  fetchSubscriptions,
  cancelSubscription,
  setSelectedLocation,
  updateDowngradePackage,
  fetchActiveSubscriptions,
  createHostedSubscription,
  openRecurringStatusModal,
  checkSuccessRecurringPayment,
  fetchPackages,
  fetchTrial,
  createTrial,
};

export default exportedFuction;
