import { useEffect, useState } from "react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { includes, isEmpty } from "lodash";
import PhoneInput from "react-phone-input-2";
import Alert from "../../../components/Alert";
import S3Image from "../../../components/S3Image";
import { Formik, useFormikContext } from "formik";
import { Actions } from "../../../internals/app/Actions";
import withReactContent from "sweetalert2-react-content";
import ImageComponent from "../../../components/UploadImage";
import FormInput from "../../../components/inputs/FormInput";
import defaultImage from "../../../assets/images/default-user.png";

const PersonalInfo = (props: any) => {
  const {
    updateUser,
    alertStatus,
    currentUser,
    openAlertBox,
    loadingAction,
    uploadUserImage,
    removeUserImage,
  } = props;
  const [image, setImage] = useState<string>("");

  useEffect(() => {
    if (currentUser) {
      setImage(currentUser.image);
    }
  }, [currentUser]);

  const onAddFile = async (acceptedFiles: any) => {
    uploadUserImage(acceptedFiles[0], currentUser.uid);
  };

  const onClickSave = (values: any) => {
    const params = {
      email: values.email,
      uid: currentUser.uid,
      address: values.address,
      lastName: values.lastName,
      firstName: values.firstName,
      phoneNumber: includes(values.phoneNumber, "+")
        ? values.phoneNumber
        : `+${values.phoneNumber}`,
    };
    updateUser(params);
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(2, "Must be 2 characters or more")
      .required("First name is required"),
    lastName: Yup.string()
      .min(2, "Must be 2 characters or more")
      .required("Last name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .min(10, "Enter valid phone number")
      .required("Must enter a phone number"),
  });

  const initialValues = {
    shopName: "",
    email: "",
    address: "",
    phoneNumber: "",
    description: "",
    paymentMethod: "",
    pin: "",
    token: "",
  };

  const onDeleteImage = () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6D71F9",
      cancelButtonColor: "#FF3333",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeUserImage(currentUser.uid);
      }
    });
  };

  return (
    <div className="h-full w-full p-6">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="h-full w-full max-w-screen-md bg-white shadow-md rounded-lg	">
        <div className="flex flex-row justify-between items-center bg-gray-50 h-12 text-right sm:px-6 rounded-t-md">
          <h3>{"PERSONAL INFORMATION"}</h3>
        </div>

        <div className="h-[calc(100vh-144px)] overflow-auto bg-white px-4 py-5 sm:p-6">
          <label className="mb-4 block text-sm font-medium text-gray-700">
            User Image
          </label>
          <div className="mb-6 w-48 bg-white overflow-hidden">
            {image ? (
              <div
                className={
                  "w-auto flex relative flex-row h-48 rounded-lg" +
                  (isEmpty(image) ? " border-dashed border border-sky-500" : "")
                }
              >
                <S3Image
                  url={image}
                  defaultImage={defaultImage}
                  className="rounded-lg w-full h-48 object-cover object-center"
                />
              </div>
            ) : (
              <Dropzone
                multiple={false}
                onDrop={onAddFile}
                accept={{
                  "image/png": [".png", ".jpeg", ".HEIC", ".JPG"],
                }}
              >
                {({ getRootProps, getInputProps }: any) => (
                  <ImageComponent
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    loadingAction={loadingAction}
                  />
                )}
              </Dropzone>
            )}
            <div className="w-full flex justify-start items-center py-3">
              <button
                onClick={() => onDeleteImage()}
                className="inline-flex justify-center rounded-md border border-transparent bg-light-coral py-2 px-2 text-sm font-medium text-white shadow focus:outline-none"
              >
                Delete image
              </button>
            </div>
          </div>

          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => onClickSave(values)}
          >
            {({ handleSubmit }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  className="space-y-3 md:space-y-4"
                >
                  <RenderComponent user={currentUser} />
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-12 text-sm font-medium text-white shadow hover:bg-indigo-700 focus:outline-none"
                  >
                    Save
                  </button>
                </form>
              );
            }}
          </Formik>
        </div>
        <div className="bg-gray-50 h-12 text-right sm:px-6 rounded-b-md" />
      </div>
    </div>
  );
};
export default connect(
  (state: any) => ({
    alertStatus: state.common.get("alertStatus"),
    loadingAction: state.common.get("loadingAction"),
    currentUser: state.setting.get("currentUser"),
  }),
  {
    updateUser: Actions.setting.updateUser,
    openAlertBox: Actions.common.openAlertBox,
    uploadUserImage: Actions.setting.uploadUserImage,
    removeUserImage: Actions.setting.removeUserImage,
  }
)(PersonalInfo);

const RenderComponent = ({ user }: any) => {
  const { values, errors, touched, handleChange, setFieldValue } =
    useFormikContext<{
      email: string;
      address: string;
      lastName: string;
      firstName: string;
      phoneNumber: string;
    }>();

  useEffect(() => {
    if (!isEmpty(user)) {
      setFieldValue("email", user.email);
      setFieldValue("address", user.address);
      setFieldValue("lastName", user.lastName);
      setFieldValue("firstName", user.firstName);
      setFieldValue("phoneNumber", user.phoneNumber);
    }
  }, [user, setFieldValue]);

  return (
    <div className="grid grid-cols-6 gap-6">
      <div className="col-span-6 sm:col-span-3">
        <FormInput
          id="firstName"
          label="First Name"
          value={values.firstName}
          error={errors.firstName}
          touched={touched.firstName}
          placeholder="First Name"
          onChange={handleChange("firstName")}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <FormInput
          id="lastName"
          label="Last Name"
          value={values.lastName}
          error={errors.lastName}
          touched={touched.lastName}
          placeholder="Last Name"
          onChange={handleChange("lastName")}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <FormInput
          id="email"
          label="Your email"
          value={values.email}
          error={errors.email}
          touched={touched.email}
          placeholder="name@company.com"
          onChange={handleChange("email")}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <div className="w-full relative flex flex-col">
          <label
            htmlFor="mobile-number"
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            Phone Number
          </label>
          <PhoneInput
            country={"lk"}
            value={values.phoneNumber}
            onChange={handleChange("phoneNumber")}
            inputStyle={{
              border: "none",
              height: "100%",
              width: "100%",
              borderRadius: "8px",
            }}
            buttonStyle={{
              border: "none",
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
            }}
            containerClass="h-10 block w-full rounded-md outline-none border-gray-300 border shadow sm:text-sm mt-0"
          />
          {!isEmpty(errors.phoneNumber) && touched.phoneNumber && (
            <span className="mt-1 first-line:block text-xs font-normal text-rose-400	">
              {errors.phoneNumber}
            </span>
          )}
        </div>
      </div>
      <div className="col-span-6 sm:col-span-3">
        <FormInput
          id="address"
          label="Location"
          value={values.address}
          error={errors.address}
          placeholder="Location"
          touched={touched.address}
          onChange={handleChange("address")}
        />
      </div>
    </div>
  );
};
