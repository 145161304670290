import { API, graphqlOperation } from '@aws-amplify/api';
import {
  OnCreateClient,
  OnUpdateClient,
} from './schema/Client.schema';
import _, { get } from 'lodash';
import Repository from './Repository';

if (typeof Buffer === 'undefined') global.Buffer = require('buffer').Buffer;

let createClientSub = null;
let updateClientSub = null;

class ClientRepository extends Repository {
  fetchClients = async (shopId, limit, lastKey) => {
    const params = {
      shopId,
      limit,
      lastKey,
    };
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.API_NETLISE_V2,
        path: '/clients',
        message,
      });
      return result.data.clientsResult;
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  };

  createClient = async client => {
    const time = Date.now();
    client.createdTime = time;
    client.updatedTime = time;
    const message = this.buildMessage(client);

    try {
      const result = await this.apiPost({
        apiName: this.API_NETLISE_V2,
        path: '/client',
        message,
      });
      return get(result, 'data.response', {});
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  };

  updateClient = async params => {
    const time = Date.now();
    params.updatedTime = time;
    const message = this.buildMessage(params);

    try {
      const result = await this.apiPut({
        apiName: this.API_NETLISE_V2,
        path: '/client',
        message,
      });
      return get(result, 'data.response', {});
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  };

  deleteClient = async (id, shopId) => {
    const message = this.buildMessage({ id, shopId });
    try {
      const result = await this.apiDelete({
        apiName: this.API_NETLISE_V2,
        path: '/client',
        message,
      });
      return get(result, 'data.clientResult', {});
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  };

  searchClients = async (shopId, searchText, limit, lastKey) => {
    const params = {
      shopId,
      limit,
      lastKey,
      searchText,
    };
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.API_NETLISE_V2,
        path: '/client/searchClient',
        message,
      });
      return result.data.clientList;
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  };

  subscribeToCreateClient = async (createdUser, callback) => {
    try {
      const param = { createdUser };
      createClientSub && createClientSub.unsubscribe();
      createClientSub = await API.graphql(
        graphqlOperation(OnCreateClient, param),
      ).subscribe({
        next: client => {
          const data = _.get(client, 'value.data.onCreateClient', {});
          callback(data, 'CREATE');
        },
        complete: () => {
        },
        error: error => {
          console.log(error);
        },
      });

      return createClientSub;
    } catch (error) {
      console.warn('catched create client subscription error', error);
    }
  };

  subscribeToUpdateClient = async (createdUser, callback) => {
    try {
      const param = { createdUser };
      updateClientSub && updateClientSub.unsubscribe();
      updateClientSub = await API.graphql(
        graphqlOperation(OnUpdateClient, param),
      ).subscribe({
        next: client => {
          const data = _.get(client, 'value.data.onUpdateClient', {});
          callback(data);
        },
        complete: () => {
        },
        error: error => {
          console.log(error);
        },
      });

      return updateClientSub;
    } catch (error) {
      console.warn('catched update client subscription error', error);
    }
  };
}

export default new ClientRepository();
