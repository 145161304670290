import { get, isEmpty } from "lodash";
import { connect } from "react-redux";
import { Actions } from "../../internals/app/Actions";
import defaultImage from "../../assets/images/default-store.jpeg";
import location from "../../assets/svgs/subscription/location.svg";
import { getShopCtaegory, getShopType } from "../../modules/login/Constants";

const LocationCard = (props: any) => {
  const {
    adminShops,
    onClickBack,
    onClickNext,
    selectedLocation,
    setSelectedLocation,
  } = props;

  const onClickNextStep = () => {
    if (!isEmpty(selectedLocation)) {
      onClickNext();
    }
  };

  return (
    <div className="flex flex-col  p-6 shadow rounded-lg">
      <div className="mb-4">Choose Location</div>

      <div className="flex flex-row flex-wrap gap-4 mb-10">
        {adminShops?.map((item: any) => {
          const isSelected =
            selectedLocation && selectedLocation.shopId === item.shopId;

          return (
            <div
              key={item.shopId}
              onClick={() => setSelectedLocation(item)}
              className={
                "w-[230px] p-1 hover:cursor-pointer bg-white border rounded-lg shadow" +
                (isSelected
                  ? " border border-medium-slate-blue"
                  : " border-white")
              }
            >
              <img
                alt=""
                src={defaultImage}
                className="w-full h-[119px] object-cover overflow-hidden rounded-t-lg"
              />
              <div className="p-3">
                <h5 className="text-[11px] font-bold tracking-tight text-medium-slate-blue">
                  {getShopCtaegory(get(item, "shopCategory", ""))}
                </h5>
                <h5 className="mb-2 text-[10px] font-bold tracking-tight text-midnight-express">
                  {getShopType(get(item, "shopType", ""))}
                </h5>

                <p className="mb-2 text-sm font-semibold text-midnight-express">
                  {item.shopName}
                </p>
                <div className="inline-flex items-start text-xs font-medium text-midnight-express opacity-40">
                  <img className="mr-2" src={location} alt="" />
                  {item.address}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex flex-row">
        <button
          type="button"
          onClick={() => onClickBack()}
          className="w-[104px] py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-medium-slate-blue focus:outline-none bg-white rounded-lg border border-medium-slate-blue hover:bg-gray-100 hover:text-blue-700 focus:z-10"
        >
          Back
        </button>
        <button
          type="button"
          disabled={isEmpty(selectedLocation)}
          onClick={() => onClickNextStep()}
          className={`"w-[104px] text-white bg-medium-slate-blue hover:bg-blue-800 hover:cursor-pointer font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none ${
            selectedLocation?.shopId ? "" : "bg-gray-400 hover:bg-gray-400"
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};
export default connect(
  (state: any) => ({
    adminShops: state.dashboard.get("adminShops"),
    selectedLocation: state.setting.get("selectedLocation"),
  }),
  {
    setSelectedLocation: Actions.setting.setSelectedLocation,
  }
)(LocationCard);
