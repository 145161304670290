import moment from "moment";
import Modal from "react-modal";
import "../../layouts/Styles.css";
import { connect } from "react-redux";
import { capitalize, get, isEmpty } from "lodash";
import Close from "../../assets/svgs/close-icon.svg";
import { Actions } from "../../internals/app/Actions";
import { formatPrice } from "../../internals/managers/PaymentManager";

const customStyles: any = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    padding: 0,
    height: "auto",
    border: "none",
    overflow: "auto",
    maxWidth: "449px",
    background: "none",
    top: "calc(50vh - 20rem)",
    left: "calc(50vw - 225px)",
    WebkitOverflowScrolling: "touch",
  },
};

const InvoiceDetail = (props: any) => {
  const { isOpen, closeModal, transaction } = props;

  const RowTitle = (props: any) => {
    const { title, qty, value } = props;
    return (
      <div className="flex flex-row justify-between">
        <h3 className="text-start text-sm text-black-85 w-2/5 font-normal">
          {title}
        </h3>
        {qty && (
          <h3 className="font-normal text-center text-sm text-black-85 w-1/5">
            {qty}
          </h3>
        )}
        <h3 className="font-normal text-end text-sm text-black-85 w-2/5">
          {value}
        </h3>
      </div>
    );
  };

  const subTotalProduct = transaction?.productDetails
    .map((product: any) => product.totalPrice)
    .reduce((prev: any, curr: any) => prev + curr, 0);
  const subTotalService = transaction?.serviceDetails
    .map((product: any) => product.totalPrice)
    .reduce((prev: any, curr: any) => prev + curr, 0);

  const subTotal = formatPrice(subTotalProduct + subTotalService);

  const serviceCharge = formatPrice(
    parseFloat(get(transaction, "serviceCharge.amount", 0))
  );

  const discount = transaction?.discountDetails
    .map((discount: any) => discount.amount)
    .reduce((prev: any, curr: any) => prev + curr, 0);

  const discountPrice = formatPrice(discount);
  const paidPrice = formatPrice(transaction?.paidPrice);

  const total =
    subTotalProduct +
    subTotalService +
    parseFloat(get(transaction, "serviceCharge.amount", 0)) -
    discount;
  const totalPrice = formatPrice(total);
  const reminder = formatPrice(transaction?.paidPrice - total);

  const employee = `${capitalize(
    get(transaction, "employee.firstName", "")
  )} ${capitalize(get(transaction, "employee.lastName", " ").charAt(0))}`;

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Brand Modal"
      onRequestClose={() => closeModal()}
    >
      <div className=" bg-white shadow rounded-lg">
        <div className="flex flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
          <button
            type="button"
            className="top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-2 ml-auto inline-flex items-center "
            onClick={() => closeModal()}
          >
            <img
              src={Close}
              alt="actions"
              className="text-gray-400 dark:text-gray-500 w-3 h-3 mx-auto"
            />
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <div className="flex flex-col px-4 py-3 text-right sm:px-6 rounded-lg pb-8">
          <div className="flex flex-col gap-2">
            <RowTitle title={"Receipt #"} value={transaction?.recieptId} />
            <RowTitle
              title={"Date"}
              value={moment(transaction.createdTime).format("MMM DD, yyyy")}
            />
            <RowTitle title={"Time"} value={transaction?.time} />
            <RowTitle value={employee} />
          </div>

          <div className="flex flex-col mt-6 mb-2">
            <RowTitle title={"Descriptions"} qty={"Qty"} value={"Total"} />
          </div>
          <div className="w-full border border-dashed border-black mb-4" />
          {!isEmpty(transaction?.productDetails) &&
            transaction?.productDetails?.map((data: any, index: number) => {
              const price = formatPrice(data.totalPrice);
              return (
                <div key={index} className="flex flex-row justify-between mb-2">
                  <h3 className="font-normal text-start text-sm text-black-85 w-2/5">
                    {data.productName}
                  </h3>
                  <h3 className="font-normal text-center text-sm text-black-85 w-1/5">
                    {data.quantity}
                  </h3>
                  <h3 className="font-normal text-end text-sm text-black-85 w-2/5">
                    {price}
                  </h3>
                </div>
              );
            })}
          <div className="w-full border border-dashed border-black mb-4 mt-3" />
          <div className="flex flex-col gap-2">
            <RowTitle title={"Sub Total"} value={subTotal} />
            <RowTitle title={"Discounts"} value={discountPrice} />
            <RowTitle title={"Service Charge"} value={serviceCharge} />
            <RowTitle title={"Total"} value={totalPrice} />
          </div>
          <div className="w-full border border-dashed border-black mb-4 mt-3" />
          <div className="flex flex-col gap-2">
            <RowTitle title={"Paid Amount"} value={paidPrice} />
            <RowTitle title={"Reminder"} value={reminder} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default connect(
  (state: any) => ({
    currentUser: state.setting.get("currentUser"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    createBrand: Actions.product.createBrand,
    updateBrand: Actions.product.updateBrand,
  }
)(InvoiceDetail);
