import {useState, useRef} from 'react';
import {Products} from '../Types';
import {capitalize, get, isEmpty} from 'lodash';
import {useFormikContext, FieldArray} from 'formik';
import IconClose from '../../../assets/svgs/icon-close.svg';
import {formatPrice} from '../../../internals/managers/PaymentManager';

const PriceForm = (props: any) => {
  const inputRef: any = useRef(null);
  const {values, errors, touched, setFieldValue} = useFormikContext<Products>();
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editType, setEditType] = useState<string | null>(null);
  const tableHeader = [
    'VARIATIONS',
    'SKU',
    'DISCOUNT PRICE',
    'ACTUAL PRICE',
    'STOCK',
    'REM',
  ];

  return (
    <div className="overflow-auto bg-white px-4 py-5 sm:p-6">
      <div className="grid grid-cols-6 gap-6">
        <FieldArray name="priceVariants">
          {({insert, remove, push}) => {
            return (
              <div className="col-span-6">
                <label
                  htmlFor={'optionName'}
                  className="block mb-2 text-sm font-medium text-gray-700"
                >
                  {'Set the product'}
                </label>

                <div className="flex flex-col w-full px-4 pt-3 bg-gray-50 rounded-md">
                  <div className="w-full flex flex-row justify-between mb-4">
                    {tableHeader?.map((item: string, index: number) => {
                      const isPrice = item === 'PRICE';
                      return (
                        <div
                          key={index}
                          onClick={() => {}}
                          className={`flex text-center text-sm font-bold text-valhalla justify-start cursor-pointer ${
                            isPrice ? 'justify-end pr-10' : 'justify-start'
                          } `}
                        >
                          {capitalize(item)}
                        </div>
                      );
                    })}
                  </div>

                  <div className="w-full flex flex-col">
                    {values?.priceVariants.map((option: any, index: number) => {
                      const priceVariantErrors: any = !isEmpty(errors)
                        ? get(errors, `priceVariants.[${index}]`, null)
                        : null;

                      return (
                        <div
                          className={`flex flex-col w-full items-end border-b mb-3 pb-2`}
                        >
                          <div
                            className={`flex flex-row w-full items-center justify-between`}
                          >
                            <div className=" text-start text-sm font-medium text-valhalla">
                              {option.optionUnit}
                            </div>
                            <div className=" text-start text-sm font-medium text-valhalla">
                              {option.sku}
                            </div>

                            <div className="gap-4 flex flex-row">
                              {editIndex === index ? (
                                <input
                                  ref={inputRef}
                                  value={`${option.price}`}
                                  onBlur={() => setEditIndex(null)}
                                  onChange={(e) => {
                                    const name = `priceVariants[${index}].price`;
                                    setFieldValue(name, e.target.value, true);
                                  }}
                                  className="p-2 h-8 block w-5/6 rounded-md outline-none border-gray-300 border shadow sm:text-sm"
                                />
                              ) : (
                                <div
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setEditIndex(index);
                                  }}
                                  className="w-5/6 p-2 h-8 text-center text-sm font-medium text-valhalla"
                                >
                                  {formatPrice(option.price)}
                                </div>
                              )}
                            </div>
                            <div className="gap-2 flex">
                              <div className="flex flex-row">
                                {editIndex === index &&
                                editType === 'ACTUAL PRICE' ? (
                                  <input
                                    ref={inputRef}
                                    value={`${option.actualPrice}`}
                                    onBlur={() => setEditIndex(null)}
                                    onChange={(e) => {
                                      const name = `priceVariants[${index}].actualPrice`;
                                      setFieldValue(name, e.target.value, true);
                                    }}
                                    className="p-2 h-8 block w-5/7 rounded-md outline-none border-gray-300 border shadow sm:text-sm w-[120px]"
                                  />
                                ) : (
                                  <div
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setEditType('ACTUAL PRICE');
                                      setEditIndex(index);
                                    }}
                                    className="w-5/6 p-2 h-8 text-center text-sm font-medium text-valhalla"
                                  >
                                    {formatPrice(option.actualPrice)}
                                  </div>
                                )}
                              </div>
                              {!isEmpty(priceVariantErrors?.actualPrice) && (
                                <span className="first-line:block text-[11px] font-normal text-rose-400	mr-26">
                                  {'Please enter option actual price*'}
                                </span>
                              )}
                            </div>
                            <div className="flex flex-row">
                              {editIndex === index && editType === 'STOCK' ? (
                                <input
                                  ref={inputRef}
                                  value={`${option.stockCount || ''}`}
                                  onBlur={() => setEditIndex(null)}
                                  onChange={(e) => {
                                    const name = `priceVariants[${index}].stockCount`;
                                    setFieldValue(name, e.target.value, true);
                                  }}
                                  type={'number'}
                                  className="p-2 h-8 block rounded-md outline-none border-gray-300 border shadow sm:text-sm w-[100px] mr-[-40px]"
                                />
                              ) : (
                                <div
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setEditType('STOCK');
                                    setEditIndex(index);
                                  }}
                                  className="text-center text-sm font-medium text-valhalla w-[70px] h-8 mr-[-20px]"
                                >
                                  {option?.stockCount
                                    ? option?.stockCount
                                    : ' '}
                                </div>
                              )}
                              
                            </div>
                            {values?.priceVariants.length > 0 && (
                                <button
                                  type="button"
                                  onClick={() => remove(index)}
                                  className="flex items-center rounded-lg p-1.5 mb-1 justify-center bg-transparent"
                                >
                                  <img
                                    alt="actions"
                                    src={IconClose}
                                    className="text-deactivate w-3 h-3"
                                  />
                                </button>
                              )}
                          </div>
                          {!isEmpty(priceVariantErrors?.price) && (
                            <span className="first-line:block text-xs font-normal text-rose-400	mr-16">
                              {'Please enter option price*'}
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};
export default PriceForm;
