import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { isEmpty, upperCase } from "lodash";
import { connect } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { Formik, Field, Form } from "formik";
import PhoneInput from "react-phone-input-2";
import Logo from "../../../assets/svgs/logo.svg";
import { CircularProgress } from "@material-ui/core";
import { Actions } from "../../../internals/app/Actions";
import FormInput from "../../../components/inputs/FormInput";
import CustomSelect from "../../../components/inputs/CustomSelect";
import { CategoryList, getEnumOptions, getEnumTypes } from "../Constants";
import UserRepository from "../../../internals/repository/UserRepository";
import MixpanelService, { MP_EVENTS } from "../../../internals/service/MixpanelService";

const BusinessView = (props: any) => {
  const formRef = useRef({} as any);
  const { createShop, currentUser, logout, loadingAction } = props;
  const { action, loading } = loadingAction;
  const [country, setCountry] = useState<any>(null);

  const getCoutry = async () => {
    await UserRepository.fetchCoutry((data: any) => {
      setCountry(data);
    });
  };

  useEffect(() => {
    getCoutry();
  }, []);

  const validationSchema = Yup.object({
    businessTitle: Yup.string()
      .min(2, "Must be 2 characters or more")
      .required("Business Title is required"),
    address: Yup.string()
      .min(2, "Must be 2 characters or more")
      .required("Address is required"),
    phoneNumber: Yup.string()
      .min(10, "Enter valid phone number")
      .required("Must enter a phone number"),
    category: Yup.string().required("Business Category is required"),
    businessType: Yup.string().required("Business Type is required"),
  });

  const initialValues = {
    address: "",
    category: "",
    phoneNumber: "",
    businessType: "",
    businessTitle: "",
  };

  const onClickSubmit = (values: any) => {
    const shortCode = values.businessTitle.replace(/\s+/g, "_").toUpperCase();
    const params = {
      shortCode,
      address: values.address,
      local: country.timezone,
      email: currentUser.email,
      shopType: values.businessType,
      shopCategory: values.category,
      shopName: values.businessTitle,
      phoneNumber: `+${values.phoneNumber}`,
    };
    MixpanelService.track(MP_EVENTS.CREATE_SHOP, params);
    createShop(params, currentUser.uid);
  };
  const categoryList = getEnumOptions(CategoryList);

  const loadingCreateShop =
    loading &&
    (action.type === "CREATE_SHOP" || action.type === "FETCH_ADMIN_SHOPS");

  return (
    <section className=" bg-gray-50 flex flex-row">
      <div className="max-w-5xl w-full h-full flex flex-col items-center justify-center p-6 mx-auto md:h-screen lg:py-0">
        <div className="flex items-center justify-center mb-6 text-4xl font-semibold text-gray-900 ">
          <img src={Logo} alt="logo" className="w-20 h-20 mr-6" />
          Netlise
        </div>
        <div className="flex flex-row w-full bg-white rounded-lg shadow md:mt-0  xl:p-0">
          <div className="flex-col w-1/2 hidden md:block items-center justify-center h-full">
            <img
              alt=""
              className="h-full rounded-l-lg"
              src="images/supply-chain.jpg"
            />
          </div>
          <div className="p-6 md:w-1/2 w-full space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-midnight-express md:text-2xl">
              Manage your business
            </h1>
            <Formik
              innerRef={formRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => onClickSubmit(values)}
            >
              {({ values, errors, touched, handleSubmit, handleChange }) => {
                const type = getEnumTypes(values.category);
                const businessType = getEnumOptions(type);

                return (
                  <Form
                    onSubmit={handleSubmit}
                    className="space-y-3 md:space-y-4"
                  >
                    <FormInput
                      id="businessTitle"
                      label="Business Title"
                      placeholder="Business Title"
                      value={values.businessTitle}
                      error={errors.businessTitle}
                      touched={touched.businessTitle}
                      onChange={handleChange("businessTitle")}
                    />
                    <FormInput
                      id="address"
                      label="Your Address"
                      placeholder="Address"
                      value={values.address}
                      error={errors.address}
                      touched={touched.address}
                      onChange={handleChange("address")}
                    />
                    <div className="w-full relative flex flex-col">
                      <label
                        htmlFor="mobile-number"
                        className="mb-1 block text-sm font-medium text-gray-700"
                      >
                        Phone Number
                      </label>
                      <PhoneInput
                        country={"lk"}
                        value={values.phoneNumber}
                        onChange={handleChange("phoneNumber")}
                        inputStyle={{
                          border: "none",
                          height: "100%",
                          width: "100%",
                          borderRadius: "8px",
                        }}
                        buttonStyle={{
                          border: "none",
                          borderTopLeftRadius: "8px",
                          borderBottomLeftRadius: "8px",
                        }}
                        containerClass="h-10 block w-full rounded-md outline-none border-gray-300 border shadow sm:text-sm mt-0"
                      />
                      {!isEmpty(errors.phoneNumber) && touched.phoneNumber && (
                        <span className="mt-1 first-line:block text-xs font-normal text-rose-400	">
                          {errors.phoneNumber}
                        </span>
                      )}
                    </div>
                    <div className="grid grid-cols-6 gap-3 md:gap-4">
                      <div className="col-span-6 sm:col-span-3">
                        <Field
                          id="category"
                          name="category"
                          label="Category"
                          options={categoryList}
                          placeholder="Category"
                          error={errors.category}
                          component={CustomSelect}
                          touched={touched.category}
                          onClickOption={() => {}}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <Field
                          id="businessType"
                          name="businessType"
                          label="Business Type"
                          options={businessType}
                          component={CustomSelect}
                          error={errors.businessType}
                          placeholder="Business Type"
                          touched={touched.businessType}
                          onClickOption={() => {}}
                        />
                      </div>
                    </div>

                    <button
                      type="submit"
                      disabled={loadingCreateShop}
                      className="w-full text-white bg-medium-slate-blue hover:bg-primary-700  focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                      {loadingCreateShop ? (
                        <CircularProgress
                          size={15}
                          style={{ color: "#EFF0F8" }}
                        />
                      ) : (
                        "Start managing"
                      )}
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
        <a
          onClick={logout}
          className="text-waikawa-grey underline mt-4"
          href="#"
        >
          Or sign in with a different account
        </a>
      </div>
    </section>
  );
};
export default connect(
  (state: any) => ({
    currentUser: state.setting.get("currentUser"),
    loadingAction: state.common.get("loadingAction"),
  }),
  {
    logout: Actions.login.logout,
    createShop: Actions.dashboard.createShop,
  }
)(BusinessView);
