import { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../Button";
import Modal from "react-modal";
import { nanoid } from "nanoid";
import { isEmpty } from "lodash";
import "../../layouts/Styles.css";
import { connect } from "react-redux";
import FormInput from "../inputs/FormInput";
import { AiOutlineClose } from "react-icons/ai";
import FormTextarea from "../inputs/FormTextarea";
import { Formik, useFormikContext } from "formik";
import { Actions } from "../../internals/app/Actions";

const customStyles: any = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    padding: 0,
    height: "auto",
    border: "none",
    overflow: "auto",
    maxWidth: "624px",
    background: "none",
    top: "calc(50vh - 20rem)",
    left: "calc(50vw - 292px)",
    WebkitOverflowScrolling: "touch",
  },
};

type CategoryProps = {
  brand?: any;
  isOpen: boolean;
  isEdit?: boolean;
  currentUser: any;
  selectedShop: any;
  closeModal: () => void;
  createBrand: (params: any) => void;
  updateBrand: (params: any) => void;
};

const AddBrand = (props: CategoryProps) => {
  const {
    isOpen,
    brand,
    closeModal,
    currentUser,
    selectedShop,
    createBrand,
    updateBrand,
  } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    setIsEdit(!isEmpty(brand));
  }, [brand]);

  const onCreateBrand = (values: {
    brandName: string;
    description: string;
  }) => {
    const param = {
      id: nanoid(),
      createdTime: Date.now(),
      updatedTime: Date.now(),
      shopId: selectedShop.shopId,
      brandName: values.brandName,
      createdUserId: currentUser.uid,
      description: values.description,
    };
    createBrand(param);
    closeModal();
  };

  const onUpdateBrand = (values: {
    brandName: string;
    description: string;
  }) => {
    const param = {
      id: brand.id,
      shopId: brand.shopId,
      updatedTime: Date.now(),
      brandName: values.brandName,
      createdTime: brand.createdTime,
      description: values.description,
      createdUserId: brand.createdUserId,
    };
    updateBrand(param);
    closeModal();
  };

  const validationSchema = Yup.object({
    brandName: Yup.string()
      .min(2, "Must be 2 characters or more")
      .required("Please enter brand name"),
  });

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Brand Modal"
      onRequestClose={() => closeModal()}
    >
      <div className=" bg-white shadow rounded-lg">
        <div className="flex flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
          <h3>{isEdit ? "Edit Brand" : "Add Brand"}</h3>
          <button
            type="button"
            className="top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
            onClick={() => closeModal()}
          >
            <AiOutlineClose size={20} />
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <Formik
          validationSchema={validationSchema}
          initialValues={{ brandName: "", description: "" }}
          onSubmit={(values) => {
            if (!isEdit) {
              onCreateBrand(values);
            } else {
              onUpdateBrand(values);
            }
          }}
        >
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="h-auto bg-white px-4 py-5 sm:p-6 rounded-lg">
                  <RenderInput brand={brand} />
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
                  <Button
                    type="submit"
                    label={isEdit ? "Update Brand" : "Add Brand"}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default connect(
  (state: any) => ({
    currentUser: state.setting.get("currentUser"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    createBrand: Actions.product.createBrand,
    updateBrand: Actions.product.updateBrand,
  }
)(AddBrand);

const RenderInput = ({ brand }: any) => {
  const { values, errors, touched, handleChange, setFieldValue } =
    useFormikContext<{
      brandName: string;
      description: string;
    }>();

  useEffect(() => {
    if (!isEmpty(brand)) {
      setFieldValue("brandName", brand.brandName);
      setFieldValue("description", brand.description);
    }
  }, [brand]);

  return (
    <div className="grid grid-cols-6 gap-6">
      <div className="col-span-6 sm:col-span-6">
        <FormInput
          id="brandName"
          label="Brand name"
          placeholder="Brand name"
          error={errors.brandName}
          value={values.brandName}
          touched={touched.brandName}
          onChange={handleChange("brandName")}
        />
      </div>
      <div className="col-span-6 sm:col-span-6">
        <FormTextarea
          id="description"
          label="Description"
          error={errors.description}
          value={values.description}
          touched={touched.description}
          onChange={handleChange("description")}
        />
      </div>
    </div>
  );
};
