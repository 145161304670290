import ModifierRepository from "../../internals/repository/ModifierRepository";
import ProductRepository from "../../internals/repository/ProductRepository";

export const ModuleEvents = {
  CREATE_MENU: "CREATE_MENU",
  UPDATE_MENU: "UPDATE_MENU",
  FETCH_MENU: "FETCH_MENU",
  DELETE_MODIFIER: "DELETE_MODIFIER"
};

const createAction =
  (type, action = () => { }, meta) =>
    (...args) => ({
      type,
      payload: action(...args),
      meta,
    });

const fetchMenu = createAction(
  ModuleEvents.FETCH_MENU,
  async (shopId, limit = 100, nextToken = null) => {
    const result = await ProductRepository.fetchMenu(
      shopId,
      limit,
      nextToken
    );
    return result;
  }
);
const createMenu = createAction(
  ModuleEvents.CREATE_MENU,
  async (param) => {
    const result = await ProductRepository.createMenu(param);
    return result;
  },
  { loading: true }
);
const updateMenu = createAction(
  ModuleEvents.UPDATE_MENU,
  async (param) => {
    const result = await ProductRepository.updateMenu(param);
    return result;
  },
  { loading: true }
);
const deleteModifier = createAction(
  ModuleEvents.DELETE_MODIFIER,
  async (id, shopId) => {
    const result = await ModifierRepository.deleteModifier(id, shopId);
    return result;
  }
);

const exportedFuction = {
  fetchMenu,
  createMenu,
  updateMenu,
  deleteModifier
};

export default exportedFuction;
