import { useEffect, useState } from "react";
import moment from "moment";
import { jsPDF } from "jspdf";
import Select from "react-select";
import { connect } from "react-redux";
import { router } from "../../../App";
import html2canvas from "html2canvas";
import Dots from "../../../assets/svgs/dots.svg";
import Back from "../../../assets/svgs/back.svg";
import { CircularProgress } from "@material-ui/core";
import { formatPrice } from "../../../internals/managers/PaymentManager";
import { getSalesSummary } from "../../../internals/managers/CloseCashManager";
import { capitalize, get, isArray, isEmpty, isString, startCase } from "lodash";

const CloseCashDetails = (props: any) => {
  const {
    selectedShop,
    selectedBatch,
    loadingAction,
    batchOrderSummary,
    selectedBatchSummary,
  } = props;
  const { action, loading } = loadingAction;
  const doc = new jsPDF();
  const [grossSales, setGrossSales] = useState<string>("");
  const [salesCount, setSalesCount] = useState<string>("");
  const [salesSummary, setSalesSummary] = useState<any>({});
  const [cardPayment, setCardPayment] = useState<string>("");
  const [cashPayment, setCashPayment] = useState<string>("");
  const [productCount, setProductCount] = useState<string>("");
  const [ordersSummary, setOrdersSummary] = useState<Array<any>>([]);

  useEffect(() => {
    if (!isEmpty(selectedBatch)) {
      const serviceSummary = getSalesSummary(selectedBatch, selectedShop);
      setSalesSummary(serviceSummary);
      const grossSalesData = formatPrice(
        get(selectedBatch, "salesSummary.grossSales", 0),
        selectedShop.currency
      );
      setGrossSales(grossSalesData);
      setSalesCount(get(selectedBatch, "salesSummary.salesCount", 0));
      setProductCount(get(selectedBatch, "salesSummary.productCounts", 0));

      const cardPaymentData = formatPrice(
        get(selectedBatch, "drawerSummary.totalCardSalesPrice", 0),
        selectedShop.currency
      );
      const cashPaymentData = formatPrice(
        get(selectedBatch, "drawerSummary.totalCashSalesPrice", 0),
        selectedShop.currency
      );
      setCardPayment(cardPaymentData);
      setCashPayment(cashPaymentData);
    }
  }, [selectedBatch]);

  // useEffect(() => {
  //   if (!isEmpty(completedCashAppointment.appointments)) {
  //     setLoadingOrder(true);
  //     const orderDetails = getOrderDetails(
  //       completedCashAppointment.appointments
  //     );
  //     setOrdersSummary(orderDetails);
  //     setLoadingOrder(false);
  //   }
  // }, [completedCashAppointment]);

  useEffect(() => {
    if (!isEmpty(batchOrderSummary)) {
      setOrdersSummary(batchOrderSummary.productSummary);
    }
  }, [batchOrderSummary]);

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: "100%",
      boxShadow: "none",
      borderColor: "#D1D5DB",
      "&:hover": {
        borderColor: "#D1D5DB",
      },
    }),
  };

  const onChangeExport = (option: any) => {
    if (option.value === "PDF") {
      const doc = new jsPDF("p", "mm");
      const data: any = document.getElementById("divToPrint");
      html2canvas(data).then((canvas: any) => {
        const imgWidth = 105;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        heightLeft -= pageHeight;
        doc.addImage(
          canvas,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(
            canvas,
            "PNG",
            0,
            position,
            imgWidth,
            imgHeight,
            "",
            "FAST"
          );
          heightLeft -= pageHeight;
        }
        doc.save(
          `${moment(selectedBatchSummary.updatedTime).format(
            "MMM-DD-yyyy"
          )}.pdf`
        );
      });
    } else {
      let values: any = [];
      salesSummary?.map((data: any, index: number) => {
        if (index > 0) {
          values.push(["", ""]);
        }
        values.push([data.header, ""]);
        data?.details.map((e: any) => {
          var price = isString(e.value)
            ? Number(e.value.replace(/[^0-9\.-]+/g, ""))
            : e.value;
          values.push([e.title, price]);
        });
      });

      Object.keys(batchOrderSummary).map((key: any) => {
        values.push(["", ""]);
        values.push([startCase(key), ""]);
        if (isArray(batchOrderSummary[key])) {
          batchOrderSummary[key].map((details: any, index: number) => {
            const name =
              details.title ||
              details.typeName ||
              details.productName ||
              details.serviceTitle;
            const price =
              details.amount || details.servicePrice || details.productPrice;
            values.push([startCase(name), price]);
          });
        }
      });
      values.push(["", ""]);
      const cash = Number(cashPayment.replace(/[^0-9\.-]+/g, ""));
      const card = Number(cardPayment.replace(/[^0-9\.-]+/g, ""));
      values.push(["Cash payments", cash]);
      values.push(["Card payments", card]);
      const csvData = [...values];
      let csvContent =
        "data:text/csv;charset=utf-8," +
        csvData.map((data) => data.join(",")).join("\n");
      var encodedUri = encodeURI(csvContent);
      window.open(encodedUri);
    }
  };

  const loadingOrderSummary =
    loading && action.type === "FETCH_BATCH_ORDER_SUMMARY";

  const employee = `${capitalize(
    get(selectedBatchSummary, "user.firstName", "")
  )} ${capitalize(get(selectedBatchSummary, "user.lastName", " ").charAt(0))}`;

  return (
    <div className="flex flex-col p-6 w-full h-[100vh] justify-start">
      <div className="h-full grid lg:grid-cols-3 grid-cols-1 gap-7">
        <div className="flex flex-col gap-10 lg:col-span-2">
          <div className="flex flex-row gap-28">
            <img
              src={Back}
              alt="actions"
              className="w-12 h-12 cursor-pointer"
              onClick={(e) => {
                setOrdersSummary([]);
                router.navigate("/transaction");
              }}
            />
            <div className="flex flex-row gap-x-28 gap-y-4 flex-wrap">
              <div className="flex flex-col">
                <h3 className="font-bold text-2xl	text-midnight-express">
                  {grossSales}
                </h3>
                <h6 className="font-medium text-sm text-midnight-express">
                  {"Gross Sales"}
                </h6>
              </div>
              <div className="flex flex-col">
                <h3 className="font-bold text-2xl	text-midnight-express">
                  {salesCount}
                </h3>
                <h6 className="font-medium text-sm text-midnight-express">
                  {"Sales"}
                </h6>
              </div>
              <div className="flex flex-col">
                <h3 className="font-bold text-2xl	text-midnight-express">
                  {productCount}
                </h3>
                <h6 className="font-medium text-sm text-midnight-express">
                  {"Products"}
                </h6>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row w-full justify-between bg-lavender p-3 rounded-t-lg">
              <div className="flex flex-row gap-12">
                <h3 className="font-medium text-sm	text-valhalla">
                  {"Order Summary"}
                </h3>
                <h3 className="font-medium text-sm	text-hit-grey">
                  {"Cash In/Out"}
                </h3>
              </div>
              <img
                src={Dots}
                alt="actions"
                className="w-5 cursor-pointer"
                onClick={(e) => {}}
              />
            </div>
            {loadingOrderSummary ? (
              <CircularProgress size={15} style={{ color: "#fff" }} />
            ) : (
              <>
                {ordersSummary.map((order: any, index: number) => {
                  const price = formatPrice(
                    order.productPrice,
                    selectedShop.currency
                  );
                  return (
                    <div
                      key={index}
                      className="flex flex-row justify-between px-3 py-4 bg-white border-b hover:bg-gray-50"
                    >
                      <h3 className="font-normal text-center text-sm text-black-85">
                        {`${startCase(order.productName)} x ${order.quantity}`}
                      </h3>
                      <h3 className="font-normal text-center text-sm text-black-85">
                        {price}
                      </h3>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>

        <div className="flex flex-col">
          <div className="w-[165px] relative flex flex-row mb-4 self-end">
            <Select
              styles={customStyles}
              placeholder={"Export"}
              options={[
                { label: "Export as PDF", value: "PDF" },
                { label: "Export as CSV", value: "CSV" },
              ]}
              onChange={onChangeExport}
              className={
                "block w-[165px] rounded-md outline-none text-sm text-valhalla"
              }
            />
          </div>

          <div className="w-full justify-center items-center border border-ghost-white shadow">
            <div
              id="divToPrint"
              className="px-5 py-7 w-full justify-center items-center"
            >
              <div className="flex flex-col w-full items-center gap-3 mb-3">
                <h3 className="font-semibold text-center text-md text-black-85">
                  {selectedShop.shopName}
                </h3>
                <h3 className="max-w-[280px] font-normal text-center text-sm text-black-85">
                  {selectedShop.address}
                </h3>
                <div className="w-full border border-dashed border-black" />
              </div>
              <div className="flex flex-col gap-3">
                <TextTitle
                  small={true}
                  title={"Date"}
                  value={moment(selectedBatchSummary.updatedTime).format(
                    "MMM DD, yyyy"
                  )}
                />
                <TextTitle
                  small={true}
                  title={"Time"}
                  value={moment(selectedBatchSummary.updatedTime).format(
                    "hh:mm A"
                  )}
                />
                <TextTitle small={true} value={employee} />
              </div>
              <>
                {!isEmpty(salesSummary) &&
                  salesSummary.map((data: any) => {
                    return (
                      <>
                        <h3 className="mt-12 mb-6 font-semibold text-start text-lg text-black-85">
                          {data.header}
                        </h3>
                        <div className="flex flex-col gap-4">
                          {data.details.map((details: any, index: number) => {
                            return (
                              <TextTitle
                                key={index}
                                title={details.title}
                                value={details.value}
                              />
                            );
                          })}
                        </div>
                      </>
                    );
                  })}
              </>
              <>
                {Object.keys(batchOrderSummary).map((key: any) => {
                  const amount = formatPrice(
                    batchOrderSummary[key],
                    selectedShop.currency
                  );
                  return (
                    <>
                      <h3 className="mt-12 mb-6 font-semibold text-start text-lg text-black-85">
                        {startCase(key)}
                      </h3>
                      <div className="flex flex-col gap-3">
                        {isArray(batchOrderSummary[key]) ? (
                          batchOrderSummary[key].map(
                            (details: any, index: number) => {
                              const value =
                                details.amount ||
                                details.servicePrice ||
                                details.productPrice;
                              const price = formatPrice(
                                value,
                                selectedShop.currency
                              );
                              const name =
                                details.title ||
                                details.typeName ||
                                details.productName ||
                                details.serviceTitle;

                              return (
                                <TextTitle
                                  key={index}
                                  value={price}
                                  title={startCase(name)}
                                />
                              );
                            }
                          )
                        ) : (
                          <TextTitle
                            value={amount}
                            title={"Service Charge Summary"}
                          />
                        )}
                      </div>
                    </>
                  );
                })}
              </>
              <div className="flex flex-col gap-4 mt-12">
                <TextTitle title={"Cash payments"} value={cashPayment} />
                <TextTitle title={"Card payments"} value={cardPayment} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(
  (state: any) => ({
    loadingAction: state.common.get("loadingAction"),
    selectedShop: state.dashboard.get("selectedShop"),
    batchSummary: state.transaction.get("batchSummary"),
    selectedBatch: state.transaction.get("selectedBatch"),
    batchOrderSummary: state.transaction.get("batchOrderSummary"),
    selectedBatchSummary: state.transaction.get("selectedBatchSummary"),
    completedCashAppointment: state.transaction.get("completedCashAppointment"),
  }),
  {}
)(CloseCashDetails);

const TextTitle = ({ title, value, index = 0, small = false }: any) => {
  return (
    <div key={index} className="flex flex-row justify-between">
      <h3
        className={`font-normal text-center text-black-85 ${
          small ? "text-xs" : "text-sm"
        }`}
      >
        {title}
      </h3>
      <h3
        className={`font-normal text-center text-black-85 ${
          small ? "text-xs" : "text-sm"
        }`}
      >
        {value}
      </h3>
    </div>
  );
};
