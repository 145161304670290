import { get } from "lodash";
import Repository from "./Repository";
import EmployeeRepository from "./EmployeeRepository";

class CloseCashRepository extends Repository {

  async fetchBatchSummary(shopId: string, userId: string, limit: number, lastKey: any) {
    const params = {
      shopId,
      userId,
      limit,
      lastKey,
      endpointType: 'USER_PASS_BATCH',
    };
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.API_POS_LAMBDA,
        path: `/batches`,
        message,
      });
      const items = get(result, 'data.batchResult.Items', [])
      const lastKey = get(result, 'data.batchResult.LastEvaluatedKey', {})
      const users: any = {};

      await Promise.all(
        items.map(async (summary: any) => {
          if (users[userId]) {
            summary.user = users[userId];
          } else {
            summary.user = await EmployeeRepository.getEmployee(summary.shopId, summary.createdUser)
            users[userId] = summary.user;
          }
        }),
      );
      return {items,  lastKey};
    } catch (error) {
      console.warn('close cash', error);
      return {error};
    }
  };

  async fetchBatchSummaryById(batchId: any) {
    const params = {
      batchId,
      endpointType: 'CLOSED_CASH_SUMMARY',
    };
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.API_POS_LAMBDA,
        path: `/close-cash`,
        message,
      });
      return result.data.batchResult;
    } catch (error) {
      console.warn('close cash', error);
      return {error};
    }
  };

  async completedCloseCashById(batchId: string, shopId: string, limit: number, lastKey: any) {
    const params = {
      limit,
      shopId,
      lastKey,
      batchId,
      endpointType: 'BATCH_APPOINTMENT',
    };
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.API_POS_LAMBDA,
        path: `/orders`,
        message,
      });
      return result.data.appointmentResult;
    } catch (error) {
      console.warn('close cash', error);
      return {error};
    }
  };

  async fetchBatchOrderSummary(batchId:string, shopId: string) {
    try {
      const result = await this.apiGet({
        apiName: this.API_NAME,
        path: `/batch/summary?shopId=${shopId}&batchId=${batchId}`,
        message: null,
      });
      return result.data;
    } catch (error) {
      console.warn("fetchBatchOrderSummary", error);
      return { error };
    }
  }


  createCash = async (params: any) => {
    params.endpointType = 'CASH_OPERATION';
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.API_POS_LAMBDA,
        path: `/close-cash`,
        message,
      });
      return result.batchResult;
    } catch (error) {
      console.warn('create cash', error);
      return {error};
    }
  };

  closeCash = async (params: any) => {
    params.endpointType = 'CLOSED_CASH';
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.API_POS_LAMBDA,
        path: `/close-cash`,
        message,
      });
      return result.batchResult;
    } catch (error) {
      console.warn('close cash', error);
      return {error};
    }
  };

  getBatchSession = async (batchId: string) => {
    try {
      const result = await this.apiGet({
        apiName: this.API_POS_LAMBDA,
        path: `/batch?batchId=${batchId}`,
      });
      return result.batchResult;
    } catch (error) {
      console.warn('close cash', error);
      return {error};
    }
  };



  async getBatchSummary(batchId: string) {
    const params = {
      batchId,
      endpointType: 'CLOSED_CASH_SUMMARY',
    };
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.API_POS_LAMBDA,
        path: `/close-cash`,
        message,
      });
      return result.batchResult;
    } catch (error) {
      console.warn('close cash', error);
      return {error};
    }
  };

  async createBatch(shopId: string, empId: string) {
    const message = this.buildMessage({shopId, empId});
    try {
      const result = await this.apiPost({
        apiName: this.API_POS_LAMBDA,
        path: `/batch`,
        message,
      });
      return result.batchResult;
    } catch (error) {
      console.warn('close cash', error);
      return {error};
    }
  };

}

export default new CloseCashRepository();
