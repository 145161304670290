import { get } from "lodash";
import Repository from "./Repository";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import { isNetworkError } from "../managers/SecurityManager";
import { CreateOffer, DeleteOffer, FetchOffers, UpdateOffer } from "./schema/Product.schema";

class OfferRepository extends Repository {
  fetchOffers = async (
    shopId,
    limit,
    nextToken,
    retryCount = 1
  ) => {
    try {
      const params = {
        shopId,
        limit,
        nextToken,
      };
      const result = await this.API.graphql(
        graphqlOperation(FetchOffers, params)
      );
      return get(result, "data.fetchOffers", []);
    } catch (error) {
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchOffers(
          shopId,
          limit,
          nextToken,
          ++retryCount
        );
      }
      console.warn("fetch offers error", error);
      return { error };
    }
  };


  async createOffer(param) {
    try {
      param['createdTime'] = Date.now();
      param['updatedTime'] = Date.now();
      const result = await this.API.graphql(
        graphqlOperation(CreateOffer, { input: param })
      );
      return result.data.createOffer;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async updateOffer(param) {
    try {
      param['updatedTime'] = Date.now();
      const result = await this.API.graphql(
        graphqlOperation(UpdateOffer, { input: param })
      );
      return result.data.updateOffer;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async deleteOffer(shopId, id) {
    try {
      const result = await this.API.graphql(
        graphqlOperation(DeleteOffer, { input: { shopId, id } })
      );
      return result.data.deleteOffer;
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  }


}
export default new OfferRepository();
