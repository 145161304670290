import { useEffect, useRef, useState } from "react";
import {
  get,
  find,
  filter,
  isEmpty,
  compact,
  orderBy,
  findIndex,
} from "lodash";
import moment from "moment";
import { jsPDF } from "jspdf";
import { connect } from "react-redux";
import html2canvas from "html2canvas";
import Table from "../../../components/Table";
import { CircularProgress } from "@material-ui/core";
import {
  getCloseCashTableData,
  getDateRangeTableData,
} from "../../../internals/managers/CloseCashManager";
import { Actions } from "../../../internals/app/Actions";
import CloseCashHeader from "../../../components/CloseCashHeader";
import { DateRangeTableHeader, CloseCashTableHeader } from "../Constant";
import { formatPrice } from "../../../internals/managers/PaymentManager";

const CloseCash = (props: any) => {
  const {
    currentUser,
    selectedShop,
    batchSummary,
    loadingAction,
    closeCashForRange,
    isSelectedDateRange,
    fetchBatchSummaryById,
    fetchPaginateBatchSummary,
    fetchPaginateCloseCashForDateRange,
  } = props;
  const cashInnerRef = useRef<any>();
  const { action, loading } = loadingAction;
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedIndexes, setSelectedIndexes] = useState<Array<string>>([]);
  const [closeCashTableData, setCloseCashTableData] = useState<Array<any>>([]);
  const [dateRangeTableData, setDateRangeTableData] = useState<Array<any>>([]);
  const [selectedDateRange, setSelectedDateRange] = useState({
    key: "selection",
    endDate: new Date(),
    startDate: new Date(),
  });

  useEffect(() => {
    if (!isEmpty(closeCashForRange.closeCash)) {
      const tableData = getDateRangeTableData(closeCashForRange.closeCash);
      const orderData = orderBy(tableData, ["START DATE"], ["desc"]);
      setDateRangeTableData(orderData);
    } else {
      setDateRangeTableData([]);
    }
  }, [closeCashForRange]);

  useEffect(() => {
    if (batchSummary.batchSummary) {
      const tableData = getCloseCashTableData(
        batchSummary.batchSummary,
        selectedShop
      );
      const orderData = orderBy(tableData, ["END DATE"], ["desc"]);
      setCloseCashTableData(orderData);
    } else {
      setCloseCashTableData([]);
    }
  }, [batchSummary, isSelectedDateRange]);

  const onCheckAll = () => {
    if (allSelected) {
      setSelectedIndexes([]);
    }
    setAllSelected(!allSelected);
  };

  const onSelectedItem = (data: any) => {
    const summary = find(
      batchSummary.batchSummary,
      (item) => item.batchId === data.id
    );
    const rangeSummary = find(
      closeCashForRange.closeCash,
      (item) => item.batchId === data.id
    );
    fetchBatchSummaryById(
      isSelectedDateRange
        ? { ...rangeSummary, shopId: selectedShop.shopId }
        : summary
    );
  };

  const onCheckItem = (data: any) => {
    const index = findIndex(
      selectedIndexes,
      (item: string) => item === data.id
    );
    if (allSelected) {
      setAllSelected(false);
      const ids: Array<string> = closeCashForRange.closeCash.map(
        ({ batchId }: any) => {
          if (batchId !== data.id) {
            return batchId;
          }
        }
      );
      setSelectedIndexes(compact(ids));
    } else {
      if (index > -1) {
        setAllSelected(false);
        const values: any = filter(
          selectedIndexes,
          (item: string) => item != data.id
        );
        setSelectedIndexes([...values]);
      } else {
        selectedIndexes.push(data.id);
        setSelectedIndexes([...selectedIndexes]);
      }
    }
  };

  const loadingData = () => {
    if (!isSelectedDateRange && !isEmpty(batchSummary.lastKey)) {
      fetchPaginateBatchSummary(
        selectedShop.shopId,
        currentUser.uid,
        30,
        batchSummary.lastKey
      );
    } else if (isSelectedDateRange && !isEmpty(closeCashForRange.lastKey)) {
      const endDate = moment(
        new Date(selectedDateRange.endDate).toDateString()
      ).valueOf();
      const startDate = moment(
        new Date(selectedDateRange.startDate).toDateString()
      ).valueOf();
      const params = {
        endDate,
        startDate,
        shopId: selectedShop.shopId,
      };
      fetchPaginateCloseCashForDateRange(params, 30, closeCashForRange.lastKey);
    }
  };

  const onChangeExport = (option: any) => {
    if (allSelected || !isEmpty(selectedIndexes)) {
      if (option.value === "PDF") {
        const doc = new jsPDF("p", "mm");

        let headerHtml: any = document.getElementById("table-head-print");
        let clonedHeader = headerHtml.cloneNode(true);
        if (clonedHeader.firstChild) {
          clonedHeader.removeChild(clonedHeader.firstChild);
        }

        const tbody = document.createElement("tbody");
        closeCashForRange.closeCash?.map((closeCash: any) => {
          const index = findIndex(
            selectedIndexes,
            (item: string) => item === closeCash.batchId
          );
          if (allSelected || index > -1) {
            let itemHtml: any = document.getElementById(closeCash.batchId);
            let clonedItem = itemHtml.cloneNode(true);
            if (clonedItem.firstChild) {
              clonedItem.removeChild(clonedItem.firstChild);
            }
            tbody.innerHTML += clonedItem.innerHTML;
          }
        });

        const table = document.createElement("table");
        const myHTMLString = clonedHeader.innerHTML + tbody.innerHTML;
        table.innerHTML = myHTMLString;
        document.body.appendChild(table);

        html2canvas(table).then((canvas: any) => {
          const imgWidth = 210;
          const pageHeight = 295;

          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          let position = 0;
          heightLeft -= pageHeight;
          doc.addImage(
            canvas,
            "PNG",
            0,
            position,
            imgWidth,
            imgHeight,
            "",
            "FAST"
          );
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(
              canvas,
              "PNG",
              0,
              position,
              imgWidth,
              imgHeight,
              "",
              "FAST"
            );
            heightLeft -= pageHeight;
          }
          let title = moment().format("yyyy-MM-DD");
          doc.save(`invoices-${title}.pdf`);
        });
        document.body.removeChild(table);
      } else {
        const data = closeCashForRange.closeCash?.map((item: any) => {
          const isSelect =
            findIndex(selectedIndexes, (id: string) => id === item.batchId) >
            -1;
          if (isSelect || allSelected) {
            const time = `${moment(item.createdTime).format(
              " hh:mmA DD/MM"
            )} - ${moment(item.updatedTime).format(" hh:mmA DD/MM")}`;
            const name = `${get(item, "user.firstName", "")} ${get(
              item,
              "user.lastName",
              ""
            )}`;
            const productCount = get(item, "salesSummary.productCounts", 0);

        
            const startCash = get(item, "drawerSummary.startingCash", 0);
            const cash = get(item, "drawerSummary.totalCashSalesPrice", 0);
            const card = get(item, "drawerSummary.totalCardSalesPrice", 0);
            const refund = get(item, "drawerSummary.totalRefundPrice", 0);
            const cashInOut = get(item, "drawerSummary.cashIn/cashOut", 0);
            // const startCash = formatPrice(
            //   get(item, "drawerSummary.startingCash", 0),
            //   selectedShop.currency
            // );

            // const cash = formatPrice(
            //   get(item, "drawerSummary.totalCashSalesPrice", 0),
            //   selectedShop.currency
            // );

            // const card = formatPrice(
            //   get(item, "drawerSummary.totalCardSalesPrice", 0),
            //   selectedShop.currency
            // );

            // const refund = formatPrice(
            //   get(item, "drawerSummary.totalRefundPrice", 0),
            //   selectedShop.currency
            // );
            // const cashInOut = formatPrice(
            //   get(item, "drawerSummary.cashIn/cashOut", 0),
            //   selectedShop.currency
            // );

            const total =
              get(item, "drawerSummary.cashIn/cashOut", 0) +
              get(item, "drawerSummary.totalCashSalesPrice", 0) -
              get(item, "drawerSummary.totalRefundPrice", 0);
            // const amout = formatPrice(total, selectedShop.currency);
            const cardTotalSale = get(
              item,
              "drawerSummary.totalCardSalesPrice",
              0
            );
            const netTotal = total + cardTotalSale;
            // const netTotal = formatPrice(
            //   total + cardTotalSale,
            //   selectedShop.currency
            // );
            return [
              time,
              name,
              productCount,
              startCash,
              cash,
              card,
              refund,
              cashInOut,
              total,
              netTotal,
            ];
          }
        });
        const csvData: any = [DateRangeTableHeader, ...compact(data)];
        let csvContent =
          "data:text/csv;charset=utf-8" +
          csvData.map((data: any) => data.join(",")).join("\n");
        var link = window.document.createElement("a");
        link.setAttribute("href", encodeURI(csvContent));
        let title = moment().format("yyyy-MM-DD");
        link.setAttribute("download", `closecash-${title}.csv`);
        link.click();
      }
    }
  };

  const loadingPaginate =
    loading &&
    (action.type === "FETCH_PAGINATE_BATCH_SUMMARY" ||
      action.type === "FETCH_PAGINATE_CLOSECASH_FOR_DATERANGE");

  return (
    <>
      <CloseCashHeader
        dateRange={selectedDateRange}
        onChangeExport={onChangeExport}
        onSelectDateRange={(ranges: any) => {
          setSelectedDateRange(ranges.selection);
        }}
      />
      <div ref={cashInnerRef} className={"overflow-auto relative"}>
        <Table
          type="CLOSE_CASH"
          isSortIcon={false}
          allSelected={allSelected}
          selectedIndexes={selectedIndexes}
          {...{
            tableData: isSelectedDateRange
              ? dateRangeTableData
              : closeCashTableData,
            tableHeader: isSelectedDateRange
              ? DateRangeTableHeader
              : CloseCashTableHeader,
          }}
          onCheckAll={() => onCheckAll()}
          isSelectTimePeriod={isSelectedDateRange}
          onCheckItem={(data: any) => onCheckItem(data)}
          onSelectedItem={(data: any) => onSelectedItem(data)}
        />
        {(!isSelectedDateRange && !isEmpty(batchSummary.lastKey)) ||
          (isSelectedDateRange && !isEmpty(closeCashForRange.lastKey) && (
            <div className="flex w-full items-end justify-end">
              <button
                type="button"
                className="inline-flex w-20 mt-4 gap-4 justify-center items-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-indigo-700"
                onClick={() => loadingData()}
              >
                {loadingPaginate ? (
                  <CircularProgress size={18} style={{ color: "#EFF0F8" }} />
                ) : (
                  "Next"
                )}
              </button>
            </div>
          ))}
      </div>
    </>
  );
};
export default connect(
  (state: any) => ({
    alertStatus: state.common.get("alertStatus"),
    currentUser: state.setting.get("currentUser"),
    loadingAction: state.common.get("loadingAction"),
    selectedShop: state.dashboard.get("selectedShop"),
    batchSummary: state.transaction.get("batchSummary"),
    selectedBatch: state.transaction.get("selectedBatch"),
    selectedScreen: state.transaction.get("selectedScreen"),
    closeCashForRange: state.transaction.get("closeCashForRange"),
    isSelectedDateRange: state.transaction.get("isSelectedDateRange"),
  }),
  {
    openAlertBox: Actions.common.openAlertBox,
    setSelectedScreen: Actions.transaction.setSelectedScreen,
    onSelectedDateRange: Actions.transaction.onSelectedDateRange,
    fetchBatchSummaryById: Actions.transaction.fetchBatchSummaryById,
    fetchOrderTransactions: Actions.transaction.fetchOrderTransactions,
    fetchPaginateBatchSummary: Actions.transaction.fetchPaginateBatchSummary,
    fetchPaginateCloseCashForDateRange:
      Actions.transaction.fetchPaginateCloseCashForDateRange,
  }
)(CloseCash);
