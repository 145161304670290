import { delay } from "lodash";
import Swal from "sweetalert2";
import { ModuleEvents } from "./Actions";
import { Actions } from "../../internals/app/Actions";
import withReactContent from "sweetalert2-react-content";

export default {
  [ModuleEvents.CREATE_OFFER]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      dispatch(Actions.common.openAlertBox("CREATE", true, "SUCCESS"));
      delay(function (text) {
        dispatch(Actions.common.openAlertBox("CREATE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning("Error occurred while creating the offer");
    }
  },

  [ModuleEvents.UPDATE_OFFER]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      dispatch(Actions.common.openAlertBox("UPDATE", true, "SUCCESS"));
      delay(function (text) {
        dispatch(Actions.common.openAlertBox("UPDATE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning("Error occurred while updating the offer");
    }
  },

  [ModuleEvents.DELETE_OFFER]: ({ dispatch, payload, appState }) => {
    if (payload) {
      dispatch(Actions.common.openAlertBox("DELETE", true, "SUCCESS"));
      delay(function (text) {
        dispatch(Actions.common.openAlertBox("DELETE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning("Offer deleted unsuccessfully");
    }
  },


};

const warning = (alert) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    icon: "error",
    text: alert,
    title: "Oops...",
    showCancelButton: true,
    showConfirmButton: false,
    cancelButtonColor: "#FF3333",
  });
};
