import ModifierRepository from "../../internals/repository/ModifierRepository";

export const ModuleEvents = {
  CREATE_MODIFIER: "CREATE_MODIFIER",
  UPDATE_MODIFIER: "UPDATE_MODIFIER",
  FETCH_MODIFIERS: "FETCH_MODIFIERS",
  DELETE_MODIFIER: "DELETE_MODIFIER"
};

const createAction =
  (type, action = () => { }, meta) =>
    (...args) => ({
      type,
      payload: action(...args),
      meta,
    });

const fetchModifiers = createAction(
  ModuleEvents.FETCH_MODIFIERS,
  async (shopId, limit = 100, nextToken = null) => {
    const result = await ModifierRepository.fetchModifiers(
      shopId,
      limit,
      nextToken
    );
    return result;
  }
);
const createModifier = createAction(
  ModuleEvents.CREATE_MODIFIER,
  async (param) => {
    const result = await ModifierRepository.createModifier(param);
    return result;
  },
  { loading: true }
);
const updateModifier = createAction(
  ModuleEvents.UPDATE_MODIFIER,
  async (param) => {
    const result = await ModifierRepository.updateModifier(param);
    return result;
  },
  { loading: true }
);
const deleteModifier = createAction(
  ModuleEvents.DELETE_MODIFIER,
  async (id, shopId) => {
    const result = await ModifierRepository.deleteModifier(id, shopId);
    return result;
  }
);

const exportedFuction = {
  fetchModifiers,
  createModifier,
  updateModifier,
  deleteModifier
};

export default exportedFuction;
