import {Map} from 'immutable';
import {ModuleEvents} from './Actions';
import {findIndex, orderBy, filter, isArray} from 'lodash';

const initialState = Map({
  imageId: '',
  menuImage: '',
  products: {
    products: [],
    nextLastKey: null,
    lastKeyList: [],
    totalCount: 0,
  },
  tags: [],
  brands: [],
  optionSets: [],
  categories: [],
});

export const Reducer = (state = initialState, action) => {
  const {payload, type, error} = action;

  switch (type) {
    case ModuleEvents.FETCH_BRANDS: {
      if (payload && !payload.error) {
        const sortResult = orderBy(payload.Items, ['updatedTime'], ['desc']);
        return state.set('brands', [...sortResult]);
      }
      return state;
    }
    case ModuleEvents.FETCH_TAGS: {
      if (payload && !payload.error && isArray(payload.items)) {
        return state.set('tags', payload.items);
      }
      return state;
    }
    case ModuleEvents.CREATE_PRODUCT_TAG: {
      if (payload && !payload.error) {
        const tags = state.get('tags');
        return state.set('tags', [...tags, payload]);
      }
      return state;
    }
    case ModuleEvents.CREATE_BRAND: {
      if (payload && !payload.error) {
        const brands = state.get('brands');
        brands.push(payload);
        const sortedBrands = orderBy(brands, ['updatedTime'], ['desc']);
        return state.set('brands', [...sortedBrands]);
      }
      return state;
    }
    case ModuleEvents.UPDATE_BRAND: {
      if (payload && !payload.error) {
        const brands = state.get('brands');
        const index = findIndex(brands, ({id}) => id === payload.id);
        brands[index] = payload;
        const sortedBrands = orderBy(brands, ['updatedTime'], ['desc']);
        return state.set('brands', [...sortedBrands]);
      }
      return state;
    }
    case ModuleEvents.DELETE_BRAND: {
      if (payload && !payload.error) {
        const brands = state.get('brands');
        const filterBrand = filter(brands, ({id}) => id !== payload.id);
        const sortedBrands = orderBy(filterBrand, ['updatedTime'], ['desc']);
        return state.set('brands', [...sortedBrands]);
      }
      return state;
    }

    case ModuleEvents.FETCH_CATEGORIES: {
      if (payload && !payload.error) {
        const sortResult = orderBy(payload, ['updatedTime'], ['desc']);
        return state.set('categories', [...sortResult]);
      }
      return state;
    }
    case ModuleEvents.CREATE_CATEGORY: {
      if (payload && !payload.error) {
        let categories = state.get('categories');
        categories.push(payload);
        const sortResult = orderBy(categories, ['updatedTime'], ['desc']);
        return state.set('categories', [...sortResult]);
      }
      return state;
    }
    case ModuleEvents.UPDATE_CATEGORY: {
      if (payload && !payload.error) {
        let categories = state.get('categories');
        const index = findIndex(
          categories,
          (category) => category.categoryId === payload.categoryId
        );
        categories[index] = payload;
        const sortResult = orderBy(categories, ['updatedTime'], ['desc']);
        return state.set('categories', [...sortResult]);
      }
      return state;
    }

    case ModuleEvents.FETCH_PRODUCTS: {
      let products = state.get('products');
      if (payload && !payload.error) {
        const sortResult = orderBy(payload.Items, ['updatedTime'], ['asc']);
        products.products = [...sortResult];
        products.nextLastKey = payload.LastEvaluatedKey;
        products.totalCount = payload.totalCount;
        return state.set('products', {...products});
      }
      return state;
    }
    case ModuleEvents.CREATE_PRODUCT: {
      if (payload && !payload.error) {
        let products = state.get('products');
        products.products.push(payload);
        const sortResult = orderBy(
          products.products,
          ['updatedTime'],
          ['desc']
        );
        products.products = [...sortResult];
        return state.set('products', {...products});
      }
      return state;
    }
    case ModuleEvents.UPDATE_PRODUCT: {
      if (payload && !payload.error) {
        let products = state.get('products');
        const index = findIndex(
          products.products,
          (product) => product.pid === payload.pid
        );
        products.products[index] = payload;
        const sortResult = orderBy(
          products.products,
          ['updatedTime'],
          ['desc']
        );
        products.products = [...sortResult];
        return state.set('products', {...products});
      }
      return state;
    }

    case ModuleEvents.UPLOAD_IMAGE: {
      if (payload && !payload.result?.error) {
        return state.set(
          payload.type === 'menuImage' ? 'menuImage' : 'imageId',
          payload.result
        );
      }
      return state;
    }
    case ModuleEvents.SET_IMAGE: {
      return state.set('imageId', payload);
    }

    case ModuleEvents.REMOVE_IMAGE: {
      return state.set('imageId', '');
    }

    case ModuleEvents.FETCH_PRODUCT_OPTION_SET: {
      if (payload && !payload.error) {
        const optionSets = state.get('optionSets');
        let sets = orderBy(payload.items, ['setName'], ['asc']);
        return state.set('optionSets', [...sets]);
      }
      return state;
    }

    default:
      return state;
  }
};

export default Reducer;
