import { Map } from "immutable";
import { ModuleEvents } from "./Actions";
import { reverse, sortBy, get } from "lodash";

const initialState = Map({
  transactions: {
    transactions: [],
    nextLastKey: null,
    lastKeyList: [],
  },
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.FETCH_ORDER_TRANSACTIONS: {
      const transactions = state.get("transactions");
      if (payload && !payload.error) {
        const sortTransaction = reverse(sortBy(payload.Items, "createdTime"));
        const appointmentLastKey = get(payload, "LastEvaluatedKey", {});
        transactions.transactions = sortTransaction;
        transactions.nextLastKey = appointmentLastKey;
        return state.set("transactions", { ...transactions });
      }
      return state;
    }

    default:
      return state;
  }
};

export default Reducer;
