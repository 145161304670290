import gql from 'graphql-tag';

export const CreateDiscount = gql`
  mutation CreateDiscount($input: CreateDiscountsInput!) {
    createDiscounts(input: $input) {
      shopId
      updatedAt
      discounts {
        inId
        discId
        title
        desc
        discType
        amountType
        amount
      }
    }
  }
`;
export const UpdateDiscount = gql`
  mutation UpdateDiscount($input: UpdateDiscountsInput!) {
    updateDiscounts(input: $input) {
      shopId
      updatedAt
      discounts {
        inId
        discId
        title
        desc
        discType
        amountType
        amount
      }
    }
  }
`;

export const GetDiscounts = gql`
  query GetDiscounts($shopId: String!) {
    getDiscounts(shopId: $shopId) {
      shopId
      updatedAt
      discounts {
        inId
        discId
        title
        desc
        discType
        amountType
        amount
      }
    }
  }
`;
