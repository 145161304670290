import { useState } from "react";
import moment from "moment";
import "../../layouts/Styles.css";
import Select from "react-select";
import { connect } from "react-redux";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { Actions } from "../../internals/app/Actions";

const customStyles = {
  control: (base: any) => ({
    ...base,
    height: "100%",
    boxShadow: "none",
    borderColor: "#D1D5DB",
    "&:hover": {
      borderColor: "#D1D5DB",
    },
  }),
};

const CloseCashHeader = (props: any) => {
  const {
    dateRange,
    selectedShop,
    loadingAction,
    onChangeExport,
    onSelectDateRange,
    isSelectedDateRange,
    onSelectedDateRange,
    fetchCloseCashForDateRange,
  } = props;
  const [openDateRange, setOpenDateRange] = useState(false);

  const toggleOpenDateRange = (event: any) => {
    event.preventDefault();
    setOpenDateRange(!openDateRange);
  };

  const filterCloseCashForDateRange = () => {
    const endDate = moment(
      new Date(dateRange.endDate).toDateString()
    ).valueOf();
    const startDate = moment(
      new Date(dateRange.startDate).toDateString()
    ).valueOf();
    const params = {
      endDate,
      startDate,
      shopId: selectedShop.shopId,
    };
    fetchCloseCashForDateRange(params);
    setOpenDateRange(false);
  };

  const resetDate = () => {
    setOpenDateRange(false);
    onSelectedDateRange(false);
  };

  return (
    <div className="flex flex-row gap-4 xl:gap-8 w-full justify-end mb-4">
      <div className="flex relative h-[38px]">
        <div
          onClick={toggleOpenDateRange}
          className="flex flex-row gap-2 items-center justify-center px-4 h-[38px] rounded-md border border-[#D1D5DB] text-sm"
        >
          <div className="text-valhalla text-sm cursor-pointer">
            {isSelectedDateRange
              ? moment(dateRange.startDate).format("DD-MM-YYYY")
              : "Start date"}
          </div>
          {"-"}
          <div className="text-valhalla text-sm cursor-pointer">
            {isSelectedDateRange
              ? moment(dateRange.endDate).format("DD-MM-YYYY")
              : "End date"}
          </div>
        </div>

        {openDateRange ? (
          <div className="absolute z-50 top-11 right-0 bg-ghost-white shadow">
            <DateRangePicker
              ranges={[dateRange]}
              onChange={onSelectDateRange}
            />
            <div className="flex gap-4 justify-end p-2">
              <button
                type="button"
                onClick={() => {
                  onSelectedDateRange(true);
                  filterCloseCashForDateRange();
                }}
                className="inline-flex justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white shadow bg-medium-slate-blue hover:bg-slate-blue"
              >
                {"Filter"}
              </button>
              <button
                type="button"
                onClick={() => resetDate()}
                className="inline-flex justify-center rounded-md border border-[#BFC0C6] py-2 px-4 text-sm font-medium text-[#999A9E] shadow bg-lavender"
              >
                {"Reset"}
              </button>
            </div>
          </div>
        ) : null}
      </div>
      {isSelectedDateRange && (
        <div className="w-[165px] relative flex flex-row self-end">
          <Select
            styles={customStyles}
            placeholder={"Export"}
            options={[
              { label: "Export as PDF", value: "PDF" },
              { label: "Export as CSV", value: "CSV" },
            ]}
            onChange={onChangeExport}
            className={
              "block w-[165px] rounded-md outline-none text-sm text-valhalla"
            }
          />
        </div>
      )}
    </div>
  );
};
export default connect(
  (state: any) => ({
    loadingAction: state.common.get("loadingAction"),
    employeeList: state.employee.get("employeeList"),
    selectedShop: state.dashboard.get("selectedShop"),
    batchSummary: state.transaction.get("batchSummary"),
    isSelectedDateRange: state.transaction.get("isSelectedDateRange"),
  }),
  {
    onSelectedDateRange: Actions.transaction.onSelectedDateRange,
    fetchOrderTransactions: Actions.transaction.fetchOrderTransactions,
    searchOrderTransactions: Actions.transaction.searchOrderTransactions,
    fetchCloseCashForDateRange: Actions.transaction.fetchCloseCashForDateRange,
  }
)(CloseCashHeader);
