import PlusIcon from '../../assets/svgs/plus-icon.svg';

const MenuAddButton = ({onClick}: any) => {
  return (
    <div className="flex bg-white" onClick={onClick}>
      <button className="my-2 flex-1 mx-4 border flex bg-white items-center justify-center rounded-md px-4 py-2 text-sm font-semibold text-liberty-blue">
        Add Menu
        {/* <PlusIcon /> */}
        <img src={PlusIcon} className="h-4 ml-2" />
      </button>
    </div>
  );
};

export default MenuAddButton;
