import React, { useState } from "react";
import "../../layouts/Styles.css";
import { connect } from "react-redux";
import { mapReportSummary } from "../../internals/managers/ReportsManager";
import { formatPrice } from "../../internals/managers/PaymentManager";

const Summary = (props: any) => {
  const { dayReportSummary, selectedShop } = props;
  const mapSummaryList = mapReportSummary(dayReportSummary);
  const currency = selectedShop?.currency;

  return (
    <div className="flex flex-row items-start gap-6 mb-4">
      {mapSummaryList.map((summary: any, index: number) => {
        return (
          <div
            key={index}
            style={{ minHeight: "126px" }}
            className="w-full p-4 max-w-sm bg-white rounded-lg shadow-md"
          >
            <h5 className="text-md tracking-tight text-gray-900 ">
              {summary.name}
            </h5>
            <div className="flex items-center mt-3 mb-2.5 justify-between">
              <span className="text-xl font-semibold text-gray-900 ">
                {summary.type === "CUSTOMERS"
                  ? summary.totalCount
                  : formatPrice(summary.totalPrice, currency)}
              </span>
              <div
                className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2.5 py-1 text-center"
                style={{
                  backgroundColor:
                    summary.type === "PRODUCTS" ? "#6D71F9" : "#54C1FB",
                }}
              >
                {summary.growth > 0 ? `+${summary.growth}` : summary.growth} %
              </div>
            </div>

            <div className="flex items-center">
              {summary.type !== "CUSTOMERS" && (
                <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded  dark:text-blue-800">
                  {summary.text}: {summary.totalCount}
                </span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default connect(
  (state: any) => ({
    selectedShop: state.dashboard.get("selectedShop"),
    dayReportSummary: state.dashboard.get("dayReportSummary"),
  }),
  () => ({})
)(Summary);
