import CheckIcon from '../../../assets/svgs/check-icon.svg';
import CloseIcon from '../../../assets/svgs/close-circle-icon.svg';
import MenuSectionButton from '../../../components/Menu/MenuSectionButton';
import MenuAddButton from '../../../components/Menu/MenuAddButton';
import {connect} from 'react-redux';
import {useState} from 'react';
import Select from 'react-select';
import {differenceBy, startCase} from 'lodash';
import {Actions} from '../../../internals/app/Actions';

const MenuGrid = ({
  onClick,
  menus,
  categories,
  products,
  updateMenu,
  onPressEditMenu,
}: any) => {
  const [selectedMenu, setSelectedMenu] = useState<any>(null);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [showCategories, setShowCategories] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<Array<any>>([]);
  const [product, setProduct] = useState<any>(null);
  const [selectedCategories, setSelectedCategories] = useState<Array<any>>([]);
  const [category, setCategory] = useState<any>(null);
  const [showProducts, setShowProducts] = useState(false);
  const [editingItem, setEditingItem] = useState<any>(null);

  const categoryOptions = differenceBy(
    categories,
    selectedCategories,
    'categoryId'
  ).map(({categoryName, categoryId}: any) => ({
    label: categoryName,
    value: categoryId,
  }));

  const filteredProducts = selectedCategory
    ? products?.products?.filter(
        (prod: any) => prod.categoryId === selectedCategory.categoryId
      )
    : [];

  const productOptions = selectedCategory
    ? differenceBy(
        filteredProducts,
        selectedCategory.products || [],
        (p: any) => p.pid || p.productId
      ).map(({productName, pid}: any) => ({
        label: startCase(productName),
        value: pid,
      }))
    : [];

  return (
    <div className="grid grid-cols-3 h-full gap-2">
      <div className=" bg-grid-bg h-full ">
        {menus.map((menu: any, index: number) => (
          <MenuSectionButton
            title={menu.title}
            onEdit={onPressEditMenu}
            onSelect={(data: any) => {
              setSelectedMenu(data);
              setSelectedCategories(
                data.categories?.map((cat: any) => {
                  const category = categories.find(
                    ({categoryId}: any) => categoryId === cat?.categoryId
                  );
                  return {...category, ...cat};
                }) || []
              );
              setSelectedProducts([]);
            }}
            onPositionChange={(move: 'up' | 'down') => {
              if (
                (move === 'up' && index === 0) ||
                (move === 'down' && index === selectedProducts.length - 1)
              ) {
                return;
              }
              menus.forEach((menu: any, idx: number) => {
                if (index === idx) {
                  const destIndex = move === 'up' ? idx - 1 : idx + 1;

                  const m = menus[destIndex] ? menus[destIndex] : menus[index];
                  m.position = move === 'up' ? idx : idx - 1;
                  menus[index].position = move === 'up' ? idx - 1 : idx;
                  updateMenu(menu);
                  updateMenu(m);
                }
              });
            }}
            data={menu}
            selected={menu.menuId === selectedMenu?.menuId}
            key={menu.menuId}
          />
        ))}
        <MenuAddButton onClick={onClick} />
      </div>
      <div className="bg-grid-bg h-full">
        {selectedCategories.map((category, index: number) => (
          <MenuSectionButton
            title={category.categoryName}
            onEdit={setEditingItem}
            isEdit={editingItem?.categoryId === category.categoryId}
            onSave={() => setEditingItem(null)}
            onDelete={(data: any) => {
              const changingCategories = [...selectedMenu.categories];
              selectedMenu?.categories?.forEach(
                (category: any, idx: number) => {
                  if (data.categoryId === category.categoryId) {
                    changingCategories.splice(idx, 1)
                    selectedMenu.categories = changingCategories;
                    
                    updateMenu(selectedMenu);
                  }
                }
              );
              setSelectedCategories(
                changingCategories?.map((cat: any) => {
                  const category = categories.find(
                    ({categoryId}: any) => categoryId === cat?.categoryId
                  );
                  return {...category, ...cat};
                }) || []
              );
              setSelectedProducts([]);
              setEditingItem(null);
            }}
            onSelect={(data: any) => {
              setSelectedCategory(data);
              const prods = data.products || [];
              setSelectedProducts(
                prods.map(({productId}: any) =>
                  products?.products.find((prod: any) => prod.pid === productId)
                ) || []
              );
              setShowProducts(false);
            }}
            onPositionChange={(move: 'up' | 'down') => {
              if (
                (move === 'up' && index === 0) ||
                (move === 'down' && index === selectedProducts.length - 1)
              ) {
                return;
              }
              const changingCategories = [...selectedMenu.categories];

              selectedMenu?.categories?.forEach(
                (category: any, idx: number) => {
                  if (index === idx) {
                    const destIndex = move === 'up' ? idx - 1 : idx + 1;
                    changingCategories[destIndex] = category;
                    changingCategories[idx] =
                      selectedMenu?.categories[destIndex];

                    selectedMenu.categories = changingCategories;
                    setSelectedCategories(
                      changingCategories?.map((cat: any) => {
                        const category = categories.find(
                          ({categoryId}: any) => categoryId === cat?.categoryId
                        );
                        return {...category, ...cat};
                      }) || []
                    );
                    updateMenu(selectedMenu);
                  }
                }
              );
            }}
            data={category}
            selected={category.categoryId === selectedCategory?.categoryId}
            key={category.categoryId}
          />
        ))}
        {showCategories ? (
          <div className="flex">
            <Select
              className="flex-1"
              id=""
              value={category}
              options={categoryOptions}
              onChange={(val) => {
                setCategory(val);
              }}
            />
            <button
              className="ml-2 shrink-0"
              onClick={() => {
                const index = categories.findIndex(
                  ({categoryId}: any) => category?.value === categoryId
                );
                if (index > -1) {
                  selectedCategories.push(categories[index]);
                  setSelectedCategories([...selectedCategories]);
                  selectedMenu.categories = selectedCategories.map(
                    ({categoryId, position, products}: any) => ({
                      categoryId,
                      position,
                      products,
                    })
                  );
                  selectedMenu.updatedTime = Date.now();
                  updateMenu(selectedMenu);
                }
                setShowCategories(false);
                setCategory(null);
              }}
            >
              <img src={CheckIcon} alt="" />
            </button>
            <button
              className="ml-1"
              onClick={() => {
                setShowCategories(false);
              }}
            >
              <img src={CloseIcon} className="h-[24px]" alt="" />
            </button>
          </div>
        ) : null}
        {categoryOptions.length > 0 ? (
          <MenuAddButton onClick={() => setShowCategories(true)} />
        ) : null}
      </div>
      <div className="bg-grid-bg h-full">
        {selectedProducts?.map((product: any, index: number) => (
          <MenuSectionButton
            title={startCase(product.productName)}
            onEdit={setEditingItem}
            isEdit={editingItem?.productId === product.productId}
            
            onSelect={(data: any) => {
              setSelectedProduct(data);
              // setShowProducts(true);
            }}
            onPositionChange={(move: 'up' | 'down') => {
              if (
                (move === 'up' && index === 0) ||
                (move === 'down' && index === selectedProducts.length - 1)
              ) {
                return;
              }

              const catIndex = selectedMenu?.categories?.findIndex(
                (cat: any, idx: number) =>
                  selectedCategory.categoryId === cat.categoryId
              );
              const menuCategory = selectedMenu?.categories[catIndex];
              const changingProducts = [...menuCategory.products];

              menuCategory?.products.forEach((prod: any, idx: number) => {
                if (index === idx) {
                  const destIndex = move === 'up' ? idx - 1 : idx + 1;
                  changingProducts[destIndex] = prod;
                  changingProducts[idx] = menuCategory.products[destIndex];
                  selectedMenu.categories[catIndex].products = changingProducts;

                  setSelectedProducts(
                    changingProducts.map(({productId}: any) =>
                      products?.products.find(
                        (prod: any) => prod.pid === productId
                      )
                    ) || []
                  );
                  updateMenu(selectedMenu);
                }
              });
            }}
            data={product}
            selected={product.pid === selectedProduct?.pid}
            key={product.pid}
          />
        ))}
        {showProducts ? (
          <div className="flex">
            <Select
              className="flex-1 w-[70%]"
              id=""
              value={product}
              options={productOptions}
              onChange={(val) => {
                setProduct(val);
              }}
            />
            <button
              className="ml-2 shrink-0"
              onClick={() => {
                const index = filteredProducts.findIndex(
                  ({pid}: any) => product?.value === pid
                );
                if (index > -1) {
                  selectedProducts.push(filteredProducts[index]);
                  selectedCategory.products = selectedProducts.map(({pid}) => ({
                    productId: pid,
                    position: '1',
                  }));

                  const catIndex = selectedCategories.findIndex(
                    ({categoryId}) => categoryId === selectedCategory.categoryId
                  );
                  selectedCategories[catIndex] = selectedCategory;

                  selectedMenu.categories = selectedCategories.map(
                    ({categoryId, position, products}: any) => ({
                      categoryId,
                      position,
                      products,
                    })
                  );
                  selectedMenu.updatedTime = Date.now();
                  updateMenu(selectedMenu);
                }
                setShowProducts(false);
                setProduct(null);
              }}
            >
              <img src={CheckIcon} alt="" />
            </button>
            <button
              className="ml-1 shrink-0"
              onClick={() => {
                setShowProducts(false);
              }}
            >
              <img src={CloseIcon} className="h-[24px]" alt="" />
            </button>
          </div>
        ) : null}
        {selectedCategory?.categoryId && productOptions.length > 0 ? (
          <MenuAddButton onClick={() => setShowProducts(true)} />
        ) : null}
      </div>
    </div>
  );
};

export default connect(
  (state: any) => ({
    products: state.product.get('products'),
    categories: state.product.get('categories'),
    menus: state.menu.get('menus'),
  }),
  {
    updateMenu: Actions.menu.updateMenu,
  }
)(MenuGrid);
