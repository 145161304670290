export const isProd = true; // <<< prod config
export const MENU_BUCKET = 'netlise-web-menu';
export const GOOGLE_KEY = "AIzaSyAZdW85BVBFMlUcsuJeFphwoTTphB8_U2I";

export const isProdEnv = process.env.NODE_ENV === 'production';

const config = {
  Auth: {
    mandatorySignIn: false,
    region: "ap-southeast-1",
    identityPoolRegion: "ap-southeast-1",
    userPoolId: "ap-southeast-1_HKRnVLeRh",
    userPoolWebClientId: "799up0e2pd4fr49au318d9lou9",
    authenticationFlowType: "USER_PASSWORD_AUTH",
    identityPoolId: "ap-southeast-1:424c5096-4794-4cb1-b9f3-37bcc413a2bb",
    oauth: {
      domain: "localhost",
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: "http://localhost:3001/oauth",
      redirectSignOut: "http://localhost:3001/signout",
      responseType: "code"
    },
  },
  aws_appsync_graphqlEndpoint:
    "https://rmwldfvcgfgfpcqttl4ns6v55a.appsync-api.ap-southeast-1.amazonaws.com/graphql",
  aws_appsync_region: "ap-southeast-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  //"aws_appsync_apiKey": 'da2-ndp24pmxyzenregerscf575v7u', //"da2-pt5mdgnfsfa5rjyclgmldx64w4",
  API: {
    region: "ap-southeast-1",
    endpoints: [
      {
        id: "xqis1pudmg",
        name: "dev-netlise-app-api",
        description: "",
        endpoint:
          "https://xqis1pudmg.execute-api.ap-southeast-1.amazonaws.com/dev",
        region: "ap-southeast-1",
        paths: [],
      },
      {
        id: "b45qsa2rkc",
        name: "dev-netlise-api-v2",
        description: "",
        endpoint:
          "https://b45qsa2rkc.execute-api.ap-southeast-1.amazonaws.com/dev",
        region: "ap-southeast-1",
        paths: [],
      },
      {
        id: "4qawsqx576",
        name: "dev-admin-dashboard-api",
        description: "",
        endpoint:
          "https://4qawsqx576.execute-api.ap-southeast-1.amazonaws.com/dev",
        region: "ap-southeast-1",
        paths: [],
      },
      {
        id: 'rvmipj7w17',
        name: 'netlise-report-api-dev',
        description: '',
        endpoint:
          'https://rvmipj7w17.execute-api.ap-southeast-1.amazonaws.com/dev',
        region: 'ap-southeast-1',
        paths: [],
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: "netlise-image-bucket",
      region: "ap-southeast-1",
    },
  },
};

const prodConfig = {
  Auth: {
    identityPoolId: "ap-southeast-1:2fb4e791-d71d-4a6f-b424-3b3d0fbf3f1a",
    region: "ap-southeast-1",
    identityPoolRegion: "ap-southeast-1",
    userPoolId: "ap-southeast-1_v2ljou2N2",
    userPoolWebClientId: "5fv53t95635r0h62v6a6efba6n",
    mandatorySignIn: false,
    authenticationFlowType: "USER_PASSWORD_AUTH",
    oauth: {
      domain: "netlise-app.auth.ap-southeast-1.amazoncognito.com",
      scope: [
        "email",
        "profile",
        "openid",
      ],
      redirectSignIn: isProdEnv ? "https://dashboard.netlise.com" : "http://localhost:3001",
      redirectSignOut: isProdEnv ? "https://dashboard.netlise.com" : "http://localhost:3001",
      responseType: "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
      "EMAIL"
    ],
    "aws_cognito_social_providers": [
      "FACEBOOK",
      "GOOGLE",
      "APPLE"
    ],
    "aws_cognito_signup_attributes": [
      "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
      "SMS"
    ],
    "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": 8,
      "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
      "EMAIL"
    ]
  },
  aws_appsync_graphqlEndpoint:
    "https://r5denx53k5evvawmy6c3wmf22q.appsync-api.ap-southeast-1.amazonaws.com/graphql",
  aws_appsync_region: "ap-southeast-1",
  aws_appsync_authenticationType: "AWS_IAM",
  API: {
    region: "ap-southeast-1",
    endpoints: [
      {
        id: "7aqkhkggh5",
        name: "dev-netlise-app-api",
        description: "",
        endpoint:
          "https://7aqkhkggh5.execute-api.ap-southeast-1.amazonaws.com/dev",
        region: "ap-southeast-1",
        paths: [],
      },
      {
        id: "ik1md8pcjk",
        name: "dev-netlise-api-v2",
        description: "",
        endpoint:
          "https://ik1md8pcjk.execute-api.ap-southeast-1.amazonaws.com/dev",
        region: "ap-southeast-1",
        paths: [],
      },
      {
        id: "4qawsqx576",
        name: "dev-admin-dashboard-api",
        description: "",
        endpoint:
          "https://4qawsqx576.execute-api.ap-southeast-1.amazonaws.com/dev",
        region: "ap-southeast-1",
        paths: [],
      },
      {
        id: "rvmipj7w17",
        name: "netlise-report-api-dev",
        description: "",
        endpoint:
          "https://rvmipj7w17.execute-api.ap-southeast-1.amazonaws.com/dev",
        region: "ap-southeast-1",
        paths: [],
      },
      {
        id: "xx0nocdla5",
        name: "payment-apis-dev",
        description: "",
        endpoint:
          "https://xx0nocdla5.execute-api.ap-southeast-1.amazonaws.com/dev",
        region: "ap-southeast-1",
        paths: [],
      },
      {
        id: "mj8lxajnve",
        name: "dev-netlise-pos-lambda-api",
        description: "global ",
        endpoint:
          "https://mj8lxajnve.execute-api.ap-southeast-1.amazonaws.com/dev",
        region: "ap-southeast-1",
        paths: [],
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: "netlise-app-bucket",
      region: "ap-southeast-1",
    },
  },
};

export default isProd ? prodConfig : config;
