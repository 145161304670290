import Modal from 'react-modal';
import CloseIcon from '../../assets/svgs/close-icon.svg';

const ModalWrapper = ({
  isOpen,
  title = '',
  closeModal,
  children,
}: {
  isOpen: boolean;
  title?: string;
  closeModal: any;
  children: any;
}) => {
  return (
    <Modal style={customStyles} isOpen={isOpen}>
      <div className="relative shadow bg-white rounded-lg">
        <div className="flex flex-row h-14 justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-t-lg">
          <h3 className='font-semibold'>{title}</h3>
          <button
            type="button"
            onClick={() => closeModal()}
            className="justify-center h-10 w-10 bg- bg-gray-200 hover:bg-medium-slate-blue hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
          >
            <img src={CloseIcon} alt="" className='h-[12px]'/>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        {children}
      </div>
    </Modal>
  );
};

const customStyles: any = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    padding: 0,
    height: "auto",
    border: "none",
    overflow: "auto",
    maxWidth: "624px",
    background: "none",
    left: "calc(50vw - 292px)",
    WebkitOverflowScrolling: "touch",
  },
};


export default ModalWrapper;
