import React from "react";

const ImageComponent = (props: any) => {
  const { getRootProps, getInputProps, loadingAction, children } = props;
  const { action, loading } = loadingAction;

  return (
    <div
      {...getRootProps({ className: "dropzone" })}
      className="w-full h-48 rounded-lg border-dashed border border-sky-500 overflow-hidden"
    >
      <input
        type="image"
        {...getInputProps()}
        alt="description of image"
        className="w-full h-48 rounded-lg border-dashed border border-sky-500"
      />
      {loading && action.type === "UPLOAD_IMAGE" && (
        <div
          style={{ left: "62px", position: "absolute", top: "70px" }}
          className="plan-loading"
        />
      )}
      {children}
    </div>
  );
};
export default ImageComponent;
