import { Map } from "immutable";
import { ModuleEvents } from "./Actions";
import { get, filter, orderBy, findIndex } from "lodash";
import { ModuleEvents as LoginEvents } from "../login/Actions";
import { ModuleEvents as SettingEvents } from "../setting/Actions";

const initialState = Map({
  dayOrders: [],
  categories: [],
  adminShops: [],
  selectedShop: "",
  dayReportSummary: {},
  monthReportSummary: null,
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case LoginEvents.LOG_OUT: {
      return initialState;
    }
    case ModuleEvents.FETCH_ADMIN_SHOPS: {
      if (payload && !payload.error) {
        const filterShop = filter(
          payload,
          (item) => item?.shopStatus !== "DELETED"
        );
        return state.set("adminShops", filterShop);
      }
      return state;
    }
    case ModuleEvents.SELECTED_ADMIN_SHOP: {
      if (payload && !payload.error) {
        return state.set("selectedShop", payload);
      }
      return state;
    }

    case ModuleEvents.FETCH_SHOP_CATEGORIES: {
      if (payload && !payload.error) {
        let sortResult = orderBy(
          get(payload, "categories", []),
          ["category"],
          ["asc"]
        );
        payload.categories = sortResult;
        return state.set("categories", [...payload]);
      }
      return state;
    }

    case SettingEvents.UPLOAD_SHOP_IMAGE: {
      let adminShops = state.get("adminShops");
      if (payload && !payload.error) {
        const index = findIndex(
          adminShops,
          (item) => item.shopId === payload.shopId
        );
        if (index > -1) {
          adminShops[index] = { ...adminShops[index], ...payload };
        }
        return state
          .set("selectedShop", { ...payload })
          .set("adminShops", [...adminShops]);
      }
      return state;
    }

    case ModuleEvents.UPDATE_SHOP: {
      if (payload && !payload.error) {
        let adminShops = state.get("adminShops");
        const selectedShop = state.get("selectedShop");
        const index = findIndex(
          adminShops,
          (item) => item.shopId === payload.shopId
        );
        if (index > -1) {
          adminShops[index] = { ...adminShops[index], ...payload };
        }
        return state
          .set("adminShops", [...adminShops])
          .set("selectedShop", { ...selectedShop, ...payload });
      }
      return state;
    }

    case ModuleEvents.FETCH_SUMMARY_FOR_MONTH: {
      if (payload && !payload.error) {
        return state.set("monthReportSummary", payload?.Items);
      }
      return state;
    }

    case ModuleEvents.FETCH_SUMMARY_FOR_DATE: {
      if (payload && !payload.error) {
        return state.set("dayReportSummary", { ...payload });
      }
      return state;
    }

    case ModuleEvents.FETCH_ORDERS_FOR_DATE: {
      if (payload && !payload.error) {
        return state.set("dayOrders", [...payload]);
      }
      return state;
    }

    default:
      return state;
  }
};

export default Reducer;
