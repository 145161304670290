import {defer, delay, isEmpty} from 'lodash';
import {
  BoldItalicUnderlineToggles,
  MDXEditor,
  MDXEditorMethods,
  UndoRedo,
  linkPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  quotePlugin,
  diffSourcePlugin,
  frontmatterPlugin,
  imagePlugin,
  linkDialogPlugin,
  tablePlugin,
  thematicBreakPlugin,
  Separator,
  BlockTypeSelect,
  CreateLink,
  DiffSourceToggleWrapper,
  InsertImage,
  ListsToggle,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import {headingsPlugin} from '@mdxeditor/editor';
import {toolbarPlugin} from '@mdxeditor/editor';
import {useEffect, useRef} from 'react';

const MDXEditorInput = ({
  id,
  label,
  error,
  value,
  touched,
  placeholder,
  onChange,
}: // ref,
{
  id: string;
  label?: string;
  error?: string;
  value?: string;
  placeholder?: string;
  touched?: boolean;
  onChange: (text: string) => void;
  ref: any;
}) => {
  const ref = useRef<MDXEditorMethods>(null);

  useEffect(() => {
    if (value && ref?.current) {
      if (ref.current.getMarkdown() !== value) {
        delay(() => {
          ref.current?.setMarkdown(value);
        }, 300);
      }
    }
  }, [ref, value]);

  return (
    <div className="w-full">
      {label && (
        <label
          htmlFor={label}
          className="block mb-2 text-sm font-medium text-gray-700 "
        >
          {label}
        </label>
      )}

      <div className="editor">
        <MDXEditor
          className="mx-1 shadow text-sm"
          ref={ref}
          onChange={onChange}
          markdown={value || ''}
          plugins={[
            toolbarPlugin({
              toolbarContents: () => (
                <DiffSourceToggleWrapper>
                  <UndoRedo />
                  <BoldItalicUnderlineToggles />
                  <ListsToggle />
                  <Separator />
                  <BlockTypeSelect />
                  <CreateLink />
                  <InsertImage />
                  <Separator />
                </DiffSourceToggleWrapper>
              ),
            }),
            listsPlugin(),
            quotePlugin(),
            headingsPlugin(),
            linkPlugin(),
            linkDialogPlugin(),
            imagePlugin(),
            tablePlugin(),
            thematicBreakPlugin(),
            frontmatterPlugin(),
            // codeBlockPlugin({ defaultCodeBlockLanguage: 'txt' }),
            // sandpackPlugin({ sandpackConfig: virtuosoSampleSandpackConfig }),
            // codeMirrorPlugin({ codeBlockLanguages: { js: 'JavaScript', css: 'CSS', txt: 'text' } }),
            // directivesPlugin({ directiveDescriptors: [YoutubeDirectiveDescriptor, AdmonitionDirectiveDescriptor] }),
            diffSourcePlugin({viewMode: 'rich-text', diffMarkdown: 'boo'}),
            markdownShortcutPlugin(),
          ]}
        />
      </div>
      {!isEmpty(error) && touched && (
        <span className="mt-1 block text-xs font-normal text-rose-500	">
          {error}
        </span>
      )}
    </div>
  );
};

export default MDXEditorInput;
