import { useEffect, useState } from "react";
import * as Yup from "yup";
import Modal from "react-modal";
import { nanoid } from "nanoid";
import "../../layouts/Styles.css";
import { connect } from "react-redux";
import FormInput from "../inputs/FormInput";
import PhoneInput from "react-phone-input-2";
import { AiOutlineClose } from "react-icons/ai";
import { find, get, includes, isEmpty } from "lodash";
import FormTextarea from "../inputs/FormTextarea";
import Delete from "../../assets/svgs/delete.svg";
import {
  getDecryptedCode,
  getEncryptedCode,
} from "../../internals/managers/SecurityManager";
import { Actions } from "../../internals/app/Actions";
import { Formik, Field, useFormikContext } from "formik";
import CustomSelect from "../../components/inputs/CustomSelect";
import { getUserColor } from "../../internals/managers/ProductManager";

const options = [
  { value: "1", label: "Admin" },
  { value: "2", label: "Front desk" },
  { value: "3", label: "Manager" },
  { value: "4", label: "Waiter" },
];

type ProductProps = {
  employee?: any;
  isOpen: boolean;
  currentUser: any;
  selectedShop: any;
  employeeList: any;
  loadingAction: any;
  closeModal: () => void;
  updateEmployee: (params: any) => void;
  createEmployee: (params: any) => void;
};

const AddEmployee = (props: ProductProps) => {
  const {
    isOpen,
    closeModal,
    currentUser,
    selectedShop,
    employeeList,
    updateEmployee,
    createEmployee,
    employee,
  } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    setIsEdit(!isEmpty(employee));
  }, [employee]);

  const customStyles: any = {
    overlay: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      position: "fixed",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      padding: 0,
      height: "auto",
      border: "none",
      overflow: "auto",
      maxWidth: "624px",
      background: "none",
      marginLeft: "20px",
      marginRight: "20px",
      left: "calc(50vw - 292px)",
      WebkitOverflowScrolling: "touch",
    },
  };

  const onSaveEmployee = (values: any) => {
    const result = values.specialization.map((item: string) => {
      const findSpecialization: any = find(
        options,
        (option: any) => option.value === item
      );
      return { id: findSpecialization.value, title: findSpecialization.label };
    });
    const color = getUserColor(employeeList.employees?.length || 0);
    const param: any = {
      color,
      email: values.email,
      specializations: result,
      updatedTime: Date.now(),
      bio: values.description,
      address: values.address,
      employeeStatus: "ACTIVE",
      lastName: values.lastName,
      firstName: values.firstName,
      shopId: selectedShop.shopId,
      createdUser: currentUser.uid,
      pin: getEncryptedCode(values.pin),
      mobileNumber: includes(values.phoneNumber, "+")
        ? values.phoneNumber
        : `+${values.phoneNumber}`,
      name:
        values.firstName.toLowerCase() + " " + values.lastName.toLowerCase(),
    };
    if (isEdit) {
      param["empId"] = employee.empId;
      param["updatedTime"] = Date.now();
      param["viewMode"] = get(employee, "viewMode", "GRID");
      updateEmployee(param);
    } else {
      param["empId"] = nanoid();
      param["viewMode"] = "GRID";
      param["createdTime"] = Date.now();
      createEmployee(param);
    }
    closeModal();
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(2, "Must be 2 characters or more")
      .required("First name is required"),
    lastName: Yup.string()
      .min(2, "Must be 2 characters or more")
      .required("Last name is required"),
    email: Yup.string().email("Invalid email format"),
    // .required("Email is required"),
    phoneNumber: Yup.string().min(8, "Please enter valid phone number")
    .required("Phone number is required"),
    pin: Yup.string()
      .required("Pin is required")
      .matches(/^[0-9]+$/, "Pin must be a number")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits"),
    specialization: Yup.array()
      .min(1, "Please select employee specialization")
      .required("Please select employee specialization")
      .nullable(),
  });

  const initialValues = {
    pin: "",
    email: "",
    address: "",
    lastName: "",
    firstName: "",
    phoneNumber: "",
    description: "",
    specialization: [],
  };

  const onProductDelete = () => {
    updateEmployee({
      empId: employee.empId,
      shopId: employee.shopId,
      employeeStatus: "DELETED",
    });
    setIsOpenDelete(false);
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Product Modal"
      onRequestClose={() => closeModal()}
    >
      <div className="relative shadow bg-white rounded-lg">
        <div className="flex flex-row h-14 justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-t-lg">
          <h3>{isEdit ? "Edit Employee" : "Create Employee"}</h3>
          <button
            type="button"
            onClick={() => closeModal()}
            className=" top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
          >
            <AiOutlineClose size={20} />
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => onSaveEmployee(values)}
        >
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="h-auto bg-white px-4 py-5 sm:p-6 rounded-lg">
                  <RenderComponent employee={employee} />
                </div>

                <div className="flex h-16 flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
                  {isEdit ? (
                    <button
                      type="button"
                      onClick={() => setIsOpenDelete(true)}
                      className="inline-flex outline-none justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-red-700"
                    >
                      Delete Employee
                    </button>
                  ) : (
                    <div />
                  )}
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-indigo-700"
                  >
                    {isEdit ? "Save Changes" : "Add Employee"}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
        {isOpenDelete ? (
          <div className="absolute top-1/4 inset-14 w-full max-w-lg z-50 justify-center items-center">
            <div className="relative h-full md:h-auto">
              <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                <button
                  type="button"
                  onClick={() => setIsOpenDelete(false)}
                  className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <AiOutlineClose size={20} />
                  <span className="sr-only">Close modal</span>
                </button>
                <img
                  className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                  src={Delete}
                  alt="actions"
                />

                <p className="mb-4 text-gray-500 dark:text-gray-300">
                  Are you sure you want to delete this employee?
                </p>
                <div className="flex justify-center items-center space-x-4">
                  <button
                    type="button"
                    onClick={() => setIsOpenDelete(false)}
                    className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:outline-none hover:text-gray-900"
                  >
                    No, cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => onProductDelete()}
                    className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none  "
                  >
                    Yes, I'm sure
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};
export default connect(
  (state: any) => ({
    currentUser: state.setting.get("currentUser"),
    loadingAction: state.common.get("loadingAction"),
    selectedShop: state.dashboard.get("selectedShop"),
    employeeList: state.employee.get("employeeList"),
  }),
  {
    createEmployee: Actions.employee.createEmployee,
    updateEmployee: Actions.employee.updateEmployee,
  }
)(AddEmployee);

const RenderComponent = ({ employee }: any) => {
  const { values, errors, touched, handleChange, setFieldValue } =
    useFormikContext<{
      pin: string;
      email: string;
      address: string;
      lastName: string;
      firstName: string;
      phoneNumber: string;
      description: string;
      specialization: string;
    }>();

  useEffect(() => {
    if (!isEmpty(employee)) {
      setFieldValue("pin", getDecryptedCode(employee.pin));
      setFieldValue("email", employee.email);
      setFieldValue("address", employee.address);
      setFieldValue("lastName", employee.lastName);
      setFieldValue("firstName", employee.firstName);
      setFieldValue("phoneNumber", employee.mobileNumber);
      setFieldValue("description", employee.bio);
      const result = employee.specializations?.map((item: any) => {
        return item.id;
      });
      setFieldValue("specialization", [...result]);
    }
  }, [employee]);

  const customHandleChange = (number: string) => {
    if (number.length <= 6) {
      setFieldValue("pin", number);
    }
  };

  return (
    <div className="grid grid-cols-6 gap-6">
      <div className="col-span-6 md:col-span-3">
        <FormInput
          id="firstName"
          label="First Name"
          value={values.firstName}
          error={errors.firstName}
          touched={touched.firstName}
          placeholder="First Name"
          onChange={handleChange("firstName")}
        />
      </div>
      <div className="col-span-6 md:col-span-3">
        <FormInput
          id="lastName"
          label="Last Name"
          value={values.lastName}
          error={errors.lastName}
          touched={touched.lastName}
          placeholder="Last Name"
          onChange={handleChange("lastName")}
        />
      </div>
      <div className="col-span-6 md:col-span-3">
        <FormInput
          id="email"
          label="Your email"
          value={values.email}
          error={errors.email}
          touched={touched.email}
          placeholder="name@company.com"
          onChange={handleChange("email")}
        />
      </div>
      <div className="col-span-6 md:col-span-3">
        <div className="w-full relative flex flex-col">
          <label
            htmlFor="mobile-number"
            className="mb-2 block text-sm font-medium text-gray-700"
          >
            Phone Number
          </label>
          <PhoneInput
            country={"lk"}
            value={values.phoneNumber}
            onChange={handleChange("phoneNumber")}
            inputStyle={{
              border: "none",
              height: "100%",
              width: "100%",
              borderRadius: "8px",
            }}
            buttonStyle={{
              border: "none",
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
            }}
            containerClass="h-10 block w-full rounded-md outline-none border-gray-300 border shadow sm:text-sm mt-0"
          />
          {!isEmpty(errors.phoneNumber) && touched.phoneNumber && (
            <span className="mt-1 first-line:block text-xs font-normal text-rose-400	">
              {errors.phoneNumber}
            </span>
          )}
        </div>
      </div>
      <div className="col-span-6 md:col-span-3">
        <label
          htmlFor="specialization"
          className="mb-2 block text-sm font-medium text-gray-700"
        >
          Specialization
        </label>
        <Field
          isMulti={true}
          options={options}
          id="specialization"
          name="specialization"
          component={CustomSelect}
          error={errors.specialization}
          touched={touched.specialization}
          placeholder="Select multi specialization..."
        />
      </div>
      <div className="col-span-6 md:col-span-3">
        <FormInput
          id="pin"
          label="Pin"
          placeholder="Pin"
          value={values.pin}
          error={errors.pin}
          touched={touched.pin}
          onChange={customHandleChange}
        />
      </div>
      <div className="col-span-6">
        <FormInput
          id="address"
          label="Location"
          value={values.address}
          error={errors.address}
          placeholder="Location"
          touched={touched.address}
          onChange={handleChange("address")}
        />
      </div>
      <div className="col-span-6">
        <FormTextarea
          id="description"
          label="Description"
          value={values.description}
          error={errors.description}
          touched={touched.description}
          onChange={handleChange("description")}
        />
      </div>
    </div>
  );
};
