import {useState, useEffect} from 'react';
import {generateImageUrl} from '../../internals/helper/ImageHelper';

export const S3Image = ({url, className, defaultImage = null, type}: any) => {
  const [image, setImage] = useState<string | null>(null);
  useEffect(() => {
    if (url) {
      setImageUrl(url);
    }
  }, [url]);

  const setImageUrl = async (image: string) => {
    if (type === 'menu') {
      const link = `https://dm6mmpk56rvc9.cloudfront.net/${image}`;
      if (link) {
        setImage(link);
      }
    } else {
      const imageUrl: string | null = await generateImageUrl(image);
      setImage(imageUrl);
    }
  };

  return image || defaultImage ? (
    <img
      alt="default-image"
      className={className}
      src={image || defaultImage}
    />
  ) : null;
};

export default S3Image;
