import { useEffect, useState } from "react";
import moment from "moment";
import S3Image from "../S3Image";
import { connect } from "react-redux";
import { TiArrowUnsorted } from "react-icons/ti";
import Close from "../../assets/svgs/close-icon.svg";
import defaultImage from "../../assets/images/default-user.png";
import { formatPrice } from "../../internals/managers/PaymentManager";
import { capitalize, find, findIndex, isEmpty, orderBy } from "lodash";
import { getIsAmount } from "../../internals/managers/CloseCashManager";

const Table = (props: any) => {
  const {
    products,
    tag = "",
    tableData,
    modalData,
    onCheckAll,
    tableHeader,
    allSelected,
    onClickItem,
    onCheckItem,
    selectedShop,
    onSelectedItem,
    onClickService,
    selectedIndexes,
    type = "NORMAL",
    onClickModalItem,
    isSortIcon = true,
    isSelectTimePeriod,
  } = props;
  const [isAsc, setIsAsc] = useState<boolean>(true);
  const [headerData, setHeaderData] = useState<Array<any>>([]);
  const [tableItems, setTableItems] = useState<Array<any>>([]);
  const [openService, setOpenService] = useState<boolean>(true);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const [selectedProdcut, setSelectedProdcut] = useState<any>({});
  const [selectedCategory, setSelectedCategory] = useState<any>({});
  const [openProductIndex, setOpenProductIndex] = useState<number>(-1);
  const [openActionModal, setOpenActionModal] = useState<boolean>(false);

  useEffect(() => {
    setTableItems(tableData);
  }, [tableData]);

  useEffect(() => {
    setHeaderData(tableHeader);
  }, [tableHeader]);

  const setOrder = (type: string) => {
    let sortResult;
    const sortedValue = type === "TIME PERIOD" ? "END DATE" : type;
    if (!isAsc) {
      sortResult = orderBy(tableItems, [sortedValue], ["asc"]);
    } else {
      sortResult = orderBy(tableItems, [sortedValue], ["desc"]);
    }
    setIsAsc(!isAsc);
    setTableItems(sortResult);
  };

  const onClickTr = (data: any, index: number) => {
    if (type === "SERVICE") {
      setSelectedCategory(data);
      toggleServiceTab();
    } else if (type === "CLOSE_CASH") {
      onSelectedItem(data);
    } else if (type === "PRODUCT") {
      const findProduct = find(
        products.products,
        (product: any) => product.pid === data.id
      );
      setSelectedProdcut(findProduct);
      setOpenProductIndex(openProductIndex != index ? index : -1);
    }
  };

  const toggleServiceTab = () => {
    setOpenService(!openService);
  };

  const toggleActionModal = () => {
    setOpenActionModal(!openActionModal);
  };

  const onClickAction = (e: any, data: any, index: number) => {
    if (!isEmpty(modalData) && index === 0) {
      toggleActionModal();
    } else {
      e.stopPropagation();
      onClickItem(data, index);
    }
  };

  return (
    <div className="w-full relative rounded">
      <table id="tableToPrint" className="w-full">
        <thead className="w-full bg-lavender rounded-t-lg">
          <tr id="table-head-print" className="w-full bg-lavender rounded-t-lg">
            {isSelectTimePeriod && (
              <div
                id="item-header-checkbox"
                className="h-full w-10 px-3 py-3 justify-center items-center"
              >
                <input
                  id="terms"
                  type="checkbox"
                  checked={allSelected}
                  aria-describedby="terms"
                  className="w-4 h-4 border rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                  onChange={(e) => {
                    e.stopPropagation();
                    onCheckAll();
                  }}
                />
              </div>
            )}
            {headerData?.map((item: string, index: number) => {
              const isAmount = getIsAmount(item);
              const isDiscount = item === "DISCOUNT";

              return (
                <th key={index} scope="col" className="px-3 py-3">
                  <div
                    onClick={() => setOrder(item)}
                    className={`flex text-xs font-medium text-start items-center text-valhalla justify-start cursor-pointer ${
                      isAmount || isDiscount ? "justify-end" : "justify-start"
                    }`}
                  >
                    {item !== "ACTIONS" ? (
                      <>
                        {capitalize(item)}
                        {isSortIcon && (
                          <TiArrowUnsorted
                            size={16}
                            style={{ marginLeft: "4px" }}
                          />
                        )}
                      </>
                    ) : null}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody id={"table-body-print"} className="overflow-scroll">
          {tableItems?.map((data: any, pIndex: number) => {
            const isSelected =
              findIndex(selectedIndexes, (item: string) => item === data.id) >
                -1 || allSelected;

            return (
              <>
                <tr
                  id={data.id}
                  onClick={() => onClickTr(data, pIndex)}
                  className="h-4 bg-white border-b hover:bg-gray-50 relative"
                >
                  {isSelectTimePeriod && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        onCheckItem(data);
                      }}
                      id={`checkbox-${data.id}`}
                      className="h-full w-10 px-3 py-3 justify-center items-center"
                    >
                      <input
                        id="terms"
                        type="checkbox"
                        checked={isSelected}
                        onChange={(e) => {}}
                        aria-describedby="terms"
                        className="w-4 h-4 border rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                      />
                    </div>
                  )}
                  {tableHeader?.map((item: any) => {
                    const image = data["IMAGE"];
                    const isAmount = getIsAmount(item);
                    const isPeriod = item === "TIME PERIOD";
                    const isUserImage = item === "FULL NAME";
                    const isEmail = item === "EMAIL" || "CLIENT EMAIL";
                    const isDate = item === "DATE" || item === "CREATED DATE";
                    const isDiscount = item === "DISCOUNT";

                    const value = isAmount
                      ? formatPrice(
                          data[item],
                          tag === "subscription-plans"
                            ? "USD"
                            : selectedShop.currency
                        )
                      : isDate
                      ? moment(data[item]).format(" hh:mmA DD/MM/YYYY")
                      : isPeriod
                      ? `${moment(data["START DATE"]).format(
                          " hh:mmA DD/MM"
                        )} - ${moment(data["END DATE"]).format(
                          " hh:mmA DD/MM"
                        )}`
                      : data[item];

                    return (
                      <td className="px-3 py-3">
                        {item === "ACTIONS" ? (
                          <div className="flex flex-row gap-3 justify-end items-center cursor-pointer">
                            {data[item] &&
                              data[item].map((icon: any, index: number) => {
                                return (
                                  <img
                                    src={icon}
                                    alt="actions"
                                    style={{ minWidth: 16 }}
                                    onClick={(e) => {
                                      setSelectedIndex(pIndex);
                                      onClickAction(e, data, index);
                                    }}
                                  />
                                );
                              })}
                          </div>
                        ) : isUserImage ? (
                          <div className="flex flex-row gap-3 items-center">
                            {image && (
                              <S3Image
                                url={image}
                                defaultImage={defaultImage}
                                className="rounded-full w-11 h-11 object-cover object-center"
                              />
                            )}
                            <div
                              className={
                                "text-xs text-start text-gray-700 capitalize"
                              }
                            >
                              {value}
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`text-xs text-start text-gray-700  
                            ${
                              isAmount || isDiscount ? "text-end" : "text-start"
                            } ${!isEmail ? "capitalize" : ""}`}
                          >
                            {value}
                          </div>
                        )}
                      </td>
                    );
                  })}
                  {openActionModal && selectedIndex === pIndex ? (
                    <div className="absolute right-6 top-10 w-[162px] shadow bg-white rounded-lg z-10">
                      <div className="flex flex-row justify-between items-center bg-gray-50 text-right">
                        <button
                          type="button"
                          className="right-1 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                          onClick={() => toggleActionModal()}
                        >
                          <img
                            src={Close}
                            alt="actions"
                            className="text-gray-400 dark:text-gray-500 w-3 h-3 mx-auto"
                          />
                          <span className="sr-only">Close modal</span>
                        </button>
                      </div>
                      {modalData.map((modal: any) => {
                        return (
                          <div
                            onClick={() => {
                              onClickModalItem(data);
                              toggleActionModal();
                            }}
                            className="cursor-pointer text-start text-sm text-paua p-4 w-full font-medium border-b"
                          >
                            {modal.title}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </tr>
                {openService &&
                data.id === selectedCategory.id &&
                !isEmpty(selectedCategory.services) ? (
                  <tr
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickTr(data, -1);
                    }}
                    className="border-b hover:bg-gray-50"
                  >
                    <td colSpan={4}>
                      <div className="flex w-full flex-wrap flex-col gap-4 items-start py-3 px-6">
                        <h3 className="flex text-xs font-medium text-valhalla justify-start cursor-pointer">
                          {"Service Details"}
                        </h3>
                        <div className="flex flex-wrap flex-row gap-4 items-center">
                          {data.services?.map((item: any, index: number) => {
                            const price = formatPrice(
                              item.price,
                              selectedShop.currency
                            );
                            return (
                              <div
                                key={index}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onClickService(item, selectedCategory);
                                }}
                                className="flex flex-col min-w-xs bg-lavender items-start p-3 border border-gray-200 rounded shadow hover:bg-gray-100"
                              >
                                <h5 className="mb-2 text-xs font-medium tracking-tight text-gray-900 ">
                                  {item.serviceTitle}
                                </h5>
                                <p className="text-xs font-normal text-gray-700 ">
                                  {price}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : null}
                {pIndex === openProductIndex &&
                !isEmpty(selectedProdcut.priceVariants) ? (
                  <tr
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickTr(data, -1);
                    }}
                    className="border-b hover:bg-gray-50"
                  >
                    <td colSpan={12}>
                      <div className="flex w-full flex-wrap flex-col gap-4 items-start py-3 px-6">
                        <h3 className="flex text-xs font-medium text-valhalla justify-start cursor-pointer">
                          {"Options"}
                        </h3>
                        <div className="flex flex-wrap flex-row gap-4 items-center">
                          {orderBy(
                            selectedProdcut.priceVariants,
                            ["price"],
                            ["asc"]
                          )?.map((item: any, index: number) => {
                            const price = formatPrice(
                              item.price,
                              selectedShop.currency
                            );
                            return (
                              <div
                                key={index}
                                onClick={(e) => {}}
                                className="flex flex-col min-w-xs bg-lavender items-start p-3 border border-gray-200 rounded shadow hover:bg-gray-100"
                              >
                                <h5 className="mb-2 text-xs font-medium tracking-tight text-gray-900 ">
                                  {item.optionUnit}
                                </h5>
                                <p className="text-xs font-normal text-gray-700 ">
                                  {price}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : null}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default connect(
  (state: any) => ({
    products: state.product.get("products"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {}
)(Table);
