import { useEffect, useState } from "react";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import OtpInput from "react-otp-input";
import { NavLink } from "react-router-dom";
import Logo from "../../../assets/svgs/logo.svg";
import { Formik, useFormikContext } from "formik";
import { CircularProgress } from "@material-ui/core";
import { Actions } from "../../../internals/app/Actions";
import WarningAlert from "../../../components/WarningAlert";
import FormInput from "../../../components/inputs/FormInput";

const PasswordView = (props: any) => {
  const {
    email,
    alertStatus,
    openAlertBox,
    loadingAction,
    navigationType,
    forgotPassword,
    forgotPasswordSubmit,
  } = props;
  const [otp, setOtp] = useState("");
  const [emailScreen, setEmailScreen] = useState<boolean>(true);
  const [isCodeMismatch, setIsCodeMismatch] = useState<boolean>(false);
  const { action, loading } = loadingAction;

  useEffect(() => {
    setEmailScreen(isEmpty(email));
  }, [email]);

  useEffect(() => {
    if (!isEmpty(navigationType)) {
      setIsCodeMismatch(navigationType.error === "CodeMismatchException");
    } else {
      setIsCodeMismatch(false);
    }
  }, [navigationType]);

  const validationEmailSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const validationPasswordSchema = Yup.object({
    password: Yup.string()
      .min(8, "Must be 8 characters or more")
      .required("Password is required"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const initialEmailValues = {
    email: "",
  };

  const initialPasswordValues = {
    password: "",
    confirmPassword: "",
  };

  const handleChange = (otp: any) => setOtp(otp);

  const onEmailSubmit = (values: any) => {
    forgotPassword(values.email);
  };

  const onPasswordSubmit = (values: any) => {
    forgotPasswordSubmit(email, otp, values.password);
  };

  return (
    <section className=" bg-gray-50 flex flex-row">
      <WarningAlert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="max-w-5xl w-full h-full flex flex-col items-center justify-center p-6 mx-auto md:h-screen lg:py-0">
        <div className="flex items-center justify-center mb-6 text-4xl font-semibold text-valhalla">
          <img src={Logo} alt="logo" className="w-20 h-20 mr-6" />
          Netlise
        </div>
        <div className="flex flex-row w-full bg-white rounded-lg shadow md:mt-0  xl:p-0">
          <div className="flex-col w-1/2 hidden md:block items-center justify-center h-full">
            <img
              alt=""
              className="h-full rounded-l-lg"
              src="/images/shopping-mall.jpg"
            />
          </div>
          <div className="p-6 md:w-1/2 w-full space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-valhalla md:text-2xl ">
              Reset your password
            </h1>
            {emailScreen ? (
              <h1 className="text-sm text-slate-500">
                Type in your email and we will send you a code to reset
                your password
              </h1>
            ) : null}

            {emailScreen ? (
              <Formik
                enableReinitialize={true}
                initialValues={initialEmailValues}
                validationSchema={validationEmailSchema}
                onSubmit={(values) => onEmailSubmit(values)}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                }) => {
                  return (
                    <form
                      onSubmit={handleSubmit}
                      className="flex flex-col h-4/6 justify-between space-y-3 md:space-y-4"
                    >
                      <FormInput
                        id="email"
                        label="Your email"
                        value={values.email}
                        error={errors.email}
                        touched={touched.email}
                        placeholder="name@company.com"
                        onChange={handleChange("email")}
                      />
                      <div className="space-y-3 md:space-y-4">
                        
                        <button
                          type="submit"
                          className="w-full text-white bg-medium-slate-blue hover:bg-primary-700 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-3 text-center"
                        >
                          {loading && action.type === "FORGET_PASSWORD" ? (
                            <CircularProgress
                              size={15}
                              style={{ color: "#EFF0F8" }}
                            />
                          ) : (
                            <>Reset Pasword</>
                          )}
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            ) : (
              <Formik
                enableReinitialize={true}
                initialValues={initialPasswordValues}
                validationSchema={validationPasswordSchema}
                onSubmit={(values) => onPasswordSubmit(values)}
              >
                {({ handleSubmit }) => {
                  return (
                    <form
                      onSubmit={handleSubmit}
                      className="space-y-3 md:space-y-4"
                    >
                      <div className="w-full flex flex-row justify-between">
                        <OtpInput
                          value={otp}
                          onChange={handleChange}
                          numInputs={6}
                          containerStyle={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                          isInputNum={true}
                          inputStyle={{
                            width: "50px",
                            height: "50px",
                            backgroundColor: "#FFFFFF",
                            boxShadow: "0 3px 10px rgb(197 197 242 / 0.4)",
                            border: isCodeMismatch
                              ? "1px solid #FD4B4B"
                              : "none",
                            borderRadius: "10px",
                          }}
                          separator={<span style={{ marginLeft: "8px" }} />}
                        />
                      </div>
                      <RenderComponent />
                      <button
                        type="submit"
                        className="w-full text-white bg-medium-slate-blue hover:bg-primary-700 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-3 text-center"
                      >
                        {loading && (action.type === "FORGET_PASSWORD_SUBMIT" || action.type === "LOGIN") ? (
                          <CircularProgress
                            size={15}
                            style={{ color: "#EFF0F8" }}
                          />
                        ) : (
                          <>Reset Pasword</>
                        )}
                      </button>
                    </form>
                  );
                }}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default connect(
  (state: any) => ({
    email: state.login.get("email"),
    alertStatus: state.common.get("alertStatus"),
    loadingAction: state.common.get("loadingAction"),
    selectedShop: state.dashboard.get("selectedShop"),
    navigationType: state.login.get("navigationType"),
  }),
  {
    openAlertBox: Actions.common.openAlertBox,
    forgotPassword: Actions.login.forgotPassword,
    forgotPasswordSubmit: Actions.login.forgotPasswordSubmit,
  }
)(PasswordView);

const RenderComponent = () => {
  const { values, errors, touched, handleChange, setFieldValue } =
    useFormikContext<{
      password: string;
      confirmPassword: string;
    }>();

  return (
    <>
      <FormInput
        id="password"
        type="password"
        label="Password"
        value={values.password}
        error={errors.password}
        placeholder="**********"
        touched={touched.password}
        onChange={handleChange("password")}
      />
      <FormInput
        type="password"
        id="confirmPassword"
        label="Confirm password"
        placeholder="**********"
        value={values.confirmPassword}
        error={errors.confirmPassword}
        touched={touched.confirmPassword}
        onChange={handleChange("confirmPassword")}
      />
    </>
  );
};
