import { useEffect, useState } from "react";
import { find } from "lodash";
import Modal from "react-modal";
import Select from "react-select";
import { connect } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { Actions } from "../../internals/app/Actions";
import { mapTableOptions } from "../../internals/managers/ProductManager";

const customStyles: any = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    padding: 0,
    height: "auto",
    border: "none",
    overflow: "auto",
    maxWidth: "624px",
    background: "none",
    top: "calc(50vh - 20rem)",
    left: "calc(50vw - 292px)",
    WebkitOverflowScrolling: "touch",
  },
};

const selectCustomStyles = {
  control: (base: any) => ({
    ...base,
    height: "100%",
    boxShadow: "none",
    borderColor: "#F0F1FA",
    color: "#F0F1FA",
    "&:hover": {
      borderColor: "#F0F1FA",
    },
  }),
};

type QRProps = {
  QRtables: any;
  isOpen: boolean;
  selectedArea: any;
  closeModal: () => void;
  generateQR: (table: any) => void;
};

const GenerateQR = (props: QRProps) => {
  const { isOpen, closeModal, generateQR, selectedArea, QRtables } = props;
  const options = mapTableOptions(selectedArea, QRtables);
  const [selectedTable, setSelectedTable] = useState<any>({});

  const onChangeTbale = (option: any) => {
    const table = find(
      selectedArea.tables,
      ({ tbId }: any) => tbId === option.value
    );
    setSelectedTable(table);
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="QR Modal"
      onRequestClose={() => closeModal()}
    >
      <div className=" bg-white shadow rounded-lg">
        <div className="flex flex-row justify-between items-center bg-gray-50 px-4 py-4 text-right sm:px-6 rounded-lg">
          <h3 className="text-2xl	font-bold text-speech-blue">
            {"Generate QR for Table"}
          </h3>
          <button
            type="button"
            className="top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
            onClick={() => closeModal()}
          >
            <AiOutlineClose size={20} />
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <div className="w-full px-4 py-10 sm:px-6 relative flex flex-col gap-2">
          <h5 className="text-sm font-bold text-speech-blue">
            {"Table Number"}
          </h5>
          <Select
            options={options}
            onChange={onChangeTbale}
            styles={selectCustomStyles}
            placeholder={"Select table of the area"}
            className={
              "block w-full h-[47px] rounded-md outline-none text-sm text-ship-cove"
            }
          />
        </div>
        <div className="flex pb-10 justify-between sm:px-6 px-4 items-center space-x-4">
          <button
            type="button"
            onClick={() => closeModal()}
            className="w-[154px] h-[45px] text-base font-bold text-valhalla bg-[#EDEDF0] rounded-lg focus:outline-none"
          >
            Discard
          </button>
          <button
            type="button"
            onClick={() => {
              if (selectedTable) {
                generateQR(selectedTable);
              }
            }}
            className="w-[154px] h-[45px] text-base font-bold text-center text-white bg-medium-slate-blue rounded-lg hover:bg-slate-blue focus:outline-none  "
          >
            Generate
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default connect(
  (state: any) => ({
    tables: state.table.get("tables"),
    QRtables: state.table.get("QRtables"),
    currentUser: state.setting.get("currentUser"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    createBrand: Actions.product.createBrand,
    updateBrand: Actions.product.updateBrand,
    createQRForTable: Actions.product.createQRForTable,
  }
)(GenerateQR);
