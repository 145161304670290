import { useState } from "react";
import "../../layouts/Styles.css";
import { connect } from "react-redux";
import AddCategory from "../AddCategory";
import { AiOutlinePlus } from "react-icons/ai";

const CategoryHeader = () => {
  const [isOpenCategory, setIsOpenCategory] = useState(false);

  const toggleCategory = () => {
    setIsOpenCategory(!isOpenCategory);
  };

  return (
    <div className="flex flex-row w-full justify-end">
      <div className="inline-flex rounded-md shadow-sm" role="group">
        <button
          type="button"
          className="inline-flex gap-2 items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-md hover:bg-gray-100 hover:text-blue-700"
          onClick={() => toggleCategory()}
        >
          <AiOutlinePlus size={20} />
          Add Category
        </button>
      </div>
      <AddCategory
        isOpen={isOpenCategory}
        closeModal={() => toggleCategory()}
      />
    </div>
  );
};
export default connect((state: any) => ({}), {
})(CategoryHeader);