import gql from 'graphql-tag';

// export const GetShop = gql`
//   query getShop($shopId: String!) {
//     getShop(shopId: $shopId) {
//       shopId
//       shopName
//       email
//       image
//       phoneNumber
//       shopStatus
//       address
//       claimStatus
//       paymentMethod
//       pin
//       billImage
//       menuImage
//       token
//       local
//       currency
//       shopType
//       createdBy
//       receiptEnd
//       description
//       shortCode
//       shopCategory
//     }
//   }
// `;

export const CreateProductTag = gql`
  mutation createProductTag($input: CreateProductTagInput!) {
    createProductTag(input: $input) {
      shopId
      tagId
      tagName
    }
  }
`;

export const FetchProductTags = gql`
  query FetchProductTags($shopId: String!) {
    fetchProductTags(shopId: $shopId) {
      items {
        shopId
        tagId
        tagName
      }
    }
  }
`;

// export const UpdateShop = gql`
//   mutation updateShop($input: UpdateShopInput!) {
//     updateShop(input: $input) {
//       shopId
//       shopName
//       email
//       image
//       phoneNumber
//       billImage
//       menuImage
//       shopStatus
//       address
//       claimStatus
//       paymentMethod
//       pin
//       local
//       token
//       currency
//       shopType
//       createdBy
//       receiptEnd
//       description
//       shortCode
//       shopCategory
//     }
//   }
// `;
