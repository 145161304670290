import DownIcon from '../../assets/svgs/down-icon.svg';
import UpIcon from '../../assets/svgs/up-icon.svg';
import EditIcon from '../../assets/svgs/edit-menu-icon.svg';
import DeleteIcon from '../../assets/svgs/menu-delete-icon.svg';
import CloseIcon from '../../assets/svgs/menu-close-icon.svg';
import CheckIcon from '../../assets/svgs/menu-check-icon.svg';

const MenuSectionButton = ({
  selected,
  title,
  data,
  onSelect,
  onEdit,
  onPositionChange,
  isEdit,
  onDelete,
  onSave,
}: any) => {
  return (
    <div
      className={`${
        selected // menu.menuId === selectedMenu?.menuId
          ? 'bg-medium-slate-blue text-white'
          : 'bg-lavender text-text-color'
      } flex justify-between hover:cursor-pointer`}
      onClick={() => onSelect(data)}
    >
      <p className="font-semibold text-sm px-2 py-4">{title}</p>

      <div className="flex mr-0.5 shrink-0">
        {isEdit ? (
          <>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(data);
              }}
              className="bg-white flex items-center justify-center mr-0.5 my-0.5 px-2"
            >
              <img src={DeleteIcon} className="" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onSave();
              }}
              className="bg-white flex items-center justify-center my-0.5 px-2"
            >
              <img src={CheckIcon} className="" />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onEdit(data);
              }}
            >
              <img src={EditIcon} className="mr-2" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onPositionChange('up');
              }}
              className="bg-white flex items-center justify-center mr-0.5 my-0.5 px-2"
            >
              <img src={UpIcon} className="" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onPositionChange('down');
              }}
              className="bg-white flex items-center justify-center my-0.5 px-2"
            >
              <img src={DownIcon} className="" />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default MenuSectionButton;
