import React, { useEffect, useState } from "react";
import FormInput from "../../../components/inputs/FormInput";
import FormSelect from "../../../components/inputs/FormSelect";
import FormTextarea from "../../../components/inputs/FormTextarea";
import Alert from "../../../components/Alert";
import SubscriptionHeader from "../../../components/SubscriptionHeader";
import { connect } from "react-redux";
import { Actions } from "../../../internals/app/Actions";
import Table from "../../../components/Table";
import checked from "../../../assets/svgs/subscription/checked.svg";
import location from "../../../assets/svgs/subscription/location.svg";
import unchecked from "../../../assets/svgs/subscription/unchecked.svg";
import defaultImage from "../../../assets/images/default-store.jpeg";
import PlanCard from "../../../components/PlanCard";
import LocationCard from "../../../components/LocationCard";
import PaymentCard from "../../../components/PaymentCard";
import AlertModal from "../../../components/AlertModal";
import { isEmpty } from "lodash";

const tableHeader = [
  "SUBSCRIPTION",
  "PURCHASE DATE",
  "TYPE",
  "TIME",
  "AMOUNT",
  "ACTIONS",
];

const Subscription = (props: any) => {
  const { openAlertBox, alertStatus, modalStatus, fetchPackages, paymentPackages, selectedShop } = props;
  const [step, setStep] = useState<number>(1);

  useEffect(() => {
    fetchPackages();
  }, [fetchPackages]);

  const renderComponent = () => {
    switch (step) {
      case 1:
        return <PlanCard paymentPackages={paymentPackages} onNextStep={() => setStep(2)} />;
      case 2:
        return (
          <LocationCard
            onClickBack={() => setStep(1)}
            onClickNext={() => setStep(3)}
          />
        );
      case 3:
        return <PaymentCard onClickBack={() => setStep(2)} />;
      default:
        return <PlanCard paymentPackages={paymentPackages} onSelectedPlan={() => setStep(2)} />;
    }
  };

  return (
    <div className="h-full w-full p-6">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="w-full max-w-5xl pb-20">
        <ol className="items-center w-full space-y-4 sm:flex sm:space-y-0 justify-between">
          <li className="flex items-center text-blue-600 dark:text-blue-500 space-x-2.5">
            <img alt="step-1" src={step >= 1 ? checked : unchecked} />
            <span>
              <p className="text-[11px] text-waikawa-grey">Step 1</p>
              <h3 className="text-sm font-normal leading-tight text-midnight-express">Subscription</h3>
            </span>
          </li>
          <li className="flex items-center text-gray-500 dark:text-gray-400 space-x-2.5">
            <img alt="step-2" src={step >= 2 ? checked : unchecked} />
            <span>
              <p className="text-[11px] text-waikawa-grey">Step 2</p>
              <h3 className="text-sm font-normal leading-tight text-midnight-express">Choose Location</h3>
            </span>
          </li>
          <li className="flex items-center text-gray-500 dark:text-gray-400 space-x-2.5">
            <img alt="step-2" src={step >= 3 ? checked : unchecked} />
            <span>
              <p className="text-[11px] text-waikawa-grey">Step 3</p>
              <h3 className="text-sm font-normal leading-tight text-midnight-express">Payment</h3>
            </span>
          </li>
        </ol>
      </div>
      {renderComponent()}
      {!isEmpty(modalStatus) && <AlertModal />}
    </div>
  );
};
export default connect(
  (state: any) => ({
    alertStatus: state.common.get("alertStatus"),
    modalStatus: state.setting.get("modalStatus"),
    paymentPackages: state.setting.get("paymentPackages"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    openAlertBox: Actions.common.openAlertBox,
    fetchPackages: Actions.setting.fetchPackages,
  }
)(Subscription);
