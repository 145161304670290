import gql from 'graphql-tag';

export const CreateWarranty = gql`
  mutation CreateWarranty($input: CreateWarrantyInput!) {
    createWarranty(input: $input) {
      wrid
      title
      shopId
      endDate
      duration
      description
      durationType
      createdTime
      updatedTime
    }
  }
`;

export const UpdateWarranty = gql`
  mutation UpdateWarranty($input: UpdateWarrantyInput!) {
    updateWarranty(input: $input) {
      wrid
      title
      shopId
      endDate
      duration
      description
      durationType
      createdTime
      updatedTime
    }
  }
`;

export const DeleteWarranty = gql`
  mutation DeleteWarranty($input: DeleteWarrantyInput!) {
    deleteWarranty(input: $input) {
      wrid
      shopId
    }
  }
`;

export const FetchWarranties = gql`
  query FetchWarranties($shopId: String, $limit: Int, $nextToken: String) {
    fetchWarranties(shopId: $shopId, limit: $limit, nextToken: $nextToken) {
      items{
        wrid
        title
        shopId
        endDate
        duration
        description
        durationType
        createdTime
        updatedTime
      }
	    nextToken
    }
  }
`;
