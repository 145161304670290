import {useEffect, useState} from 'react';
import '../../layouts/Styles.css';
import {connect} from 'react-redux';
import {Actions} from '../../internals/app/Actions';
import {isEmpty, sortBy} from 'lodash';
import {formatPrice} from '../../internals/managers/PaymentManager';

const plans = [
  {title: 'Cloud POS'},
  {title: 'Dashboard'},
  {title: 'Daily Reports'},
  {title: 'Transactions'},
  {title: 'Client Management'},
  {title: 'Per Location'},
];

const PlanCard = (props: any) => {
  const {
    onNextStep,
    setSelectedPlan,
    selectedPlan,
    paymentPackages = [],
    selectedShop,
  } = props;

  const onClickBuy = (e: any, item: any) => {
    e.stopPropagation();
    if (!isEmpty(selectedPlan) && item.priceId === selectedPlan.priceId) {
      onNextStep();
    } else if (item.priceId !== selectedPlan.priceId) {
      setSelectedPlan(item);
      onNextStep();
    }
  };

  const sortedPackages = sortBy(paymentPackages || [], ['amount']).filter(
    ({isVisible}) => isVisible
  );

  return (
    <div className="flex flex-row flex-wrap gap-12 shadow p-9 rounded-lg">
      {sortedPackages.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className={
              'w-[310px] h-[438px] relative cursor-pointer border border-gray-200 rounded-lg p-1 bg-valhalla'
            }
          >
            <div
              onClick={(e) => onClickBuy(e, item)}
              style={{
                background: index > 0 ? '#54C1FB' : '#F8F9FD',
              }}
              className={
                'w-[310px] h-[438px] absolute top-2 left-2 first-line:max-w-xs cursor-pointer border border-gray-200 rounded-lg p-4 sm:p-8 bg-ghost-white'
              }
            >
              <h5 className="flex items-center justify-center mb-4 text-lg font-medium text-midnight-express">
                {item.title}
              </h5>

              <button className="flex w-full max-w-xs py-2 items-baseline justify-center text-white bg-medium-slate-blue rounded-lg">
                <span className="text-base font-semibold"></span>
                <span className="text-2xl font-bold tracking-tight">
                  {formatPrice(item.amount, 'USD')}
                </span>
                <span className="ml-1 text-base font-normal">/month</span>
              </button>

              <ul className="space-y-5 my-7">
                {item.features?.map((title: string, index: number) => {
                  return (
                    <li key={index} className="flex space-x-3 items-center">
                      {/* <img alt="step-2" className="w-5 h-5" src={checked} /> */}
                      <div className="w-3 h-3 bg-medium-slate-blue rounded-full border border-valhalla" />
                      <span className="text-base font-normal leading-tight text-midnight-express ">
                        {title}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default connect(
  (state: any) => ({
    selectedPlan: state.setting.get('selectedPlan'),
    selectedShop: state.dashboard.get('selectedShop'),
  }),
  {
    setSelectedPlan: Actions.setting.setSelectedPlan,
  }
)(PlanCard);
