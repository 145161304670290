import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Alert from "../../../components/Alert";
import Table from "../../../components/Table";
import Add from "../../../assets/svgs/add.svg";
import Edit from "../../../assets/svgs/edit.svg";
import { filter, find, get, minBy } from "lodash";
import Delete from "../../../assets/svgs/delete.svg";
import DeleteItem from "../../../components/DeleteItem";
import AddService from "../../../components/AddService";
import { Actions } from "../../../internals/app/Actions";
import ServiceHeader from "../../../components/ServiceHeader";
import AddServiceCategory from "../../../components/AddServiceCategory";

const tableHeader = ["CATEGORY", "SERVICES", "MIN PRICE", "ACTIONS"];

const ServiceView = (props: any) => {
  const { alertStatus, openAlertBox, updateCategory, category } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [isOpenService, setIsOpenService] = useState<boolean>(false);

  useEffect(() => {
    if (category.categories) {
      const tableData = getData(category.categories);
      setTableData(tableData);
    }
  }, [category, alertStatus]);

  useEffect(() => {
    if (category.categories) {
      const tableData = getData(category.categories);
      setTableData(tableData);
    }
  }, [category, alertStatus]);

  const getData = (data: any) => {
    return data.map((item: any) => {
      const minService = minBy(item.services, "price");
      return {
        id: item.id,
        CATEGORY: item.category,
        services: [...item.services],
        ACTIONS: [Add, Edit, Delete],
        SERVICES: item.services.length,
        "MIN PRICE": get(minService, "price", 0),
      };
    });
  };

  const onClickItem = (data: any, index: number) => {
    const value = find(
      category.categories,
      (category) => category.id === data.id
    );
    setSelectedCategory(value);
    if (index === 0) {
      setSelectedService(null);
      toggleService();
    } else if (index === 1) {
      toggleCategory();
    } else if (index === 2) {
      toggleDelete();
    }
  };

  const toggleDelete = () => {
    setIsOpenDelete(!isOpenDelete);
  };

  const toggleCategory = () => {
    setIsOpen(!isOpen);
  };

  const toggleService = () => {
    setIsOpenService(!isOpenService);
  };

  const onDeleteCategory = (data: any) => {
    const values = filter(
      category.categories,
      (category: any) => category.id !== data.id
    );
    category.categories = [...values];
    updateCategory(category, "DELETE");
    toggleDelete();
  };

  const onClickService = (serviceData: any, categoryData: any) => {
    const param = find(
      category.categories,
      (data: any) => data.id === categoryData.id
    );
    setSelectedService(serviceData);
    setSelectedCategory(param);
    toggleService();
  };

  return (
    <div className="flex flex-col w-full p-6 justify-start">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="pb-6">
        <ServiceHeader
          openCategory={() => {
            setSelectedCategory(null);
            toggleCategory();
          }}
        />
      </div>
      <Table
        onClickItem={onClickItem}
        {...{ tableHeader, tableData, type: "SERVICE" }}
        onClickService={(service: any, category: any) =>
          onClickService(service, category)
        }
      />
      <AddServiceCategory
        isOpen={isOpen}
        selectedCategory={selectedCategory}
        closeModal={() => toggleCategory()}
      />
      <AddService
        isOpen={isOpenService}
        selectedService={selectedService}
        closeModal={() => toggleService()}
        selectedCategory={selectedCategory}
      />
      <DeleteItem
        isOpen={isOpenDelete}
        closeModal={() => toggleDelete()}
        onDelete={() => onDeleteCategory(selectedCategory)}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    category: state.service.get("category"),
    categories: state.product.get("categories"),
    alertStatus: state.common.get("alertStatus"),
    employeeList: state.employee.get("employeeList"),
  }),
  {
    openAlertBox: Actions.common.openAlertBox,
    updateCategory: Actions.service.updateCategory,
  }
)(ServiceView);
