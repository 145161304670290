import Repository from "./Repository";
import { get } from 'lodash';
import { graphqlOperation } from "@aws-amplify/api-graphql";
import { isNetworkError } from "../managers/SecurityManager";
import { CreateModifier, UpdateModifier, DeleteModifier, FetchModifiers } from "./schema/Modifier.schema";

class ModifierRepository extends Repository {
  async createModifier(param) {
    try {
      const result = await this.API.graphql(
        graphqlOperation(CreateModifier, { input: param })
      );
      return result.data.createModifier;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async updateModifier(param) {
    try {
      const result = await this.API.graphql(
        graphqlOperation(UpdateModifier, { input: param })
      );
      return result.data.updateModifier;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async deleteModifier(id, shopId) {
    try {
      const result = await this.API.graphql(
        graphqlOperation(DeleteModifier, { input: { id, shopId } })
      );
      return result.data.deleteModifier;
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  }

  fetchModifiers = async (
    shopId,
    limit,
    nextToken,
    retryCount = 1
  ) => {
    try {
      const params = {
        shopId,
        limit,
        nextToken,
      };
      const result = await this.API.graphql(
        graphqlOperation(FetchModifiers, params)
      );
      return get(result, "data.fetchModifiers", {});
    } catch (error) {
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchModifiers(
          shopId,
          limit,
          nextToken,
          ++retryCount
        );
      }
      console.warn("fetch modifiers error", error);
      return { error };
    }
  };


}
export default new ModifierRepository();
