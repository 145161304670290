import { isEmpty } from "lodash";
import { connect } from "react-redux";
import AlertModal from "../../../components/AlertModal";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

export const PaymentLoading = (props: any) => {
  const { modalStatus } = props;
  const { innerHeight } = window;
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        height: innerHeight,
        alignItems: "center",
        marginBottom: "100px",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <>
        {!isEmpty(modalStatus) ? (
          <AlertModal />
        ) : (
          <>
            <h3 className="font-bold leading-tight text-midnight-express mb-5">
              {"Processing"}
            </h3>
            <CircularProgress
              style={{ color: "#6D71F9" }}
              size={55}
              value={5}
            />
          </>
        )}
      </>
    </div>
  );
};
export default connect(
  (state: any) => ({
    modalStatus: state.setting.get("modalStatus"),
  }),
  {}
)(PaymentLoading);
