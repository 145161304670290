import { useState } from "react";
import "../../layouts/Styles.css";
import AddBrand from "../AddBrand";
import { connect } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";

const BrandHeader = () => {
  const [isOpenBrand, setIsOpenBrand] = useState(false);

  const toggleBrand = () => {
    setIsOpenBrand(!isOpenBrand);
  };

  return (
    <div className="flex flex-row w-full justify-end">
      <div className="inline-flex rounded-md shadow-sm" role="group">
        <button
          type="button"
          className="inline-flex gap-2 items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-md hover:bg-gray-100 hover:text-blue-700"
          onClick={() => toggleBrand()}
        >
          <AiOutlinePlus size={20} />
          Add Brand
        </button>
      </div>
      <AddBrand
        isOpen={isOpenBrand}
        closeModal={() => toggleBrand()}
      />
    </div>
  );
};
export default connect((state: any) => ({}), {})(BrandHeader);
