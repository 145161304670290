import React, { useEffect, useState } from "react";
import "../../layouts/Styles.css";
import { connect } from "react-redux";
import Tooltip from "react-power-tooltip";
import { BiListPlus } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";
import { HiViewGridAdd } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { Actions } from "../../internals/app/Actions";
import AddProduct from "../../modules/product/views/AddProduct";

const ProductHeader = ({
  isGrid,
  setImage,
  toggleGridList,
}: {
  isGrid: boolean;
  setImage: (image: string) => void;
  toggleGridList: (status: boolean) => void;
}) => {
  const [searchParams] = useSearchParams();
  const [showGuide, setGuide] = useState<boolean>(false);
  const [isOpenProduct, setIsOpenProduct] = useState(false);

  useEffect(() => {
    const guide = searchParams.get("guide");
    if (guide) {
      setGuide(true);
    }
  }, [searchParams]);

  const toggleProduct = () => {
    setImage("");
    setIsOpenProduct(!isOpenProduct);
    setGuide(false);
  };

  return (
    <div className="flex flex-row w-full justify-between">
      <div className="flex flex-row flex-wrap gap-3 ">
        <div className="inline-flex rounded-md shadow-sm" role="group">
          <button
            type="button"
            onClick={() => toggleGridList(true)}
            className={
              "inline-flex gap-2 items-center px-4 py-2 text-sm font-medium border border-gray-200 rounded-l-lg hover:bg-gray-100 focus:z-10" +
              (isGrid
                ? " bg-gray-100 text-medium-slate-blue"
                : " bg-white text-gray-900")
            }
          >
            <HiViewGridAdd color={isGrid ? "#6D71F9" : "black"} size={20} />
            Grid
          </button>
          <button
            type="button"
            onClick={() => toggleGridList(false)}
            className={
              "inline-flex gap-2 items-center px-4 py-2 text-sm font-medium border border-gray-200 rounded-r-md hover:bg-gray-100 focus:z-10" +
              (!isGrid
                ? " bg-gray-100 text-medium-slate-blue"
                : " bg-white text-gray-900")
            }
          >
            <BiListPlus color={!isGrid ? "#6D71F9" : "black"} size={20} />
            List
          </button>
        </div>
      </div>

      <div className="flex flex-row flex-wrap gap-3 ">
        <div className="inline-flex rounded-md shadow-sm relative" role="group">
          <button
            type="button"
            className="inline-flex gap-2 items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-md hover:bg-gray-100 hover:text-medium-slate-blue"
            onClick={() => toggleProduct()}
          >
            <AiOutlinePlus size={20} />
            Add Product
          </button>
          {/* @ts-ignore */}
          <Tooltip
            // textBoxWidth="200px"
            show={showGuide}
            position="left center"
            backgroundColor="#6D71F9"
          >
            <p className="text-sm font-normal text-white">
              Create your new products by pressing Add Products
            </p>
          </Tooltip>
        </div>
      </div>
      <AddProduct isOpen={isOpenProduct} closeModal={() => toggleProduct()} />
    </div>
  );
};
export default connect((state: any) => ({}), {
  setImage: Actions.product.setImage,
})(ProductHeader);
