import { Map } from "immutable";
import { ModuleEvents } from "./Actions";
import { findIndex, orderBy, filter } from "lodash";

const initialState = Map({
  modifiers: [],
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (type) {
    case ModuleEvents.FETCH_MODIFIERS: {
      if (payload && !payload.error) {
        const sortResult = orderBy(payload.items, ["updatedTime"], ["desc"]);
        return state.set("modifiers", [...sortResult]);
      }
      return state;
    }
    case ModuleEvents.CREATE_MODIFIER: {
      if (payload && !payload.error) {
        const modifiers = state.get('modifiers');
        modifiers.push(payload);
        const sortedModifiers = orderBy(modifiers, ['updatedTime'], ['desc']);
        return state.set('modifiers', [...sortedModifiers])
      }
      return state;
    }
    case ModuleEvents.UPDATE_MODIFIER: {
      if (payload && !payload.error) {
        const modifiers = state.get('modifiers');
        const index = findIndex(modifiers, ({ id }) => id === payload.id)
        modifiers[index] = payload;
        const sortedModifiers = orderBy(modifiers, ['updatedTime'], ['desc']);
        return state.set('modifiers', [...sortedModifiers])
      }
      return state;
    }
    case ModuleEvents.DELETE_MODIFIER: {
      if (payload && !payload.error) {
        const modifiers = state.get('modifiers');
        const filtermodifiers = filter(modifiers, ({ id }) => id !== payload.id)
        const sortedModifiers = orderBy(filtermodifiers, ['updatedTime'], ['desc']);
        return state.set('modifiers', [...sortedModifiers])
      }
      return state;
    }

    default:
      return state;
  }
};

export default Reducer;
