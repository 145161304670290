import EmployeeRepository from "../../internals/repository/EmployeeRepository";
import MixpanelService, { MP_EVENTS } from "../../internals/service/MixpanelService";

export const ModuleEvents = {
  CREATE_EMPLOYEE: "CREATE_EMPLOYEE",
  UPDATE_EMPLOYEE: "UPDATE_EMPLOYEE",
  FETCH_EMPLOYEES_FOR_SHOPID: "FETCH_EMPLOYEES_FOR_SHOPID",
};

const createAction =
  (type, action = () => {}, meta) =>
  (...args) => ({
    type,
    payload: action(...args),
    meta,
  });

const fetchEmployeesForShopId = createAction(
  ModuleEvents.FETCH_EMPLOYEES_FOR_SHOPID,
  async (shopId, limit = 30, lastKey = null) => {
    const result = await EmployeeRepository.fetchEmployeesForShopId(
      shopId,
      limit,
      lastKey
    );
    return result;
  },
  { loading: true }
);

const createEmployee = createAction(
  ModuleEvents.CREATE_EMPLOYEE,
  async (param) => {
    const result = await EmployeeRepository.createEmployee(param);
    MixpanelService.track(MP_EVENTS.CREATE_EMPLOYEE, param);
    return result;
  },
  { loading: true }
);

const updateEmployee = createAction(
  ModuleEvents.UPDATE_EMPLOYEE,
  async (param) => {
    const result = await EmployeeRepository.updateEmployee(param);
    return result;
  },
  { loading: true }
);

const exportedFuction = {
  createEmployee,
  updateEmployee,
  fetchEmployeesForShopId,
};

export default exportedFuction;
