import DiscountRepository from "../../internals/repository/DiscountRepository";

export const ModuleEvents = {
  FETCH_DISCOUNTS: "FETCH_DISCOUNTS",
  CREATE_DISCOUNT: "CREATE_DISCOUNT",
  UPDATE_DISCOUNT: "UPDATE_DISCOUNT"
};

const createAction =
  (type, action = () => {}, meta) =>
  (...args) => ({
    type,
    payload: action(...args),
    meta,
  });

const fetchDiscounts = createAction(
  ModuleEvents.FETCH_DISCOUNTS,
  async (shopId) => {
    const result = await DiscountRepository.fetchDiscounts(shopId);
    return result;
  }
);

const createDiscount = createAction(
  ModuleEvents.CREATE_DISCOUNT,
  async (params) => {
    const result = await DiscountRepository.createDiscount(params);
    return result;
  }
);

const updateDiscount = createAction(
  ModuleEvents.UPDATE_DISCOUNT,
  async (params, status) => {
    const result = await DiscountRepository.updateDiscounts(params);
    return {result, status};
  }
);

const exportedFuction = {
  createDiscount,
  updateDiscount,
  fetchDiscounts,
};

export default exportedFuction;
