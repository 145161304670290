import { useState } from "react";
import RevenueView from "./RevenueView";
import ProductView from "./ProductView";
import SalesSummaryView from "./SalesSummaryView";
import ReportHeaderTabs from "../../../components/ReportHeaderTabs";

const ReportView = () => {
  const [selectedTab, setSelectedTab] = useState("SALES_SUMMARY");

  const renderScreen = (tab: string) => {
    switch (tab) {
      case "SALES_SUMMARY":
        return (
          <div className="w-full p-6">
            <SalesSummaryView />
          </div>
        );
      case "REVENUE":
        return (
          <div className="w-full p-6">
            <RevenueView />
          </div>
        );
      default:
        return <ProductView />;
    }
  };

  return (
    <>
      <div className="w-full p-6">
        <ReportHeaderTabs
          selectedTab={selectedTab}
          onChange={(type: string) => setSelectedTab(type)}
        />
      </div>
      {renderScreen(selectedTab)}
    </>
  );
};

export default ReportView;
