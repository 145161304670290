import { connect } from "react-redux";
import {
  Title,
  Filler,
  Legend,
  Tooltip,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale,
  Chart as ChartJS,
} from "chart.js";
import { Line } from "react-chartjs-2";
import {
  getDaySales,
  getDayTimeDetails,
} from "../../internals/managers/ReportsManager";
ChartJS.register(
  Title,
  Filler,
  Legend,
  Tooltip,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale
);

const LineChart = (props: any) => {
  const { dayOrders } = props;
  const labels = getDayTimeDetails(dayOrders);
  const sales = getDaySales(labels, dayOrders);

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          font: {
            size: 12,
            align: "start",
            family: "Inter",
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          padding: 5,
          font: "Inter",
          color: "#272848",
          crossAlign: "start",
          callback: (v: any) => `Rs: ${v}`,
        },
      },
      x: {
        ticks: {
          padding: 5,
          font: "Inter",
          color: "#272848",
        },
        grid: {
          display: false,
        },
      },
    },
    interaction: {
      intersect: false,
    },
  };

  let width: any, height: any, gradient: any;
  const getGradient = (ctx: any, chartArea: any) => {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.top
      );
      gradient.addColorStop(0, "rgb(109, 113, 249, 0.1)");
      gradient.addColorStop(0.5, "rgb(109, 113, 249, 0.7)");
      gradient.addColorStop(1, "rgb(109, 113, 249)");
    }

    return gradient;
  };

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        data: sales,
        label: "Sales",
        borderColor: (context: any) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return;
          }
          return getGradient(ctx, chartArea);
        },
        backgroundColor: (context: any) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return;
          }
          return getGradient(ctx, chartArea);
        },
      },
    ],
    tension: 0.1,
    cubicInterpolationMode: "monotone",
  };

  return <Line id="myChart" options={options} data={data} />;
};
export default connect(
  (state: any) => ({
    dayOrders: state.dashboard.get("dayOrders"),
  }),
  () => ({})
)(LineChart);
