import { useCallback, useEffect, useState } from "react";
import "../Styles.css";
import { includes } from "lodash";
import { connect } from "react-redux";
import Sidebar from "../../components/Sidebar";
import { Actions } from "../../internals/app/Actions";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Headerbar from "../../components/Headerbar";

const Admin = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout, trialDetails, trialLoading, activeSubscriptions } = props;
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const [selectedRoute, setSelectedRoute] = useState<string>("");
  const [selectedSubRoute, setSelectedSubRoute] = useState<string>("");

  const onLocationUpdated = useCallback(
    (pathname: string) => {
      if (trialLoading) {
        return;
      }
      const isActiveSubscription = activeSubscriptions.length > 0;
      // const days = moment(trialDetails?.isExpired).diff(moment(), 'days');
      if ((!trialDetails && !isActiveSubscription) || (trialDetails?.isExpired && !isActiveSubscription)) {
        if (!includes(PathsWithoutSubscription, pathname)) {
          navigate("/signup-subscription");
        }
      }
    },
    [trialDetails, activeSubscriptions, navigate, trialLoading]
  );

  useEffect(() => {
    if (location.pathname) {
      onLocationUpdated(location.pathname);
    }
  }, [location.pathname, onLocationUpdated]);

  const toggleOpenDrawer = () => {
    setOpenSidebar(!openSidebar);
  };

  return (
    <div
      className={`w-screen h-screen flex flex-col md:flex-row items-start justify-start`}
    >
      <div
        className={`h-full hidden md:block bg-lavender ${
          openSidebar ? "w-[255px]" : "w-[60px] lg:w-[255px]"
        }`}
      >
        <Sidebar
          logout={() => logout()}
          openSidebar={openSidebar}
          selectedRoute={selectedRoute}
          onSelectDashBoard={() => {}}
          selectedSubRoute={selectedSubRoute}
          toggleOpenDrawer={() => toggleOpenDrawer()}
          setSelectedRoute={(route: string) => setSelectedRoute(route)}
          setSelectedSubRoute={(route: string) => setSelectedSubRoute(route)}
        />
      </div>
      <div
        className={`flex flex-col h-full items-start bg-white justify-start overflow-auto ${
          openSidebar
            ? "w-full md:w-[calc(100vw-255px)]"
            : "w-full md:w-[calc(100vw-60px)] lg:w-[calc(100vw-255px)]"
        }`}
      >
        <div
          className={`w-full block md:hidden h-[65px] bg-lavender ${
            openSidebar ? "w-[100vw - 255px]" : ""
          }`}
        >
          <Headerbar toggleOpenDrawer={() => toggleOpenDrawer()} />
        </div>
        <div
          className={`flex flex-col items-start  bg-white justify-start overflow-auto ${
            openSidebar
              ? "w-full md:w-[calc(100vw-255px)] h-[calc(100vh-65px)] sm:h-full"
              : "w-full h-[calc(100vh-65px)] sm:h-full md:w-[calc(100vw-60px)] lg:w-[calc(100vw-255px)]"
          }`}
        >
          <Outlet />
        </div>
      </div>

      {/* <div
        className={`flex flex-col items-start h-full bg-white justify-start overflow-scroll ${
          openSidebar
            ? "w-[calc(100vw-255px)]"
            : "w-screen md:w-[calc(100vw-60px)] lg:w-[calc(100vw-255px)]"
        }`}
      >
        <Outlet />
      </div> */}
    </div>
  );
};

export default connect(
  (state: any) => ({
    trialDetails: state.setting.get("trialDetails"),
    activeSubscriptions: state.setting.get("activeSubscriptions"),
    subscriptionsList: state.setting.get("subscriptionsList"),
    trialLoading: state.setting.get("trialLoading"),
  }),
  {
    logout: Actions.login.logout,
  }
)(Admin);

const PathsWithoutSubscription = [
  "/signin",
  "/signup-subscription",
  "/signup",
  "/forget-password",
  "/business-manage",
  "/otp-screen",
  "/subscription",
];
