import React from "react";
import { connect } from "react-redux";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { getDayOrderDetails } from "../../internals/managers/ReportsManager";
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = (props: any) => {
  const { dayOrders } = props;
  const { labels, datasets, colorList, ordersArray } =
    getDayOrderDetails(dayOrders);

  const options: any = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: 1,
    layout: {
      padding: {
        bottom: 0,
      },
    },
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          font: {
            size: 10,
            align: "start",
            family: "Inter",
          },
        },
        align: "start",
      },
    },
  };

  const data: any = {
    labels: labels,
    datasets: [
      {
        data: datasets,
        backgroundColor: colorList,
      },
    ],
  };
  return <Doughnut width={"100px"} options={options} data={data} />;
};
export default connect(
  (state: any) => ({
    dayOrders: state.dashboard.get("dayOrders"),
    dayReportSummary: state.dashboard.get("dayReportSummary"),
  }),
  () => ({})
)(DoughnutChart);
