export const LOGIN_ERROR = {
  RESOURCE_NOT_FOUND: "ResourceNotFoundException",
  USER_NAME_EXISTS: "UsernameExistsException",
  USER_NOT_CONFIRMED: "UserNotConfirmedException",
};

export enum CategoryList {
  BEAUTY = "Beauty",
  RETAILS = "Retails",
  SERVICES = "Services",
  HOSPITALITY = "Hospitality",
  FOOD_AND_BEVERAGES = "Food and Beverages",
}

export enum FoodType {
  QUICK_SERVICE = "Quick Service",
  FULL_SERVICE = "Full Service",
  BARS_AND_BREWERIES = "Bars and Breweries",
  COFFEE_SHOPS = "Coffee shops",
  DELIVERY_SERVICES = "Delivery Services",
}

export enum RetailsType {
  CLOTHING_AND_ACCESSORIES = "Clothing and Accessories",
  GROCERIES_AND_CONVENIENVES = "Groceries and Convenience",
  APPLIANCES_AND_HOME_GOODS = "Appliances and Home Goods",
  ELECTRONICS = "Electronics",
  E_COMMERCE = "E-Commerce",
}

export enum BeautyType {
  BEAUTY_SALONS = "Beauty Salons",
  HAIR_SALONS = "Hair Salons",
  NAIL_SALONS = "Nail Salons",
  DAY_SPA = "Day Spa",
  BARBERSHOP = "Barbershop",
}

export enum ServiceType {
  VEHICLE_SERVICES = "Vehicle services",
  PROFESSIONAL_SERVICES = "Professional Services",
  HEALTH_AND_FITNESS = "Health and Fitness",
  REPAIR = "Repair",
}

export enum HospitalityType {
  HOTEL = "Hotel",
  //  RENTAL = 'House Rental'
}

export const getEnumOptions = (list: any) => {
  let options: any = Object.entries(list).map(([value, label]) => ({
    label,
    value,
  }));
  return options;
};

export const getEnumTypes = (category: string) => {
  switch (category) {
    case "BEAUTY":
      return BeautyType;
    case "RETAILS":
      return RetailsType;
    case "SERVICES":
      return ServiceType;
    case "FOOD_AND_BEVERAGES":
      return FoodType;
    case "HOSPITALITY":
      return HospitalityType;
    default:
      return [];
  }
};

export const getShopCtaegory = (category: string) => {
  switch (category) {
    case "BEAUTY":
      return "Beauty";
    case "RETAILS":
      return "Retails";
    case "SERVICES":
      return "Services";
    case "FOOD_AND_BEVERAGES":
      return "Food and Beverages";
    case "HOSPITALITY":
      return "Hospitality";
    default:
      return "";
  }
};

export const getShopType = (type: string) => {
  switch (type) {
    case "QUICK_SERVICE":
      return "Quick Service";
    case "FULL_SERVICE":
      return "Full Service";
    case "BARS_AND_BREWERIES":
      return "Bars and Breweries";
    case "COFFEE_SHOPS":
      return "Coffee shops";
    case "DELIVERY_SERVICES":
      return "Delivery Services";
    case "CLOTHING_AND_ACCESSORIES":
      return "Clothing and Accessories";
    case "GROCERIES_AND_CONVENIENVES":
      return "Groceries and Convenience";
    case "APPLIANCES_AND_HOME_GOODS":
      return "Appliances and Home Goods";
    case "ELECTRONICS":
      return "Electronics";
    case "BEAUTY_SALONS":
      return "Beauty Salons";
    case "HAIR_SALONS":
      return "Hair Salons";
    case "NAIL_SALONS":
      return "Nail Salons";
    case "DAY_SPA":
      return "Day Spa";
    case "BARBERSHOP":
      return "Barbershop";
    case "VEHICLE_SERVICES":
      return "Vehicle services";
    case "PROFESSIONAL_SERVICES":
      return "Professional Services";
    case "HEALTH_AND_FITNESS":
      return "Health and Fitness";
    case "REPAIR":
      return "Repair";
    case "HOTEL":
      return "Hotel";
    default:
      return "";
  }
};
