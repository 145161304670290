import { nanoid } from "nanoid";
import { generatePin } from "../../internals/helper/SalonHelper";
import ShopRepository from "../../internals/repository/ShopRepository";
import ReportRepository from "../../internals/repository/ReportRepository";

export const ModuleEvents = {
  CREATE_SHOP: "CREATE_SHOP",
  UPDATE_SHOP: "UPDATE_SHOP",
  FETCH_REPORTS: "FETCH_REPORTS",
  FETCH_ADMIN_SHOPS: "FETCH_ADMIN_SHOPS",
  SELECTED_ADMIN_SHOP: "SELECTED_ADMIN_SHOP",
  FETCH_ORDERS_FOR_DATE: "FETCH_ORDERS_FOR_DATE",
  FETCH_SHOP_CATEGORIES: "FETCH_SHOP_CATEGORIES",
  FETCH_SUMMARY_FOR_DATE: "FETCH_SUMMARY_FOR_DATE",
  FETCH_SUMMARY_FOR_MONTH: "FETCH_SUMMARY_FOR_MONTH",
  FETCH_DAY_REPORT_SUMMARY: "FETCH_DAY_REPORT_SUMMARY",
};

const createAction =
  (type, action = () => {  }, meta) =>
      (...args) => ({
        type,
        payload: action(...args),
        meta,
      });

const fetchAdminShops = createAction(
  ModuleEvents.FETCH_ADMIN_SHOPS,
  async (userId) => {
    const result = await ShopRepository.fetchAdminShops(userId);
    return result;
  },
  { loading: true }
);

const selectedAdminShop = createAction(
  ModuleEvents.SELECTED_ADMIN_SHOP,
  async (shop) => shop
);

const fetchShopCategories = createAction(
  ModuleEvents.FETCH_SHOP_CATEGORIES,
  async (shopId) => {
    const result = await ShopRepository.fetchShopCategories(shopId);
    return result;
  }
);

const createShop = createAction(
  ModuleEvents.CREATE_SHOP,
  async (params, userId) => {
    const shopId = nanoid(20);
    const time = Date.now();
    const shop = {
      ...params,
      shopId,
      currency: "LKR",
      createdBy: userId,
      createdTime: time,
      updatedTime: time,
      pin: generatePin(),
    };
    const claim = {
      shopId,
      role: "ADMIN",
      createdTime: time,
      updatedTime: time,
      claimType: "ACTIVE",
      claimStatus: "PENDING",
      requestedUserId: userId,
    };
    const result = await ShopRepository.createShop(shop);
    await ShopRepository.createShopClaim(claim);
    return result;
  },
  { loading: true }
);

const updateShop = createAction(
  ModuleEvents.UPDATE_SHOP,
  async (param) => {
    const result = await ShopRepository.updateShop(param);
    return result;
  },
  { loading: true }
);

const fetchSummaryForMonth = createAction(
  ModuleEvents.FETCH_SUMMARY_FOR_MONTH,
  async (shopId, reportMonth) => {
    const result = await ReportRepository.fetchSummaryForMonth(
      shopId,
      reportMonth
    );
    return result;
  }
);

const fetchSummaryForDate = createAction(
  ModuleEvents.FETCH_SUMMARY_FOR_DATE,
  async (shopId, startDate) => {
    const result = await ReportRepository.fetchSummaryForDate(
      shopId,
      startDate
    );
    return result;
  }
);

const fetchOrdersForDate = createAction(
  ModuleEvents.FETCH_ORDERS_FOR_DATE,
  async (shopId, startDate) => {
    const result = await ReportRepository.fetchOrdersForDate(
      shopId,
      startDate
    );
    return result;
  }
);

const exportedFuction = {
  createShop,
  updateShop,
  fetchAdminShops,
  selectedAdminShop,
  fetchSummaryForMonth,
  fetchShopCategories,
  fetchSummaryForDate,
  fetchOrdersForDate,
};

export default exportedFuction;
