import Swal from "sweetalert2";
import { delay } from "lodash";
import { router } from "../../App";
import { ModuleEvents } from "./Actions";
import { Actions } from "../../internals/app/Actions";
import withReactContent from "sweetalert2-react-content";

export default {
  [ModuleEvents.FETCH_BATCH_SUMMARY_BY_ID]: ({
    dispatch,
    payload,
    appState,
  }) => {
    const { params, result } = payload;
    if (result && !result.error) {
      dispatch(
        Actions.transaction.completedCloseCashById(
          params.batchId,
          params.shopId
        )
      );
      dispatch(
        Actions.transaction.fetchBatchOrderSummary(
          params.batchId,
          params.shopId
        )
      );
      router.navigate("/close-cash");
    } else {
      return warning("Unexpected result found!");
    }
  },
};

const warning = (alert) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    icon: "error",
    text: alert,
    title: "Oops...",
    showCancelButton: true,
    showConfirmButton: false,
    cancelButtonColor: "#FF3333",
  });
};
