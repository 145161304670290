import React from "react";
import Summary from "../../../components/Summary";
import { connect } from "react-redux";
import ServicesGrowthWidget from "../../../components/ServicesGrowthWidget";
import HelpGuide from "../../help/HelpGuide";

export const DashboardView = (props: any) => {
  return (
    <div className="flex flex-col w-full p-6 justify-start">
      <Summary />
      <ServicesGrowthWidget />
    </div>
  );
};
export default connect((state: any) => ({}), {})(DashboardView);
