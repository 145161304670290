import { Map } from "immutable";
import { ModuleEvents } from "./Actions";
import { reverse, sortBy, get, orderBy, uniqBy } from "lodash";
import { ModuleEvents as LoginEvents } from "../login/Actions";
import { ModuleEvents as HomeEvents } from "../dashboard/Actions";

const initialState = Map({
  transactions: {
    transactions: [],
    nextLastKey: null,
    lastKeyList: [],
  },
  batchSummary: {
    lastKey: {},
    batchSummary: [],
  },
  selectedBatch: {},
  selectedBatchSummary: {},
  completedCashAppointment: {
    appointments: [],
    nextLastKey: null,
  },
  batchOrderSummary: {},
  selectedScreen: 'CLOSE_CASH',

  closeCashForRange: {
    lastKey: {},
    closeCash: [],
  },
  isSelectedDateRange: false,
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.FETCH_ORDER_TRANSACTIONS: {
      const transactions = state.get("transactions");
      if (payload && !payload.error) {
        const list = uniqBy(
          [...transactions.transactions, ...payload.Items],
          "orderId"
        );
        const sortTransaction = orderBy(
          list,
          ["updatedTime"],
          ["desc"]
        );
        const appointmentLastKey = get(payload, "LastEvaluatedKey", null);
        transactions.transactions = sortTransaction;
        transactions.nextLastKey = appointmentLastKey;
        return state.set("transactions", { ...transactions });
      }
      return state;
    }

    case ModuleEvents.FETCH_PAGINATE_ORDER_TRANSACTION: {
      const transactions = state.get("transactions");
      if (payload && !payload.error) {
        const appointmentLastKey = get(payload, "lastKey", {});
        const newAppointment = reverse(
          sortBy(get(payload, "items", []), "updatedTime")
        );
        transactions.transactions = [
          ...transactions.transactions,
          ...newAppointment,
        ];
        transactions.nextLastKey = appointmentLastKey;
        return state.set("transactions", { ...transactions });
      }
      return state;
    }

    case ModuleEvents.SEARCH_ORDER_TRANSACTIONS: {
      const transactions = state.get("transactions");
      if (payload && !payload.error) {
        const sortTransaction = reverse(sortBy(payload.Items, "updatedTime"));
        const appointmentLastKey = get(payload, "LastEvaluatedKey", {});
        transactions.transactions = sortTransaction;
        transactions.nextLastKey = appointmentLastKey;
        return state.set("transactions", { ...transactions });
      }
      return state;
    }

    case ModuleEvents.SET_SELECTED_DATERANGE: {
      return state.set("isSelectedDateRange", payload);
    }

    case ModuleEvents.FETCH_CLOSECASH_FOR_DATERANGE: {
      if (payload && !payload.error) {
        const closeCashForRange = state.get("closeCashForRange");
        const lastKey = get(payload, "LastEvaluatedKey", {});
        const summary = orderBy(
          get(payload, "Items", []),
          ["createdTime"],
          ["desc"]
        );
        closeCashForRange.lastKey = lastKey;
        closeCashForRange.closeCash = summary;
        return state.set("closeCashForRange", { ...closeCashForRange });
      }
      return state;
    }

    case ModuleEvents.FETCH_PAGINATE_CLOSECASH_FOR_DATERANGE: {
      const closeCashForRange = state.get("closeCashForRange");
      if (payload && !payload.error) {
        const lastKey = get(payload, "LastEvaluatedKey", {});
        const summary = orderBy(
          get(payload, "Items", []),
          ["updatedTime"],
          ["desc"]
        );
        closeCashForRange.lastKey = lastKey;
        closeCashForRange.closeCash = [
          ...closeCashForRange.closeCash,
          ...summary,
        ];
        return state.set("closeCashForRange", { ...closeCashForRange });
      }
      return state;
    }

    case ModuleEvents.FETCH_BATCH_SUMMARY: {
      if (payload && !payload.error) {
        const batchSummary = state.get("batchSummary");
        const lastKey = get(payload, "lastKey", {});
        const summary = orderBy(
          get(payload, "items", []),
          ["updatedTime"],
          ["desc"]
        );
        batchSummary.lastKey = lastKey;
        batchSummary.batchSummary = summary;
        return state.set("batchSummary", { ...batchSummary });
      }
      return state;
    }

    case ModuleEvents.FETCH_PAGINATE_BATCH_SUMMARY: {
      const batchSummary = state.get("batchSummary");
      if (payload && !payload.error) {
        const lastKey = get(payload, "lastKey", {});
        const summary = orderBy(
          get(payload, "items", []),
          ["updatedTime"],
          ["desc"]
        );
        batchSummary.lastKey = lastKey;
        batchSummary.batchSummary = [
          ...batchSummary.batchSummary,
          ...summary,
        ];
        return state.set("batchSummary", { ...batchSummary });
      }
      return state;
    }


    case ModuleEvents.FETCH_BATCH_SUMMARY_BY_ID: {
      const { result, params } = payload;
      if (result && !result.error) {
        return state.set("selectedBatch", result)
          .set("selectedBatchSummary", params);
      }
      return state;
    }

    case ModuleEvents.COMPLETED_CLOSE_CASH_BY_ID: {
      if (payload && !payload.error) {
        const completedCashAppointment = state.get("completedCashAppointment");

        const appointments = orderBy(
          get(payload, "Items", []),
          ["updatedTime"],
          ["desc"]
        );
        const nextLastKey = get(payload, "LastEvaluatedKey", {});
        completedCashAppointment.appointments = appointments;
        completedCashAppointment.nextLastKey = nextLastKey;
        return state.set("completedCashAppointments", {
          ...completedCashAppointment,
        });
      }
      return state;
    }

    case ModuleEvents.FETCH_BATCH_ORDER_SUMMARY: {
      if (payload && !payload.error) {
        return state.set("batchOrderSummary", payload);
      }
      return state;
    }
    case ModuleEvents.SET_SELECTED_SCREEN: {
      return state.set("selectedScreen", payload);
    }
    case LoginEvents.LOG_OUT: {
      const transactions = {
        transactions: [],
        nextLastKey: null,
        lastKeyList: [],
      };
      const batchSummary = {
        lastKey: {},
        batchSummary: [],
      };
      const completedCashAppointment = {
        appointments: [],
        nextLastKey: null,
      };
      return state
        .set("selectedBatch", {})
        .set("selectedBatchSummary", {})
        .set("transactions", { ...transactions })
        .set("batchSummary", { ...batchSummary })
        .set("completedCashAppointments", {
          ...completedCashAppointment,
        });
    }

    case HomeEvents.SELECTED_ADMIN_SHOP: {
      const transactions = {
        transactions: [],
        nextLastKey: null,
        lastKeyList: [],
      };
      const batchSummary = {
        lastKey: {},
        batchSummary: [],
      };
      const completedCashAppointment = {
        appointments: [],
        nextLastKey: null,
      };
      return state
        .set("selectedBatch", {})
        .set("selectedBatchSummary", {})
        .set("transactions", { ...transactions })
        .set("batchSummary", { ...batchSummary })
        .set("completedCashAppointments", {
          ...completedCashAppointment,
        });
    }

    default:
      return state;
  }
};

export default Reducer;
