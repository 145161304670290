import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/svgs/logo.svg";
import { HiOutlineMenuAlt2 } from "react-icons/hi";

const Headerbar = (props: { toggleOpenDrawer: () => void }) => {
  const { toggleOpenDrawer } = props;

  return (
    <div className={`flex flex-row justify-between pl-3 py-4 pr-6 items-center`}>
      <NavLink to="/" className="flex items-center">
        <img src={Logo} className="mr-3 h-9" alt="Netlise Logo" />
        <span className="self-center text-xl font-semibold whitespace-nowrap text-valhalla">
          Netlise
        </span>
      </NavLink>
      <button
        type="button"
        onClick={() => toggleOpenDrawer()}
        className="flex w-9 h-9 justify-center items-center rounded-lg bg-medium-slate-blue hover:bg-slate-blue focus:outline-none"
      >
        <HiOutlineMenuAlt2 color={"#FFFFFF"} size={20} />
      </button>
    </div>
  );
};

export default connect((state: any) => ({}), {})(Headerbar);
