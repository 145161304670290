import { useEffect, useState } from "react";
import { connect } from "react-redux";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { getRevenueData } from "../../internals/managers/ReportsManager";

const tableHeader = ["Description", "Sales", "Refunds", "Net"];

const RevenueTable = (props: any) => {
  const { monthRange, yearReports } = props;
  const [tableData, setTableData] = useState<Array<any>>([]);

  useEffect(() => {
    const { summmary }: any = getRevenueData(monthRange, yearReports);
    setTableData(summmary);
  }, [yearReports]);

  return (
    <div className="w-[100%]">
      <table className="w-full text-sm text-left text-gray-700 bg-boysenberry rounded-lg">
        <thead className="text-xs text-gray-700">
          <tr>
            {tableHeader?.map((item: string, index: number) => {
              return (
                <th key={index} scope="col" className="px-6 py-3">
                  <div className="flex items-center justify-center text-center font-inter font-medium leading-tight text-sm text-[#88938F]">
                    {item}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableData?.map((data: any) => {
            const isBold =
              data.type === "NET_SALE" || data.type === "TOTAL_COLLECTED";
            return (
              <tr className="bg-white border-b border-[rgba(32,3758,.06]">
                {tableHeader?.map((item: any, index: number) => {
                  return (
                    <td key={index} className="px-6 py-4">
                      <div
                        className={
                          "text-xs text-center text-[rgba(0,0,0,.85)] capitalize " +
                          (isBold ? "font-bold" : "font-normal")
                        }
                      >
                        {data[item]}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default connect(
  (state: any) => ({
    yearReports: state.report.get("yearReports"),
  }),
  {}
)(RevenueTable);
