import Repository from './Repository';
import {API, graphqlOperation} from '@aws-amplify/api';
import {FetchPayments, UpdateBilling} from './schema/Payment.schema';

interface Customer {
  name: string;
  empId: string;
  email: string;
  phone: string;
  shopId: string;
}

interface HostedSubscription {
  empId: string;
  planId: string;
  shopId: string;
  priceId: string;
}

interface PaymentMethod {
  empId: string;
  shopId: string;
  subscriptionId: string;
}

interface Package {
  userId: string;
  planId: string;
  amount: number;
  updatedTime: number;
  subscriptionId: string;
}

interface SubscriptionCancel {
  userId: string;
  subscriptionId: string;
}

class PaymentRepository extends Repository {
  async createCustomer(params: Customer) {
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.API_PAYMENT,
        path: '/customer',
        message,
      });
      return result.data.userResult;
    } catch (error) {
      console.warn('error', error);
      return {error};
    }
  }

  async createHostedSubscription(params: HostedSubscription) {
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.API_PAYMENT,
        path: '/hosted/subscription',
        message,
      });
      return result.data.response;
    } catch (error) {
      console.warn('error', error);
      return {error};
    }
  }

  async changePayment(params: PaymentMethod) {
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.API_PAYMENT,
        path: '/change-method',
        message,
      });

      return result;
    } catch (error) {
      console.warn('error', error);
      return {error};
    }
  }

  async updateDowngradePackage(params: Package) {
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.API_PAYMENT,
        path: '/change-package',
        message,
      });

      return result;
    } catch (error) {
      console.warn('error', error);
      return {error};
    }
  }

  async cancelSubscription(params: SubscriptionCancel) {
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.API_PAYMENT,
        path: '/cancel-subscription',
        message,
      });
      return result.data;
    } catch (error) {
      console.warn('error', error);
      return {error};
    }
  }

  async fetchActiveSubscriptions(userId: string) {
    const message = this.buildMessage({userId});
    try {
      const result = await this.apiPost({
        apiName: this.API_PAYMENT,
        path: '/active-subscriptions',
        message,
      });

      return result;
    } catch (error) {
      console.warn('error', error);
      return {error};
    }
  }

  async fetchPackages() {
    // const message = this.buildMessage({});
    try {
      const result = await this.apiGet({
        apiName: this.API_ADMIN_DASHBOARD,
        path: '/payment/packages',
      });
      return result.packages.packages;
    } catch (error) {
      console.warn('error', error);
      return {error};
    }
  }

  async fetchSubscriptions(params: {
    userId: string;
    limit: number;
    lastKey: any;
  }) {
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.API_PAYMENT,
        path: '/all-subscriptions',
        message,
      });
      return result.data.subscriptionResult;
    } catch (error) {
      console.warn('error', error);
      return {error};
    }
  }

  async fetchShopPayments(shopId: string) {
    try {
      const result: any = await this.API.graphql(
        graphqlOperation(FetchPayments, {shopId})
      );
      return result.data.fetchPayments;
    } catch (error) {
      console.warn('fetch menu error', error);
    }
  }

  async getRecurringForRefId(params: {referenceId: string}) {
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.API_PAYMENT,
        path: '/reference-id',
        message,
      });
      return result.data.subscriptionResult;
    } catch (error) {
      console.warn('error', error);
      return {error};
    }
  }

  async updateRecurringCheckStatus(param: any) {
    try {
      const result: any = await API.graphql(
        graphqlOperation(UpdateBilling, {input: param})
      );
      return result.data.updateBillingDetails;
    } catch (error) {
      console.warn('error', error);
      return {error};
    }
  }

  async createTrial({shopId, createdBy}: {shopId: string; createdBy: string}) {
    const message = this.buildMessage({
      shopId,
      createdBy,
    });
    try {
      const result = await this.apiPost({
        apiName: this.API_ADMIN_DASHBOARD,
        path: '/shop/trial',
        message,
      });

      return result.data.result.data;
    } catch (error) {
      console.warn('error', error);
      return {error};
    }
  }

  async fetchTrial(shopId: string) {
    try {
      const result = await this.apiGet({
        apiName: this.API_ADMIN_DASHBOARD,
        path: `/shop/trial/${shopId}`,
      });
      return result.result;
    } catch (error) {
      console.warn('error', error);
      return {error};
    }
  }
}
export default new PaymentRepository();
