import _, {
  get,
  find,
  sumBy,
  filter,
  concat,
  groupBy,
  isEmpty,
  upperCase,
  capitalize,
  flattenDeep,
} from "lodash";
import moment from "moment";
import { formatPrice } from "./PaymentManager";

export const mapReportSummary = (dayReportSummary) => [
  {
    type: "SALES",
    name: "Today Sales",
    text: "Total Transactions",
    totalPrice: _.get(dayReportSummary, "totalSalesPrice", 0),
    totalCount: _.get(dayReportSummary, "totalSalesCount", 0),
    growth: _.get(dayReportSummary, "growth.saleGrowth", 0),
  },
  {
    type: "PRODUCTS",
    name: "Today Products",
    text: "Product sales",
    totalPrice: _.get(dayReportSummary, "totalProductPrice", 0),
    totalCount: _.get(dayReportSummary, "totalProductCount", 0),
    growth: _.get(dayReportSummary, "growth.productGrowth", 0),
  },
  {
    type: "SERVICES",
    name: "Today Services",
    text: "Services sales",
    totalPrice: _.get(dayReportSummary, "totalServicePrice", 0),
    totalCount: _.get(dayReportSummary, "totalServiceCount", 0),
    growth: _.get(dayReportSummary, "growth.serviceGrowth", 0),
  },
  {
    type: "CUSTOMERS",
    name: "Total Customers",
    totalCount: _.get(dayReportSummary, "totalClients", 0),
    growth: _.get(dayReportSummary, "growth.clientGrowth", 0),
  },
];

export const getDayOrderDetails = (orders) => {
  let labels = [];
  let datasets = [];
  let colorList = [];
  let productList = [];
  let productArray = [];
  let serviceList = [];
  let serviceArray = [];

  const colors = [
    "#6712A4",
    "#1A4FCF",
    "#EBED1B",
    "#1FD6D3",
    "#E9346A",
    "#06810A",
    "#1E0CB8",
    "#3A0671",
    "#7A043F",
    "#7295E9",
  ];

  orders.forEach((appointment) => {
    if (appointment.productDetails) {
      appointment.productDetails.forEach((product) => {
        productList.push(product);
      });
    }
    if (appointment.serviceDetails) {
      appointment.serviceDetails.forEach((service) => {
        serviceList.push(service);
      });
    }
  });

  const groupProducts = groupBy(flattenDeep(productList), "id");
  const groupServices = groupBy(flattenDeep(serviceList), "id");

  Object.keys(groupProducts).map((key) => {
    productArray.push({
      name: capitalize(get(groupProducts[key], "0.productName")),
      quantity: sumBy(groupProducts[key], "quantity"),
      totalPrice: sumBy(groupProducts[key], "totalPrice"),
    });
    return key;
  });
  Object.keys(groupServices).map((key) => {
    serviceArray.push({
      name: capitalize(get(groupServices[key], "0.title")),
      quantity: sumBy(groupServices[key], "quantity"),
      totalPrice: sumBy(groupServices[key], "totalPrice"),
    });
    return key;
  });
  const ordersArray = concat(serviceArray, productArray);

  ordersArray.forEach((data, index) => {
    const id = index > colors.length ? Math.floor(Math.random() * (9 - 0 + 1)) + 0 : index;
    labels.push(data.name);
    datasets.push(data.totalPrice);
    colorList.push(colors[id]);
  });
  if (isEmpty(datasets)) {
    datasets.push(1)
    colorList.push('#575AC7')
  }
  return { labels, datasets, colorList, ordersArray };
};

const roundDownTime = (begin, end) => {
  const startHour = moment(begin, ["h:mm A"]).startOf("hour");
  const endHour = moment(end, ["h:mm A"]).startOf("hour");

  const startDiff = moment
    .duration(moment(begin, ["h:mm A"]).diff(startHour))
    .asMinutes();
  const endDiff = moment
    .duration(moment(end, ["h:mm A"]).diff(endHour))
    .asMinutes();

  let newStart;
  let newEnd;
  if (startDiff === 0) {
    newStart = begin;
  } else {
    if (startDiff >= 30) {
      newStart = moment(begin, ["h:mm A"]).subtract(startDiff - 30, "minutes");
    } else {
      newStart = moment(begin, ["h:mm A"]).subtract(startDiff, "minutes");
    }
  }
  if (endDiff === 0) {
    newEnd = end;
  } else {
    if (endDiff > 30) {
      newEnd = moment(end, ["h:mm A"]).add(60 - endDiff, "minutes");
    } else {
      newEnd = moment(end, ["h:mm A"]).add(30 - endDiff, "minutes");
    }
  }

  const start = moment(newStart, ["h:mm A"]).format("HH:mm");
  const close = moment(newEnd, ["h:mm A"]).format("HH:mm");
  return { start: start, end: close };
};

export const getStartAndEndTime = (orderList) => {
  if (orderList && orderList.length > 0) {
    orderList.sort(
      (a, b) =>
        new Date("1970/01/01 " + a.time) - new Date("1970/01/01 " + b.time)
    );
    const orderLength = orderList.length;
    let startTime = orderLength > 0 ? orderList[0].time : "00:00";
    const endTime =
      orderLength > 0 ? orderList[orderList.length - 1].time : "00:00";

    let { start, end } = roundDownTime(startTime, endTime);

    return { start, end };
  }

  return { start: "08:00", end: "20:00" };
};

export const getDayTimeDetails = (orders) => {
  const { start, end } = getStartAndEndTime(orders);

  let timeArray = [];
  let newStart = moment(start, "HH:mm").valueOf();
  let newEnd = moment(end, "HH:mm").valueOf();
  while (newStart <= newEnd) {
    const time = moment(newStart).format("hh:mm A");
    timeArray.push(time);
    newStart = moment(newStart).add(1, "hours").valueOf();
  }
  return timeArray;
};

const mapChartValues = (labelList) =>
  labelList.map((label) => {
    const total = _.sumBy(label.values, "totalPrice");
    return total > 0 ? total : 0;
  });

export const getDaySales = (labels, orders) => {
  const groupByList = _.groupBy(orders, "time");
  let filterList = [];

  const minutes = moment(labels[0], "hh:mm A").format("mm");
  const has30 = minutes.includes("30");

  Object.keys(groupByList).map((key) => {
    const hours = moment(key, "hh:mm A").format("hh");
    const timeType = moment(key, "hh:mm A").format("A");
    const newTime = `${hours}:${has30 ? "30" : "00"} ${timeType}`;
    const findIndex = _.findIndex(labels, (item) => item === newTime);
    if (findIndex > -1) {
      filterList.push({
        label: newTime,
        values: groupByList[key],
      });
    }
    return newTime;
  });

  const labelList = labels.map((label) => {
    let itemValues = [];
    const filterData = _.filter(filterList, (item) => item.label === label);

    filterData.map((item) => {
      itemValues.push(...item.values)
    })
    return {
      label: label,
      values: itemValues,
    };
  });
  return mapChartValues(labelList);
};

export const monthLabels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export const getMonthLabels = (range, reports) => {
  const { startDate, endDate } = range;
  let endTimestamp = moment(endDate).endOf("month").valueOf();
  let startTimestamp = moment(startDate).startOf("month").valueOf();
  const isSameMonth = moment(startDate).isSame(endDate, 'month')

  let labels = [];
  if (isSameMonth) {
    let endDayTimestamp = moment(endDate).endOf("day").valueOf();
    let startDayTimestamp = moment(startDate).startOf("day").valueOf();
    while (startDayTimestamp <= endDayTimestamp) {
      const value = upperCase(moment(startDayTimestamp).format('DD MMM'));
      labels.push(value);
      startDayTimestamp = moment(startDayTimestamp).add(1, "days").valueOf();
    }
  } else {
    while (startTimestamp <= endTimestamp) {
      const value = upperCase(moment(startTimestamp).format('MMM YY'));
      labels.push(value);
      startTimestamp = moment(startTimestamp).add(1, "months").valueOf();
    }
  }
  return labels;
};

const getSumSummary = (labelList) =>
  labelList.map((label) => {
    let total = 0;
    if (label.values) {
      total = _.sumBy(label.values.summary, "totalPrice");
    }
    return total;
  });

export const getMonthSales = (monthRange, labels, reports) => {
  const { startDate, endDate } = monthRange;
  const endDay = moment(endDate).dates();
  const startDay = moment(startDate).dates();
  const isSameMonth = moment(startDate).isSame(endDate, 'month')

  const newReport = reports.map((report) => {
    const startMonth = moment(startDate).startOf("month").valueOf();
    const endMonth = moment(endDate).startOf("month").valueOf();
    if (isSameMonth) {
      const filterSummary = filter(report.summary, (summary) => (summary.day <= endDay && summary.day >= startDay))
      report.summary = filterSummary;
    } else {
      if (startMonth === report.monthStartTimesTamp) {
        const filterSummary = filter(report.summary, (summary) => summary.day >= startDay)
        report.summary = filterSummary;
      }
      if (endMonth === report.monthStartTimesTamp) {
        const filterSummary = filter(report.summary, (summary) => summary.day <= endDay)
        report.summary = filterSummary;
      }
    }
    return report;
  })

  const groupByList = _.groupBy(newReport, "monthStartTimesTamp");
  let filterList = [];

  if (isSameMonth) {
    const summaryData = get(newReport, '[0].summary', []);
    summaryData.map((summary) => {
      const day = `${summary.day}`.length === 1 ? '0' + summary.day : summary.day
      let labelValue = `${day} ${upperCase(moment(newReport[0].monthStartTimesTamp).format('MMM'))}`;
      const findIndex = _.findIndex(labels, (item) => item === labelValue);
      if (findIndex > -1) {
        filterList.push({
          label: labelValue,
          values: summary,
        });
      }
    })
  } else {
    Object.keys(groupByList).map((key) => {
      let labelValue = upperCase(moment(groupByList[key].monthStartTimesTamp).format('MMM YY'));
      const findIndex = _.findIndex(labels, (item) => item === labelValue);
      if (findIndex > -1) {
        filterList.push({
          label: labelValue,
          values: groupByList[key],
        });
      }
    });
  }

  const labelList = labels.map((label) => {
    if (isSameMonth) {
      const filterData = find(filterList, (item) => item.label === label);
      return {
        label: label,
        values: get(filterData, 'values', {}),
      };
    } else {
      const filterData = find(filterList, (item) => item.label === label);
      return {
        label: label,
        values: get(filterData, 'values[0]', {}),
      };
    }
  });

  if (isSameMonth) {
    return labelList.map((label) => {
      return get(label, 'values.totalPrice', 0);
    });
  } else {
    return getSumSummary(labelList);
  }
};



export const getRevenueData = (monthRange, reports) => {
  const { startDate, endDate } = monthRange;
  const endDay = moment(endDate).dates();
  const startDay = moment(startDate).dates();
  const isSameMonth = moment(startDate).isSame(endDate, 'month')
  const isSameDay = moment(startDate).isSame(endDate, 'day')

  const newReport = reports?.map((report) => {
    const startMonth = moment(startDate).startOf("month").valueOf();
    const endMonth = moment(endDate).startOf("month").valueOf();
    if (isSameMonth) {
      const filterSummary = filter(report.summary, (summary) => (summary.day <= endDay && summary.day >= startDay))
      report.summary = filterSummary;
    } else {
      if (startMonth === report.monthStartTimesTamp) {
        const filterSummary = filter(report.summary, (summary) => summary.day >= startDay)
        report.summary = filterSummary;
      }
      if (endMonth === report.monthStartTimesTamp) {
        const filterSummary = filter(report.summary, (summary) => summary.day <= endDay)
        report.summary = filterSummary;
      }
    }
    return report;
  })

  let summaryData = {
    total: 0,
    totalPrice: 0,
    refundCount: 0,
    productCount: 0,
    serviceCount: 0,
    discountCount: 0,
    totalRefundPrice: 0,
    totalProductPrice: 0,
    totalServicePrice: 0,
    totalDiscountPrice: 0,
    totalServiceChargePrice: 0,
  };
  reports.map((report) => {
    report?.summary?.map((value) => {
      summaryData.total += get(value, 'total', 0);
      summaryData.totalPrice += get(value, 'totalPrice', 0);
      summaryData.refundCount += get(value, 'refundCount', 0);
      summaryData.serviceCount += get(value, 'serviceCount', 0);
      summaryData.productCount += get(value, 'productCount', 0);
      summaryData.discountCount += get(value, 'discountCount', 0);
      summaryData.totalRefundPrice += get(value, 'totalRefundPrice', 0);
      summaryData.totalProductPrice += get(value, 'totalProductPrice', 0);
      summaryData.totalServicePrice += get(value, 'totalServicePrice', 0);
      summaryData.totalDiscountPrice += get(value, 'totalDiscountPrice', 0);
      summaryData.totalServiceChargePrice += get(value, 'totalServiceChargePrice', 0);
    })
  })
  const totalRefundsPrice = formatPrice(summaryData.totalRefundPrice);

  const grossPrice = summaryData.totalProductPrice + summaryData.totalServicePrice;
  const grossSales = formatPrice(grossPrice);
  const grossNetPrice = grossPrice - summaryData.totalRefundPrice;
  const grossNetSales = formatPrice(grossNetPrice);

  const totalDiscountPrice = formatPrice(summaryData.totalDiscountPrice);
  const discounNetPrice = summaryData.totalDiscountPrice + summaryData.totalRefundPrice;
  const discountNetSales = formatPrice(discounNetPrice);

  const netSalesPrice = (grossPrice + summaryData.totalServiceChargePrice);
  const netSales = formatPrice(netSalesPrice);

  const netPrice = netSalesPrice - summaryData.totalRefundPrice;
  const net = formatPrice(netPrice);

  const totalSalesPrice = formatPrice(summaryData.totalPrice);
  const totalPrice = summaryData.totalPrice - summaryData.totalRefundPrice;
  const totalSales = formatPrice(totalPrice);

  const summmary = [
    {
      Description: "Gross Sales",
      Sales: grossSales,
      Refunds: totalRefundsPrice,
      Net: grossNetSales,
      type: "GROSS_SALE",
    },
    {
      Description: "Discounts & Comps",
      Sales: totalDiscountPrice,
      Refunds: totalRefundsPrice,
      Net: discountNetSales,
      type: "DISCOUNT",
    },
    {
      Description: "Net Sales",
      Sales: netSales,
      Refunds: totalRefundsPrice,
      Net: net,
      type: "NET_SALE",
    },
    {
      Description: "Total Collected",
      Sales: totalSalesPrice,
      Refunds: totalRefundsPrice,
      Net: totalSales,
      type: "TOTAL_COLLECTED",
    },
  ];
  return {summmary, net: netPrice, totalRefunds: summaryData.totalRefundPrice, totalDiscount: summaryData.totalDiscountPrice}
};

export const getCloseCashRow = () => {

}
