import { useState } from "react";
import { connect } from "react-redux";
import Alert from "../../../components/Alert";
import { Actions } from "../../../internals/app/Actions";
import ProductGrid from "../../../components/ProductGrid";
import ProductList from "../../../components/ProductList";
import CategoryList from "../../../components/CategoryList";
import ProductHeader from "../../../components/ProductHeader";

const ProductView = (props: any) => {
  const { openAlertBox, alertStatus } = props;
  const [isGrid, setIsGrid] = useState<boolean>(false);

  const toggleGridList = (status: boolean) => {
    setIsGrid(status);
  };

  return (
    <div className="flex flex-col w-full p-6 justify-start">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="pb-6">
        <ProductHeader isGrid={isGrid} toggleGridList={(status) => toggleGridList(status)} />
      </div>
      {isGrid ? (
        <div className="w-full justify-start grid grid-cols-1 gap-6 sm:grid-cols-7">
          <div className="flex justify-start col-span-1">
            <CategoryList />
          </div>
          <div className="col-span-6">
            <ProductGrid />
          </div>
        </div>
      ) : (
        <ProductList />
      )}
    </div>
  );
};

export default connect(
  (state: any) => ({
    products: state.product.get("products"),
    alertStatus: state.common.get("alertStatus"),
  }),
  {
    openAlertBox: Actions.common.openAlertBox,
  }
)(ProductView);
