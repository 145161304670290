import TransactionRepository from "../../internals/repository/TransactionRepository";

export const ModuleEvents = {
    FETCH_ORDER_TRANSACTIONS: "FETCH_ORDER_TRANSACTIONS",
};

const createAction =
  (type, action = () => {}, meta) =>
  (...args) => ({
    type,
    payload: action(...args),
    meta,
  });

const fetchOrderTransactions = createAction(
  ModuleEvents.FETCH_ORDER_TRANSACTIONS,
  async (shopId, limit = 10, lastKey = null) => {
    const result = await TransactionRepository.fetchOrderTransactions(
      shopId,
      limit,
      lastKey
    );
    return result;
  },
  { loading: true }
);

const exportedFuction = {
    fetchOrderTransactions,
};

export default exportedFuction;
