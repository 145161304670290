import {useEffect, useState} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import Table from '../../../components/Table';
import Alert from '../../../components/Alert';
import {capitalize, find, get} from 'lodash';
import {Actions} from '../../../internals/app/Actions';
import Edit from '../../../assets/svgs/subscription/dots.svg';
import SubscriptionHeader from '../../../components/SubscriptionHeader';
import SubscriptionCancelModal from '../../../components/SubscriptionCancelModal';
import SubscriptionPayments from './SubscriptionPayments';

const tableHeader = [
  'SUBSCRIPTION',
  'PURCHASE DATE',
  'TYPE',
  'TIME',
  'STATUS',
  'AMOUNT',
  'ACTIONS',
];

const SubscriptionPlans = (props: any) => {
  const {
    openAlertBox,
    alertStatus,
    subscriptionsList,
    cancelSubscription,
    selectedShop,
  } = props;
  const [list, setList] = useState<string>('SUBSCRIPTION');
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [selectedPlan, setSelectedPlan] = useState<any>({});
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);

  useEffect(() => {
    if (subscriptionsList.subscriptions) {
      const tableData = getData(
        subscriptionsList.subscriptions.filter(
          ({shopId}: any) => shopId === selectedShop.shopId
        )
      );
      setTableData(tableData);
    }
  }, [subscriptionsList]);

  const getData = (data: any) => {
    return data.map((item: any) => {
      const date = moment(item.periodStart * 1000).format('YYYY/MM/DD');
      return {
        id: item.subscriptionId,
        SUBSCRIPTION: item.planId,
        TYPE: capitalize(get(item, 'categoryResult.categoryName', '')),
        'PURCHASE DATE': date,
        TIME: capitalize(item.recurringFrequency),
        STATUS:
          get(item, 'status', '') === 'SUCCESS' ? 'Active' : 'Deactivated',
        AMOUNT: get(item, 'amount', 0),
        ACTIONS: get(item, 'status', '') === 'SUCCESS' ? [Edit] : null,
      };
    });
  };

  const onClickOption = (data: any, index: number) => {
    const subscription = find(
      subscriptionsList.subscriptions,
      (plan: any) => plan.subscriptionId === data.id
    );
    setSelectedPlan(subscription);
    if (index === 0) {
    }
  };

  const modalData = [
    {title: 'Update Plan', id: 'UPDATE'},
    {title: 'Cancel Plan', id: 'CANCEL'},
  ];

  const toggleCancelModal = () => {
    setOpenCancelModal(!openCancelModal);
  };

  const onClickPlanOption = (data: any) => {
    const subscription = find(
      subscriptionsList.subscriptions,
      (plan: any) => plan.subscriptionId === data.id
    );
    setSelectedPlan(subscription);
    if (data.id === 'UPDATE') {
    } else {
      toggleCancelModal();
    }
  };

  const onCancelSubscription = (data: any) => {
    const params = {
      userId: selectedPlan.userId,
      subscriptionId: selectedPlan.subscriptionId,
    };
    cancelSubscription(params);
    toggleCancelModal();
  };

  return (
    <div className="h-full w-full p-6 bg-white">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="pb-6">
        <SubscriptionHeader list={list} toggleList={(type) => setList(type)} />
      </div>
      {list === 'SUBSCRIPTION' ? (
        <Table
          tag="subscription-plans"
          {...{tableHeader, tableData, modalData}}
          onClickModalItem={(data: any) => onClickPlanOption(data)}
          onClickItem={(data: any, index: number) => onClickOption(data, index)}
        />
      ) : (
        <SubscriptionPayments />
      )}
      <SubscriptionCancelModal
        isOpen={openCancelModal}
        closeModal={() => toggleCancelModal()}
        onCancelSubscription={(data: any) => onCancelSubscription(data)}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    alertStatus: state.common.get('alertStatus'),
    modalStatus: state.setting.get('modalStatus'),
    selectedShop: state.dashboard.get('selectedShop'),
    subscriptionsList: state.setting.get('subscriptionsList'),
  }),
  {
    openAlertBox: Actions.common.openAlertBox,
    cancelSubscription: Actions.setting.cancelSubscription,
  }
)(SubscriptionPlans);
