const Button = ({
  label,
  onClick,
  secondary = false,
  type = 'button',
  disabled = false,
}: {
  type?: 'button' | 'submit' | 'reset' | undefined;
  secondary?: boolean;
  label?: string;
  disabled?: boolean;
  onClick?: () => void;
}) => {
  const buttonColors = secondary
    ? 'text-ship-cove bg-grid-bg hover:bg-dismiss-focus'
    : 'text-white shadow bg-medium-slate-blue hover:bg-slate-blue';

  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={`inline-flex justify-center rounded-md shadow border border-transparent  py-2 px-4 text-sm font-medium ${buttonColors}`}
    >
      {label}
    </button>
  );
};

export default Button;
