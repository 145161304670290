import { Map } from "immutable";
import { ModuleEvents } from "./Actions";
import { ModuleEvents as LoginEvents } from "../login/Actions";
import { ModuleEvents as CommonEvents } from "../common/Actions";

const initialState = Map({
  employee: {},
  adminShops: [],
  currentUser: {},
  modalStatus: "",
  selectedPlan: {},
  selectedLocation: {},
  subscriptionsList: {},
  paymentPackages: [],
  trialLoading: true,
  trialDetails: null,
  shopPayments: [],
  activeSubscriptions: [], // subscriptions for the shop
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case LoginEvents.LOG_OUT: {
      return initialState;
    }
    case ModuleEvents.GET_CURRENT_USER: {
      if (payload && !payload.error) {
        return state.set("currentUser", payload);
      }
      return state;
    }
    case ModuleEvents.SET_SELECTED_PLAN: {
      if (payload) {
        let selectedPlan = state.get("selectedPlan");
        // if (selectedPlan && selectedPlan.priceId === payload.priceId) {
        // return state.set("selectedPlan", {});
        // } else {
        return state.set("selectedPlan", payload);
        // }
      }
      return state;
    }
    case ModuleEvents.SET_SELECTED_LOCATION: {
      if (payload) {
        let selectedLocation = state.get("selectedLocation");
        if (selectedLocation && selectedLocation.shopId === payload.shopId) {
          return state.set("selectedLocation", {});
        } else {
          return state.set("selectedLocation", payload);
        }
      }
      return state;
    }
    case ModuleEvents.CREATE_CUSTOMER: {
      if (payload && !payload.error) {
        let currentUser = state.get("currentUser");
        currentUser.custId = payload.custId;
        return state
          .set("currentUser", { ...currentUser })
          .set("employee", payload);
      }
      return state;
    }

    case ModuleEvents.OPEN_RECURRING_STATUS_MODAL: {
      return state.set("modalStatus", payload);
    }

    case ModuleEvents.FETCH_SUBSCRIPTIONS: {
      let subscriptionsList = state.get("subscriptionsList");
      if (payload && !payload.error) {
        subscriptionsList.subscriptions = payload.items;
        subscriptionsList.nextLastKey = payload.lastKey;
        subscriptionsList.totalCount = payload.totalCount;
      }
      return state.set("subscriptionsList", { ...subscriptionsList });
    }

    case ModuleEvents.UPLOAD_USER_IMAGE: {
      if (payload && !payload.error) {
        return state.set("currentUser", { ...payload });
      }
      return state;
    }

    case ModuleEvents.UPDATE_USER: {
      if (payload && !payload.error && payload.uid) {
        return state.set("currentUser", { ...payload });
      }
      return state;
    }
    case ModuleEvents.FETCH_PACKAGES: {
      if (payload && !payload.error) {
        return state.set("paymentPackages", payload);
      }
      return state;
    }
    case ModuleEvents.FETCH_SHOP_PAYMENT: {
      if (payload && !payload.error) {
        return state.set("shopPayments", payload);
      }
      return state;
    }
    case CommonEvents.LOADING_STARTED: {
      if (payload && payload.type === 'FETCH_TRIAL') {
        return state.set("trialLoading", true);
      }
      return state;
    }
    case CommonEvents.LOADING_FINISHED: {
      if (payload && payload.type === 'FETCH_TRIAL') {
        return state.set("trialLoading", false);
      }
      return state;
    }
    case ModuleEvents.FETCH_TRIAL: {
      if (payload && !payload.error) {
        return state.set("trialDetails", payload.trialDetails)
          .set('activeSubscriptions', payload.subscriptions);
      }
      return state.set("trialDetails", null).set('activeSubscriptions', []);
    }
    case ModuleEvents.CREATE_TRIAL: {
      if (payload && !payload.error) {
        return state.set("trialDetails", payload);
      }
      return state.set("trialDetails", null);
    }

    default:
      return state;
  }
};

export default Reducer;
