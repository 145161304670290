const SaleSummaryCard = (props:any) => {
  const {mainText, price, icon, type} = props;
  const isRevenue = type === "REVENUE";
  return (
    <div className={"flex flex-row items-center w-[210px] pt-[19px] pl-[16px] pb-[16px] rounded-lg mr-[16px] " + (isRevenue ? `bg-[#EFF0F8]` : `bg-[#E8F0FB]`)}>
      <div>
        <img src={icon}/>
      </div>
      <div className="ml-[15px]">
        <div className="font-speech-blue text-sm font-normal font-inter">
          {mainText}
        </div>
        <div className="font-speech-blue text-sm font-semibold font-inter">
          {price}
        </div>
      </div>
    </div>
  );
};
export default SaleSummaryCard;
