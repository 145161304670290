import { Map } from "immutable";
import { ModuleEvents } from "./Actions";
import { filter, isEmpty, orderBy, findIndex } from "lodash";

const initialState = Map({
  offer: {
    offers: [],
    nextToken: null,
  },
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.FETCH_OFFERS: {
      const offer = state.get("offer");
      if (payload && !payload.error) {
        const sortOffers = orderBy(payload.items, ['updatedTime'], ['desc']);
        offer.offers = sortOffers;
        offer.nextToken = payload.nextToken;
        return state.set("offer", { ...offer });
      }
      return state;
    }
    case ModuleEvents.CREATE_OFFER: {
      if (payload && !payload.error) {
        const offer = state.get("offer");
        if (!isEmpty(offer.offers)) {
          offer.offers.push(payload)
        } else {
          offer.offers = [payload]
        }
        const sortOffers = orderBy(offer.offers, ['updatedTime'], ['desc']);
        offer.offers = sortOffers;
        return state.set("offer", { ...offer });
      }
      return state;
    }
    case ModuleEvents.UPDATE_OFFER: {
      if (payload && !payload.error) {
        const offer = state.get("offer");
        const index = findIndex(offer.offers, ({ id }) => id === payload.id)
        offer.offers[index] = payload;
        const sortOffers = orderBy(offer.offers, ['updatedTime'], ['desc']);
        offer.offers = sortOffers;
        return state.set("offer", { ...offer });
      }
      return state;
    }
    case ModuleEvents.DELETE_OFFER: {
      if (payload && !payload.error) {
        const offer = state.get("offer");
        const offers = filter(offer.offers, ({ id }) => id != payload.id)
        const sortOffers = orderBy(offers, ['updatedTime'], ['desc']);
        offer.offers = sortOffers;
        return state.set("offer", { ...offer });
      }
      return state;
    }
    default:
      return state;
  }
};

export default Reducer;
