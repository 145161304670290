import { useEffect } from "react";
import { Hub } from "aws-amplify";
import Admin from "./layouts/Admin";
import { API } from "@aws-amplify/api";
import { Provider } from "react-redux";
import { Auth } from "@aws-amplify/auth";
import { Amplify } from "@aws-amplify/core";
import { store } from "./internals/app/Store";
import { Storage } from "@aws-amplify/storage";
import { Actions } from "./internals/app/Actions";
import InviteModal from "./components/InviteModal";
import OtpView from "./modules/login/views/OtpView";
import UserView from "./modules/user/views/UserView";
import MenuView from "./modules/menu/views/MenuView";
import awsconfig from "./internals/config/aws_config";
import BrandView from "./modules/brand/views/BrandView";
import TableView from "./modules/table/views/TableView";
import OfferView from "./modules/offer/views/OfferView";
import StockView from "./modules/stock/views/StockView";
import SignInView from "./modules/login/views/SignInView";
import SignUpView from "./modules/login/views/SignUpView";
import ProductView from "./modules/product/views/Product";
import ReportView from "./modules/report/views/ReportView";
import RewardView from "./modules/reward/views/RewardView";
import PasswordView from "./modules/login/views/PasswordView";
import ServiceView from "./modules/service/views/ServiceView";
import BusinessView from "./modules/login/views/BusinessView";
import CompanyInfo from "./modules/setting/views/CompanyInfo";
import PersonalInfo from "./modules/setting/views/PersonalInfo";
import RegisterInfo from "./modules/setting/views/RegisterInfo";
import Subscription from "./modules/setting/views/Subscription";
import CustomerView from "./modules/customer/views/CustomerView";
import CategoryView from "./modules/category/views/CategoryView";
import SupplierView from "./modules/supplier/views/SupplierView";
import EmployeeView from "./modules/employee/views/EmployeeView";
import DownloadView from "./modules/download/views/DownloadView";
import DiscountView from "./modules/discount/views/DiscountView";
import ModifierView from "./modules/modifier/views/ModifierView";
import WarrantyView from "./modules/warranty/views/WarrantyView";
import MixpanelService from "./internals/service/MixpanelService";
import DashboardView from "./modules/dashboard/views/DashboardView";
import PaymentLoading from "./modules/setting/views/PaymentLoading";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import PurchaseStockView from "./modules/stock/views/PurchaseStockView";
import SubscriptionPlans from "./modules/setting/views/SubscriptionPlans";
import TransactionView from "./modules/transaction/views/TransactionView";
import SignUpSubscription from "./modules/login/views/SignUpSubscription";
import CloseCashDetails from "./modules/transaction/views/CloseCashDetails";

API.configure(awsconfig);
Auth.configure(awsconfig);
Amplify.configure(awsconfig);
Storage.configure(awsconfig);

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Admin />,
    children: [
      {
        path: "/dashboard",
        element: <DashboardView />,
      },
      {
        path: "/product",
        element: <ProductView />,
      },
      {
        path: "/menu",
        element: <MenuView />,
      },
      {
        path: "/category",
        element: <CategoryView />,
      },
      {
        path: "/brand",
        element: <BrandView />,
      },
      {
        path: "/supplier",
        element: <SupplierView />,
      },
      {
        path: "/service",
        element: <ServiceView />,
      },
      {
        path: "/customers",
        element: <CustomerView />,
      },
      {
        path: "/employees",
        element: <EmployeeView />,
      },
      {
        path: "/report",
        element: <ReportView />,
      },
      {
        path: "/transaction",
        element: <TransactionView />,
      },
      {
        path: "/personal-info",
        element: <PersonalInfo />,
      },
      {
        path: "/company-info",
        element: <CompanyInfo />,
      },
      {
        path: "/register-info",
        element: <RegisterInfo />,
      },
      {
        path: "/subscription-plans",
        element: <SubscriptionPlans />,
      },
      {
        path: "/subscription",
        element: <Subscription />,
      },
      {
        path: "/admin/recurring-loading",
        element: <PaymentLoading />,
      },
      {
        path: "/reward",
        element: <RewardView />,
      },
      {
        path: "/discounts",
        element: <DiscountView />,
      },
      {
        path: "/modifiers",
        element: <ModifierView />,
      },
      {
        path: "/stock",
        element: <StockView />,
      },
      {
        path: "/purchase-stock",
        element: <PurchaseStockView />,
      },
      {
        path: "/warranty",
        element: <WarrantyView />,
      },
      {
        path: "/offer",
        element: <OfferView />,
      },
      {
        path: "/close-cash",
        element: <CloseCashDetails />,
      },
      {
        path: "/tables",
        element: <TableView />,
      },
      {
        path: "/download",
        element: <DownloadView />,
      },
      {
        path: "/users",
        element: <UserView />,
      },
    ],
  },
  {
    path: "/signup-subscription",
    element: <SignUpSubscription />,
  },
  {
    path: "/signin",
    element: <SignInView />,
  },
  {
    path: "/signup",
    element: <SignUpView />,
  },
  {
    path: "/forget-password",
    element: <PasswordView />,
  },
  {
    path: "/business-manage",
    element: <BusinessView />,
  },
  {
    path: "/otp-screen",
    element: <OtpView />,
  },
]);
const unsubscribe = Hub.listen("auth", ({ payload: { event, data }}) => {
  console.log({event, data})
  switch (event) {
    case "signIn":
      // setUser(data);
      break;
    case "signOut":
      // setUser(null);
      break;
    case "customOAuthState":
      // setCustomState(data);
  }
});
const App = () => {

  useEffect(() => {
    // console.log('App auth')
    MixpanelService.init();
    // const unsubscribe = Hub.listen("auth", ({ payload: { event, data }}) => {
    //   console.log({event, data})
    //   switch (event) {
    //     case "signIn":
    //       // setUser(data);
    //       break;
    //     case "signOut":
    //       // setUser(null);
    //       break;
    //     case "customOAuthState":
    //       // setCustomState(data);
    //   }
    // });

    // getUser();

    return unsubscribe;
  }, []);
  
  return (
    <Provider store={store}>
      <InviteModal />
      <RouterProvider router={router} />
    </Provider>
  );
};
export default App;
store.dispatch(Actions.login.authenticatedUser());
