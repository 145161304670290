
import { Map } from "immutable";
import { ModuleEvents } from "./Actions";
import { ModuleEvents as LoginEvents } from "../login/Actions";

const initialState = Map({
  yearReports: [],
  monthsReports: [],
  monthSummaryReport: [],
  productReportSummary: [],
  dateRangeSummaryReport: {}
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case LoginEvents.LOG_OUT: {
      return initialState;
    }

    case ModuleEvents.FETCH_SUMMARY_FOR_MONTH_RANGE: {
      if (payload && !payload.error) {
        return state.set("monthsReports", payload);
      }
      return state;
    }

    case ModuleEvents.FETCH_SUMMARY_FOR_MONTH: {
      if (payload && !payload.error) {
        return state.set("monthSummaryReport", payload);
      }
      return state;
    }

    case ModuleEvents.FETCH_SUMMARY_FOR_DATE_RANGE: {
      if (payload && !payload.error) {
        return state.set("dateRangeSummaryReport", { ...payload });
      }
      return state;
    }

    case ModuleEvents.FETCH_SUMMARY_FOR_YEAR_RANGE: {
      if (payload && !payload.error) {
        return state.set("yearReports", payload);
      }
      return state;
    }

    case ModuleEvents.FETCH_PRODUCT_REPORT_SUMMARY_FOR_MONTH_RANGE: {
      if (payload && !payload.error) {
        return state.set("productReportSummary", [...payload]);
      }
      return state;
    }

    default:
      return state;
  }
};

export default Reducer;
