import Modal from "react-modal";
import { find, omit } from "lodash";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Actions } from "../../internals/app/Actions";
import success from "../../assets/svgs/subscription/success.svg";

const customStyles: any = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    height: "auto",
    padding: 0,
    overflow: "auto",
    maxWidth: "374px",
    maxHeight: "349px",
    background: "#fff",
    borderRadius: "8px",
    position: "absolute",
    top: "calc(50vh - 20rem)",
    left: "calc(50vw - 170px)",
    WebkitOverflowScrolling: "touch",
    boxShadow: "0 4px 8px 1px rgb(0 0 0 / 0.8)",
  },
};

const InviteModal = (props: any) => {
  const {
    adminShops,
    currentUser,
    inviteClaim,
    inviteStatus,
    setInviteStatus,
    updateInviteShopClaim,
  } = props;
  const [inviteShop, setInviteShop] = useState<any>({});
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (inviteClaim) {
      setInviteShop(inviteClaim.shop);
    }
  }, [inviteClaim, adminShops]);

  useEffect(() => {
    setIsOpen(inviteStatus);
  }, [inviteStatus]);

  const closeModal = () => {
    setInviteStatus(false);
  };

  const onClickAccept = () => {
    let claim = inviteClaim;
    claim["claimType"] = "ACTIVE";
    updateClaim(claim);
    setInviteStatus(false);
  };

  const onClickDeny = () => {
    let claim = inviteClaim;
    claim["claimType"] = "INACTIVE";
    updateClaim(claim);
    setInviteStatus(false);
  };

  const updateClaim = (claim: any) => {
    claim["updatedTime"] = Date.now();
    const isCreate = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(claim.requestedUserId);
    claim["requestedUserId"] = currentUser.uid;
    const newClaim = omit(claim, ["user", "shop"]);
    updateInviteShopClaim(newClaim, isCreate);
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Category Modal"
      onRequestClose={() => closeModal()}
    >
      <div className="flex flex-col w-full h-full p-6 selection:overflow-hidden shadow rounded-lg items-center justify-center">
        <img src={success} alt="altimage" />
        <p className="mt-7 text-lg font-bold text-paua">{"Congratulations!"}</p>
        <p className="mt-2 text-xs text-waikawa-grey">
          {"You are invited to manage "}
        </p>
        <p className="text-sm font-bold text-speech-blue">
          {inviteShop?.shopName}
        </p>
        <div className="flex flex-row w-full justify-between mt-7">
          <button
            type="button"
            onClick={() => onClickDeny()}
            className="w-5/12 text-valhalla bg-dismiss hover:bg-dismiss-focus font-medium rounded-lg text-sm px-5 py-2.5  focus:outline-none"
          >
            {"Deny"}
          </button>
          <button
            type="button"
            onClick={() => onClickAccept()}
            className="w-6/12 text-white bg-medium-slate-blue hover:bg-slate-blue font-medium rounded-lg text-sm px-5 py-2.5  focus:outline-none"
          >
            {"Accept Invitation"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default connect(
  (state: any) => ({
    shopClaims: state.user.get("shopClaims"),
    inviteClaim: state.user.get("inviteClaim"),
    inviteStatus: state.user.get("inviteStatus"),
    currentUser: state.setting.get("currentUser"),
    selectedShop: state.dashboard.get("selectedShop"),
    adminShops: state.dashboard.get("adminShops"),
  }),
  {
    setInviteStatus: Actions.user.setInviteStatus,
    updateInviteShopClaim: Actions.user.updateInviteShopClaim,
  }
)(InviteModal);
