import { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import Modal from "react-modal";
import QRCode from "react-qr-code";
import { connect } from "react-redux";
import html2canvas from "html2canvas";
import QRBorder from "../../assets/svgs/qr-border-icon.svg";
import { AiOutlineClose, AiOutlineArrowDown } from "react-icons/ai";

type ModifierProps = {
  shop: any;
  tableArea?: any;
  tableData?: any;
  isOpen: boolean;
  closeModal: () => void;
};

const QRDownloadModal = (props: ModifierProps) => {
  const { isOpen, closeModal, shop, tableArea, tableData } = props;
  let [area, setArea] = useState<any>({});
  let [table, setTable] = useState<any>({});
  let [url, setUrl] = useState<string>("");

  useEffect(() => {
    setTable(tableData);
    setArea(tableArea);
    if (!isEmpty(tableData)) {
      setUrl(`https://menu.netlise.com/${tableData?.qrId}`);
    } else {
      setUrl(`https://menu.netlise.com/${shop.shortCode}`);
    }
  }, [tableData, tableArea]);

  const customStyles: any = {
    overlay: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      position: "fixed",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      padding: 0,
      height: "auto",
      border: "none",
      overflow: "auto",
      maxWidth: "557px",
      background: "none",
      left: "calc(50vw - 277px)",
      WebkitOverflowScrolling: "touch",
    },
  };

  const downloadQR = async () => {
    const div: any = document.getElementById("divToDownload");
    div.setAttribute("style", "border-radius:0");
    html2canvas(div).then((canvas) => {
      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.download = !isEmpty(table) ? `${tableData?.qrId}.png` : `menuQR.png`;
      link.href = image;
      link.click();
    });
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Product Modal"
      onRequestClose={() => closeModal()}
    >
      <div className="relative shadow bg-white rounded-lg">
        <div className="flex flex-row h-14 justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-t-lg">
          <h3>{"Table QR Preview"}</h3>
          <div className="flex flex-row justify-end gap-4">
            <button
              type="button"
              onClick={() => downloadQR()}
              className="top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
            >
              <AiOutlineArrowDown size={20} />
              <span className="sr-only">Download QR</span>
            </button>
            <button
              type="button"
              onClick={() => closeModal()}
              className="top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            >
              <AiOutlineClose size={20} />
              <span className="sr-only">Close modal</span>
            </button>
          </div>
        </div>

        <div className="h-[530px] bg-ghost-white-2 rounded-b-lg">
          <div className="flex justify-center items-center h-[530px] bg-ghost-white-2 rounded-b-lg">
            <div
              id="divToDownload"
              className="flex flex-col justify-center items-center border border-genteel-lavender bg-white pt-4 pb-3 px-12 rounded-lg w-[338px]"
            >
              <div className="pb-9 text-speech-blue text-xl	font-semibold">
                {shop.shopName}
              </div>
              <div className="relative p-2	w-[218px] h-[218px]">
                <QRCode
                  size={400}
                  value={url}
                  viewBox={`0 0 256 256`}
                  style={{ height: "auto", width: "100%" }}
                />
                <img src={QRBorder} className="absolute top-0 left-0" />
              </div>
              <div className="pt-9 text-speech-blue text-xl	font-semibold">
                {`${area.areaName} ${!isEmpty(table) ? table.tblNo : ""}`}
              </div>
              <div className="pt-4 text-speech-blue text-xs	font-light">
                {"by netlise.com"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default connect(
  (state: any) => ({
    shop: state.dashboard.get("selectedShop"),
    currentUser: state.setting.get("currentUser"),
    loadingAction: state.common.get("loadingAction"),
  }),
  {}
)(QRDownloadModal);
