import Swal from "sweetalert2";
import { router } from "../../App";
import { ModuleEvents } from "./Actions";
import withReactContent from "sweetalert2-react-content";

export default {
  [ModuleEvents.SET_STOCK_RECORD]: ({ dispatch, payload, appState }) => {
    if (payload.navigation) {
      router.navigate("/purchase-stock");
    }
  },
  [ModuleEvents.UPDATE_STOCK_RECORD]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      router.navigate("/stock");
    }
  },
  [ModuleEvents.CREATE_STOCK_RECORD]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      router.navigate("/stock");
    }
  },
};
