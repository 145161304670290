import _ from 'lodash';

const tstKey =
  'pk_test_51J2Y2VF4Lddyu2NtO5CbWgpVyxiaPMlo91dUtamACcIeIWzYNKeh6RZgUtdDRMeUHD01PmmURncQa21l1USu1MuT00ZY3lSLel';
const prodKey = 'pk_live_51J2Y2VF4Lddyu2NtvXChsuzindlLNtx7Su4RK5KxAY31jOZKPBHNDC3FTiehPqcvg4ICKcD40vD4fKlopZiMUxSo00V4Cxvv5o';

export const getTotalPrice = services => {
  let total = 0;
  services.forEach(service => {
    total += service.price * _.get(service, 'quantity', 1);
  });

  return total;
};

export const getStripeKey = type => {
  if (type === 'PROD') {
    return prodKey;
  } else {
    return tstKey;
  }
};

export const formatPrice = (price, currency = 'LKR') => {
  const language = navigator.language;
  if (!currency) {
    return new Intl.NumberFormat(language, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(price);
  }
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency,
  }).format(price);
};
