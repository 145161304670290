import Repository from './Repository';
import { API, graphqlOperation } from '@aws-amplify/api';
import {
  CreateEmployee,
  UpdateEmployee,
  GetEmployee,
} from './schema/Employee.schema';

class EmployeeRepository extends Repository {
  async fetchEmployeesForShopId(shopId, limit, lastKey) {
    const message = this.buildMessage({ shopId, limit, lastKey });
    try {
      const result = await this.apiPost({
        apiName: this.API_ADMIN_DASHBOARD,
        path: '/employees',
        message,
      });
      return result.data.result;
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  }

  async searchEmployeeForSalonId(searchText, shopId, limit, lastKey) {
    const message = this.buildMessage({ shopId, searchText, limit, lastKey });
    try {
      const result = await this.apiPost({
        apiName: this.API_ADMIN_DASHBOARD,
        path: '/search/empoyees',
        message,
      });
      return result.data.employeeList;
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  }

  async createEmployee(param) {
    try {
      const data = await API.graphql(
        graphqlOperation(CreateEmployee, { input: param }),
      );
      return data.data.createEmployee;
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  }

  async updateEmployee(param) {
    try {
      const data = await API.graphql(
        graphqlOperation(UpdateEmployee, { input: param }),
      );
      return data.data.updateEmployee;
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  }

  async getEmployee(shopId, empId) {
    try {
      // await AuthRepository.authenticateUser('ADMIN');
      const data = await API.graphql(
        graphqlOperation(GetEmployee, { shopId, empId }),
      );
      return data.data.getEmployee;
    } catch (error) {
      console.warn('fetch employee', error);
      return { error };
    }
  }

  async fetchAdminEmployee(salonId, userPosition, limit, lastKey) {
    const message = this.buildMessage({
      salonId,
      userPosition,
      limit,
      lastKey,
    });
    try {
      const result = await this.apiPost({
        apiName: this.API_ADMIN_DASHBOARD,
        path: '/employee/admin',
        message,
      });

      return result.data.employeeResult;
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  }
}

export default new EmployeeRepository();

