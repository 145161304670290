import {useState, useEffect} from 'react';
import {nanoid} from 'nanoid';
import {connect} from 'react-redux';
import {find, get, isEmpty, isString} from 'lodash';
import {AiOutlineClose} from 'react-icons/ai';
import CreatableSelect from 'react-select/creatable';
import {BsFillPlusCircleFill} from 'react-icons/bs';
import {useFormikContext, FieldArray} from 'formik';
import IconClose from '../../../assets/svgs/icon-close.svg';
import FormInput from '../../../components/inputs/FormInput';
import ImageComponent from '../../../components/UploadImage';
import Dropzone from 'react-dropzone';
import S3Image from '../../../components/S3Image';
import defaultImage from '../../../assets/images/default-store.jpeg';
import ImageRemoveIcon from '../../../assets/svgs/imageRemoveIcon.svg';

const customStyles = {
  container: (base: any) => ({
    ...base,
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: 0,
  }),
  singleValue: (base: any) => ({
    ...base,
    marginLeft: 0,
    marginRight: 0,
  }),
  placeholder: (base: any) => ({
    ...base,
    marginLeft: 0,
    marginRight: 0,
    color: '#979797',
  }),
  input: (base: any) => ({
    ...base,
    margin: 0,
  }),
  control: (base: any, state: any) => ({
    ...base,
    height: 40,
    // width: "100%",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 10,
    borderRadius: 8,
    paddingRight: 10,
    color: '#434343',
    boxShadow: '0px 0px 10px 5px #c4d3f020',
    border: state.isFocused ? '1px solid #ebeaea' : '1px solid #ebeaea',
    '&:hover': {
      border: state.isFocused ? '1px solid #ebeaea' : '1px solid #ebeaea',
    },
  }),
};

const OptionForm = (props: any) => {
  const {selectedShop, product, productOptions, optionSets, loadingAction} =
    props;
  const {values, errors, touched, setErrors, submitCount, setFieldValue} =
    useFormikContext<any>();
  const [selectOptions, setSelectOptions] = useState<Array<any>>([]);

  useEffect(() => {
    if (!isEmpty(optionSets)) {
      const options = optionSets.map((item: any) => {
        return {
          value: item.id,
          label: item.setName,
        };
      });
      setSelectOptions([...options]);
    }
  }, [optionSets]);

  useEffect(() => {
    if (!isEmpty(product)) {
      let prevErrors = {
        ...errors,
        itemPrice: undefined,
        priceVariants: [{price: 'Item price must be more than 0'}],
      };
      setErrors(prevErrors);
      if (isEmpty(values?.productOptions)) {
        const option = isEmpty(product.productOptions)
          ? [...productOptions]
          : product.productOptions;
        setFieldValue('productOptions', [...option]);
      }
    }
  }, [product]);

  const shouldDisplayOptionImages = selectedShop?.shopType === 'E_COMMERCE';

  return (
    <div className="overflow-auto bg-white px-4 py-5 sm:p-6">
      <div className="grid grid-cols-6 gap-6 flex-row">
        <FieldArray name="productOptions">
          {({insert, remove, push, replace}) => {
            return (
              <>
                {values?.productOptions.map(
                  (productOption: any, index: number) => {
                    const error: any = !isEmpty(errors)
                      ? get(errors, `productOptions.[${index}]`, null)
                      : null;
                    const touch: any = get(
                      touched,
                      `productOptions.[${index}]`,
                      null
                    );
                    return (
                      <>
                        <div className="flex col-span-6 justify-end">
                          {index > 0 && (
                            <button
                              type="button"
                              onClick={() => remove(index)}
                              className="flex items-center w-[22px] h-[22px] rounded-xl justify-center bg-deactivate hover:bg-deactivate-focus"
                            >
                              <AiOutlineClose size={15} color="#fff" />
                            </button>
                          )}
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor={'optionName'}
                            className="block mb-2 text-sm font-medium text-gray-700"
                          >
                            {'Option Set name*'}
                          </label>
                          <CreatableSelect
                            isClearable
                            styles={customStyles}
                            options={selectOptions}
                            placeholder={'Option set name*'}
                            value={{
                              label: productOption.setName,
                              value: productOption.id,
                            }}
                            onChange={(newValue: any, actionMeta: any) => {
                              setFieldValue(
                                `productOptions[${index}].setName`,
                                actionMeta.action === 'clear'
                                  ? ''
                                  : newValue.label
                              );
                              if (actionMeta.action === 'select-option') {
                                const options = find(
                                  optionSets,
                                  (item: any) => item.id === newValue.value
                                );
                                replace(index, options);
                              } else if (actionMeta.action === 'clear') {
                                const clearData = {
                                  setName: '',
                                  id: nanoid(),
                                  setDisplayName: '',
                                  createdTime: Date.now(),
                                  updatedTime: Date.now(),
                                  shopId: selectedShop.shopId,
                                  options: [
                                    {
                                      id: nanoid(),
                                      optionName: '',
                                    },
                                  ],
                                };
                                replace(index, clearData);
                              }
                            }}
                          />
                          {!isEmpty(error?.setName) && submitCount > 0 && (
                            <span className="mb-1 first-line:block text-xs font-normal text-rose-400">
                              {error.setName}
                            </span>
                          )}
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <FormInput
                            id="displayName"
                            label="Display Name"
                            error={error?.setDisplayName}
                            value={productOption.setDisplayName}
                            touched={touch?.setDisplayName}
                            onChange={(text: string) => {
                              setFieldValue(
                                `productOptions[${index}].setDisplayName`,
                                text
                              );
                              // childPush(`productOptions[${index}].options[${subIndex}]`, data)
                            }}
                          />
                        </div>
                        <FieldArray name={`productOptions.${index}.options`}>
                          {({form, remove: childRemove, push: childPush}) => {
                            const error: any = get(
                              errors,
                              `productOptions.[${index}].options`,
                              null
                            );
                            const touch: any = get(
                              touched,
                              `productOptions.[${index}].setDisplayName`,
                              null
                            );

                            return (
                              <div className="col-span-6 mb-4">
                                <label
                                  htmlFor={'optionName'}
                                  className="block mb-2 text-sm font-medium text-gray-700"
                                >
                                  {'Option name*'}
                                </label>

                                <div className="flex flex-col w-full px-4 pt-3 bg-gray-50 rounded-md">
                                  {productOption.options.map(
                                    (option: any, subIndex: number) => {
                                      return (
                                        <div className="flex flex-row justify-between border-b mb-4">
                                          <input
                                            type="text"
                                            name={`productOption[${index}].options[${subIndex}].optionName`}
                                            value={option.optionName}
                                            onChange={(e) => {
                                              setFieldValue(
                                                `productOptions[${index}].options[${subIndex}].optionName`,
                                                e.target.value
                                              );
                                            }}
                                            className="p-2.5 border-b-2 h-10 block bg-gray-50 outline-none focus:bg-transparent text-start text-sm font-medium text-valhalla"
                                          />
                                          {shouldDisplayOptionImages ? (
                                            <div className="w-32">
                                              <Dropzone
                                                multiple={false}
                                                onDrop={(files) => {
                                                  setFieldValue(
                                                    `productOptions[${index}].options[${subIndex}].optionImage`,
                                                    files[0]
                                                  );
                                                }}
                                                accept={{
                                                  'image/png': [
                                                    '.png',
                                                    '.jpeg',
                                                    '.JPG',
                                                  ],
                                                }}
                                              >
                                                {({
                                                  getRootProps,
                                                  getInputProps,
                                                }: any) => (
                                                  <ImageComponent
                                                    getRootProps={getRootProps}
                                                    getInputProps={
                                                      getInputProps
                                                    }
                                                    loadingAction={
                                                      loadingAction
                                                    }
                                                    height="32"
                                                  >
                                                    {option.optionImage ? (
                                                      <div className="w-auto flex relative flex-row h-32 rounded-lg">
                                                        {isString(
                                                          option.optionImage
                                                        ) ? (
                                                          <S3Image
                                                            url={
                                                              option.optionImage
                                                            }
                                                            type="menu"
                                                            className="w-auto rounded-lg"
                                                            defaultImage={
                                                              defaultImage
                                                            }
                                                          />
                                                        ) : (
                                                          <img
                                                            src={URL.createObjectURL(
                                                              option.optionImage
                                                            )}
                                                          />
                                                        )}

                                                        <img
                                                          alt="remove"
                                                          src={ImageRemoveIcon}
                                                          onClick={() => {
                                                            setFieldValue(
                                                              `productOptions[${index}].options[${subIndex}].optionImage`,
                                                              null
                                                            );
                                                          }}
                                                          className="w-6 absolute right-2 top-2 cursor-pointer"
                                                        />
                                                      </div>
                                                    ) : null}
                                                  </ImageComponent>
                                                )}
                                              </Dropzone>
                                            </div>
                                          ) : null}

                                          {productOption.options.length > 1 && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                childRemove(subIndex);
                                              }}
                                              className="flex items-center rounded-lg p-1.5 mb-1 justify-center bg-transparent"
                                            >
                                              <img
                                                alt="actions"
                                                src={IconClose}
                                                className="text-deactivate w-3 h-3"
                                              />
                                            </button>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                  {!isEmpty(error) && submitCount > 0 && (
                                    <span className="mb-1 first-line:block text-xs font-normal text-rose-400	">
                                      {'Please enter option name*'}
                                    </span>
                                  )}
                                </div>

                                <button
                                  onClick={() => {
                                    const options: any = {
                                      id: nanoid(),
                                      optionName: '',
                                      optionPrice: 0,
                                      optionUnit: '',
                                    };
                                    childPush(options);
                                  }}
                                  className="inline-flex mt-3 justify-center rounded-md border border-transparent bg-medium-slate-blue py-1 px-2 text-center text-sm font-medium text-white shadow hover:bg-slate-blue"
                                >
                                  {'Add Option'}
                                </button>
                              </div>
                            );
                          }}
                        </FieldArray>
                      </>
                    );
                  }
                )}
                <div className="col-span-6">
                  <div
                    className="flex flex-row gap-4 mb-2 w-44 cursor-pointer"
                    onClick={() => {
                      const options: any = {
                        id: nanoid(),
                        setName: '',
                        setDisplayName: '',
                        options: [
                          {
                            id: nanoid(),
                            optionName: '',
                            optionPrice: 0,
                            optionUnit: '',
                          },
                        ],
                        shopId: selectedShop.shopId,
                      };
                      push(options);
                    }}
                  >
                    <label
                      htmlFor={'amountType'}
                      className="block text-sm mb-2 text-gray-700 cursor-pointer font-bold"
                    >
                      Add Price Options
                    </label>
                    <BsFillPlusCircleFill color="#6D71F9" size={20} />
                  </div>
                </div>
              </>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};

export default connect(
  (state: any) => ({
    loadingAction: state.common.get('loadingAction'),
    optionSets: state.product.get('optionSets'),
    selectedShop: state.dashboard.get('selectedShop'),
  }),
  {}
)(OptionForm);
