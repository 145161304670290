import {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {
  CategoryList,
  getEnumTypes,
  getEnumOptions,
} from '../../login/Constants';
import Swal from 'sweetalert2';
import {connect} from 'react-redux';
import Dropzone from 'react-dropzone';
import {includes, isEmpty} from 'lodash';
import PhoneInput from 'react-phone-input-2';
import Alert from '../../../components/Alert';
import S3Image from '../../../components/S3Image';
import {Actions} from '../../../internals/app/Actions';
import withReactContent from 'sweetalert2-react-content';
import {Formik, Field, useFormikContext} from 'formik';
import FormInput from '../../../components/inputs/FormInput';
import ImageComponent from '../../../components/UploadImage';
import CustomSelect from '../../../components/inputs/CustomSelect';
import FormTextarea from '../../../components/inputs/FormTextarea';
import defaultImage from '../../../assets/images/default-store.jpeg';

const CompanyInfo = (props: any) => {
  const {
    selectedShop,
    loadingAction,
    uploadShopImage,
    removeShopImage,
    openAlertBox,
    alertStatus,
    updateShop,
  } = props;
  const [image, setImage] = useState<string>('');
  const [billImage, setBillImage] = useState<string>('');
  const [menuImage, setMenuImage] = useState<string | null>(null);

  useEffect(() => {
    if (selectedShop) {
      setImage(selectedShop.image);
      setBillImage(selectedShop.billImage);
      setMenuImage(
        selectedShop.menuImage
          ? `https://dm6mmpk56rvc9.cloudfront.net/${selectedShop.menuImage}`
          : null
      );
    }
  }, [selectedShop]);

  const onAddFile = async (acceptedFiles: any, type: string) => {
    uploadShopImage(acceptedFiles[0], selectedShop.shopId, type);
  };

  const onClickSave = (values: any) => {
    const params = {
      token: values.token,
      email: values.email,
      shopName: values.shopName,
      shopId: selectedShop.shopId,
      shopType: values.businessType,
      shopCategory: values.category,
      description: values.description,
      paymentMethod: values.paymentMethod,
      phoneNumber: includes(values.phoneNumber, '+')
        ? values.phoneNumber
        : `+${values.phoneNumber}`,
    };
    updateShop(params);
  };

  const validationSchema = Yup.object({
    shopName: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .required('First name is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    phoneNumber: Yup.string()
      .min(10, 'Enter valid phone number')
      .required('Must enter a phone number'),
    // token: Yup.string()
    //   .nullable()
    //   .max(6, "Must be exactly 6 digits")
    //   .min(6, "Must be exactly 6 digits")
    //   .required("Token is required"),
    category: Yup.string().required('Business Category is required'),
    businessType: Yup.string().required('Business Type is required'),
  });

  const initialValues = {
    pin: '',
    email: '',
    token: '',
    address: '',
    category: '',
    shopName: '',
    description: '',
    phoneNumber: '',
    businessType: '',
    paymentMethod: '',
  };

  const onDeleteImage = (type: string) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: 'Are you sure?',
      text: "You won't be able to delete this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#6D71F9',
      cancelButtonColor: '#FF3333',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        removeShopImage(selectedShop.shopId, type);
      }
    });
  };

  return (
    <div className="h-full w-full p-6">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="h-full w-full max-w-screen-md bg-white shadow-md rounded-lg	">
        <div className="flex flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-t-md">
          <h3>{'COMPANY INFORMATION'}</h3>
        </div>

        <div className="h-[calc(100vh-145px)] overflow-auto bg-white px-4 py-5 sm:p-6">
          <label className="mb-4 block text-sm font-medium text-gray-700">
            Shop Image
          </label>
          <div className="mb-6 max-w-sm bg-white overflow-hidden cursor-pointer">
            {image ? (
              <div
                className={
                  'w-auto flex relative flex-row h-48 rounded-lg' +
                  (isEmpty(image) ? ' border-dashed border border-sky-500' : '')
                }
              >
                <S3Image
                  url={image}
                  defaultImage={defaultImage}
                  className="rounded-lg w-full h-48 object-cover object-center"
                />
              </div>
            ) : (
              <Dropzone
                multiple={false}
                onDrop={(file) => onAddFile(file, 'image')}
                accept={{
                  'image/png': ['.png', '.jpeg', '.JPG'],
                }}
              >
                {({getRootProps, getInputProps}: any) => (
                  <ImageComponent
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    loadingAction={loadingAction}
                  />
                )}
              </Dropzone>
            )}
            <div className="w-full flex justify-start items-center py-3">
              <button
                onClick={() => onDeleteImage('image')}
                className="inline-flex justify-center rounded-md border border-transparent bg-light-coral py-2 px-2 text-sm font-medium text-white shadow focus:outline-none"
              >
                Delete image
              </button>
            </div>
          </div>

          <label className="mb-4 block text-sm font-medium text-gray-700">
            Receipt Logo
          </label>
          <div className="mb-6 max-w-sm bg-white overflow-hidden">
            {billImage ? (
              <div
                className={
                  'w-auto flex relative flex-row h-48 rounded-lg' +
                  (isEmpty(billImage)
                    ? ' border-dashed border border-sky-500'
                    : '')
                }
              >
                <S3Image
                  url={billImage}
                  defaultImage={defaultImage}
                  className="rounded-lg w-full h-48 object-cover object-center"
                />
              </div>
            ) : (
              <Dropzone
                multiple={false}
                onDrop={(file) => onAddFile(file, 'billImage')}
                accept={{
                  'image/png': ['.png', '.jpeg', '.JPG'],
                }}
              >
                {({getRootProps, getInputProps}: any) => (
                  <ImageComponent
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    loadingAction={loadingAction}
                  />
                )}
              </Dropzone>
            )}

            <div className="w-full flex justify-start items-center py-3">
              <button
                onClick={() => onDeleteImage('billImage')}
                className="inline-flex justify-center rounded-md border border-transparent bg-light-coral py-2 px-2 text-sm font-medium text-white shadow focus:outline-none"
              >
                Delete image
              </button>
            </div>

            <label className="mb-4 block text-sm font-medium text-gray-700">
              Menu Image
            </label>
            <div className="mb-6 max-w-sm bg-white overflow-hidden cursor-pointer">
              {menuImage ? (
                <div
                  className={
                    'w-auto flex relative flex-row h-48 rounded-lg' +
                    (isEmpty(menuImage)
                      ? ' border-dashed border border-sky-500'
                      : '')
                  }
                >
                  <img
                    alt="menu-image"
                    className="rounded-lg w-full h-48 object-cover object-center"
                    src={menuImage || defaultImage}
                  />
                </div>
              ) : (
                <Dropzone
                  multiple={false}
                  onDrop={(file) => onAddFile(file, 'menuImage')}
                  accept={{
                    'image/png': ['.png', '.jpeg', '.JPG'],
                  }}
                >
                  {({getRootProps, getInputProps}: any) => (
                    <ImageComponent
                      getRootProps={getRootProps}
                      getInputProps={getInputProps}
                      loadingAction={loadingAction}
                    />
                  )}
                </Dropzone>
              )}
              <div className="w-full flex justify-start items-center py-3">
                <button
                  onClick={() => onDeleteImage('menuImage')}
                  className="inline-flex justify-center rounded-md border border-transparent bg-light-coral py-2 px-2 text-sm font-medium text-white shadow focus:outline-none"
                >
                  Delete image
                </button>
              </div>
            </div>
          </div>

          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => onClickSave(values)}
          >
            {({handleSubmit, errors, values}) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  className="space-y-3 md:space-y-4"
                >
                  <RenderInput shop={selectedShop} />
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-12 text-sm font-medium text-white shadow hover:bg-indigo-700 focus:outline-none"
                  >
                    Save
                  </button>
                </form>
              );
            }}
          </Formik>
        </div>
        <div className="bg-gray-50 h-12 text-right sm:px-6 rounded-b-md" />
      </div>
    </div>
  );
};
export default connect(
  (state: any) => ({
    alertStatus: state.common.get('alertStatus'),
    loadingAction: state.common.get('loadingAction'),
    selectedShop: state.dashboard.get('selectedShop'),
  }),
  {
    updateShop: Actions.dashboard.updateShop,
    openAlertBox: Actions.common.openAlertBox,
    uploadShopImage: Actions.setting.uploadShopImage,
    removeShopImage: Actions.setting.removeShopImage,
  }
)(CompanyInfo);

const RenderInput = ({shop}: any) => {
  const {values, errors, touched, handleChange, setFieldValue} =
    useFormikContext<{
      pin: string;
      email: string;
      token: string;
      address: string;
      shopName: string;
      category: string;
      phoneNumber: string;
      description: string;
      businessType: string;
      paymentMethod: string;
    }>();

  useEffect(() => {
    if (!isEmpty(shop)) {
      setFieldValue('pin', shop.pin);
      setFieldValue('email', shop.email);
      setFieldValue('token', shop.token);
      setFieldValue('address', shop.address);
      setFieldValue('shopName', shop.shopName);
      setFieldValue('category', shop.shopCategory);
      setFieldValue('businessType', shop.shopType);
      setFieldValue('description', shop.description);
      setFieldValue('phoneNumber', shop.phoneNumber);
      setFieldValue('paymentMethod', shop.paymentMethod);
    }
  }, [shop]);

  const categoryList = getEnumOptions(CategoryList);
  const type = getEnumTypes(values.category);
  const businessType = getEnumOptions(type);

  return (
    <div className="grid grid-cols-6 gap-6">
      <div className="col-span-6 sm:col-span-3">
        <FormInput
          id="shopName"
          label="Shop Name"
          value={values.shopName}
          error={errors.shopName}
          touched={touched.shopName}
          placeholder="Shop Name"
          onChange={handleChange('shopName')}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <FormInput
          id="email"
          label="Your email"
          value={values.email}
          error={errors.email}
          touched={touched.email}
          placeholder="name@company.com"
          onChange={handleChange('email')}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <FormInput
          id="address"
          label="Location"
          value={values.address}
          error={errors.address}
          placeholder="Location"
          touched={touched.address}
          onChange={handleChange('address')}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <div className="w-full relative flex flex-col">
          <label
            htmlFor="mobile-number"
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            Phone Number
          </label>
          <PhoneInput
            country={'lk'}
            value={values.phoneNumber}
            onChange={handleChange('phoneNumber')}
            inputStyle={{
              border: 'none',
              height: '100%',
              width: '100%',
              borderRadius: '8px',
            }}
            buttonStyle={{
              border: 'none',
              borderTopLeftRadius: '8px',
              borderBottomLeftRadius: '8px',
            }}
            containerClass="h-10 block w-full rounded-md outline-none border-gray-300 border shadow sm:text-sm mt-0"
          />
          {!isEmpty(errors.phoneNumber) && touched.phoneNumber && (
            <span className="mt-1 first-line:block text-xs font-normal text-rose-400	">
              {errors.phoneNumber}
            </span>
          )}
        </div>
      </div>
      <div className="col-span-6 sm:col-span-3">
        <Field
          id="category"
          name="category"
          label="Category"
          options={categoryList}
          placeholder="Category"
          error={errors.category}
          component={CustomSelect}
          touched={touched.category}
          onClickOption={() => {}}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <Field
          id="businessType"
          name="businessType"
          label="Business Type"
          options={businessType}
          component={CustomSelect}
          error={errors.businessType}
          placeholder="Business Type"
          touched={touched.businessType}
          onClickOption={() => {}}
        />
      </div>
      <div className="col-span-6 sm:col-span-6">
        <FormTextarea
          id="description"
          label="Shop description"
          value={values.description}
          error={errors.description}
          touched={touched.description}
          onChange={handleChange('description')}
        />
      </div>

      <div className="col-span-6 sm:col-span-6">
        <label
          htmlFor={'Payment'}
          className="block text-sm font-medium text-gray-700"
        >
          Payment
        </label>
        <div
          role="group"
          aria-labelledby="my-radio-group"
          className="mt-1 w-full col-span-6 flex flex-row gap-6"
        >
          <label className="flex items-center text-sm font-medium text-gray-700">
            <Field
              type="radio"
              value="CARD_CASH"
              name="paymentMethod"
              className="mr-3 mw-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            Cards and cash
          </label>
          <label className="flex items-center text-sm font-medium text-gray-700">
            <Field
              type="radio"
              value="CARD"
              name="paymentMethod"
              className="mr-3 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            Cash
          </label>
        </div>
      </div>
    </div>
  );
};
