import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Actions } from "../../../internals/app/Actions";
import Alert from "../../../components/Alert";
import EmployeeHeader from "../../../components/EmployeeHeader";
import Table from "../../../components/Table";
import Edit from "../../../assets/svgs/edit.svg";
import Delete from "../../../assets/svgs/delete.svg";
import { find } from "lodash";
import AddEmployee from "../../../components/AddEmployee";
import DeleteItem from "../../../components/DeleteItem";

const tableHeader = [
  "EMPLOYEE NAME",
  "EMAIL",
  "PHONE NUMBER",
  "ASSIGNED SERVICES",
  "ACTIONS",
];

const EmployeeView = (props: any) => {
  const { alertStatus, openAlertBox, employeeList, updateEmployee } = props;
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [employee, setEmployee] = useState(null);

  useEffect(() => {
    if (employeeList.employees) {
      const tableData = getData(employeeList.employees);
      setTableData(tableData);
    }
  }, [employeeList]);

  const getData = (data: any) => {
    return data.map((item: any) => {
      return {
        id: item.empId,
        "EMPLOYEE NAME": `${item.firstName} ${item.lastName}`,
        EMAIL: item.email,
        "PHONE NUMBER": item.mobileNumber,
        "ASSIGNED SERVICES": "",
        ACTIONS: [Edit, Delete],
      };
    });
  };

  const onClickItem = (data: any, index: number) => {
    const employee = find(
      employeeList.employees,
      (item) => item.empId === data.id
    );
    setEmployee(employee);
    if (index === 0) {
      toggleEmployee();
    } else if (index === 1) {
      toggleDelete();
    }
  };

  const toggleDelete = () => {
    setIsOpenDelete(!isOpenDelete);
  };

  const toggleEmployee = () => {
    setIsOpen(!isOpen);
  };

  const onDeleteEmployee = (data: any) => {
    updateEmployee({
      empId: data.empId,
      shopId: data.shopId,
      employeeStatus: "DELETED",
    });
    toggleDelete();
  };

  return (
    <div className="flex flex-col w-full p-6 justify-start">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="pb-6">
        <EmployeeHeader
          openEmployee={() => {
            setEmployee(null);
            toggleEmployee();
          }}
        />
      </div>
      <Table {...{ tableHeader, tableData }} onClickItem={onClickItem} />
      <AddEmployee
        isOpen={isOpen}
        employee={employee}
        closeModal={() => toggleEmployee()}
      />
      <DeleteItem
        isOpen={isOpenDelete}
        closeModal={() => toggleDelete()}
        onDelete={() => onDeleteEmployee(employee)}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    categories: state.product.get("categories"),
    alertStatus: state.common.get("alertStatus"),
    employeeList: state.employee.get("employeeList"),
  }),
  {
    openAlertBox: Actions.common.openAlertBox,
    updateEmployee: Actions.employee.updateEmployee,
  }
)(EmployeeView);
