import {useEffect, useState} from 'react';
import {isEmpty} from 'lodash';
import tokenNumber from 'randomatic';
import {connect} from 'react-redux';
import {Actions} from '../../../internals/app/Actions';
import FormInput from '../../../components/inputs/FormInput';
import FormTextarea from '../../../components/inputs/FormTextarea';
import Tooltip from 'react-power-tooltip';
import {useSearchParams} from 'react-router-dom';
import MixpanelService, { MP_EVENTS } from '../../../internals/service/MixpanelService';

const RegisterInfo = (props: any) => {
  const {updateShop, selectedShop, currentUser, updateUser} = props;

  const [searchParams] = useSearchParams();
  const [showGuide, setGuide] = useState<boolean>(false);
  const [pin, setPin] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const [receiptEnd, setReceiptEnd] = useState<string>('');

  useEffect(() => {
    if (!isEmpty(selectedShop)) {
      setPin(selectedShop.pin);
      setReceiptEnd(selectedShop.receiptEnd);
    }
  }, [selectedShop]);

  const onGenerateToken = () => {
    let shopToken = tokenNumber('0', 6);
    onSave(shopToken);
    setToken(shopToken);
    onPressGenerate();

    MixpanelService.track(MP_EVENTS.GENERATE_TOKEN, {...currentUser, ...selectedShop});
    setGuide(false);
  };

  useEffect(() => {
    const guide = searchParams.get('guide');
    if (guide) {
      setGuide(true);
    }
    // document.addEventListener('click', onClickAll);

    // return () => document.removeEventListener('click', onClickAll);
  }, [searchParams]);

  const onSave = (token: string) => {
    const param = {
      token: token,
      updatedTime: Date.now(),
      shopId: selectedShop.shopId,
    };
    updateShop(param);
  };

  const saveRegisterDetails = () => {
    updateShop({
      receiptEnd,
      updatedTime: Date.now(),
      shopId: selectedShop.shopId,
    });
  };

  const onPressGenerate = async () => {
    if (!currentUser?.didPinToken) {
      updateUser({
        uid: currentUser.uid,
        didPinToken: true,
      });
    }
  };

  return (
    <div className="h-full w-full p-6">
      <div className="w-full max-w-screen-md bg-white shadow-md rounded-lg">
        <div className="flex flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-t-md">
          <h3>{'REGISTER INFORMATION'}</h3>
        </div>

        <div className="overflow-auto bg-white px-4 py-5 sm:p-6 space-y-3 md:space-y-4 relative">
          <p className="text-sm">
            Enter the PIN and token by generating from here to onboard your POS
            register. <br />
            This is a one time token and will expire after onboarding
          </p>
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <FormInput
                id="pin"
                label="Pin"
                value={pin}
                disabled={true}
                onChange={() => {}}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <FormInput
                id="token"
                label="Token"
                value={token}
                disabled={true}
                onChange={(text) => {}}
              />
            </div>
          </div>
          <div className='relative w-[220px]'>
            <button
              className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-12 text-sm font-medium text-white shadow hover:bg-indigo-700 focus:outline-none"
              onClick={() => onGenerateToken()}
            >
              Generate token
            </button>
            {/* @ts-ignore */}
            <Tooltip textBoxWidth="200px"
              show={showGuide}
              backgroundColor="#6D71F9"
            >
              <p className="w-[200px] text-sm font-normal text-white">
                Create your new employees by pressing Add Employee
              </p>
            </Tooltip>
          </div>

          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-6">
              <FormTextarea
                id="message"
                placeholder="This will appear in the bottom of your reciept"
                value={receiptEnd}
                label="Reciept Printer Message"
                onChange={(text) => setReceiptEnd(text)}
              />
            </div>
          </div>
          <button
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-12 text-sm font-medium text-white shadow hover:bg-indigo-700 focus:outline-none"
            onClick={() => saveRegisterDetails()}
          >
            Save Register
          </button>
        </div>
        <div className="bg-gray-50 h-12 text-right sm:px-6 rounded-b-md" />
      </div>
    </div>
  );
};
export default connect(
  (state: any) => ({
    alertStatus: state.common.get('alertStatus'),
    loadingAction: state.common.get('loadingAction'),
    selectedShop: state.dashboard.get('selectedShop'),
    currentUser: state.setting.get('currentUser'),
  }),
  {
    updateUser: Actions.setting.updateUser,
    updateShop: Actions.dashboard.updateShop,
    openAlertBox: Actions.common.openAlertBox,
  }
)(RegisterInfo);
