import { useState } from "react";
import moment from "moment";
import { isEmpty } from "lodash";
import "../../layouts/Styles.css";
import Select from "react-select";
import { connect } from "react-redux";
import { GoSearch } from "react-icons/go";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { CircularProgress } from "@material-ui/core";
import { Actions } from "../../internals/app/Actions";
import { mapEmployee } from "../../internals/managers/ProductManager";

const TransactionHeader = (props: any) => {
  const {
    selectedShop,
    employeeList,
    loadingAction,
    onChangeExport,
    searchOrderTransactions,
    fetchOrderTransactions,
  } = props;
  const { action, loading } = loadingAction;
  const [openDateRange, setOpenDateRange] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [isSelectedDateRange, setIsSelectedDateRange] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const employeeOptions = mapEmployee(employeeList.employees);

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: "100%",
      boxShadow: "none",
      borderColor: "#D1D5DB",
      "&:hover": {
        borderColor: "#D1D5DB",
      },
    }),
  };

  const onChangeEmployee = (option: any) => {};

  const handleSelect = (ranges: any) => {
    setIsSelectedDateRange(true);
    setSelectedDateRange(ranges.selection);
  };

  const toggleOpenDateRange = (event: any) => {
    event.preventDefault();
    setOpenDateRange(!openDateRange);
  };

  const searchTransactionForDate = (
    searchText: string,
    isSelected: boolean
  ) => {
    const endDate = moment(
      new Date(selectedDateRange.endDate).toDateString()
    ).valueOf();
    const startDate = moment(
      new Date(selectedDateRange.startDate).toDateString()
    ).valueOf();
    const text = !isEmpty(searchText) ? searchText : null;
    if (isSelected) {
      if (startDate === endDate) {
        searchOrderTransactions(selectedShop.shopId, text, startDate);
      } else {
        searchOrderTransactions(selectedShop.shopId, text, startDate, endDate);
      }
    } else {
      searchOrderTransactions(selectedShop.shopId, text);
    }
    setOpenDateRange(false);
  };

  const fetchTransactions = (isSelected: boolean) => {
    if (isSelected) {
      searchTransactionForDate("", isSelectedDateRange);
    } else {
      fetchOrderTransactions(selectedShop.shopId);
    }
  };

  const onSearchTransactions = (text: string) => {
    if (text && text.length > 0) {
      searchTransactionForDate(text, isSelectedDateRange);
    } else {
      fetchTransactions(isSelectedDateRange);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      searchTransactionForDate(event.target.value, isSelectedDateRange);
    }
  };

  const onChangeText = (text: string) => {
    setSearchText(text);
    if (text.length === 0) {
      fetchTransactions(isSelectedDateRange);
    }
  };

  const resetDate = () => {
    setIsSelectedDateRange(false);
    if (searchText && searchText.length > 0) {
      searchTransactionForDate(searchText, false);
    } else {
      fetchTransactions(false);
    }
    setOpenDateRange(false);
  };

  const loadingSearch =
    loading &&
    (action.type === "FETCH_ORDER_TRANSACTIONS" ||
      action.type === "SEARCH_ORDER_TRANSACTIONS");


  return (
    <div className="flex max-[1180px]:flex-col flex-row max-[1180px]:gap-4 w-full justify-between mb-4">
      <div className="flex max-w-sm items-end">
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <GoSearch size={20} />
          </div>
          <input
            required
            type="text"
            id="simple-search"
            onKeyDown={handleKeyDown}
            placeholder="Search here.."
            onChange={(event) => onChangeText(event.target.value)}
            className="h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-2.5 focus:outline-none"
          />
        </div>
        <button
          onClick={() => onSearchTransactions(searchText)}
          className="flex items-center justify-center h-[38px] w-[47px] ml-4 text-sm font-medium text-white bg-medium-slate-blue rounded-md border border-medium-slate-blue  focus:outline-none"
        >
          {loadingSearch ? (
            <CircularProgress size={15} style={{ color: "#fff" }} />
          ) : (
            <GoSearch size={20} />
          )}
        </button>
      </div>

      <div className="flex flex-row items-end max-[1180px]:justify-between flex-wrap gap-4 xl:gap-8">
        <div className="w-[165px] relative flex flex-row">
          <Select
            styles={customStyles}
            placeholder={"Employee"}
            options={employeeOptions}
            onChange={onChangeEmployee}
            className={
              "block w-[165px] rounded-md outline-none text-sm text-valhalla"
            }
          />
        </div>

        <div className="flex relative h-[38px]">
          <div
            className="flex flex-row gap-2 items-center justify-center px-4 h-[38px] rounded-md border border-[#D1D5DB] text-sm"
            onClick={toggleOpenDateRange}
          >
            <div className="text-valhalla text-sm cursor-pointer">
              {isSelectedDateRange
                ? moment(selectedDateRange.startDate).format("DD-MM-YYYY")
                : "Start date"}
            </div>
            {"-"}
            <div className="text-valhalla text-sm cursor-pointer">
              {isSelectedDateRange
                ? moment(selectedDateRange.endDate).format("DD-MM-YYYY")
                : "End date"}
            </div>
          </div>
          {openDateRange ? (
            <div className="absolute z-50 top-11 right-0 bg-ghost-white shadow">
              <DateRangePicker
                ranges={[selectedDateRange]}
                onChange={handleSelect}
              />
              <div className="flex gap-4 justify-end p-2">
                <button
                  type="button"
                  onClick={() => {
                    setIsSelectedDateRange(true);
                    searchTransactionForDate(searchText, true);
                  }}
                  className="inline-flex justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white shadow bg-medium-slate-blue hover:bg-slate-blue"
                >
                  {"Filter"}
                </button>
                <button
                  type="button"
                  onClick={() => resetDate()}
                  className="inline-flex justify-center rounded-md border border-[#BFC0C6] py-2 px-4 text-sm font-medium text-[#999A9E] shadow bg-lavender"
                >
                  {"Reset"}
                </button>
              </div>
            </div>
          ) : null}
        </div>
        <div className="w-[165px] relative flex flex-row self-end">
          <Select
            styles={customStyles}
            placeholder={"Export"}
            options={[
              { label: "Export as PDF", value: "PDF" },
              { label: "Export as CSV", value: "CSV" },
            ]}
            onChange={onChangeExport}
            className={
              "block w-[165px] rounded-md outline-none text-sm text-valhalla"
            }
          />
        </div>
      </div>
    </div>
  );
};
export default connect(
  (state: any) => ({
    loadingAction: state.common.get("loadingAction"),
    employeeList: state.employee.get("employeeList"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    fetchOrderTransactions: Actions.transaction.fetchOrderTransactions,
    searchOrderTransactions: Actions.transaction.searchOrderTransactions,
  }
)(TransactionHeader);
