import React from "react";
import { connect } from "react-redux";
import { BsFillPlusCircleFill } from "react-icons/bs";

type Props = {
  title: string;
  addItem: () => void;
};

const ItemPlusLabel = (props: Props) => {
  const { title, addItem } = props;

  return (
    <div
      className="flex flex-row gap-4 mb-4 w-44 cursor-pointer"
      onClick={() => addItem()}
    >
      <label
        htmlFor={"amountType"}
        className="block text-sm text-gray-700 cursor-pointer font-bold"
      >
        {title}
      </label>
      <BsFillPlusCircleFill color="#6D71F9" size={20} />
    </div>
  );
};
export default connect((state: any) => ({}), {})(ItemPlusLabel);
