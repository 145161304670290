import WarrantyRepository from "../../internals/repository/WarrantyRepository";

export const ModuleEvents = {
  CREATE_WARRANTY: "CREATE_WARRANTY",
  UPDATE_WARRANTY: "UPDATE_WARRANTY",
  DELETE_WARRANTY: "DELETE_WARRANTY",
  FETCH_WARRANTIES: "FETCH_WARRANTIES",
  SET_PRODUCT_WARRANTY: "SET_PRODUCT_WARRANTY",
};

const createAction =
  (type, action = () => { }, meta) =>
    (...args) => ({
      type,
      payload: action(...args),
      meta,
    });

const fetchWarranties = createAction(
  ModuleEvents.FETCH_WARRANTIES,
  async (shopId, limit = 50, nextToken = null) => {
    const result = await WarrantyRepository.fetchWarranties(
      shopId,
      limit,
      nextToken
    );
    return result;
  },
  { loading: true }
);
const createWarranty = createAction(
  ModuleEvents.CREATE_WARRANTY,
  async (params) => {
    const result = await WarrantyRepository.createWarranty(params);
    return result;
  },
  { loading: true }
);

const updateWarranty = createAction(
  ModuleEvents.UPDATE_WARRANTY,
  async (params) => {
    const result = await WarrantyRepository.updateWarranty(params);
    return result;
  },
  { loading: true }
);

const deleteWarranty = createAction(
  ModuleEvents.DELETE_WARRANTY,
  async (params) => {
    const result = await WarrantyRepository.deleteWarranty(params);
    return result;
  },
  { loading: true }
);

const exportedFuction = {
  createWarranty,
  updateWarranty,
  deleteWarranty,
  fetchWarranties,
};

export default exportedFuction;
