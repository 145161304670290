import {useState, useEffect} from 'react';
import moment from 'moment';
import Select from 'react-select';
import {router} from '../../App';
import {connect} from 'react-redux';
import sidebarRoutes from '../../routes';
import {NavLink} from 'react-router-dom';
import Logo from '../../assets/svgs/logo.svg';
import {HiOutlineMenuAlt2} from 'react-icons/hi';
import {Actions} from '../../internals/app/Actions';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import {get, isEmpty, filter, findIndex} from 'lodash';
import IconUp from '../../assets/svgs/sidebar/icon-up.svg';
import IconDown from '../../assets/svgs/sidebar/icon-down.svg';
import IconLogout from '../../assets/svgs/sidebar/icon-logout.svg';
import WarrantyView from '../../modules/warranty/views/WarrantyView';
import {AiOutlineClose} from 'react-icons/ai';

const customStyles = {
  control: (base: any) => ({
    ...base,
    height: '100%',
    boxShadow: 'none',
    borderColor: '#D1D5DB',
    '&:hover': {
      borderColor: '#D1D5DB',
    },
  }),
};

const Sidebar = (props: {
  userClaims: any;
  shopClaims: any;
  adminShops: any;
  selectedShop: any;
  trialDetails: any;
  logout: () => void;
  openSidebar: boolean;
  selectedRoute: string;
  selectedAdminShop: any;
  selectedSubRoute: string;
  activeSubscriptions: any;
  toggleOpenDrawer: () => void;
  setSelectedRoute: (route: string) => void;
  onSelectDashBoard: (name: string) => void;
  setSelectedSubRoute: (route: string) => void;
}) => {
  const {
    logout,
    userClaims,
    shopClaims,
    adminShops,
    openSidebar,
    trialDetails,
    selectedShop,
    selectedRoute,
    toggleOpenDrawer,
    setSelectedRoute,
    selectedSubRoute,
    onSelectDashBoard,
    selectedAdminShop,
    activeSubscriptions,
    setSelectedSubRoute,
  } = props;
  const [activeShop, setActiveShop] = useState<any>([]);
  const [routes, setRoutes] = useState<any>([]);

  const shopCategory = selectedShop.shopCategory;

  useEffect(() => {
    if (selectedShop) {
      const data = [...sidebarRoutes];
      if (selectedShop.shopCategory === 'RETAILS') {
        let index = findIndex(data, (admin: any) => admin.name === 'Products');
        if (data[index].subCategory?.findIndex(({path}) => path === '/warranty') === -1) {
          data[index].subCategory?.push({
            path: '/warranty',
            name: 'Warranty',
            rtlName: 'Warranty Management',
            component: WarrantyView,
            layout: '/admin',
          });
        }
      }
      setRoutes([...data]);
    }
  }, [selectedShop]);

  useEffect(() => {
    let shops: any = [];
    userClaims.map((claim: any) => {
      if (
        get(claim, 'claimType', null) === 'ACTIVE' ||
        get(claim, 'claimType', null) === null
      ) {
        const filterShop = filter(
          adminShops,
          (shop: any) => shop.shopId === claim.shopId
        );
        shops = [...shops, ...filterShop];
      }
    });
    setActiveShop(shops);
  }, [userClaims, adminShops]);

  const isActiveSubscription =
    activeSubscriptions?.filter(({status}: any) => status === 'SUCCESS')
      .length > 0;

  const renderItem = (route: any, isActive: boolean) => {
    const icon = route.icon;
    const selectedIcon = route.selectedIcon;
    // some features are restricted according to the plan they are in
    const shouldRestrict =
      trialPeriod < 1 &&
      route?.plans &&
      route.plans.findIndex(
        (plan: string) =>
          activeSubscriptions.findIndex((sub: any) => plan === sub.planId) > -1
      ) === -1;

    return (
      <div className="flex flex-row justify-center md:justify-between items-center p-2 text-base font-normal text-gray-900 rounded-lg">
        <div className="flex flex-row justify-start items-center">
          <img alt="home" src={isActive ? selectedIcon : icon} />
          <span
            className={`${
              isActive ? 'text-valhalla' : 'text-hit-grey'
            } ml-3 text-sm lg:block  ${openSidebar ? 'block' : 'hidden'}`}
          >
            {route.name}{' '}
            {shouldRestrict ? (
              <span
                data-tooltip-id={route.path}
                className=" bg-genteel-lavender px-2 rounded py-0.5 text-xs text-white"
              >
                paid
                <ReactTooltip
                  id={route.path}
                  place="right"
                  variant="info"
                  content="Upgrade yor plan to access this feature"
                />
              </span>
            ) : (
              ''
            )}
          </span>
        </div>
        {selectedSubRoute === route.name ? (
          <img
            alt="home"
            className="w-3 h-3"
            src={selectedSubRoute === route.name ? IconUp : IconDown}
          />
        ) : null}
      </div>
    );
  };

  const renderSubItem = (route: any) => {
    const isSelected = selectedSubRoute === route.name;
    const icon = route.icon;
    const selectedIcon = route.selectedIcon;

    return (
      <>
        <div
          onClick={() => {
            router.navigate(route.path);
            setSelectedRoute(route.name);
            setSelectedSubRoute(route.name);
          }}
          className="flex flex-row cursor-pointer justify-center md:justify-between items-center p-2 text-base font-normal text-gray-900 rounded-lg"
        >
          <div className="flex flex-row justify-start items-center">
            <img alt={`${route.name}`} src={isSelected ? selectedIcon : icon} />
            <span
              className={`${
                isSelected ? 'text-valhalla' : 'text-hit-grey'
              } ml-3 text-sm lg:block ${openSidebar ? 'block' : 'hidden'}`}
            >
              {route.name}
            </span>
          </div>
          <img
            alt={`${route.name}`}
            className={`w-3 h-3 lg:block ${openSidebar ? 'block' : 'hidden'}`}
            src={isSelected ? IconUp : IconDown}
          />
        </div>
        {isSelected && !isEmpty(route.subCategory)
          ? route.subCategory.map((sub: any) => {
              const shouldRestrict =
                trialPeriod < 1 &&
                sub?.plans &&
                sub.plans.findIndex(
                  (plan: string) =>
                    activeSubscriptions.findIndex(
                      (sub: any) => plan === sub.planId
                    ) > -1
                ) === -1;

              if (shouldRestrict) {
                return null;
              }

              return (
                <NavLink
                  to={sub.path}
                  onClick={() => {
                    if (!shouldRestrict) {
                      setSelectedRoute(sub.name);
                      onSelectDashBoard(sub.rtlName);
                    }
                  }}
                >
                  {({isActive}: {isActive: boolean}) => {
                    return (
                      <div
                        className={`flex flex-row ${
                          openSidebar ? 'justify-start' : 'justify-center'
                        } items-center lg:justify-start p-2 text-base font-normal text-gray-900 rounded-lg`}
                      >
                        <div
                          className={`lg:ml-10 ${
                            openSidebar ? 'ml-10' : 'ml-0'
                          } flex flex-row items-center text-sm`}
                        >
                          <div
                            className={`${
                              isActive ? 'bg-medium-slate-blue' : 'bg-hit-grey'
                            } w-2 h-2 rounded lg:block ${
                              openSidebar ? 'block' : 'hidden'
                            }`}
                          />
                          <span
                            className={`${
                              isActive ? 'text-valhalla' : 'text-hit-grey'
                            } ml-3 lg:block ${
                              openSidebar ? 'block' : 'hidden'
                            }`}
                          >
                            {sub.name}{' '}
                            {shouldRestrict ? (
                              <span
                                data-tooltip-id={route.path}
                                className=" bg-genteel-lavender px-2 rounded py-0.5 text-xs text-white"
                              >
                                paid
                                <ReactTooltip
                                  id={route.path}
                                  place="right"
                                  variant="info"
                                  content="Upgrade yor plan to access this feature"
                                />
                              </span>
                            ) : (
                              ''
                            )}
                          </span>
                          <span
                            className={`${
                              isActive ? 'text-valhalla' : 'text-hit-grey'
                            } ${openSidebar ? 'hidden' : 'block'} lg:hidden`}
                          >
                            {sub.name.charAt(0)}
                          </span>
                        </div>
                      </div>
                    );
                  }}
                </NavLink>
              );
            })
          : null}
      </>
    );
  };

  const trialPeriod = moment(trialDetails?.trialEnds).diff(moment(), 'days');

  return (
    <>
      <div
        className={`h-full flex flex-col justify-between gap-20 items-center ${
          openSidebar ? 'hidden' : ''
        } block lg:hidden`}
      >
        <button
          type="button"
          onClick={() => toggleOpenDrawer()}
          className="flex w-9 h-9 justify-center items-center rounded-lg bg-medium-slate-blue hover:bg-slate-blue focus:outline-none mt-4"
        >
          <HiOutlineMenuAlt2 color={'#FFFFFF'} size={20} />
        </button>
        <div className="hidden md:flex h-[calc(100vh-150px)] mb-6 gap-20 overflow-y-auto flex-col justify-between">
          <div className="h-full flex gap-10 overflow-y-auto flex-col items-center lg:items-start">
            <ul className="space-y-4">
              {routes.map((route: any) => {
                const isOpen = selectedRoute === route.name;
                const isActiveSub = !isEmpty(route.subCategory);
                const shouldRestrict =
                  trialPeriod < 1 &&
                  route?.plans &&
                  route.plans.findIndex(
                    (plan: string) =>
                      activeSubscriptions.findIndex(
                        (sub: any) => plan === sub.planId
                      ) > -1
                  ) === -1;
                if (shouldRestrict) {
                  return renderItem(route, isOpen);
                }

                return (
                  <li>
                    {isActiveSub ? (
                      renderSubItem(route)
                    ) : (
                      <NavLink
                        to={route.path}
                        onClick={() => {
                          setSelectedSubRoute('');
                          setSelectedRoute(route.name);
                          onSelectDashBoard(route.rtlName);
                        }}
                      >
                        {({isActive}: {isActive: boolean}) =>
                          renderItem(route, (isActive = isActive || isOpen))
                        }
                      </NavLink>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <div
            onClick={() => logout()}
            className="w-full flex self-center items-center justify-center gap-4 cursor-pointer"
          >
            <img alt="logout" src={IconLogout} />
          </div>
        </div>
      </div>

      <div
        className={`flex-col h-full p-0 text-white bg-primary shadow-inner transition-all duration-500 ease-in-out drawer drawer-mobile ${
          openSidebar ? 'w-[255px] fixed' : 'sm:w-[60px] lg:w-[255px]'
        } overflow-y-auto`}
      >
        <div className="h-full flex px-3 py-4 gap-10 overflow-y-auto flex-col justify-between">
          <div className="h-full flex gap-10 overflow-y-auto flex-col flex-start">
            <div className="w-full flex flex-row">
              <NavLink
                to="/dashboard"
                onClick={() => setSelectedRoute('/dashboard')}
                className="flex items-center"
              >
                <img src={Logo} className="mr-3 h-9" alt="Netlise Logo" />
                <span className="self-center text-xl font-semibold whitespace-nowrap text-valhalla">
                  Netlise
                </span>
              </NavLink>

              <button
                type="button"
                onClick={() => toggleOpenDrawer()}
                className={`${
                  openSidebar ? 'block' : 'hidden'
                } flex items-center justify-center w-8 h-8 top-3 right-4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm ml-auto`}
              >
                <AiOutlineClose size={20} />
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {selectedShop ? (
              <Select
                styles={customStyles}
                defaultValue={{
                  label: selectedShop?.shopName,
                  value: selectedShop?.shopId,
                }}
                options={activeShop?.map((shop: any) => ({
                  label: shop.shopName,
                  value: shop.shopId,
                }))}
                onChange={({value}: any) => {
                  const shop = activeShop.find(
                    ({shopId}: any) => shopId === value
                  );
                  selectedAdminShop(shop);
                  setSelectedRoute('Dashboard');
                  setSelectedSubRoute('Dashboard');
                }}
                className={
                  'block w-full rounded-md outline-none text-sm text-valhalla'
                }
              />
            ) : null}

            <ul className="space-y-4">
              {sidebarRoutes.map((route: any) => {
                const isOpen = selectedRoute === route.name;
                const isActiveSub = !isEmpty(route.subCategory);

                // filter categories
                if (
                  shopCategory !== 'HOSPITALITY' &&
                  shopCategory !== 'FOOD_AND_BEVERAGES' &&
                  route.name === 'Tables'
                ) {
                  return null;
                }

                const shouldRestrict =
                  trialPeriod < 1 &&
                  route?.plans &&
                  route.plans.findIndex(
                    (plan: string) =>
                      activeSubscriptions.findIndex(
                        (sub: any) => plan === sub.planId
                      ) > -1
                  ) === -1;
                if (shouldRestrict) {
                  return renderItem(route, isOpen);
                }

                return (
                  <li>
                    {isActiveSub ? (
                      renderSubItem(route)
                    ) : (
                      <NavLink
                        to={route.path}
                        onClick={() => {
                          setSelectedSubRoute('');
                          setSelectedRoute(route.name);
                          onSelectDashBoard(route.rtlName);
                        }}
                      >
                        {({isActive}: {isActive: boolean}) =>
                          renderItem(route, (isActive = isActive || isOpen))
                        }
                      </NavLink>
                    )}
                  </li>
                );
              })}
            </ul>
            {isActiveSubscription || trialPeriod < 1 ? null : (
              <p className="text-sm text-hit-grey md:flex-none">
                Trial Period: {trialPeriod > 0 ? trialPeriod : 0} days
              </p>
            )}
          </div>
          <div
            onClick={() => logout()}
            className="w-full flex self-center items-center justify-center gap-4 cursor-pointer text-valhalla"
          >
            <img alt="logout" src={IconLogout} />
            <div>{'Logout'}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state: any) => ({
    shopClaims: state.user.get('shopClaims'),
    userClaims: state.user.get('userClaims'),
    adminShops: state.dashboard.get('adminShops'),
    trialDetails: state.setting.get('trialDetails'),
    selectedShop: state.dashboard.get('selectedShop'),
    activeSubscriptions: state.setting.get('activeSubscriptions'),
  }),
  {
    selectedAdminShop: Actions.dashboard.selectedAdminShop,
  }
)(Sidebar);
