import gql from "graphql-tag";

export const UpdateBilling = gql`
  mutation updateBillingDetails($input: UpdateBillingDetailsInput!) {
    updateBillingDetails(input: $input) {
      userId
      subscriptionId
      smsLimit
      freeSMSCount
      sendSMSCount
      year
      month
      didPay
      planId
      createdTime
      updatedTime
      periodStart
      periodEnd
      customer
      status
      isExpired
      assignmentId
      cancelStatus
      recurringFrequency
      shopId
      isHnbPayment
      referenceNumber
      isPendingPeriodEnd
      amount
      isChecked
      paymentMethod
    }
  }
`;

export const FetchPayments = gql`
query fetchPayments($shopId: String!) {
  fetchPayments(shopId: $shopId) {
    items { 
      paymentId
	    chargeId
	    invoiceId
	    currency
	    createdTime
	    updatedTime
	    customer
	    email
	    name
	    phoneNumber
	    intentId
	    periodStartTime
	    periodEndTime
	    subscriptionId
	    userId
	    amount
	    actualAmount
	    status
	    cardId
	    balanceTransactionId
	    plan {
        amount
        product
      }
	    recurringFrequency
	    shopId
	    authTransRefNo
	    cardExpiryDate
	    cardTypeName
	    billTransRefNo
	    paymentMethod
	    billToAddressCountry
	    billToAddressLine2
	    billToAddressLine1
	    cardNumber
	    billToAddressCity
	    billToAddressPostalCode
	    billToForename
	    requestToken
	    transactionUuid
	    referenceNumber
	    billToAddressState
	    isHnbPayment
	    transactionId
	    hostedInvoiceUrl
	    invoicePdf
    }
  }
}
`