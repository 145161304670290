import { get } from "lodash";
import { Map } from "immutable";
import { ModuleEvents } from "./Actions";

const initialState = Map({
  tables: [],
  QRtables: [],
  tableAreas: [],
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.FETCH_TABLES: {
      return state
        .set("tableAreas", payload)
        .set("tables", get(payload, "tableAreas[0].tables", []));
    }
    case ModuleEvents.FETCH_QR_TABLES: {
      if (payload && !payload.error) {
        return state.set("QRtables", payload)
      }
      return state;
    }
    case ModuleEvents.CREATE_QR_FOR_TABLE: {
      if (payload && !payload.error) {
        const QRtables = state.get("QRtables");
        QRtables.push(payload)
        return state.set("QRtables", [...QRtables])
      }
      return state;
    }

    default:
      return state;
  }
};

export default Reducer;
