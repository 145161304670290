import { useEffect, useState } from "react";
import * as Yup from "yup";
import Modal from "react-modal";
import "../../layouts/Styles.css";
import { connect } from "react-redux";
import FormInput from "../inputs/FormInput";
import PhoneInput from "react-phone-input-2";
import { AiOutlineClose } from "react-icons/ai";
import { get, includes, isEmpty } from "lodash";
import Delete from "../../assets/svgs/delete.svg";
import CustomSelect from "../inputs/CustomSelect";
import { Actions } from "../../internals/app/Actions";
import { Formik, Field, useFormikContext } from "formik";

const options = [
  { value: "ADMIN", label: "Admin" },
  { value: "MANAGER", label: "Manager" },
  { value: "REPORT_USER", label: "Report User" },
];

type ProductProps = {
  user?: any;
  isOpen: boolean;
  currentUser: any;
  selectedShop: any;
  closeModal: () => void;
  updateShopClaim: (params: any) => void;
  createShopClaim: (params: any) => void;
  deleteShopClaim: (params: any) => void;
};

const AddClient = (props: ProductProps) => {
  const {
    user,
    isOpen,
    closeModal,
    currentUser,
    selectedShop,
    updateShopClaim,
    createShopClaim,
    deleteShopClaim,
  } = props;

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    setIsEdit(!isEmpty(user));
  }, [user]);

  const customStyles: any = {
    overlay: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      position: "fixed",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      padding: 0,
      height: "auto",
      border: "none",
      overflow: "auto",
      maxWidth: "624px",
      background: "none",
      marginLeft: "20px",
      marginRight: "20px",
      left: "calc(50vw - 292px)",
      WebkitOverflowScrolling: "touch",
    },
  };

  const onSaveUser = (values: any) => {
    let claim: any = {
      shopId: selectedShop.shopId,
      lastName: values.lastName,
      firstName: values.firstName,
      phoneNumber: includes(values.phoneNumber, "+")
        ? values.phoneNumber
        : `+${values.phoneNumber}`,
      email: values.email,
      role: values.permission,
      createdBy: currentUser.uid
    };
    createShopClaim(claim);
    closeModal();
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(2, "Must be 2 characters or more")
      .required("First name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const initialValues = {
    email: "",
    lastName: "",
    firstName: "",
    permission: "",
    phoneNumber: "",
  };

  const onProductDelete = () => {
    deleteShopClaim({
      shopId: user.shopId,
      requestedUserId: user.requestedUserId,
    });
    setIsOpenDelete(false);
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Product Modal"
      onRequestClose={() => closeModal()}
    >
      <div className="relative shadow bg-white rounded-lg">
        <div className="flex flex-row h-14 justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-t-lg">
          <h3>{isEdit ? "Edit User" : "Create User"}</h3>
          <button
            type="button"
            onClick={() => closeModal()}
            className=" top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
          >
            <AiOutlineClose size={20} />
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => onSaveUser(values)}
        >
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="overscroll-contain overflow-auto h-auto bg-white px-4 py-5 sm:p-6 rounded-lg">
                  <RenderComponent user={user} />
                </div>

                <div className="flex h-16 flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
                  {isEdit ? (
                    <button
                      type="button"
                      onClick={() => setIsOpenDelete(true)}
                      className="inline-flex outline-none justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-red-700"
                    >
                      {"Delete User"}
                    </button>
                  ) : (
                    <div />
                  )}
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-indigo-700"
                  >
                    {isEdit ? "Save Changes" : "Add User"}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
        {isOpenDelete ? (
          <div className="absolute top-1/4 inset-14 w-full max-w-lg z-50 justify-center items-center">
            <div className="relative h-full md:h-auto">
              <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                <button
                  type="button"
                  onClick={() => setIsOpenDelete(false)}
                  className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <AiOutlineClose size={20} />
                  <span className="sr-only">Close modal</span>
                </button>
                <img
                  className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                  src={Delete}
                  alt="actions"
                />

                <p className="mb-4 text-gray-500 dark:text-gray-300">
                  Are you sure you want to delete this employee?
                </p>
                <div className="flex justify-center items-center space-x-4">
                  <button
                    type="button"
                    onClick={() => setIsOpenDelete(false)}
                    className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:outline-none hover:text-gray-900"
                  >
                    No, cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => onProductDelete()}
                    className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none  "
                  >
                    Yes, I'm sure
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};
export default connect(
  (state: any) => ({
    currentUser: state.setting.get("currentUser"),
    loadingAction: state.common.get("loadingAction"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    createShopClaim: Actions.user.createShopClaim,
    updateShopClaim: Actions.user.updateShopClaim,
    deleteShopClaim: Actions.user.deleteShopClaim,
  }
)(AddClient);

const RenderComponent = ({ user }: any) => {
  const { values, errors, touched, handleChange, setFieldValue } =
    useFormikContext<{
      email: string;
      lastName: string;
      firstName: string;
      phoneNumber: string;
      permission: string;
    }>();

  useEffect(() => {
    if (!isEmpty(user)) {
      const isUnclaimed = user.requestedUserId === "UNCLAIMED";
      const firstName = !isUnclaimed
        ? get(user, "user.firstName", "")
        : user.firstName;
      const lastName = !isUnclaimed
        ? get(user, "user.lastName", "")
        : user.lastName;
      const email = !isUnclaimed ? get(user, "user.email", "") : user.email;
      const phoneNumber = !isUnclaimed
        ? get(user, "user.phoneNumber", "")
        : user.phoneNumber;

      setFieldValue("email", email);
      setFieldValue("permission", user.role);
      setFieldValue("lastName", lastName);
      setFieldValue("firstName", firstName);
      setFieldValue("phoneNumber", phoneNumber);
    }
  }, [user]);

  return (
    <div className="grid grid-cols-6 gap-6 pb-10 pr-1">
      <div className="col-span-6 md:col-span-3">
        <FormInput
          id="firstName"
          label="First Name"
          value={values.firstName}
          error={errors.firstName}
          placeholder="First Name"
          touched={touched.firstName}
          onChange={handleChange("firstName")}
        />
      </div>
      <div className="col-span-6 md:col-span-3">
        <FormInput
          id="lastName"
          label="Last Name"
          value={values.lastName}
          error={errors.lastName}
          placeholder="Last Name"
          touched={touched.lastName}
          onChange={handleChange("lastName")}
        />
      </div>
      <div className="col-span-6 md:col-span-3">
        <FormInput
          id="email"
          label="Your email"
          value={values.email}
          error={errors.email}
          touched={touched.email}
          placeholder="name@company.com"
          onChange={handleChange("email")}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <Field
          isMulti={false}
          id="permission"
          options={options}
          name="permission"
          label="permission"
          error={errors.permission}
          component={CustomSelect}
          touched={touched.permission}
          placeholder="Select permission"
        />
      </div>
      <div className="col-span-6 md:col-span-3">
        <div className="w-full relative flex flex-col">
          <label
            htmlFor="mobile-number"
            className="mb-2 block text-sm font-medium text-gray-700"
          >
            Phone Number
          </label>
          <PhoneInput
            country={"lk"}
            value={values.phoneNumber}
            onChange={handleChange("phoneNumber")}
            inputStyle={{
              border: "none",
              height: "100%",
              width: "100%",
              borderRadius: "8px",
            }}
            buttonStyle={{
              border: "none",
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
            }}
            containerClass="h-10 block w-full rounded-md outline-none border-gray-300 border shadow sm:text-sm mt-0"
          />
        </div>
      </div>
      {/* <div className="col-span-6 md:col-span-3">
        <label
          htmlFor="permissions"
          className="mb-2 block text-sm font-medium text-gray-700"
        >
          Permissions
        </label>
        <Field
          id="permissions"
          name="permissions"
          options={options}
          component={CustomSelect}
          error={errors.permissions}
          touched={touched.permissions}
          placeholder="Select permissions..."
        />
      </div> */}
    </div>
  );
};
