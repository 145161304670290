import {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {isEmpty, lowerCase} from 'lodash';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import Logo from '../../../assets/svgs/logo.svg';
import es from 'react-phone-input-2/lang/es.json';
import {CircularProgress} from '@material-ui/core';
import {Actions} from '../../../internals/app/Actions';
import FormInput from '../../../components/inputs/FormInput';
import UserRepository from '../../../internals/repository/UserRepository';
import AuthRepository from '../../../internals/repository/AuthRepository';

const SignUpView = (props: any) => {
  const {signUp, loadingAction} = props;
  const {action, loading} = loadingAction;
  const [country, setCountry] = useState<any>(null);

  const getCoutry = async () => {
    await UserRepository.fetchCoutry((data: any) => {
      setCountry(data);
    });
  };

  useEffect(() => {
    getCoutry();
  }, []);

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .required('First name is required'),
    lastName: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .required('Last name is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    phoneNumber: Yup.string()
      .min(10, 'Enter valid phone number')
      .required('Must enter a phone number'),
    password: Yup.string()
      .min(8, 'Must be 8 characters or more')
      .required('Password is required'),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
    terms: Yup.boolean()
      .required('The terms and conditions must be accepted.')
      .oneOf([true], 'The terms and conditions must be accepted.'),
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    confirmPassword: '',
    terms: false,
  };

  const onClickSubmit = (values: any) => {
    signUp(values, values.password);
  };

  const federatedLogin = async () => {
    await AuthRepository.federatedSignIn();
  };

  const loadingSignup = loading && action.type === 'SIGNUP';

  return (
    <section className=" bg-gray-50 flex flex-row">
      <div className="max-w-5xl w-full h-full flex flex-col items-center justify-center p-6 mx-auto md:h-screen lg:py-0">
        <div className="flex items-center justify-center mb-6 text-4xl font-semibold text-gray-900 ">
          <img src={Logo} alt="logo" className="w-20 h-20 mr-6" />
          Netlise
        </div>
        <div className="flex flex-row w-full bg-white rounded-lg shadow md:mt-0  xl:p-0">
          <div className="flex-col w-1/2 hidden md:block overflow-hidden items-center justify-center h-full">
            <img
              alt=""
              className="h-full rounded-l-lg object-cover"
              src="/images/shopping-mall.jpg"
            />
          </div>
          <div className="p-6 md:w-1/2 w-full space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-midnight-express md:text-2xl">
              Sign up with Netlise
            </h1>

            <Formik
              onSubmit={(values) => onClickSubmit(values)}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                setFieldValue,
              }) => {
                return (
                  <form
                    onSubmit={handleSubmit}
                    className="space-y-3 md:space-y-4"
                  >
                    <div className="grid grid-cols-6 gap-3 md:gap-4">
                      <div className="col-span-6 sm:col-span-3">
                        <FormInput
                          id="firstName"
                          label="First name"
                          placeholder="First name"
                          value={values.firstName}
                          error={errors.firstName}
                          touched={touched.firstName}
                          onChange={handleChange('firstName')}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <FormInput
                          id="lastName"
                          label="Last name"
                          value={values.lastName}
                          error={errors.lastName}
                          placeholder="Last name"
                          touched={touched.lastName}
                          onChange={handleChange('lastName')}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-6 gap-3 md:gap-4">
                      <div className="col-span-6 sm:col-span-3">
                        <FormInput
                          id="email"
                          label="Your email"
                          value={values.email}
                          error={errors.email}
                          touched={touched.email}
                          placeholder="name@company.com"
                          onChange={handleChange('email')}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <div className="w-full relative flex flex-col">
                          <label
                            htmlFor="mobile-number"
                            className="mb-1 block text-sm font-medium text-gray-700"
                          >
                            Phone Number
                          </label>
                          <PhoneInput
                            localization={es}
                            inputStyle={{
                              width: '100%',
                              height: '100%',
                              border: 'none',
                              borderRadius: '8px',
                            }}
                            buttonStyle={{
                              border: 'none',
                              borderTopLeftRadius: '8px',
                              borderBottomLeftRadius: '8px',
                            }}
                            value={values.phoneNumber}
                            onChange={handleChange('phoneNumber')}
                            country={lowerCase(country?.country_code)}
                            containerClass="h-10 block w-full rounded-md outline-none border-gray-300 border shadow sm:text-sm mt-0"
                          />
                          {!isEmpty(errors.phoneNumber) &&
                            touched.phoneNumber && (
                              <span className="mt-1 first-line:block text-xs font-normal text-rose-400	">
                                {errors.phoneNumber}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>

                    <FormInput
                      id="password"
                      type="password"
                      label="Password"
                      value={values.password}
                      error={errors.password}
                      placeholder="**********"
                      touched={touched.password}
                      onChange={handleChange('password')}
                    />
                    <FormInput
                      type="password"
                      id="confirmPassword"
                      label="Confirm password"
                      placeholder="**********"
                      value={values.confirmPassword}
                      error={errors.confirmPassword}
                      touched={touched.confirmPassword}
                      onChange={handleChange('confirmPassword')}
                    />

                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="terms"
                          type="checkbox"
                          aria-describedby="terms"
                          className="w-4 h-4 border rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                          onChange={(e) =>
                            setFieldValue('terms', e.target.checked)
                          }
                          style={{
                            borderColor:
                              !isEmpty(errors.terms) && touched.terms
                                ? '#FF8080'
                                : '',
                          }}
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="terms"
                          className="font-light text-gray-500 "
                        >
                          I accept the{' '}
                          <NavLink
                            to="/signup"
                            className="font-medium text-medium-slate-blue hover:underline "
                          >
                            Terms and Conditions
                          </NavLink>
                        </label>
                      </div>
                    </div>

                    <button
                      type="submit"
                      disabled={loadingSignup}
                      className="w-full text-white bg-medium-slate-blue hover:bg-primary-700  focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                      {loadingSignup ? (
                        <CircularProgress
                          size={15}
                          style={{color: '#EFF0F8'}}
                        />
                      ) : (
                        'Create an account'
                      )}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        federatedLogin();
                      }}
                      disabled={loadingSignup}
                      className="w-full flex justify-center items-center text-theme_text-color shadow-md hover:bg-primary-700 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-3 text-center"
                    >
                      {loadingSignup ? (
                        <CircularProgress
                          size={15}
                          style={{color: '#EFF0F8'}}
                        />
                      ) : (
                        <div className="flex flex-row items-center">
                          <img src="/images/google-logo.png" className="mr-4" />
                          Sign up with Google
                        </div>
                      )}
                    </button>
                    <p className="text-sm font-light text-gray-500 ">
                      Already have an account?{' '}
                      <NavLink
                        to="/signin"
                        className="font-medium text-medium-slate-blue hover:underline "
                      >
                        Login here
                      </NavLink>
                    </p>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};
export default connect(
  (state: any) => ({
    loadingAction: state.common.get('loadingAction'),
    selectedShop: state.dashboard.get('selectedShop'),
  }),
  {
    signUp: Actions.login.signUp,
  }
)(SignUpView);
