import {Formik} from 'formik';
import ModalWrapper from '../../../components/Modal/ModalWrapper';
import FormInput from '../../../components/inputs/FormInput';
import SwitchInput from '../../../components/inputs/SwitchInput';
import Button from '../../../components/Button';
import {AddMenuSchema} from './Validator';
import {connect} from 'react-redux';
import {Actions} from '../../../internals/app/Actions';
import {nanoid} from 'nanoid';
import {useEffect, useState} from 'react';

const AddMenuModal = ({
  closeModal,
  isOpen,
  createMenu,
  shop,
  position = 0,
  editingMenu,
}: any) => {
  const [menuData, setMenuData] = useState({
    title: '',
    showMenu: false,
  });

  useEffect(() => {
    if (editingMenu) {
      setMenuData({
        ...editingMenu,
      });
    } else {
      setMenuData({
        title: '',
        showMenu: false,
      });
    }
  }, [editingMenu]);

  const onCreateMenu = (values: any) => {
    createMenu({
      ...values,
      shopId: shop.shopId,
      menuId: nanoid(),
      position,
      updatedTime: Date.now(),
    });
  };

  return (
    <ModalWrapper isOpen={isOpen} title="Add Menu" closeModal={closeModal}>
      <Formik
        initialValues={menuData}
        enableReinitialize={true}
        validationSchema={AddMenuSchema}
        onSubmit={onCreateMenu}
      >
        {({errors, values, setErrors, handleSubmit, setFieldValue}) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="px-6 py-4">
                <FormInput
                  onChange={(value) => setFieldValue('title', value)}
                  label="Menu Title"
                  value={values.title}
                  error={errors.title}
                  touched={true}
                />
                <SwitchInput
                  label="Display in Menu App"
                  value={values.showMenu}
                  onChange={(value: boolean) =>
                    setFieldValue('showMenu', value)
                  }
                />
                <div className="flex justify-between py-2">
                  <Button secondary label="Cancel" onClick={closeModal} />
                  <Button type="submit" label="Create Menu" />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </ModalWrapper>
  );
};

export default connect(
  (state: any) => ({
    shop: state.dashboard.get('selectedShop'),
  }),
  {
    createMenu: Actions.menu.createMenu,
  }
)(AddMenuModal);
