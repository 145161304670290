import Dashboard from "../src/assets/svgs/sidebar/dashboard-gray.svg";
import DashboardSelected from "../src/assets/svgs/sidebar/dashboard-select.svg";
import Products from "../src/assets/svgs/sidebar/product-gray.svg";
import ProductsSelected from "../src/assets/svgs/sidebar/product-select.svg";
import Services from "../src/assets/svgs/sidebar/services-gray.svg";
import ServicesSelected from "../src/assets/svgs/sidebar/services-select.svg";
import Customers from "../src/assets/svgs/sidebar/clients-gray.svg";
import CustomersSelected from "../src/assets/svgs/sidebar/clients-select.svg";
import Reports from "../src/assets/svgs/sidebar/transactions-gray.svg";
import ReportsSelected from "../src/assets/svgs/sidebar/transactions-select.svg";
import Settings from "../src/assets/svgs/sidebar/settings-gray.svg";
import SettingsSelected from "../src/assets/svgs/sidebar/settings-select.svg";
import Transaction from "../src/assets/svgs/sidebar/invoice-gray.svg";
import TransactionSelected from "../src/assets/svgs/sidebar/invoice-select.svg";
import Tables from "../src/assets/svgs/sidebar/tables-gray.svg";
import TablesSelected from "../src/assets/svgs/sidebar/tables-selected.svg";
import Reward from "../src/assets/svgs/sidebar/reward-gray.svg";
import RewardSelected from "../src/assets/svgs/sidebar/reward-select.svg";
import Download from "../src/assets/svgs/sidebar/download-icon.svg";
import DownloadSelected from "../src/assets/svgs/sidebar/download-selected-icon.svg";

import UserView from "./modules/user/views/UserView";
import TableView from "./modules/table/views/TableView";
import BrandView from "./modules/brand/views/BrandView";
import OfferView from "./modules/offer/views/OfferView";
import StockView from "./modules/stock/views/StockView";
import ProductView from "./modules/product/views/Product";
import ReportView from "./modules/report/views/ReportView";
import ServiceView from "./modules/service/views/ServiceView";
import CompanyInfo from "./modules/setting/views/CompanyInfo";
import PersonalInfo from "./modules/setting/views/PersonalInfo";
import RegisterInfo from "./modules/setting/views/RegisterInfo";
import DownloadView from "./modules/download/views/DownloadView";
import CustomerView from "./modules/customer/views/CustomerView";
import CategoryView from "./modules/category/views/CategoryView";
import SupplierView from "./modules/supplier/views/SupplierView";
import EmployeeView from "./modules/employee/views/EmployeeView";
import DiscountView from "./modules/discount/views/DiscountView";
import ModifierView from "./modules/modifier/views/ModifierView";
import WarrantyView from "./modules/warranty/views/WarrantyView";
import DashboardView from "./modules/dashboard/views/DashboardView";
import TransactionView from "./modules/transaction/views/TransactionView";
import SubscriptionPlans from "./modules/setting/views/SubscriptionPlans";
import MenuViews from "./modules/menu/views/MenuView";

const sidebarRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "Overview",
    icon: Dashboard,
    selectedIcon: DashboardSelected,
    component: DashboardView,
    layout: "/admin",
  },
  {
    icon: Products,
    name: "Products",
    path: "/product",
    layout: "/admin",
    rtlName: "Product Management",
    selectedIcon: ProductsSelected,
    // plans: ['PREMIUM', 'STANDARD'],
    subCategory: [
      {
        path: "/product",
        name: "Product",
        rtlName: "Product Management",
        component: ProductView,
        layout: "/admin",
      },
      {
        path: "/category",
        name: "Category",
        rtlName: "Category Management",
        component: CategoryView,
        layout: "/admin",
      },
      {
        path: "/brand",
        name: "Brand",
        rtlName: "Category Management",
        component: BrandView,
        layout: "/admin",
      },
      {
        path: "/supplier",
        name: "Supplier",
        rtlName: "Supplier Management",
        component: SupplierView,
        plans: ['PREMIUM', 'STANDARD'],
        layout: "/admin",
      },
      {
        path: "/modifiers",
        name: "Modifier",
        rtlName: "Modifiers Management",
        component: ModifierView,
        layout: "/admin",
      },
      {
        path: "/menu",
        name: "Menu",
        rtlName: "Menu Management",
        component: MenuViews,
        layout: "/admin",
      },
      // {
      //   path: "/warranty",
      //   name: "Warranty",
      //   rtlName: "Warranty Management",
      //   component: WarrantyView,
      //   layout: "/admin",
      // },
      {
        path: "/stock",
        name: "Stock",
        rtlName: "Stock Management",
        component: StockView,
      },
      {
        path: "/warranty",
        name: "Warranty",
        rtlName: "Warranty Management",
        component: WarrantyView,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/service",
    name: "Services",
    rtlName: "Services",
    icon: Services,
    selectedIcon: ServicesSelected,
    component: ServiceView,
    layout: "/admin",
  },
  {
    path: "/employees",
    name: "Employees",
    rtlName: "Employees",
    icon: Customers,
    selectedIcon: CustomersSelected,
    component: EmployeeView,
    plans: ['PREMIUM', 'STANDARD'],
    layout: "/admin",
  },
  {
    path: "/customers",
    name: "Customers",
    rtlName: "Customers",
    icon: Customers,
    selectedIcon: CustomersSelected,
    component: CustomerView,
    plans: ['PREMIUM', 'STANDARD'],
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    rtlName: "Users",
    icon: Customers,
    selectedIcon: CustomersSelected,
    component: UserView,
    plans: ['PREMIUM', 'STANDARD'],
    layout: "/admin",
  },
  {
    path: "/report",
    name: "Reports",
    rtlName: "Reports",
    icon: Reports,
    selectedIcon: ReportsSelected,
    component: ReportView,
    plans: ['PREMIUM', 'STANDARD'],
    layout: "/admin",
  },
  {
    path: "/transaction",
    name: "Transaction",
    rtlName: "Transaction",
    icon: Transaction,
    selectedIcon: TransactionSelected,
    component: TransactionView,
    plans: ['PREMIUM', 'STANDARD'],
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "Tables",
    rtlName: "Tables",
    icon: Tables,
    selectedIcon: TablesSelected,
    component: TableView,
    layout: "/admin",
    plans: ["PREMIUM", "MENU"],
  },
  {
    path: "/discounts",
    name: "Discounts",
    rtlName: "Discounts",
    icon: Reward,
    selectedIcon: RewardSelected,
    component: DiscountView,
    plans: ['PREMIUM', 'STANDARD'],
    layout: "/admin",
  },
  // {
  //   path: "/reward",
  //   name: "Reward",
  //   rtlName: "Reward",
  //   icon: Reward,
  //   selectedIcon: RewardSelected,
  //   component: RewardView,
  //   layout: "/admin",
  // },
  {
    path: "/offer",
    name: "Offer",
    rtlName: "Offer",
    icon: Reward,
    selectedIcon: RewardSelected,
    component: OfferView,
    plans: ['PREMIUM', 'STANDARD'],
    layout: "/admin",
  },
  {
    path: "/download",
    name: "Download",
    rtlName: "Download",
    icon: Download,
    selectedIcon: DownloadSelected,
    component: DownloadView,
    layout: "/admin",
  },
  {
    icon: Settings,
    layout: "/admin",
    path: "/personal-info",
    name: "Settings",
    rtlName: "Personal Information",
    selectedIcon: SettingsSelected,
    subCategory: [
      {
        layout: "/admin",
        path: "/personal-info",
        component: PersonalInfo,
        name: "Personal",
        rtlName: "Personal Information",
      },
      {
        layout: "/admin",
        path: "/company-info",
        component: CompanyInfo,
        name: "Company",
        rtlName: "Company Information",
      },
      {
        layout: "/admin",
        name: "Register",
        path: "/register-info",
        component: RegisterInfo,
        plans: ['PREMIUM', 'STANDARD'],
        rtlName: "Register Information",
      },
      {
        layout: "/admin",
        name: "Subscription Plans",
        path: "/subscription-plans",
        component: SubscriptionPlans,
        rtlName: "Subscription Plans",
      },
    ],
  },
];
export default sidebarRoutes;
