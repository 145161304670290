import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { getRevenueData } from "../../internals/managers/ReportsManager";
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = (props: any) => {
  const { monthRange, yearReports } = props;
  const [refund, setRefund] = useState(0);
  const [netPrice, setNetPrice] = useState(0);
  const [discount, setDiscount] = useState(0);

  const options: any = {
    legend: {
      display: false,
    },
    responsive: true,
    aspectRatio: 2,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
  };
  const data: any = {
    labels: ["Income", "Discount", "Refund"],
    datasets: [
      {
        label: "Income",
        data: [netPrice, discount, refund],
        backgroundColor: ["#6D71F9", "#54C1FB", "#FF3333"],
        borderColor: ["#6D71F9", "#54C1FB", "#FF3333"],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    const { net, totalRefunds, totalDiscount }: any = getRevenueData(
      monthRange,
      yearReports
    );
    setNetPrice(net);
    setRefund(totalRefunds);
    setDiscount(totalDiscount);
  }, [yearReports]);

  return (
    <>
      {netPrice > 0 ? (
        <Doughnut options={options} height={"100px"} data={data} />
      ) : null}
    </>
  );
};
export default connect(
  (state: any) => ({
    yearReports: state.report.get("yearReports"),
  }),
  () => ({})
)(DoughnutChart);
