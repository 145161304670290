import ServiceRepository from "../../internals/repository/ServiceRepository";

export const ModuleEvents = {
  SEARCH_CATEGORIES: "SEARCH_CATEGORIES",
  UPDATE_SERVICE_CATEGORY: "UPDATE_SERVICE_CATEGORY",
  CREATE_SERVICE_CATEGORY: "CREATE_SERVICE_CATEGORY",
  FETCH_SERVICE_CATEGORIES: "FETCH_SERVICE_CATEGORIES",
};

const createAction =
  (type, action = () => {}, meta) =>
  (...args) => ({
    type,
    payload: action(...args),
    meta,
  });

const fetchCategories = createAction(
  ModuleEvents.FETCH_SERVICE_CATEGORIES,
  async (shopId) => {
    const result = await ServiceRepository.fetchCategories(shopId);
    return result;
  },
  { loading: true }
);

const searchCategories = createAction(
  ModuleEvents.SEARCH_CATEGORIES,
  async (data) => {
    return data;
  },
  { loading: true }
);

const createCategory = createAction(
  ModuleEvents.CREATE_SERVICE_CATEGORY,
  async (params) => {
    const result = await ServiceRepository.createCategory(params);
    return result;
  }
);

const updateCategory = createAction(
  ModuleEvents.UPDATE_SERVICE_CATEGORY,
  async (params, status) => {
    const result = await ServiceRepository.updateCategory(params);
    return { result, status };
  }
);

const exportedFuction = {
  createCategory,
  updateCategory,
  fetchCategories,
  searchCategories,
};

export default exportedFuction;
