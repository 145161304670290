import { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import Table from "../../../components/Table";
import Alert from "../../../components/Alert";
import { capitalize, find, get } from "lodash";
import { Actions } from "../../../internals/app/Actions";
import Edit from "../../../assets/svgs/subscription/dots.svg";

const tableHeader = [
  "NAME",
  "PURCHASE DATE",
  "START DATE",
  "END DATE",
  "CARD",
  "AMOUNT",
  "ACTIONS",
];

const SubscriptionPayments = (props: any) => {
  const { shopPayments, selectedShop, fetchShopPayments } =
    props;
  const [tableData, setTableData] = useState<Array<any>>([]);

  useEffect(() => {
    if (selectedShop.shopId) {
      fetchShopPayments(selectedShop.shopId);
    }
  }, [selectedShop?.shopId, fetchShopPayments]);

  useEffect(() => {
    if (shopPayments) {
      const tableData = getData(shopPayments.filter(({shopId}: any) => shopId === selectedShop.shopId));
      setTableData(tableData);
    }
  }, [shopPayments, selectedShop.shopId]);

  const getData = (data: any) => {
    return data.map((item: any) => {
      const date = moment(item.createdTime).format("YYYY/MM/DD");
      const start = moment(item.periodStartTime * 1000).format("YYYY/MM/DD");
      const end = moment(item.periodEndTime * 1000).format("YYYY/MM/DD");
      return {
        id: item.paymentId,
        url: item.hostedInvoiceUrl,
        NAME: item.name,
        'PURCHASE DATE': date,
        'START DATE': start,
        'END DATE': end,
        CARD: item.cardNumber,
        AMOUNT: get(item, "amount", 0),
        ACTIONS: get(item, "status", "") === "SUCCESS" ? [Edit] : null,
      };
    });
  };

  const onClickOption = (data: any, index: number) => {
    window.open(data.hostedInvoiceUrl, '_blank')
  };

  const modalData = [
    { title: "View Transaction", id: "VIEW" },
  ];

  const onClickPlanOption = (data: any) => {
    window.open(data.url, '_blank')
    // const subscription = find(
    //   subscriptionsList.subscriptions,
    //   (plan: any) => plan.subscriptionId === data.id
    // );
    // setSelectedPlan(subscription);
    // if (data.id === "UPDATE") {
    // } else {
    //   toggleCancelModal();
    // }
  };

  return (
    <div className="h-full w-full bg-white">
      {
        <Table
          tag="subscription-plans"
          {...{ tableHeader, tableData, modalData }}
          onClickModalItem={(data: any) => onClickPlanOption(data)}
          onClickItem={(data: any, index: number) => onClickOption(data, index)}
        />
      }
    </div>
  );
};

export default connect(
  (state: any) => ({
    alertStatus: state.common.get("alertStatus"),
    modalStatus: state.setting.get("modalStatus"),
    selectedShop: state.dashboard.get("selectedShop"),
    shopPayments: state.setting.get("shopPayments"),
    subscriptionsList: state.setting.get("subscriptionsList"),
  }),
  {
    openAlertBox: Actions.common.openAlertBox,
    cancelSubscription: Actions.setting.cancelSubscription,
    fetchShopPayments: Actions.setting.fetchShopPayments,
  }
)(SubscriptionPayments);
