import {useEffect, useState} from 'react';
import S3Image from '../S3Image';
import '../../layouts/Styles.css';
import {connect} from 'react-redux';
import {get, isEmpty, toUpper, truncate} from 'lodash';
import {Actions} from '../../internals/app/Actions';
import AddProduct from '../../modules/product/views/AddProduct';
import {formatPrice} from '../../internals/managers/PaymentManager';

const ProductGrid = (props: any) => {
  const {products, selectedShop, setImage} = props;
  const [list, setList] = useState<Array<any>>([]);
  const [isOpenProduct, setIsOpenProduct] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any>({});

  useEffect(() => {
    if (products.products) {
      setList(products.products);
    }
  }, [products.products]);

  const getNameIcon = (name: string) => {
    if (name) {
      const character: any = name.match(/\b(\w)/g);
      return character.length === 2
        ? toUpper(character[0]) + toUpper(character[1])
        : toUpper(character[0]);
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedProduct)) {
      toggleProduct();
      setImage(selectedProduct.productImage);
    }
  }, [selectedProduct]);

  const toggleProduct = () => {
    setIsOpenProduct(!isOpenProduct);
  };

  return (
    <div className="flex flex-row flex-wrap gap-3 ">
      {list.map((product: any, index) => {
        let price =
          get(product, 'itemPrice', 0) || get(product, 'itemSalePrice', 0);
        if (!isEmpty(product?.priceVariants)) {
          price = product.priceVariants.reduce(
            (min: any, obj: any) => (obj.price < min ? obj.price : min),
            product.priceVariants[0].price
          );
        }
        const priceValue = formatPrice(price, selectedShop.currency);
        return (
          <div
            key={index}
            onClick={() => setSelectedProduct(product)}
            className="w-[150px] relative h-[210px] flex flex-col items-center self-center justify-between bg-white border border-gray-200 rounded-lg shadow-md"
          >
            {product?.showMenu ? (
              <div className="absolute right-2 top-2 bg-emerald-400 rounded px-2 py-1 text-white text-xs">ACTIVE</div>
            ) : (
              <div className="absolute right-2 top-2 bg-red-500 px-2 rounded py-1 text-white text-xs">DISABLED</div>
            )}
            {product.productImage || product.menuImage ? (
              <S3Image
                type={!product.productImage && 'menu'}
                className="flex w-full h-[150px] object-contain overflow-hidden items-center justify-center bg-auto bg-no-repeat bg-center rounded-t-lg hover:scale-110 transition duration-300 ease-in-out"
                url={product.productImage || product.menuImage}
              />
            ) : (
              <div className="flex min-h-[135px] max-w-[150px] max-h-[150px] justify-center text-4xl items-center rounded-t-lg hover:scale-150 transition duration-300 ease-in-out text-[#201C53]">
                {getNameIcon(product.productName)}
              </div>
            )}
            <div className="flex flex-col justify-center items-center p-2">
              <h6 className="text-xs font-medium tracking-tight text-gray-600 text-center capitalize">
                {truncate(product.productName, {length: 64})}
              </h6>
              <p className="text-xs font-semibold text-gray-700">{priceValue}</p>
            </div>
          </div>
        );
      })}

      <AddProduct
        isOpen={isOpenProduct}
        product={selectedProduct}
        closeModal={() => toggleProduct()}
      />
    </div>
  );
};
export default connect(
  (state: any) => ({
    products: state.product.get('products'),
    categories: state.product.get('categories'),
    selectedShop: state.dashboard.get('selectedShop'),
  }),
  {
    setImage: Actions.product.setImage,
  }
)(ProductGrid);
