import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { jsPDF } from "jspdf";
import CloseCash from "./CloseCash";
import { connect } from "react-redux";
import html2canvas from "html2canvas";
import { find, isEmpty } from "lodash";
import Alert from "../../../components/Alert";
import Table from "../../../components/Table";
import { TransactionTableHeader } from "../Constant";
import Receipt from "../../../assets/svgs/receipt.svg";
import { Actions } from "../../../internals/app/Actions";
import InvoiceDetail from "../../../components/InvoiceDetail";
import TransactionHeader from "../../../components/TransactionHeader";
import { formatPrice } from "../../../internals/managers/PaymentManager";

const TransactionView = (props: any) => {
  const {
    alertStatus,
    openAlertBox,
    selectedShop,
    transactions,
    selectedScreen,
    setSelectedScreen,
    fetchOrderTransactions,
  } = props;
  const innerRef = useRef<any>();
  const [openReceipt, setOpenReceipt] = useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] = useState<any>({});
  const [transactionTableData, setTransactionTableData] = useState<Array<any>>(
    []
  );

  useEffect(() => {
    if (transactions.transactions) {
      const tableData = getTransactionData(transactions.transactions);
      setTransactionTableData(tableData);
    }
  }, [transactions]);

  const getTransactionData = (data: any) => {
    return data.map((item: any) => {
      return {
        id: item.orderId,
        DATE: item.updatedTime,
        AMOUNT: item.totalPrice,
        ACTIONS: [Receipt], //to do create option modal
        "ORDER ID": item.orderId,
        EMPLOYEE: item.employeeName,
        "RECEIPT ID": item.recieptId,
      };
    });
  };

  const onChangeExport = (option: any) => {
    if (option.value === "PDF") {
      const doc = new jsPDF("p", "mm");
      const data: any = document.getElementById("tableToPrint");
      html2canvas(data).then((canvas: any) => {
        const imgWidth = 210;
        const pageHeight = 295;

        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        heightLeft -= pageHeight;
        doc.addImage(
          canvas,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(
            canvas,
            "PNG",
            0,
            position,
            imgWidth,
            imgHeight,
            "",
            "FAST"
          );
          heightLeft -= pageHeight;
        }
        let title = moment().format("yyyy-MM-DD");
        doc.save(`invoices-${title}.pdf`);
      });
    } else {
      const header = ["Order Id", "Date", "Employee", "Receipt Id", "Amount"];
      const data = transactions?.transactions?.map((item: any) => {
        const time = moment(item.time, "hh:mm A").format("hh:mmA");
        const date = moment(item.date).format("DD/MM/YYYY");
        const price = formatPrice(item.totalPrice, selectedShop.currency);
        return [
          item.orderId,
          `${date} ${time}`,
          item.employeeName,
          item.recieptId,
          item.totalPrice,
        ];
      });
      const csvData: any = [header, ...data];
      let csvContent =
        "data:text/csv;charset=utf-8" +
        csvData.map((data: any) => data.join(",")).join("\n");

      var link = window.document.createElement("a");
      link.setAttribute("href", encodeURI(csvContent));
      let title = moment().format("yyyy-MM-DD");
      link.setAttribute("download", `invoices-${title}.csv`);
      link.click();
      // var encodedUri = encodeURI(csvContent);
      // window.open(encodedUri, `invoices-${title}.csv`);
    }
  };

  const toggleReceipt = () => {
    setOpenReceipt(!openReceipt);
  };

  const onClickInvoiceAction = (data: any, index: number) => {
    const transaction = find(
      transactions.transactions,
      (item) => item.orderId === data.id
    );
    setSelectedTransaction(transaction);
    if (index === 0) {
      toggleReceipt();
    }
  };

  const onScroll = () => {
    if (innerRef && innerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = innerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight &&
        transactions.nextLastKey
      ) {
        fetchOrderTransactions(
          selectedShop.shopId,
          30,
          transactions.nextLastKey
        );
      }
    }
  };

  const renderScreen = () => {
    switch (selectedScreen) {
      case "INVOICES":
        return (
          <>
            <TransactionHeader onChangeExport={onChangeExport} />
            <div
              ref={innerRef}
              onScroll={() => onScroll()}
              className={"overflow-auto relative"}
            >
              <Table
                {...{
                  tableHeader: TransactionTableHeader,
                  tableData: transactionTableData,
                }}
                onClickItem={(data: any, index: number) =>
                  onClickInvoiceAction(data, index)
                }
              />
            </div>
          </>
        );
      case "CLOSE_CASH":
        return <CloseCash />;
    }
  };

  return (
    <div className="flex flex-col w-full p-6 justify-start h-full">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="flex flex-row gap-10 mb-6">
        <h3
          onClick={() => setSelectedScreen("INVOICES")}
          className={`${
            selectedScreen === "INVOICES"
              ? "text-midnight-express"
              : "text-echo-Blue"
          } + cursor-pointer text-md font-semibold`}
        >
          {"Invoices"}
        </h3>
        <h3
          onClick={() => setSelectedScreen("CLOSE_CASH")}
          className={`${
            selectedScreen === "CLOSE_CASH" || selectedScreen === "DETAILS"
              ? "text-midnight-express"
              : "text-echo-Blue"
          } + cursor-pointer text-md font-semibold`}
        >
          {"Close Cash"}
        </h3>
      </div>
      {renderScreen()}
      {!isEmpty(selectedTransaction) && (
        <InvoiceDetail
          isOpen={openReceipt}
          transaction={selectedTransaction}
          closeModal={() => toggleReceipt()}
        />
      )}
    </div>
  );
};
export default connect(
  (state: any) => ({
    alertStatus: state.common.get("alertStatus"),
    currentUser: state.setting.get("currentUser"),
    loadingAction: state.common.get("loadingAction"),
    selectedShop: state.dashboard.get("selectedShop"),
    transactions: state.transaction.get("transactions"),
    selectedScreen: state.transaction.get("selectedScreen"),
  }),
  {
    openAlertBox: Actions.common.openAlertBox,
    setSelectedScreen: Actions.transaction.setSelectedScreen,
    fetchOrderTransactions: Actions.transaction.fetchOrderTransactions,
  }
)(TransactionView);
