import { mapStockRecord } from "../../internals/managers/StockManager";
import StockRepository from "../../internals/repository/StockRepository";

export const ModuleEvents = {
  FETCH_STOCKS: "FETCH_STOCKS",
  CREATE_STOCK: "CREATE_STOCK",
  SET_STOCK_RECORD: "SET_STOCK_RECORD",
  SET_SELECTED_SCREEN: "SET_SELECTED_SCREEN",
  CREATE_STOCK_RECORD: "CREATE_STOCK_RECORD",
  UPDATE_STOCK_RECORD: "UPDATE_STOCK_RECORD",
  DELETE_STOCK_RECORD: "DELETE_STOCK_RECORD",
  FETCH_STOCK_RECORDS: "FETCH_STOCK_RECORDS",
};

const createAction =
  (type, action = () => { }, meta) =>
    (...args) => ({
      type,
      payload: action(...args),
      meta,
    });

const fetchStocks = createAction(
  ModuleEvents.FETCH_STOCKS,
  async (shopId) => {
    const result = await StockRepository.fetchStocks(shopId);
    return result;
  },
  { loading: true }
);
const createStock = createAction(
  ModuleEvents.CREATE_STOCK,
  async (param) => {
    const result = await StockRepository.createStock(param);
    return result;
  },
  { loading: true }
);

const createStockRecord = createAction(ModuleEvents.CREATE_STOCK_RECORD, async (param) => {
  const stockRecords = mapStockRecord(param)
  const result = await StockRepository.createStockRecord(stockRecords);
  return result;
}, { loading: true });

const updateStockRecord = createAction(ModuleEvents.UPDATE_STOCK_RECORD, async (param) => {
  const stockRecords = mapStockRecord(param)
  const result = await StockRepository.updateStockRecord(stockRecords);
  return result;
}, { loading: true });

const fetchStockRecords = createAction(ModuleEvents.FETCH_STOCK_RECORDS, async (shopId) => {
  const result = await StockRepository.fetchStockRecords(shopId);
  return result;
}, { loading: true });

const deleteStockRecord = createAction(ModuleEvents.DELETE_STOCK_RECORD, async (param) => {
  const result = await StockRepository.deleteStockRecord(param);
  return result;
}, { loading: true });

const setStockRecord = createAction(ModuleEvents.SET_STOCK_RECORD, async ({ record, navigation = true }) => {
  return { record, navigation };
}, { loading: true });

const setSelectedScreen = createAction(
  ModuleEvents.SET_SELECTED_SCREEN,
  async (screen) => {
    return screen;
  }
);

const exportedFuction = {
  fetchStocks,
  createStock,
  setStockRecord,
  setSelectedScreen,
  fetchStockRecords,
  createStockRecord,
  updateStockRecord,
  deleteStockRecord,
};

export default exportedFuction;
