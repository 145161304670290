import { delay } from "lodash";
import Swal from "sweetalert2";
import { ModuleEvents } from "./Actions";
import { Actions } from "../../internals/app/Actions";
import withReactContent from "sweetalert2-react-content";

export default {
  [ModuleEvents.CREATE_CLIENT]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      if (payload.code === "SUCCESSFULLY_CREATED") {
        dispatch(Actions.common.openAlertBox("CREATE", true, "SUCCESS"));
        delay(function (text) {
          dispatch(Actions.common.openAlertBox("CREATE", false, "SUCCESS"));
        }, 3000);
      } else if (payload.code === "CLIENT_ALREADY_CREATED") {
        return warning(
          "Client allready created. Please use another phone number!"
        );
      }
    } else {
      return warning("Client created unsuccessfully!");
    }
  },

  [ModuleEvents.UPDATE_CLIENT]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      dispatch(Actions.common.openAlertBox("UPDATE", true, "SUCCESS"));
      delay(function (text) {
        dispatch(Actions.common.openAlertBox("UPDATE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning("Client updated unsuccessfully!");
    }
  },

  [ModuleEvents.DELETE_CLIENT]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      dispatch(Actions.common.openAlertBox("DELETE", true, "SUCCESS"));
      delay(function (text) {
        dispatch(Actions.common.openAlertBox("DELETE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning("Client deleted unsuccessfully!");
    }
  },
};

const warning = (alert) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    icon: "error",
    text: alert,
    title: "Oops...",
    showCancelButton: true,
    showConfirmButton: false,
    cancelButtonColor: "#FF3333",
  });
};
