import React from "react";
import { connect } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import Delete from "../../assets/svgs/delete.svg";

type Props = {
  title: string;
  onDelete: () => void;
  closeModal: () => void;
};

const DeleteItem = (props: Props) => {
  const { title, closeModal, onDelete } = props;

  return (
    <div className="relative h-full md:h-auto">
      <div className="relative p-4 text-center bg-white rounded-lg shadow sm:p-5">
        <button
          type="button"
          onClick={() => closeModal()}
          className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
        >
          <AiOutlineClose size={20} />
          <span className="sr-only">Close modal</span>
        </button>
        <img className="w-11 h-11 mb-3.5 mx-auto" src={Delete} alt="actions" />
        <p className="mb-4 text-gray-500">{title}</p>
        <div className="flex justify-center items-center space-x-4">
          <button
            type="button"
            onClick={() => closeModal()}
            className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:outline-none hover:text-gray-900"
          >
            No, cancel
          </button>
          <button
            type="button"
            onClick={() => onDelete()}
            className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none  "
          >
            Yes, I'm sure
          </button>
        </div>
      </div>
    </div>
  );
};
export default connect((state: any) => ({}), {})(DeleteItem);
