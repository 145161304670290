import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Tooltip from 'react-power-tooltip';
import {AiOutlinePlus} from 'react-icons/ai';
import {useSearchParams} from 'react-router-dom';

const EmployeeHeader = (props: any) => {
  const {openEmployee} = props;
  const [showGuide, setGuide] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const guide = searchParams.get('guide');
    if (guide) {
      setGuide(true);
    }
    // document.addEventListener('click', onClickAll);

    // return () => document.removeEventListener('click', onClickAll);
  }, [searchParams]);

  const onPressEmployee = () => {
    openEmployee();
    setGuide(false);
  }

  return (
    <div className="flex flex-row w-full justify-end">
      <div className="inline-flex rounded-md shadow-sm relative" role="group">
        <button
          type="button"
          onClick={() => onPressEmployee()}
          className="inline-flex gap-2 items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-md hover:bg-gray-100 hover:text-medium-slate-blue"
        >
          <AiOutlinePlus size={20} />
          Add Employee
        </button>
        {/* @ts-ignore */}
        <Tooltip textBoxWidth="200px"
          show={showGuide}
          position="left center"
          backgroundColor="#6D71F9"
        >
          <p className="w-[200px] text-sm font-normal text-white">
            Create your new employees by pressing Add Employee
          </p>
        </Tooltip>
      </div>
    </div>
  );
};
export default connect((state: any) => ({}), {})(EmployeeHeader);
