import { API, graphqlOperation } from "@aws-amplify/api";
import Repository from "./Repository";
import {
  GetDiscounts,
  UpdateDiscount,
  CreateDiscount,
} from "./schema/Discount.schema";

class DiscountRepository extends Repository {
  async createDiscount(params) {
    try {
      const data = await API.graphql(
        graphqlOperation(CreateDiscount, { input: params })
      );
      return data.data.createDiscounts;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }
  async updateDiscounts(params) {
    try {
      const data = await API.graphql(
        graphqlOperation(UpdateDiscount, { input: params })
      );
      return data.data.updateDiscounts;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }
  async fetchDiscounts(shopId) {
    try {
      const data = await API.graphql(
        graphqlOperation(GetDiscounts, { shopId })
      );
      return data.data.getDiscounts;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }
}

export default new DiscountRepository();
