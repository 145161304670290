import gql from "graphql-tag";

export const CreateMenu = gql`
  mutation CreateMenu($input: CreateMenuInput!) {
    createMenu(input: $input) {
      shopId
      menuId
      title
      showMenu
      position
      updatedTime
      categories {
        categoryId
        position
        products {
          productId
          position
        }
      }
    }
  }
`;

export const UpdateMenu = gql`
  mutation UpdateMenu($input: UpdateMenuInput!) {
    updateMenu(input: $input) {
      shopId
      menuId
      title
      showMenu
      position
      categories {
        categoryId
        position
        products {
          productId
          position
        }
      }
      updatedTime
    }
  }
`;

export const DeleteMenu = gql`
  mutation DeleteMenu($input: DeleteMenuInput!) {
    deleteMenu(input: $input) {
      shopId
      menuId
    }
  }
`;

export const GetMenus = gql`
  query getMenus($shopId: String!) {
    getMenus(shopId: $shopId) {
      shopId
      menuId
      title
      showMenu
      position
      categories {
        categoryId
        position
        products {
          productId
          position
        }
      }
      updatedTime
    }
  }
`;
