import React, { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import moment from "moment";
import { jsPDF } from "jspdf";
import Select from "react-select";
import { DatePicker } from "antd";
import { connect } from "react-redux";
import html2canvas from "html2canvas";
import Table from "../../../components/Table";
import { get, isEmpty, find } from "lodash";
import { Actions } from "../../../internals/app/Actions";
import Chart from "../../../components/Chart/barChartProduct";
import { formatPrice } from "../../../internals/managers/PaymentManager";

const customStyles = {
  control: (base: any) => ({
    ...base,
    height: "100%",
    boxShadow: "none",
    borderColor: "#D1D5DB",
    "&:hover": {
      borderColor: "#D1D5DB",
    },
  }),
};

const tableHeaderData = [
  "PRODUCT NAME",
  "PRODUCT TYPE",
  "CATEGORY",
  "QUANTITY",
  "AMOUNT",
];
const ProductView = (props: any) => {
  const { products, selectedShop, fetchProductSummary, productReportSummary } =
    props;
  const [netPrice, setNetPrice] = useState<any>(0);
  const [tableHeader] =
    useState<Array<string>>(tableHeaderData);
  const [tableData, setTableData] = useState<Array<any>>([]);
  // const [selectedMonth, setSelectedMonth] = useState<any>(
  //   moment(moment().format("YYYY-MM"), "YYYY-MM")
  // );
  const [selectedMonth, setSelectedMonth] = useState<any>(
    moment().format("YYYY-MM")
  );
  const scrollableDivRef = useRef(null);

  useEffect(() => {
    if (!isEmpty(productReportSummary)) {
      if (productReportSummary[0]?.summary) {
        const data = productReportSummary[0]?.summary;
        const total = Object.keys(data)
          .map((summary: any) => data[summary].total)
          .reduce((prev: any, curr: any) => prev + curr, 0);
        setNetPrice(formatPrice(total));
      }
      const tableData = getData(productReportSummary[0]?.summary);
      setTableData(tableData);
    } else {
      setTableData([]);
    }
  }, [productReportSummary]);

  const getData = (data: any) => {
    const values = Object.keys(data).map((key: any) => {
      const product = find(
        products.products,
        (product: any) => product.pid === key
      );
      return {
        id: key,
        "PRODUCT TYPE": "",
        AMOUNT: data[key].total,
        QUANTITY: data[key].qty,
        "PRODUCT NAME": data[key].name,
        CATEGORY: get(product, "categoryResult.categoryName", ""),
      };
    });
    return values;
  };

  const getCurrentMonthDates = () => {
    const currentYear = parseInt(moment().format("yyyy"));
    const currentMonth = parseInt(moment().format("MM"));
    const firstDay = new Date(currentYear, currentMonth - 1, 1);
    const lastDay = new Date(currentYear, currentMonth, 0);
    return { firstDay, lastDay };
  };

  const getProductSummary = (range: any) => {
    const { startDate, endDate }: any = range;
    const shopId = selectedShop.shopId;
    const endMonth = moment(endDate).endOf("month").valueOf();
    const startMonth = moment(startDate).startOf("month").valueOf();
    fetchProductSummary(shopId, startMonth, endMonth);
  };

  useEffect(() => {
    const dates = getCurrentMonthDates();
    getProductSummary({
      startDate: dates.firstDay,
      endDate: dates.lastDay,
    });
  }, []);

  const handleMonthChange = (date: any, dateString: any) => {
    setSelectedMonth(date);
    const data = dateString.split("-");
    const startDate = new Date(data[0], data[1] - 1, 1);
    const endDate = new Date(data[0], data[1], 0);
    getProductSummary({
      startDate,
      endDate,
    });
  };

  const onChangeExport = async (option: any) => {
    if (option.value === "PDF") {
      const divToExport: any = scrollableDivRef.current;
      const canvas = await html2canvas(divToExport, {
        scrollX: 0,
        scrollY: -window.scrollY,
      });
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF("p", "mm");
      var position = 0;
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight - 16);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight - 16);
        heightLeft -= pageHeight;
      }
      doc.save(
        `${moment(
          get(productReportSummary, "[0].updatedTime", Date.now())
        ).format("MMM-DD-yyyy")}.pdf`
      );
    } else {
      let values: any = [];
      const summary = productReportSummary[0]?.summary;

      Object.keys(summary).map((key: any) => {
        const product = find(
          products.products,
          (product: any) => product.pid === key
        );
        values.push(["Product name", summary[key].name]);
        values.push(["Amount", summary[key].total]);
        values.push(["Quantity", summary[key].qty]);
        values.push([
          "Category",
          get(product, "categoryResult.categoryName", ""),
        ]);
        values.push([" "]);
      });

      const csvData = [...values];
      let csvContent =
        "data:text/csv;charset=utf-8," +
        csvData.map((data) => data.join(",")).join("\n");

      var link = window.document.createElement("a");
      link.setAttribute("href", encodeURI(csvContent));
      let title = moment().format("yyyy-MM-DD");
      link.setAttribute("download", `product-summary-${title}.csv`);
      link.click();
    }
  };

  const disabledDate = (current: any) => {
    const maxDate = moment();
    return current && current.isAfter(maxDate, "month");
  };

  return (
    <div className="w-full">
      <div className="w-full p-6">
        <div className="flex max-[624px]:flex-col flex-row max-[1024px]:gap-4 w-full justify-between mb-8">
          <div className="flex flex-row items-start">
            <div className="flex flex-col">
              <h5 className="font-inter font-bold leading-tight text-[31px] mb-[7px] dark-black">
                {netPrice}
              </h5>
              <div className="text-gray-6c text-[11px] font-semibold font-inter">
                Total summary
              </div>
            </div>
            <div className="flex flex-row gap-4 ml-[55px] relative h-[38px]">
              {/* <DatePicker
              picker="month"
              format="YYYY-MM"
              value={selectedMonth}
              disabledDate={disabledDate}
              onChange={handleMonthChange}
              // defaultValue={moment("2023-07", "YYYY-MM") as any}
            /> */}
              <DatePicker
                picker="month"
                defaultValue={dayjs()}
                disabledDate={disabledDate}
                onChange={handleMonthChange}
              />
            </div>
          </div>
          <div className="flex flex-row items-start">
            {!isEmpty(productReportSummary) && (
              <Select
                styles={customStyles}
                placeholder={"Export"}
                options={[
                  { label: "Export as PDF", value: "PDF" },
                  { label: "Export as CSV", value: "CSV" },
                ]}
                onChange={onChangeExport}
                className={
                  "block w-[165px] rounded-md outline-none text-sm text-valhalla"
                }
              />
            )}
          </div>
        </div>
        <Chart />
      </div>
      <div id="divToPrint" ref={scrollableDivRef} className="flex flex-row p-8">
        <Table
          {...{ tableHeader, tableData, isSortIcon: false }}
          onClickItem={(data: any, index: number) => {}}
        />
      </div>
    </div>
  );
};

export default connect(
  (state: any) => ({
    products: state.product.get("products"),
    selectedShop: state.dashboard.get("selectedShop"),
    productReportSummary: state.report.get("productReportSummary"),
  }),
  {
    fetchProductSummary: Actions.report.fetchProductReportSummaryForMonthRange,
  }
)(ProductView);
