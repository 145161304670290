import _, { orderBy } from "lodash";

export const mapStockItemText = (list) => {
  const textList = list?.map((item) => {
    const itemName =
      item?.itemData?.label + " " + item.qty + " " + item?.itemData?.metricValue;
    return itemName;
  });
  return textList.join(", ");
};

export const getStockTotalPrice = (list) => {
  let total = 0;
  list?.forEach((item) => {
    total = total + parseFloat(item.totalPrice);
  });
  return total;
};

export const mapStockItemLabel = (stocks) => {
  const result = stocks.map((stock) => ({
    label: stock.itemName,
    value: stock.stockId,
  }));
  return orderBy(result, 'label', 'asc')
};

export const mapStockItem = (stock) => {
  return {
    shopId: stock.shopId,
    metric: stock.metric,
    value: stock.itemName,
    label: stock.itemName,
    stockId: stock.stockId,
    itemName: stock.itemName,
    metricValue: stock.metricValue,
  };
};

export const mapStockRecord = (stock) => {
  const records = stock.stockRecords.map((record) => {
    return {
      ...record,
      qty: parseInt(record.qty),
      totalPrice: parseFloat(record.totalPrice),
      unitPrice: parseFloat(record.unitPrice),
    }
  })
  stock.stockRecords = records;
  return stock;
};
