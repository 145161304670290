import { ENCRYPTED_KEY } from '../config/security_key';
import CryptoJS from 'crypto-js';
import { get, isFunction } from "lodash";

export const getDecryptedCode = encrypted => {
  const bytes = CryptoJS.AES.decrypt(encrypted, ENCRYPTED_KEY);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

export const getEncryptedCode = secret => {
  const encrypted = CryptoJS.AES.encrypt(secret, ENCRYPTED_KEY).toString();
  return encrypted;
};

export const isNetworkError = (error) => {
  return (
    get(error, "errors.0.message") === "Network Error" ||
    get(error, "message") === "Network Error" ||
    error === "Error: Network Error" ||
    (isFunction(error.toJSON) && error.toJSON().message === "Network Error")
  );
};
