import { useEffect, useState } from "react";
import * as Yup from "yup";
import Modal from "react-modal";
import { nanoid } from "nanoid";
import Select from "react-select";
import "../../../layouts/Styles.css";
import { connect } from "react-redux";
import { find, isEmpty } from "lodash";
import { mapStockMetrics } from "../Constant";
import { AiOutlineClose } from "react-icons/ai";
import { Formik, useFormikContext } from "formik";
import { Actions } from "../../../internals/app/Actions";
import DeleteItem from "../../../components/Card/DeleteItem";
import FormInput from "../../../components/inputs/FormInput";

const customStyles: any = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    padding: 0,
    height: "auto",
    border: "none",
    overflow: "auto",
    maxWidth: "524px",
    background: "none",
    left: "calc(50vw - 262px)",
    WebkitOverflowScrolling: "touch",
  },
};

const selectStyles = {
  control: (base: any) => ({
    ...base,
    height: "100%",
    boxShadow: "none",
    color: "#F0F1FA",
    borderColor: "#F0F1FA",
    "&:hover": {
      borderColor: "#F0F1FA",
    },
  }),
  container: (base: any) => ({
    ...base,
  }),
};

type CategoryProps = {
  shop: any;
  stock?: any;
  isOpen: boolean;
  isEdit?: boolean;
  currentUser: any;
  closeModal: () => void;
  createStock: (params: any) => void;
};

const AddStockItem = (props: CategoryProps) => {
  const { shop, stock, isOpen, closeModal, currentUser, createStock } = props;

  const Schema = Yup.object({
    itemName: Yup.string()
      .min(1, "Must be 1 characters or more")
      .required("Please enter stock item name*"),
    metric: Yup.string().required("Please select stock metric"),
  });

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [stockInitials, setStockInitials] = useState<any>({
    itemName: "",
    metric: "",
  });

  useEffect(() => {
    setIsEdit(!isEmpty(stock));
    if (stock) {
      setStockInitials({ itemName: stock.itemName, metric: stock.metricValue });
    }
  }, [stock]);

  const onSubmit = (values: any, { resetForm }: any) => {
    const selectedMetric: any = find(
      mapStockMetrics,
      (metric: any) => metric.metricValue === values.metric
    );
    const param: any = {
      stockId: nanoid(8),
      shopId: shop.shopId,
      updatedTime: Date.now(),
      createdTime: Date.now(),
      itemName: values.itemName,
      metric: selectedMetric.value,
      createdUserId: currentUser.empId,
      metricValue: selectedMetric.metricValue,
    };
    if (isEdit) {
      param.stockId = stock.stockId;
      param.createdTime = stock.createdTime;
    }
    createStock(param);
    closeModal();
    resetForm();
  };

  const onStockDelete = () => {
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Category Modal"
      onRequestClose={() => closeModal()}
    >
      <div className="bg-white shadow rounded-lg">
        <div className="flex flex-row h-14 justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-t-lg">
          <h3>{isEdit ? "Edit Stock Item" : "Create Stock Item"}</h3>
          <button
            type="button"
            onClick={() => closeModal()}
            className="top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
          >
            <AiOutlineClose size={20} />
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <Formik
          onSubmit={onSubmit}
          validationSchema={Schema}
          initialValues={stockInitials}
        >
          {({ handleSubmit, isSubmitting, errors }) => {
            return (
              <form onSubmit={handleSubmit}>
                <RenderInput />
                <div className="flex flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
                  {isEdit ? (
                    <button
                      type="button"
                      onClick={() => setIsOpenDelete(true)}
                      className="inline-flex outline-none justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-red-700"
                    >
                      {"Delete"}
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => closeModal()}
                      className="inline-flex outline-none justify-center rounded-md border border-gray-300 bg-ghost-white-2 py-2 px-4 text-sm font-medium text-valhalla shadow hover:bg-ghost-white"
                    >
                      {"Close"}
                    </button>
                  )}
                  <button
                    type="submit"
                    className="disabled:opacity-25 inline-flex justify-center rounded-md border border-transparent bg-medium-slate-blue py-2 px-4 text-sm font-medium text-white shadow hover:bg-slate-blue"
                  >
                    {isEdit ? "Update Stock Item" : "Add Stock Item"}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
        {isOpenDelete ? (
          <div className="absolute top-1/4 inset-14 w-full max-w-lg z-50 justify-center items-center">
            <DeleteItem
              onDelete={() => onStockDelete()}
              closeModal={() => setIsOpenDelete(false)}
              title="Are you sure you want to delete this item?"
            />
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default connect(
  (state: any) => ({
    shop: state.dashboard.get("selectedShop"),
    currentUser: state.setting.get("currentUser"),
  }),
  {
    createStock: Actions.stock.createStock,
  }
)(AddStockItem);

const RenderInput = () => {
  const { values, errors, touched, handleChange, setFieldValue, submitCount } =
    useFormikContext<{
      itemName: string;
      metric: string;
    }>();

  return (
    <div className="h-auto bg-white px-4 py-5 sm:p-6 rounded-lg">
      <div className="grid grid-cols-6 gap-6 mb-6">
        <div className="col-span-6">
          <FormInput
            id="itemName"
            label="Stock Item Name"
            error={errors.itemName}
            value={values.itemName}
            touched={touched.itemName}
            placeholder="Stock Item Name"
            onChange={handleChange("itemName")}
          />
        </div>
        <div className="col-span-6">
          <label
            htmlFor={"stockMetric"}
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            {"Stock Metric"}
          </label>
          <Select
            styles={selectStyles}
            options={mapStockMetrics}
            placeholder={"Stock Metric"}
            className={
              "block w-full h-10 shadow rounded-lg outline-none text-sm text-ship-cove border-gray-300"
            }
            onChange={(option: any) =>
              setFieldValue("metric", option.metricValue)
            }
          />
          {!isEmpty(errors?.metric) && submitCount > 0 && (
            <span className="mb-1 text-xs font-normal text-rose-400 mr-10">
              {errors?.metric}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
