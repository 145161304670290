import { get } from "lodash";
import Repository from "./Repository";
import { graphqlOperation } from "@aws-amplify/api";
import { createQrTable, GetTableAreas } from "./schema/Table.schema";
import { isNetworkError } from "../managers/SecurityManager";

class TableRepository extends Repository {
  async fetchTables(shopId: string, retryCount: number = 1) {
    try {
      const response: any = await this.API.graphql(
        graphqlOperation(GetTableAreas, { shopId })
      );
      const tables: any = get(response, "data.getTableAreas", {});
      return tables;
    } catch (error) {
      console.warn("fetch tables", error);
      if (isNetworkError(error) && retryCount <= 3) {
        await this.fetchTables(shopId, ++retryCount);
      }
      return { error };
    }
  }

  async fetchQRTables(shopId: string) {
    const message = this.buildMessage({ shopId });
    try {
      const result: any = await this.apiPost({
        apiName: this.API_ADMIN_DASHBOARD,
        path: "/shop/qr",
        message,
      });
      return result.data.result;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async createQRForTable(input: any) {
    input["createdTime"] = Date.now();
    input["updatedTime"] = Date.now();
    try {
      const result: any = await this.API.graphql(
        graphqlOperation(createQrTable, { input })
      );
      return result.data.createQrTable;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }
}

export default new TableRepository();
