import Modal from "react-modal";
import { connect } from "react-redux";
import { AiOutlineWarning } from "react-icons/ai";

const customStyles: any = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    padding: 0,
    border: "none",
    maxWidth: "448px",
    position: "absolute",
    backgroundColor: "unset",
    top: "calc(50vh - 20rem)",
    left: "calc(50vw - 14rem)",
  },
};

const WarningAlert = (props: any) => {
  const { alertStatus, onCloseAlert } = props;
  const { isOpenWarningAlert, message } = alertStatus;

  return (
    <Modal
      style={customStyles}
      isOpen={isOpenWarningAlert}
      contentLabel="Category Modal"
      onRequestClose={() => onCloseAlert()}
    >
      <div className="flex flex-col relative py-6 text-center bg-white rounded-lg shadow items-center justify-center">
        <AiOutlineWarning size={50} style={{ color: "#FF3333" }} />
        <p className="mt-5 text-base text-red-orange">{"Error"}</p>
        <p className="mt-2 text-sm text-center text-valhalla">{message}</p>
        <button
          type="button"
          onClick={() => onCloseAlert()}
          className="mt-5 w-[164px] text-white bg-red-orange font-medium rounded-lg text-sm py-2.5"
        >
          {"Ok"}
        </button>
      </div>
    </Modal>
  );
};

export default connect((state: any) => ({}), {})(WarningAlert);
