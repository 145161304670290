import ReportRepository from "../../internals/repository/ReportRepository";

export const ModuleEvents = {
  FETCH_SUMMARY_FOR_MONTH: "FETCH_SUMMARY_FOR_MONTH",
  FETCH_SUMMARY_FOR_YEAR_RANGE: "FETCH_SUMMARY_FOR_YEAR_RANGE",
  FETCH_SUMMARY_FOR_DATE_RANGE: "FETCH_SUMMARY_FOR_DATE_RANGE",
  FETCH_SUMMARY_FOR_MONTH_RANGE: "FETCH_SUMMARY_FOR_MONTH_RANGE",
  FETCH_PRODUCT_REPORT_SUMMARY_FOR_MONTH_RANGE: "FETCH_PRODUCT_REPORT_SUMMARY_FOR_MONTH_RANGE"
};

const createAction =
  (type, action = () => { }, meta) =>
    (...args) => ({
      type,
      payload: action(...args),
      meta,
    });

const fetchSummaryForMonthRange = createAction(
  ModuleEvents.FETCH_SUMMARY_FOR_MONTH_RANGE, async (shopId, startDate, endDate) => {
    const result = await ReportRepository.fetchSummaryForMonthRange(shopId, startDate, endDate);
    return result;
  }
);

const fetchSummaryForMonth = createAction(
  ModuleEvents.FETCH_SUMMARY_FOR_MONTH, async (shopId, reportMonth) => {
    const result = await ReportRepository.fetchSummaryForMonth(shopId, reportMonth);
    return result;
  }
);

const filterSummaryForDateRange = createAction(ModuleEvents.FETCH_SUMMARY_FOR_DATE_RANGE, async (param) => {
  const result = await ReportRepository.filterSummaryForDateRange(param);
  return result;
}
);

const fetchSummaryForYearRange = createAction(
  ModuleEvents.FETCH_SUMMARY_FOR_YEAR_RANGE, async (shopId, startDate, endDate) => {
    const result = await ReportRepository.fetchSummaryForMonthRange(shopId, startDate, endDate);
    return result;
  }
);


const fetchProductReportSummaryForMonthRange = createAction(
  ModuleEvents.FETCH_PRODUCT_REPORT_SUMMARY_FOR_MONTH_RANGE, async (shopId, startDate, endDate) => {
    const result = await ReportRepository.fetchProductReportSummaryForMonthRange(shopId, startDate, endDate);
    return result;
  }
);

const exportedFuction = {
  fetchSummaryForMonth,
  fetchSummaryForYearRange,
  filterSummaryForDateRange,
  fetchSummaryForMonthRange,
  fetchProductReportSummaryForMonthRange,
};

export default exportedFuction;
