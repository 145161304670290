import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { find, orderBy, get } from "lodash";
import Table from "../../../components/Table";
import Alert from "../../../components/Alert";
import Edit from "../../../assets/svgs/edit.svg";
import Delete from "../../../assets/svgs/delete.svg";
import DeleteItem from "../../../components/DeleteItem";
import { Actions } from "../../../internals/app/Actions";
import AddCategory from "../../../components/AddCategory";
import CategoryHeader from "../../../components/CategoryHeader";

const CategoryView = (props: any) => {
  const { categories, alertStatus, openAlertBox, deleteCategory } = props;
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [isOpenCategory, setIsOpenCategory] = useState(false);
  const [tableHeader, setTableHeader] = useState<Array<any>>([]);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<any>({});

  useEffect(() => {
    if (categories) {
      const headers = getHeader();
      const tableData = getData(categories);
      setTableHeader(headers);
      setTableData(tableData);
    }
  }, [categories]);

  const getHeader = () => {
    return [
      "CATEGORY NAME",
      "CATEGORY DESCRIPTION",
      "TOTAL PRODUCT",
      "ACTIONS",
    ];
  };

  const getData = (data: any) => {
    const tableData = data.map((item: any) => {
      return {
        id: item.categoryId,
        TIME: item.updatedTime,
        "CATEGORY NAME": item.categoryName,
        "CATEGORY DESCRIPTION": item.description,
        "TOTAL PRODUCT": get(item, "productCount", 0),
        ACTIONS: [Edit, Delete],
      };
    });
    return orderBy(tableData, ["TIME"], ["desc"]);
  };

  const toggleCategory = () => {
    setIsOpenCategory(!isOpenCategory);
  };

  const toggleDelete = () => {
    setIsOpenDelete(!isOpenDelete);
  };

  const onClickItem = (data: any, index: number) => {
    const category = find(categories, (item) => item.categoryId === data.id);
    setSelectedCategory(category);
    if (index === 0) {
      toggleCategory();
    } else if (index === 1) {
      toggleDelete();
    }
  };

  const onDeleteCategory = (data: any) => {
    deleteCategory(data.categoryId, data.shopId);
    toggleDelete();
  };

  return (
    <div className="flex flex-col w-full p-6 justify-start">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="pb-6">
        <CategoryHeader />
      </div>
      <Table
        {...{ tableHeader, tableData }}
        onClickItem={(data: any, index: number) => onClickItem(data, index)}
      />
      <AddCategory
        isOpen={isOpenCategory}
        category={selectedCategory}
        closeModal={() => toggleCategory()}
      />
      <DeleteItem
        isOpen={isOpenDelete}
        closeModal={() => toggleDelete()}
        onDelete={() => onDeleteCategory(selectedCategory)}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    categories: state.product.get("categories"),
    alertStatus: state.common.get("alertStatus"),
  }),
  {
    openAlertBox: Actions.common.openAlertBox,
    deleteCategory: Actions.product.deleteCategory,
  }
)(CategoryView);
