import _, { get } from "lodash";
import { Map } from "immutable";
import { ModuleEvents } from "./Actions";

const initialState = Map({
  userData: {},
  email: "",
  navigationType: {},
  loginUserDetails: {},
  password: "",
  newUser: {},
  signupUserId: "",
  signupUser: {},
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.LOG_OUT: {
      return initialState;
    }
    case ModuleEvents.LOGIN: {
      if (payload?.error && payload?.username) {
        return state.set('email', payload.username)
          .set("password", payload.password)
          ;
      }
      return state;
    }
    case ModuleEvents.SIGNUP: {
      const { password, params, result } = payload;
      if (result) {
        const user = state.get("newUser");
        let userData;
        if (get(result, "userSub")) {
          userData = {
            id: result.userSub,
            email: params.email,
          };
        }
        return state
          .set("email", params.email)
          .set("password", password)
          .set("newUser", { ...user, ...userData });
      } else {
        return state.set("email", params.email).set("password", password);
      }
    }

    case ModuleEvents.NAVIGATE_OTP_VIEW: {
      return state.set("navigationType", payload);
    }

    case ModuleEvents.CONFIRM_SIGN_UP: {
      if (payload && payload.userId) {
        return state.set("signupUserId", payload.userId);
      }
      return state;
    }

    case ModuleEvents.SIGNUP_USER: {
      return state.set("signupUser", payload);
    }

    case ModuleEvents.FORGET_PASSWORD: {
      const { data, email } = payload;
      if (data && !data.error && data.code !== "UserNotFoundException") {
        return state.set("email", email);
      }
      return state;
    }

    case ModuleEvents.FORGET_PASSWORD_SUBMIT: {
      if (payload && payload.code === "CodeMismatchException") {
        const value = {
          type: "",
          error: "CodeMismatchException",
        };
        return state.set("navigationType", value);
      }
      return state;
    }

    default:
      return state;
  }
};

export default Reducer;
