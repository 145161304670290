import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { find, truncate } from "lodash";
import AddModifier from "./AddModifier";
import Alert from "../../../components/Alert";
import Table from "../../../components/Table";
import Edit from "../../../assets/svgs/edit.svg";
import Delete from "../../../assets/svgs/delete.svg";
import DeleteItem from "../../../components/DeleteItem";
import { Actions } from "../../../internals/app/Actions";
import ComponentHeader from "../../../components/ComponentHeader";

const tableHeader = [
  "MODIFIER TITLE",
  "ITEMS",
  "CATEGORIES",
  "TOTAL PRODUCTS	",
  "ACTIONS",
];

const ModifierView = (props: any) => {
  const { alertStatus, openAlertBox, modifiers, deleteModifier } = props;
  const [modifier, setModifier] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    if (modifiers) {
      const tableData = getData(modifiers);
      setTableData(tableData);
    }
  }, [modifiers]);

  const getData = (data: any) => {
    return data.map((item: any) => {
      const items = truncate(
        item?.items.map((values: any) => values.itemName).join(", "),
        { length: 35 }
      );
      return {
        id: item.id,
        ITEMS: items,
        CATEGORIES: "",
        "TOTAL PRODUCTS": "",
        ACTIONS: [Edit, Delete],
        "MODIFIER TITLE": item.title,
      };
    });
  };

  const onClickItem = (data: any, index: number) => {
    const selectedItem = find(modifiers, (item) => item.id === data.id);
    setModifier(selectedItem);
    if (index === 0) {
      toggleModifier();
    } else if (index === 1) {
      toggleDelete();
    }
  };

  const toggleDelete = () => {
    setIsOpenDelete(!isOpenDelete);
  };

  const toggleModifier = () => {
    setIsOpen(!isOpen);
  };

  const onDeleteClient = (data: any) => {
    deleteModifier(data.id, data.shopId);
    toggleDelete();
  };

  return (
    <div className="flex flex-col w-full p-6 justify-start">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="pb-6">
        <ComponentHeader
          createTitle={"Add Modifiers"}
          openCreate={() => {
            setModifier(null);
            toggleModifier();
          }}
        />
      </div>
      <Table {...{ tableHeader, tableData }} onClickItem={onClickItem} />
      <AddModifier
        isOpen={isOpen}
        modifier={modifier}
        closeModal={() => toggleModifier()}
      />
      <DeleteItem
        isOpen={isOpenDelete}
        closeModal={() => toggleDelete()}
        onDelete={() => onDeleteClient(modifier)}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    alertStatus: state.common.get("alertStatus"),
    modifiers: state.modifier.get("modifiers"),
  }),
  {
    openAlertBox: Actions.common.openAlertBox,
    deleteModifier: Actions.modifier.deleteModifier,
  }
)(ModifierView);
