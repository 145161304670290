import { useState } from "react";
import { connect } from "react-redux";
import OtpInput from "react-otp-input";
import Logo from "../../../assets/svgs/logo.svg";
import { CircularProgress } from "@material-ui/core";
import { Actions } from "../../../internals/app/Actions";

const OtpView = (props: any) => {
  const { confirmSignUp, email, navigationType, loadingAction } = props;
  const [otp, setOtp] = useState("");
  const { type, error } = navigationType;
  const { action, loading } = loadingAction;
  const isCodeMismatch = error === "CodeMismatchException";

  const handleChange = (otp: any) => setOtp(otp);

  const onClickSubmit = () => {
    const params = {
      email,
      code: otp,
    };
    confirmSignUp(params);
  };

  const loadingSignup =
    loading && (action.type === "CONFIRM_SIGN_UP" || action.type === "LOGIN");

  return (
    <section className=" bg-gray-50 flex flex-row">
      <div className="max-w-5xl w-full h-full flex flex-col items-center justify-center p-6 mx-auto md:h-screen lg:py-0">
        <div className="flex items-center justify-center mb-6 text-4xl font-semibold text-gray-900 ">
          <img src={Logo} alt="logo" className="w-20 h-20 mr-6" />
          Netlise
        </div>
        <div className="flex flex-row w-full bg-white rounded-lg shadow md:mt-0  xl:p-0">
          <div className="flex-col w-1/2 hidden md:block items-center justify-center h-full">
            <img
              alt=""
              className="h-full rounded-l-lg"
              src="/images/shopping-mall.jpg"
            />
          </div>
          <div className="p-6 md:w-1/2 w-full space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-midnight-express md:text-2xl">
              Sign up with Netlise
            </h1>

            <div className="h-4/5 flex flex-col justify-between">
              <div>
                <label
                  htmlFor="otp-number"
                  className="mb-6 block text-sm font-medium text-gray-700"
                >
                  A passcode has been sent to {email}. Please enter it here to verify your email.
                </label>
                <div className="w-full flex flex-row justify-between">
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    containerStyle={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                    isInputNum={true}
                    inputStyle={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: "#FFFFFF",
                      boxShadow: "0 3px 10px rgb(197 197 242 / 0.5)",
                      border: isCodeMismatch ? "1px solid #FD4B4B" : "none",
                      borderRadius: "10px",
                    }}
                    separator={<span style={{ marginLeft: "8px" }} />}
                  />
                </div>
              </div>
              <div>
                <button
                  disabled={loadingSignup}
                  onClick={() => onClickSubmit()}
                  className="w-full mt-28 text-white bg-medium-slate-blue hover:bg-primary-700 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  {loadingSignup ? (
                    <CircularProgress size={15} style={{ color: "#EFF0F8" }} />
                  ) : (
                    "Confirm sign up"
                  )}
                </button>
                <p className="mt-2 text-sm font-light text-gray-500 ">
                  Already have an account?{" "}
                  <a
                    href="signin"
                    className="font-medium text-medium-slate-blue hover:underline "
                  >
                    Login here
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default connect(
  (state: any) => ({
    email: state.login.get("email"),
    loadingAction: state.common.get("loadingAction"),
    navigationType: state.login.get("navigationType"),
  }),
  {
    confirmSignUp: Actions.login.confirmSignUp,
  }
)(OtpView);
