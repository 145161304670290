import { Map } from "immutable";
import { ModuleEvents } from "./Actions";

const initialState = Map({
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.FETCH_ORDER_TRANSACTIONS: {
      if (payload && !payload.error) {
      }
      return state;
    }

    default:
      return state;
  }
};

export default Reducer;
