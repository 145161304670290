import gql from 'graphql-tag';

export const CreateBrand = gql`
  mutation CreateBrand($input: CreateBrandInput!) {
    createBrand(input: $input) {
      id
      brandName
      shopId
      description
      createdTime
      createdUserId
      updatedTime
    }
  }
`;

export const UpdateBrand = gql`
  mutation UpdateBrand($input: UpdateBrandInput!) {
    updateBrand(input: $input) {
      id
      brandName
      shopId
      description
      createdTime
      createdUserId
      updatedTime
    }
  }
`;

export const DeleteBrand = gql`
  mutation DeleteBrand($input: DeleteBrandInput!) {
    deleteBrand(input: $input) {
      id
      shopId
    }
  }
`;

export const FetchProductTypes = gql`
  query FetchProductTypes($shopId: String!) {
    fetchProductTypes(shopId: $shopId) {
      shopId
      id
      typeName
      description
      updatedTime
      createdTime
      createdUserId
    }
  }
`;

export const CreateProductType = gql`
  mutation CreateProductType($input: CreateProductTypeInput!) {
    createProductType(input: $input) {
      shopId
      id
      typeName
      description
      updatedTime
      createdTime
      createdUserId
    }
  }
`;

export const UpdateProductType = gql`
  mutation UpdateProductType($input: UpdateProductTypeInput!) {
    updateProductType(input: $input) {
      shopId
      id
      typeName
      description
      updatedTime
      createdTime
      createdUserId
    }
  }
`;

export const DeleteProductType = gql`
  mutation DeleteProductType($input: DeleteProductTypeInput!) {
    deleteProductType(input: $input) {
      shopId
      id
      typeName
      description
      updatedTime
      createdTime
      createdUserId
    }
  }
`;

export const CreateOption = gql`
  mutation CreateOption($input: CreateProductOptionSetInput!) {
    createProductOptionSet(input: $input) {
      id
      shopId
      setName
      setDisplayName
      createdTime
      updatedTime
      options {
        id
        optionName
      }
    }
  }
`;

export const UpdateOption = gql`
  mutation UpdateOption($input: UpdateProductOptionSetInput!) {
    updateProductOptionSet(input: $input) {
      id
      shopId
      setName
      setDisplayName
      createdTime
      updatedTime
      options {
        id
        optionName
      }
    }
  }
`;

export const DeleteOption = gql`
  mutation DeleteOption($input: DeleteProductOptionSetInput!) {
    deleteProductOptionSet(input: $input) {
      id
      shopId
    }
  }
`;

export const fetchOptionSets = gql`
  query FetchOptionSets($shopId: String!, $limit: Int, $nextToken: String) {
    fetchProductOptionSets(shopId: $shopId, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopId
        setName
        createdTime
        updatedTime
        setDisplayName
        options {
          id
          optionName
        }
      }
      nextToken
    }
  }
`;

export const CreateOffer = gql`
  mutation CreateOffer($input: CreateOfferInput!) {
    createOffer(input: $input) {
    	id
      shopId
      title
      displayTitle
      isEveryDay
      createdTime
      updatedTime
      timePeriod {
        startTime
        endTime
      }
      timePeriods {
        date
        startTime
        endTime
      }
      offerItems {
        pid
        name
        type
        quantity
        discount {
          amount
          amountType
        }
        products {
          pid
          name
          quantity
        }
      }
    }
  }
`;

export const UpdateOffer = gql`
  mutation UpdateOffer($input: UpdateOfferInput!) {
    updateOffer(input: $input) {
      id
      shopId
      title
      displayTitle
      isEveryDay
      createdTime
      updatedTime
      timePeriod {
        startTime
        endTime
      }
      timePeriods {
        date
        startTime
        endTime
      }
      offerItems {
        pid
        name
        type
        quantity
        discount {
          amount
          amountType
        }
        products {
          pid
          name
          quantity
        }
      }
    }
  }
`;

export const DeleteOffer = gql`
  mutation DeleteOffer($input: DeleteOfferInput!) {
    deleteOffer(input: $input) {
      id
      shopId
    }
  }
`;

export const FetchOffers = gql`
  query FetchOffers($shopId: String!, $limit: Int, $nextToken: String) {
    fetchOffers(shopId: $shopId, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopId
        title
        displayTitle
        isEveryDay
        createdTime
        updatedTime
        timePeriod {
          startTime
          endTime
        }
        timePeriods {
          date
          startTime
          endTime
        }
        offerItems {
          pid
          name
          type
          quantity
          discount {
            amount
            amountType
          }
          products {
            pid
            name
            quantity
          }
        }
      }
      nextToken
    }
  }
`;
