import { useState } from "react";
import { nanoid } from "nanoid";
import QRCode from "react-qr-code";
import { connect } from "react-redux";
import html2canvas from "html2canvas";
import { findIndex, filter } from "lodash";
import ReactDOMServer from "react-dom/server";
import { isEmpty, find, truncate } from "lodash";
import Icon from "../../../assets/svgs/plus-icon.svg";
import GenerateQR from "../../../components/GenerateQR";
import { Actions } from "../../../internals/app/Actions";
import SelectIcon from "../../../assets/svgs/select-icon.svg";
import QRBorder from "../../../assets/svgs/qr-border-icon.svg";
import UnselectIcon from "../../../assets/svgs/unselect-icon.svg";
import QRDownloadModal from "../../../components/Modal/QRDownloadModal";

const QRCodesView = (props: any) => {
  const { selectedShop, tableAreas, createQRForTable, QRtables } = props;
  let [tableArea, setTableArea] = useState<any>({});
  let [tableDetails, setTableDetails] = useState<any>({});
  const [selectedArea, setSelectedArea] = useState<any>({});
  let [selectedQR, setSelectedQR] = useState<Array<any>>([]);
  const [isDownload, setIsDownload] = useState<boolean>(false);
  const [openQRModal, setOpenQRModal] = useState<boolean>(false);
  const [openQRDownloadModal, setOpenQRDownloadModal] =
    useState<boolean>(false);

  const toggleGenerateQR = (area: any = {}) => {
    setSelectedArea(area);
    setOpenQRModal(!openQRModal);
  };

  const generateQR = (table: any) => {
    const params = {
      tblId: table.tbId,
      tblNo: table.tbNumber,
      shopId: selectedShop.shopId,
      qrId: `${selectedShop.shortCode}/${nanoid(7)}`,
    };
    createQRForTable(params);
    toggleGenerateQR();
  };

  const downloadQRs = async () => {
    let str = "";
    if (isEmpty(selectedQR)) {
      return;
    }
    selectedQR.forEach(async (url: any) => {
      const splitData = url.split("/");
      const isTable = splitData.length > 4;
      const id = isTable
        ? `${splitData[3]}/${splitData[4]}`
        : `${splitData[3]}`;

      const findQR = find(QRtables, (item: any) => item?.qrId === id);
      str += ReactDOMServer.renderToString(
        <div
          id={id}
          className="flex flex-col justify-center items-center bg-white py-10 px-16"
        >
          <div className="pb-9 text-speech-blue text-xl	font-semibold">
            {selectedShop.shopName}
          </div>
          <div className="relative p-2	w-[218px] h-[218px]">
            <QRCode
              size={400}
              value={url}
              viewBox={"0 0 256 256"}
              style={{
                height: "auto",
                width: "100%",
              }}
            />
            <img src={QRBorder} className="absolute top-0 left-0" />
          </div>
          <div className="pt-9 text-speech-blue text-xl	font-semibold">
            {isEmpty(findQR) ? "Main Menu" : findQR.tblNo}
          </div>
          <div className="pt-4 text-speech-blue text-xs	font-light">
            by netlise.com
          </div>
        </div>
      );
    });
    const wrapper = document.createElement("div");
    wrapper.innerHTML = str;
    wrapper.style.cssText =
      "display:flex;flex-direction:row;flex-wrap:wrap;justify-content:start;align-items:center;padding:24px";
    document.body.appendChild(wrapper);
    html2canvas(wrapper).then((canvas) => {
      const link = document.createElement("a");
      link.download = `${selectedShop?.shortCode}.png`;
      link.href = canvas.toDataURL("image/png");
      wrapper.style.display = "none";
      link.click();
    });
    setSelectedQR([]);
    setIsDownload(false);
  };

  const onSelectAndDownload = () => {
    if (isDownload) {
      return downloadQRs();
    }
    setSelectedQR([]);
    setIsDownload(!isDownload);
  };

  const onSelectQR = (selectedUrl: string) => {
    const index = findIndex(selectedQR, (url) => url === selectedUrl);
    if (index > -1) {
      selectedQR = filter(selectedQR, (url) => url != selectedUrl);
    } else {
      selectedQR.push(selectedUrl);
    }
    setSelectedQR([...selectedQR]);
  };

  const renderQRSelect = (
    selectedurl: string,
    area: any = {},
    table: any = {}
  ) => {
    const isSelected = findIndex(selectedQR, (url) => url === selectedurl) > -1;
    return (
      <>
        {isSelected ? (
          <img
            alt="actions"
            src={SelectIcon}
            onClick={(e) => {
              e.preventDefault();
              onSelectQR(selectedurl);
              setTableArea({});
              setTableDetails({});
            }}
            className="w-[18px] h-[18px] bg-transparent absolute bottom-2 left-2 rounded-[3px]"
          />
        ) : (
          <img
            alt="actions"
            src={UnselectIcon}
            onClick={(e) => {
              e.preventDefault();
              onSelectQR(selectedurl);
              setTableArea(area);
              setTableDetails(table);
            }}
            className="w-[18px] h-[18px] bg-transparent absolute bottom-2 left-2 rounded-[3px]"
          />
        )}
      </>
    );
  };

  const toggleQRModal = (area: any = {}, item: any = {}) => {
    setTableArea(area);
    setTableDetails(item);
    setOpenQRDownloadModal(!openQRDownloadModal);
  };

  const onCloseDownload = () => {
    setSelectedQR([]);
    setIsDownload(false);
  };

  const menuUrl = `https://menu.netlise.com/${selectedShop.shortCode}`;

  return (
    <div className="flex flex-col w-full h-full justify-start">
      <div className="h-full grid gap-8">
        <div className="flex flex-row gap-6">
          <button
            type="button"
            className="inline-flex justify-center rounded-lg border border-transparent bg-medium-slate-blue py-2 px-8 text-sm font-medium text-white shadow hover:bg-slate-blue focus:outline-none"
            onClick={() => onSelectAndDownload()}
          >
            {isDownload ? "Download QRs" : "Select and Download QRs"}
          </button>
          {isDownload && (
            <button
              type="button"
              onClick={() => onCloseDownload()}
              className="inline-flex outline-none justify-center rounded-lg border border-gray-300 bg-ghost-white-2 py-2 px-8 text-sm font-medium text-valhalla shadow hover:bg-ghost-white"
            >
              {"Close"}
            </button>
          )}
        </div>

        <div>
          <h3
            className={`cursor-pointer text-md font-semibold text-midnight-express`}
          >
            {"Main Menu"}
          </h3>
          <div className="mt-4 border relative border-genteel-lavender p-2 rounded-lg	w-[120px] h-[120px]">
            <QRCode
              size={256}
              value={menuUrl}
              viewBox={`0 0 256 256`}
              style={{ height: "auto", width: "100%" }}
              onClick={() => {
                if (!isDownload) {
                  toggleQRModal({ areaName: "Main Menu" });
                }
              }}
            />
            {isDownload && renderQRSelect(menuUrl, { areaName: "Main Menu" })}
            <div className="mt-2">
              <a
                href={menuUrl}
                target="_blank"
                rel="noreferrer"
                className="text-xs underline text-theme_primary"
              >
                {truncate(menuUrl, { length: 20 })}
              </a>
            </div>
          </div>
        </div>
        {!isEmpty(tableAreas)
          ? tableAreas?.tableAreas.map((table: any) => {
              let data: any = [];
              let availableQR: boolean = false;
              table.tables.map(({ tbId }: any) => {
                const cretedQr = find(QRtables, (qr) => qr.tblId === tbId);
                if (!isEmpty(cretedQr)) {
                  data.push(cretedQr);
                  availableQR = true;
                }
              });
              const isAreaQrCompleted = table.tables.length === data.length;

              return (
                <div>
                  <h3
                    className={`cursor-pointer text-md font-semibold text-midnight-express`}
                  >
                    {table.areaName}
                  </h3>
                  <div className="mt-4 flex flex-row gap-3">
                    {availableQR && !isEmpty(table.tables)
                      ? table.tables.map((data: any) => {
                          const QRtable = find(
                            QRtables,
                            (qr) => qr.tblId === data.tbId
                          );
                          const tableUrl = `https://menu.netlise.com/${QRtable?.qrId}`;
                          return (
                            <>
                              {!isEmpty(QRtable) && (
                                <div className="flex flex-col w-[120px]">
                                  <div className="border relative border-genteel-lavender p-2 rounded-lg	w-[120px] h-[120px]">
                                    <QRCode
                                      size={256}
                                      value={tableUrl}
                                      viewBox={`0 0 256 256`}
                                      style={{ height: "auto", width: "100%" }}
                                      onClick={() => {
                                        if (!isDownload) {
                                          toggleQRModal(table, QRtable);
                                        }
                                      }}
                                    />
                                    {isDownload &&
                                      renderQRSelect(tableUrl, table, QRtable)}
                                  </div>
                                  <div
                                    className={`mt-3 cursor-pointer text-sm font-normal text-midnight-express text-center`}
                                  >
                                    {data.tbNumber}
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })
                      : null}
                    {!isAreaQrCompleted && (
                      <div
                        onClick={() => toggleGenerateQR(table)}
                        className="flex cursor-pointer border border-genteel-lavender p-2 rounded-lg	w-[120px] h-[120px] items-center justify-center"
                      >
                        <img src={Icon} />
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          : null}
      </div>
      {!isEmpty(selectedArea) && (
        <GenerateQR
          isOpen={openQRModal}
          selectedArea={selectedArea}
          closeModal={() => toggleGenerateQR()}
          generateQR={(table) => generateQR(table)}
        />
      )}
      <QRDownloadModal
        tableArea={tableArea}
        tableData={tableDetails}
        isOpen={openQRDownloadModal}
        closeModal={() => toggleQRModal()}
      />
    </div>
  );
};
export default connect(
  (state: any) => ({
    QRtables: state.table.get("QRtables"),
    tableAreas: state.table.get("tableAreas"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    createQRForTable: Actions.table.createQRForTable,
  }
)(QRCodesView);
