import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Modal from "react-modal";
import { Formik } from "formik";
import { nanoid } from "nanoid";
import OfferForm from "./OfferForm";
import { connect } from "react-redux";
import { isEmpty, omit } from "lodash";
import DeleteItem from "../DeleteItem";
import OfferItemForm from "./OfferItemForm";
import CreateModalHeader from "../CreateModalHeader";
import { Actions } from "../../internals/app/Actions";

const customStyles: any = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    padding: 0,
    border: "none",
    overflow: "auto",
    maxWidth: "624px",
    background: "none",
    left: "calc(50vw - 312px)",
    WebkitOverflowScrolling: "touch",
  },
};

const Schema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Must be 2 characters or more")
    .required("Please enter offer title*"),
  displayTitle: Yup.string()
    .min(2, "Must be 2 characters or more")
    .required("Please enter offer display title*"),
  isEveryDay: Yup.boolean(),
  timePeriod: Yup.object().shape({
    endTime: Yup.string()
      .min(1, "Must be 1 characters or more")
      .required("Please enter offer end time*"),
    startTime: Yup.string()
      .min(1, "Must be 1 characters or more")
      .required("Please enter offer start time*"),
  }),
  timePeriods: Yup.array().of(
    Yup.object().shape({
      date: Yup.string()
        .min(1, "Must be 1 characters or more")
        .required("Please enter offer date*"),
      endTime: Yup.string()
        .min(1, "Must be 1 characters or more")
        .required("Please enter offer end time*"),
      startTime: Yup.string()
        .min(1, "Must be 1 characters or more")
        .required("Please enter offer start time*"),
    })
  ),
  offerItems: Yup.array()
    .min(1, "Please select offer product*")
    .required("Please select offer product*"),
});

const AddOffer = (props: any) => {
  const {
    offer,
    isOpen,
    closeModal,
    updateOffer,
    selectedShop,
    createOffer,
    deleteOffer,
  } = props;

  const initials = {
    title: "",
    displayTitle: "",
    isEveryDay: false,
    timePeriods: [
      {
        date: "",
        endTime: "",
        startTime: "",
      },
    ],
    offerItems: [],
  };

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedOffer, setSelectedOffer] = useState<any>({});
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>(initials);
  const [openItemModal, setOpenItemModal] = useState<boolean>(false);
  const [validationSchema, setValidationSchema] = useState<any>(Schema);

  useEffect(() => {
    const isValid = !isEmpty(offer);
    setIsEdit(isValid);
    if (isValid) {
      if (offer.isEveryDay) {
        const updatedSchema = Schema.omit(["timePeriods"]);
        setValidationSchema(updatedSchema);
      } else {
        const updatedSchema = Schema.omit(["timePeriod"]);
        setValidationSchema(updatedSchema);
      }
      setSelectedOffer(offer);
    } else {
      setInitialValues(initials);
    }
  }, [offer]);

  const toggleDelete = () => {
    setIsOpenDelete(!isOpenDelete);
  };

  const toggleOfferItem = () => {
    setOpenItemModal(!openItemModal);
  };

  const addOfferItem = (index: number) => {
    toggleOfferItem();
  };

  const onCloseModal = () => {
    if (openItemModal) {
      setOpenItemModal(!openItemModal);
    } else {
      closeModal();
    }
  };

  const headerTitle = openItemModal
    ? isEdit
      ? "Edit new offer item"
      : "Add new offer item"
    : isEdit
    ? "Edit Offers"
    : "Add Offers";

  const submitButton = openItemModal
    ? isEdit
      ? "Edit offer item"
      : "Add offer item"
    : isEdit
    ? "Update Offer"
    : "Add Offer";

  const onSubmitOffer = (values: any, { resetForm }: any) => {
    const params = {
      ...values,
      shopId: selectedShop.shopId,
    };
    if (isEdit) {
      params["id"] = offer.id;
      params["createdTime"] = offer.createdTime;
      updateOffer(params);
    } else {
      params["id"] = nanoid();
      createOffer(params);
    }
    resetForm();
    closeModal();
  };

  const onDeleteProduct = (data: any) => {
    deleteOffer(data.shopId, data.id);
    toggleDelete();
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Reward Modal"
      onRequestClose={() => closeModal()}
    >
      <div className="bg-white shadow rounded-lg">
        <CreateModalHeader
          title={headerTitle}
          closeModal={() => onCloseModal()}
        />
        {
          <Formik
            onSubmit={onSubmitOffer}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              handleSubmit,
              setFieldValue,
              setFieldError,
            }) => {
              return (
                <>
                  {openItemModal ? (
                    <OfferItemForm
                      closeModal={() => onCloseModal()}
                      addOfferItem={(offerItem: any) => {
                        var result = omit(
                          offerItem,
                          offerItem.type === "DISCOUNT"
                            ? "products"
                            : "discount"
                        );
                        const offers = values.offerItems;
                        setFieldValue("offerItems", [...offers, result]);
                        toggleOfferItem();
                      }}
                    />
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <div className="overflow-auto bg-white px-4 py-5 sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <OfferForm
                            offer={offer}
                            removeTimePeriod={() => {
                              const updatedSchema = Schema.omit(["timePeriod"]);
                              setValidationSchema(updatedSchema);
                            }}
                            openOfferItem={(index: number) =>
                              addOfferItem(index)
                            }
                          />
                        </div>
                      </div>
                      <div className="flex flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
                        {isEdit ? (
                          <button
                            type="button"
                            onClick={() => toggleDelete()}
                            className="inline-flex min-w-[154px] outline-none justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-red-700"
                          >
                            {"Delete Offer"}
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => onCloseModal()}
                            className="inline-flex min-w-[154px] outline-none justify-center rounded-md border border-lavender bg-dismiss-button py-2 px-4 text-sm font-medium text-valhalla shadow "
                          >
                            {"Close"}
                          </button>
                        )}
                        <button
                          type="submit"
                          className="inline-flex min-w-[154px] justify-center rounded-md border border-transparent bg-medium-slate-blue py-2 px-4 text-sm font-medium text-white shadow hover:bg-slate-blue"
                        >
                          {submitButton}
                        </button>
                      </div>
                    </form>
                  )}
                </>
              );
            }}
          </Formik>
        }
        <DeleteItem
          isOpen={isOpenDelete}
          closeModal={() => toggleDelete()}
          onDelete={() => onDeleteProduct(selectedOffer)}
        />
      </div>
    </Modal>
  );
};
export default connect(
  (state: any) => ({
    currentUser: state.setting.get("currentUser"),
    loadingAction: state.common.get("loadingAction"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    createOffer: Actions.offer.createOffer,
    updateOffer: Actions.offer.updateOffer,
    deleteOffer: Actions.offer.deleteOffer,
  }
)(AddOffer);
