import gql from 'graphql-tag';

export const CreateEmployee = gql`
  mutation CreateEmployee($input: CreateEmployeeInput!) {
    createEmployee(input: $input) {
      empId
      shopId
      createdUser
      firstName
      lastName
      email
      name
      color
      pin
      userPosition
      mobileNumber
      employeeStatus
      address
      specializations {
        id
        title
      }
      bio
      createdTime
      updatedTime
      profileImage
      homeImage
      service {
        id
        title
        price
        duration
        isPrimary
        type {
          id
          title
        }
        status
      }
      userFeedBack {
        ratingAverage
        ratingCount
      }
      userId
      isPrimary
    }
  }
`;

export const UpdateEmployee = gql`
  mutation UpdateEmployee($input: UpdateEmployeeInput!) {
    updateEmployee(input: $input) {
      empId
      shopId
      createdUser
      firstName
      lastName
      email
      name
      pin
      color
      userPosition
      mobileNumber
      address
      specializations {
        id
        title
      }
      bio
      createdTime
      updatedTime
      employeeStatus
      profileImage
      homeImage
      service {
        id
        title
        price
        duration
        isPrimary
        type {
          id
          title
        }
        status
      }
      userFeedBack {
        ratingAverage
        ratingCount
      }
      userId
      isPrimary
    }
  }
`;

export const GetEmployee = gql`
  query getEmployee($shopId: String!, $empId: String!) {
    getEmployee(shopId: $shopId, empId: $empId) {
      bio
      name
      empId
      email
      shopId
      address
      lastName
      firstName
      createdUser
      mobileNumber
      color
      specializations {
        id
        title
      }
      createdTime
      updatedTime
      profileImage
      homeImage
      service {
        id
        title
        serviceTitle
        price
        duration
        isPrimary
        type {
          id
          title
        }
        status
      }
      userFeedBack {
        ratingAverage
        ratingCount
      }
      userId
      isPrimary
    }
  }
`;
