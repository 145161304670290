import gql from 'graphql-tag';

export const CreateClient = gql`
  mutation CreateClient($input: CreateClientInput!) {
    createClient(input: $input) {
      id
      createdUser
      name
      email
      mobileNumber
      address
      isContactApp
      isContactText
      createdTime
      updatedTime
      firstName
      lastName
      countryCode
    }
  }
`;

export const GetClient = gql`
  query getClient($id: String!) {
    getClient(id: $id) {
      id
      createdUser
      name
      email
      mobileNumber
      address
      isContactApp
      isContactText
      createdTime
      updatedTime
      firstName
      lastName
      countryCode
    }
  }
`;

export const UpdateClient = gql`
  mutation UpdateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      id
      createdUser
      name
      email
      mobileNumber
      address
      isContactApp
      isContactText
      createdTime
      updatedTime
      firstName
      lastName
      countryCode
    }
  }
`;

export const DeleteClient = gql`
  mutation DeleteClient($input: DeleteClientInput!) {
    deleteClient(input: $input) {
      id
      shopId
    }
  }
`;

export const OnCreateClient = gql`
  subscription onCreateClient($createdUser: String!) {
    onCreateClient(createdUser: $createdUser) {
      id
      createdUser
      userId
      firstName
      lastName
      name
      email
      countryCode
      mobileNumber
      address
      isContactApp
      isContactText
      createdTime
      updatedTime
    }
  }
`;

export const OnUpdateClient = gql`
  subscription onUpdateClient($createdUser: String!) {
    onUpdateClient(createdUser: $createdUser) {
      id
      createdUser
      userId
      firstName
      lastName
      name
      email
      countryCode
      mobileNumber
      address
      isContactApp
      isContactText
      createdTime
      updatedTime
    }
  }
`;

export const OnCreateAppointment = gql`
  subscription onCreateAppointment($createdUserId: String!) {
    onCreateAppointment(createdUserId: $createdUserId) {
      appointmentId
      createdUserId
      stylistId
      salonId
      firstName
      lastName
      email
      date
      time
      mobileNumber
      createdTime
      updatedTime
      bookingStatus
      paymentMethod
      tax
      notes
      clientId
      serviceDetails {
        id
        title
        serviceTitle
        price
        duration
        isPrimary
        type {
          id
          title
        }
        status
      }
      name
      stylistName
      salonName
    }
  }
`;

export const OnUpdateAppointment = gql`
  subscription onUpdateAppointment($createdUserId: String!) {
    onUpdateAppointment(createdUserId: $createdUserId) {
      appointmentId
      createdUserId
      stylistId
      salonId
      firstName
      lastName
      email
      date
      time
      mobileNumber
      createdTime
      updatedTime
      bookingStatus
      paymentMethod
      tax
      notes
      clientId
      serviceDetails {
        id
        title
        serviceTitle
        price
        duration
        isPrimary
        type {
          id
          title
        }
        status
      }
      name
      stylistName
      salonName
    }
  }
`;
