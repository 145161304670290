import React, { useEffect, useState } from "react";
import moment from "moment";
import _, { get } from "lodash";
import { connect } from "react-redux";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { Actions } from "../../../internals/app/Actions";
import AreaChart from "../../../components/Chart/areaChart";
import RevenueTable from "../../../components/Table/revenueTable";
import DoughnutChart from "../../../components/Chart/doughnutChart";
import { formatPrice } from "../../../internals/managers/PaymentManager";
import { getRevenueData } from "../../../internals/managers/ReportsManager";

const RevenueView = (props: any) => {
  const { yearReports, selectedShop, fetchSummaryForYearRange } = props;
  const [netPrice, setNetPrice] = useState<any>();
  const [monthRange, setMonthRange] = useState({
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(new Date().getFullYear(), 11, 31),
    key: "selection",
  });
  const [isOpenCalender, setIsOpenCalender] = useState(false);

  useEffect(() => {
    const { net }: any = getRevenueData(monthRange, yearReports);
    setNetPrice(formatPrice(net));
  }, [yearReports]);

  const handleSelect = (ranges: any) => {
    setMonthRange(ranges.selection);
  };

  const resetDate = () => {
    const range = {
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: new Date(new Date().getFullYear(), 11, 31),
      key: "selection",
    };
    setMonthRange(range);
    fetchRevenueData(range);
    setIsOpenCalender(false);
  };

  const toggleOpenDateRange = (event: any) => {
    event.preventDefault();
    setIsOpenCalender(!isOpenCalender);
  };

  const fetchRevenueData = (range: any) => {
    const { startDate, endDate }: any = range;
    const shopId = selectedShop.shopId;
    const endMonth = moment(endDate).endOf("month").valueOf();
    const startMonth = moment(startDate).startOf("month").valueOf();
    fetchSummaryForYearRange(shopId, startMonth, endMonth);
    setIsOpenCalender(false);
  };

  useEffect(() => {
    fetchRevenueData(monthRange);
  }, []);

  return (
    <div>
      <div className="flex flex-row items-center">
        <h5 className="font-inter font-bold leading-tight text-[31px] mb-[7px] dark-black">
          {netPrice}
        </h5>
        <div className="flex ml-[55px] relative h-[38px]">
          <div
            className="flex flex-row gap-2 items-center justify-center px-4 h-[38px] rounded-md border border-[#D1D5DB] text-sm"
            onClick={toggleOpenDateRange}
          >
            <div className="text-valhalla text-sm cursor-pointer">
              {moment(monthRange.startDate).format("DD-MM-YYYY")}
            </div>
            {"-"}
            <div className="text-valhalla text-sm cursor-pointer">
              {moment(monthRange.endDate).format("DD-MM-YYYY")}
            </div>
          </div>
          {isOpenCalender ? (
            <div className="absolute z-50 top-11 left-0 bg-ghost-white shadow">
              <DateRangePicker
                maxDate={new Date()}
                ranges={[monthRange]}
                onChange={handleSelect}
              />
              <div className="flex gap-4 justify-end p-2">
                <button
                  type="button"
                  onClick={() => {
                    fetchRevenueData(monthRange);
                  }}
                  className="inline-flex justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white shadow bg-medium-slate-blue hover:bg-slate-blue"
                >
                  {"Filter"}
                </button>
                <button
                  type="button"
                  onClick={() => resetDate()}
                  className="inline-flex justify-center rounded-md border border-[#BFC0C6] py-2 px-4 text-sm font-medium text-[#999A9E] shadow bg-lavender"
                >
                  {"Reset"}
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="text-gray-6c text-[11px] font-semibold font-inter">
        Total summary
      </div>
      <div className="mt-[20px]">
        <AreaChart monthRange={monthRange} />
      </div>
      <div className="flex flex-row mt-[50px]">
        <div className="w-[500px]">
          <div className="text-black text-[11px] font-normal font-inter">
            Income
          </div>
          <h5 className="font-inter font-bold leading-tight text-[31px] mt-[7px] text-dark-black">
            {netPrice}
          </h5>
          <div className="text-gray-6c text-[11px] font-normal font-inter">
            For last month
          </div>
          <DoughnutChart monthRange={monthRange} />
        </div>
        <RevenueTable monthRange={monthRange} />
      </div>
    </div>
  );
};

export default connect(
  (state: any) => ({
    yearReports: state.report.get("yearReports"),
    monthsReports: state.report.get("monthsReports"),
    selectedShop: state.dashboard.get("selectedShop"),
    monthSummaryReport: state.report.get("monthSummaryReport"),
    dateRangeSummaryReport: state.report.get("dateRangeSummaryReport"),
  }),
  {
    fetchSummaryForYearRange: Actions.report.fetchSummaryForYearRange,
    filterSummaryForDateRange: Actions.report.filterSummaryForDateRange,
  }
)(RevenueView);
