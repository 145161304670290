export type WarrantyType = {
  wrid: string;
  title: string;
  duration: number;
  createdTime: number;
  description: string;
  durationType: string;
};

export enum DurationTypes {
  DAYS = "DAYS",
  WEEKS = "WEEKS",
  MONTHS = "MONTHS",
  YEARS = "YEARS",
}

export const WarrantyDuration = [
  {
    value: DurationTypes.DAYS,
    label: "Days",
  },
  {
    value: DurationTypes.WEEKS,
    label: "Weeks",
  },
  {
    value: DurationTypes.MONTHS,
    label: "Months",
  },
  {
    value: DurationTypes.YEARS,
    label: "Years",
  },
];
