import React, { useEffect, useState } from 'react';

const SupplierView = () => {

  return (
    <div>
  
    </div>
  );
};

export default SupplierView