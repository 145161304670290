import { useState } from "react";
import * as Yup from "yup";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import FormTextarea from "../inputs/FormTextarea";
import { Actions } from "../../internals/app/Actions";
import cencel from "../../assets/svgs/subscription/cancel.svg";

const SubscriptionCancelModal = (props: any) => {
  const { isOpen, closeModal, onCancelSubscription } = props;
  const [dataStatus, setDataStatus] = useState<string>("FIRST_STATE");

  const onClose = () => {
    closeModal();
    setDataStatus("FIRST_STATE");
  };

  const customStyles: any = {
    overlay: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      position: "fixed",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
      padding: 0,
      height: "auto",
      overflow: "auto",
      maxWidth: "374px",
      background: "#fff",
      borderRadius: "8px",
      position: "absolute",
      top: "calc(50vh - 20rem)",
      left: "calc(50vw - 187px)",
      WebkitOverflowScrolling: "touch",
      boxShadow: "0 4px 8px 1px rgb(0 0 0 / 0.8)",
      maxHeight: dataStatus === "FIRST_STATE" ? "376px" : "446px",
    },
  };

  const renderFirstState = () => {
    return (
      <div className="flex flex-col w-full h-full p-7 selection:overflow-hidden shadow rounded-lg items-center justify-center">
        <img src={cencel} alt="altimage" />
        <p className="mt-7 text-lg font-medium text-paua">
          {"Are you sure to deactivate?"}
        </p>
        <p className="mt-2 text-sm text-waikawa-grey text-center">
          {
            "We are sorry to see you go. Are you sure To cancel the subscription"
          }
        </p>
        <div className="flex flex-row w-full justify-between mt-7">
          <button
            type="button"
            onClick={() => onClose()}
            className="w-[116px] text-valhalla bg-dismiss hover:bg-dismiss-focus font-medium rounded-lg text-sm px-5 py-2.5  focus:outline-none"
          >
            {"Dismiss"}
          </button>
          <button
            type="button"
            onClick={() => setDataStatus("SECOND_STATE")}
            className="w-[116px] text-white bg-deactivate hover:bg-deactivate-focus font-medium rounded-lg text-sm px-5 py-2.5  focus:outline-none"
          >
            {"Deactivate"}
          </button>
        </div>
      </div>
    );
  };

  const initialValues = {
    desc: "",
    reason: "OTHER",
  };

  const validationSchema = Yup.object({
    reason: Yup.string().required("Please select the reason..."),
  });

  const reasonData = [
    { title: "I am going to resubscribe", status: "RESUBSCRIBE" },
    { title: "Going with a different vendor", status: "DIFFERENT_VENDOR" },
    { title: "I don’t feel platform is good", status: "NOT_GOOD" },
    { title: "Other", status: "OTHER" },
  ];

  const renderSecondState = () => {
    return (
      <div className="flex flex-col w-full h-full px-6 pt-8 pb-5 selection:overflow-hidden shadow rounded-lg">
        <p className="text-lg font-medium text-paua">
          {"Let us know how to improve"}
        </p>
        <p className="mt-2 text-sm text-waikawa-grey">{"Tell us the reason"}</p>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => onCancelSubscription(values)}
        >
          {({ values, errors, touched, handleSubmit, handleChange }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="col-span-6 sm:col-span-6">
                  <div
                    role="group"
                    className="mt-5 w-full"
                    aria-labelledby="my-radio-group"
                  >
                    {reasonData.map((data: any) => {
                      return (
                        <label className="flex gap-3 mb-4 items-center text-sm font-medium text-waikawa-grey">
                          <Field
                            type="radio"
                            name="reason"
                            value={data.status}
                            className="w-4 h-4 text-waikawa-grey bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                          />
                          {data.title}
                        </label>
                      );
                    })}
                  </div>
                  <FormTextarea
                    id="desc"
                    value={values.desc}
                    error={errors.desc}
                    touched={touched.desc}
                    onChange={handleChange("desc")}
                  />
                </div>

                <div className="flex flex-row w-full justify-between mt-6">
                  <button
                    type="button"
                    onClick={() => setDataStatus("FIRST_STATE")}
                    className="w-[116px] text-valhalla bg-dismiss hover:bg-dismiss-focus font-medium rounded-lg text-sm px-5 py-2.5  focus:outline-none"
                  >
                    {"Dismiss"}
                  </button>
                  <button
                    type="submit"
                    className="w-[160px] text-white bg-deactivate hover:bg-deactivate-focus font-medium rounded-lg text-sm px-5 py-2.5  focus:outline-none"
                  >
                    {"Cancel Account"}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Category Modal"
      onRequestClose={() => onClose()}
    >
      {dataStatus === "FIRST_STATE" ? renderFirstState() : renderSecondState()}
    </Modal>
  );
};

export default connect((state: any) => ({}), {})(SubscriptionCancelModal);
