import "./success-alert.css";
import AlertCloseIcon from "../../assets/svgs/alertCloseIcon.svg";
import SuccessfullyUpdateIcon from "../../assets/svgs/successfullyUpdateIcon.svg";
import SuccessfullyCreatedIcon from "../../assets/svgs/successfullyCreatedIcon.svg";
import SuccessfullyDeletedIcon from "../../assets/svgs/successfullyDeletedIcon.svg";

const Alert = (props: any) => {
  const { alertStatus, onCloseAlert } = props;
  const { isOpenSuccessAlert, isOpenFailedAlert, alertType } = alertStatus;

  return (
    <div>
      {alertType === "CREATE" && isOpenSuccessAlert ? (
        <div className="alert-box-container">
          <div className="alert-box-view">
            <div className="alert-box-left-box" />
            <img
              alt="close"
              src={SuccessfullyCreatedIcon}
              style={{ marginLeft: "20px" }}
            />
            <div className="successfully-created-text">
              Successfully Created
            </div>
            <div
              className="alert-close-icon"
              onClick={() => onCloseAlert("CREATE")}
            >
              <img alt="close" src={AlertCloseIcon} />
            </div>
          </div>
        </div>
      ) : alertType === "UPDATE" && isOpenSuccessAlert ? (
        <div className="alert-box-container">
          <div className="alert-box-view">
            <div
              className="alert-box-left-box"
              style={{ backgroundColor: "#2196F3" }}
            />
            <img
              alt="success"
              src={SuccessfullyUpdateIcon}
              style={{ marginLeft: "20px" }}
            />
            <div
              className="successfully-created-text"
              style={{ color: "#2196F3" }}
            >
              Successfully Updated
            </div>
            <div
              className="alert-close-icon"
              onClick={() => onCloseAlert("UPDATE")}
            >
              <img alt="success" src={AlertCloseIcon} />
            </div>
          </div>
        </div>
      ) : isOpenSuccessAlert ? (
        <div className="alert-box-container">
          <div className="alert-box-view">
            <div
              className="alert-box-left-box"
              style={{ backgroundColor: "#FF4D4D" }}
            />
            <img
              alt="success"
              src={SuccessfullyDeletedIcon}
              style={{ marginLeft: "20px" }}
            />
            <div
              className="successfully-created-text"
              style={{ color: "#FF4D4D" }}
            >
              Successfully Deleted
            </div>
            <div
              className="alert-close-icon"
              onClick={() => onCloseAlert("DELETE")}
            >
              <img alt="success" src={AlertCloseIcon} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default Alert;
