import Repository from "./Repository";
import { nanoid } from "nanoid";
import { concat, get } from "lodash";
import UserRepository from "./UserRepository";
import { API, graphqlOperation } from '@aws-amplify/api';
import { CreateShop, CreateShopClaim, DeleteShopClaim, GetShop, QueryShopClaims, QueryShopClaimsById, UpdateShop, UpdateShopClaim } from './schema/Shop.schema';

class ShopRepository extends Repository {
  async fetchAdminShops(userId) {
    const message = this.buildMessage({ userId });
    try {
      const result = await this.apiPost({
        apiName: this.API_ADMIN_DASHBOARD,
        path: "/shops/claimed",
        message,
      });
      return result.data.result;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async fetchShopCategories(shopId) {
    const message = this.buildMessage({ shopId });
    try {
      const result = await this.apiPost({
        apiName: this.API_ADMIN_DASHBOARD,
        path: "/categories",
        message,
      });

      return result.data.categoryResult;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async createShop(input) {
    try {
      const data = await API.graphql(graphqlOperation(CreateShop, { input }));
      return data.data.createShop;
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  }

  async updateShop(param) {
    try {
      const data = await API.graphql(
        graphqlOperation(UpdateShop, { input: param }),
      );
      return data.data.updateShop;
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  }

  async createShopClaim(params) {
    try {
      const data = await API.graphql(
        graphqlOperation(CreateShopClaim, { input: params }),
      );
      return data.data.createShopClaim;
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  }

  async inviteShopClaim(params) {
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.API_NAME,
        path: '/claim/invite',
        message,
      });
      return get(result, 'data.result', null);
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  }

  async deleteShopClaim(params) {
    try {
      const data = await API.graphql(
        graphqlOperation(DeleteShopClaim, { input: params }),
      );
      return data.data.deleteShopClaim;
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  }

  async updateInviteShopClaim(params, isCreate) {
    try {
      const input = {
        shopId: params.shopId,
        requestedUserId: params.email
      }
      const result = await API.graphql(
        graphqlOperation(DeleteShopClaim, { input }),
      );
      let data = null;
      if (isCreate) {
        data = await this.createShopClaim(params)
      } else {
        data = await this.updateShopClaim(params)
      }
      return data;
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  }

  async updateShopClaim(params) {
    try {
      const data = await API.graphql(
        graphqlOperation(UpdateShopClaim, { input: params }),
      );
      return data.data.updateShopClaim;
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  }

  async fetchClaims(params) {
    try {
      const data = await API.graphql(
        graphqlOperation(QueryShopClaims, params),
      );
      const claims = get(data, 'data.queryShopClaims.items', [])
      await Promise.all(
        claims.map(async (item) => {
          if (item.requestedUserId != 'UNCLAIMED') {
            const user = await UserRepository.getUserForId(item.requestedUserId);
            item['user'] = user;
          }
          return item
        })
      );
      return { items: claims, nextToken: get(data, 'data.queryShopClaims.nextToken', null) };
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  }

  async getShop(shopId) {
    try {
      const data = await API.graphql(
        graphqlOperation(GetShop, { shopId })
      );
      return data.data.getShop;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async fetchUserClaims(params) {
    try {
      const activeResult = await API.graphql(
        graphqlOperation(QueryShopClaimsById, { requestedUserId: params.uid }),
      );
      const inactiveResult = await API.graphql(
        graphqlOperation(QueryShopClaimsById, { requestedUserId: params.email }),
      );
      const activeClaim = get(activeResult, 'data.queryShopClaimsByRequestedUserIdIndex.items', [])
      const inactiveClaim = get(inactiveResult, 'data.queryShopClaimsByRequestedUserIdIndex.items', [])
      const claims = concat(activeClaim, inactiveClaim);
      await Promise.all(
        claims.map(async (claim) => {
          const shop = await this.getShop(claim.shopId)
          claim.shop = shop
          return claim
        }),
      );
      return claims;
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  }

  async uploadToS3Image(file, bucket = null) {
    try {
      let fileId;
      if (file) {
        fileId = `${nanoid()}.${file.name.split('.').pop()}`;
        return await this.uploadImage(file, fileId, bucket);
      }
      return null;
    } catch (error) {
      return { error };
    }
  }

}

export default new ShopRepository();
