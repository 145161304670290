export const mapStockMetrics = [
  {
    value: "kg (Kilogram)",
    label: "kg (Kilogram)",
    metricValue: "kg",
  },
  {
    value: "l (Litre)",
    label: "l (Litre)",
    metricValue: "l",
  },
  {
    value: "m (Meter)",
    label: "m (Meter)",
    metricValue: "m",
  },
  {
    value: "ml (Millilitre)",
    label: "ml (Millilitre)",
    metricValue: "ml",
  },
  {
    value: "g (Gram)",
    label: "g (Gram)",
    metricValue: "g",
  },
  {
    value: "Item (per Item)",
    label: "Item (per Item)",
    metricValue: "Item",
  },
];

export const headerTabs = [
  {
    screen: "SUPPLIER",
    label: "Supplier Details",
  },
  {
    screen: "STORAGE",
    label: "Select Storage",
  },
  {
    screen: "DISCOUNTS",
    label: "Add Discounts",
  },
  {
    screen: "CHARGERS",
    label: "Add Other Charges",
  },
  {
    screen: "COMMENTS",
    label: "Comments",
  },
];
