import Swal from "sweetalert2";
import { delay } from "lodash";
import { router } from "../../App";
import { ModuleEvents } from "./Actions";
import { Actions } from "../../internals/app/Actions";
import withReactContent from "sweetalert2-react-content";
import { navigate } from "../../internals/service/Navigation.service";
import PaymentRepository from "../../internals/repository/PaymentRepository";
import MixpanelService, { MP_EVENTS } from "../../internals/service/MixpanelService";

export default {
  [ModuleEvents.GET_CURRENT_USER]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      MixpanelService.setProfile(payload);
      MixpanelService.track(MP_EVENTS.SIGN_IN, payload);
      dispatch(Actions.dashboard.fetchAdminShops(payload.uid, payload.email));
      dispatch(Actions.user.fetchUserClaims(payload));
    } else {
      navigate("/signin");
    }
  },
  [ModuleEvents.FETCH_TRIAL]: ({ dispatch, payload, pathname }) => {
    if (payload && !payload.error && !payload?.isExpired) {
      // router.navigate("/business-manage");
      router.navigate("/dashboard");
    } else {
      // router.navigate("/signup-subscription");
    }
  },
  // [ModuleEvents.FETCH_SUBSCRIPTIONS]: ({ dispatch, payload, appState }) => {
  //   if (payload && !payload.error) {
  //     router.navigate("/dashboard");
  //   }
  // },
  [ModuleEvents.CREATE_TRIAL]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      router.navigate("/dashboard");
    }
  },
  [ModuleEvents.CREATE_HOSTED_SUBSCRIPTION]: ({
    dispatch,
    payload,
    appState,
  }) => {
    if (payload && !payload.error) {
      if (payload?.code === "FAILED") {
        return warning("Unexpected payment result failed!");
      } else {
        const url = payload.data.url;
        window.location.replace(url);
      }
    } else {
      return warning("Unexpected result found!");
    }
  },

  [ModuleEvents.CHECK_SUCCESS_RECURRING_PAYMENT]: async ({
    dispatch,
    payload,
    appState,
  }) => {
    const { refId, isUpdate } = payload;
    const currentUser = appState.setting.get("currentUser");
    MixpanelService.track(MP_EVENTS.SUCCESS_SUBSCRIPTION, payload);

    const result = await PaymentRepository.getRecurringForRefId({
      referenceId: refId,
    });
    if (result && result.isChecked) {
      // dispatch(Actions.setting.fetchActiveSubscriptions(currentUser.uid));
      dispatch(Actions.setting.fetchSubscriptions(currentUser.uid));
      return router.navigate("/subscription-plans");
    } else if (result && !result.isChecked) {
      dispatch(
        Actions.setting.updateCheckStatus({
          subscriptionId: result.subscriptionId,
          isChecked: true,
          updatedTime: Date.now(),
          userId: currentUser.uid,
        })
      );
      if (result.status === "SUCCESS") {
        if (isUpdate) {
          dispatch(Actions.setting.openRecurringStatusModal("UPDATED"));
        } else {
          dispatch(Actions.setting.openRecurringStatusModal("SUCCESS"));
        }
      } else if (result.status === "CANCEL") {
        dispatch(Actions.setting.openRecurringStatusModal("FAILED"));
      }
    }
  },

  [ModuleEvents.UPDATE_CHECK_STATUS]: async ({
    dispatch,
    payload,
    appState,
  }) => {
    const currentUser = appState.setting.get("currentUser");
    // dispatch(Actions.setting.fetchActiveSubscriptions(currentUser.uid));
    dispatch(Actions.setting.fetchSubscriptions(currentUser.uid));
    // dispatch(Actions.setting.fetchUserPayments(currentUser.uid));
  },

  [ModuleEvents.UPLOAD_USER_IMAGE]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      if (payload.image) {
        dispatch(Actions.common.openAlertBox("UPDATE", true, "SUCCESS"));
        delay(() => {
          dispatch(Actions.common.openAlertBox("UPDATE", false, "SUCCESS"));
        }, 3000);
      } else {
        dispatch(Actions.common.openAlertBox("DELETE", true, "SUCCESS"));
        delay(() => {
          dispatch(Actions.common.openAlertBox("DELETE", false, "SUCCESS"));
        }, 3000);
      }
    } else {
      return warning(`User image upload fail!`);
    }
  },

  [ModuleEvents.UPDATE_USER]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      MixpanelService.track(MP_EVENTS.CANCEL_SUBSCRIPTION, payload);
      dispatch(Actions.common.openAlertBox("UPDATE", true, "SUCCESS"));
      delay((text) => {
        dispatch(Actions.common.openAlertBox("UPDATE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning("User updated fail");
    }
  },
  [ModuleEvents.CANCEL_SUBSCRIPTION]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error && payload.status === "SUCCESS") {
      const userId = payload.updatedResult.userId
      MixpanelService.track(MP_EVENTS.CANCEL_SUBSCRIPTION, payload);
      dispatch(Actions.setting.fetchSubscriptions(userId));
    }
  },
};

const warning = (alert) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    icon: "error",
    text: alert,
    title: "Oops...",
    showCancelButton: true,
    showConfirmButton: false,
    cancelButtonColor: "#FF3333",
  });
};
