import { useEffect, useState } from "react";
import { find, get } from "lodash";
import { connect } from "react-redux";
import Alert from "../../../components/Alert";
import Table from "../../../components/Table";
import AddUser from "../../../components/AddClient";
import Delete from "../../../assets/svgs/delete.svg";
import UserHeader from "../../../components/UserHeader";
import DeleteItem from "../../../components/DeleteItem";
import { Actions } from "../../../internals/app/Actions";
const tableHeader = ["FULL NAME", "EMAIL", "PHONE NUMBER", "STATUS", "ACTIONS"];

const UserView = (props: any) => {
  const { alertStatus, openAlertBox, deleteShopClaim, shopClaims } = props;
  const [user, setUser] = useState(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    if (shopClaims) {
      const tableData = getData(shopClaims);
      setTableData(tableData);
    }
  }, [shopClaims]);

  const getData = (data: any) => {
    return data.map((item: any) => {
      const isInvites =
        get(item, "claimType", "") === "INVITED" ||
        get(item, "claimType", "") === "INACTIVE";
      const name = !isInvites
        ? `${get(item, "user.firstName", "")} ${get(item, "user.lastName", "")}`
        : `${item.firstName} ${item.lastName}`;
      const email = !isInvites ? get(item, "user.email", "--") : item.email;
      const phoneNumber = !isInvites
        ? item.user?.phoneNumber?.length > 7
          ? item.user.phoneNumber
          : "--"
        : item.phoneNumber.length > 7
        ? item.phoneNumber
        : "--";
      const image = get(item, "user.image", null);
      const status =
        item.claimType === "INVITED" || item.claimType === "INACTIVE"
          ? "Inactive"
          : "Active";

      return {
        id: email,
        EMAIL: email,
        IMAGE: image,
        STATUS: status,
        ACTIONS: [Delete],
        "FULL NAME": name,
        "PHONE NUMBER": phoneNumber,
      };
    });
  };

  const toggleUser = () => {
    setIsOpen(!isOpen);
  };

  const onClickItem = (data: any, index: number) => {
    const selectedUser = find(shopClaims, (item) => {
      if (item.claimType === "INVITED" || item.claimType === "INACTIVE") {
        return item.email === data.id;
      } else {
        return item.user.email === data.id;
      }
    });
    setUser(selectedUser);
    if (index === 0) {
      toggleDelete();
    }
  };

  const toggleDelete = () => {
    setIsOpenDelete(!isOpenDelete);
  };

  const onDeleteUser = (data: any) => {
    deleteShopClaim({
      shopId: data.shopId,
      requestedUserId: data.requestedUserId,
    });
    toggleDelete();
  };

  return (
    <div className="flex flex-col w-full p-6 justify-start">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="pb-6">
        <UserHeader
          openUser={() => {
            setUser(null);
            toggleUser();
          }}
        />
      </div>
      <Table {...{ tableHeader, tableData }} onClickItem={onClickItem} />
      <AddUser user={user} isOpen={isOpen} closeModal={() => toggleUser()} />
      <DeleteItem
        isOpen={isOpenDelete}
        closeModal={() => toggleDelete()}
        onDelete={() => onDeleteUser(user)}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    shopClaims: state.user.get("shopClaims"),
    alertStatus: state.common.get("alertStatus"),
  }),
  {
    openAlertBox: Actions.common.openAlertBox,
    deleteShopClaim: Actions.user.deleteShopClaim,
  }
)(UserView);
