import { useEffect, useState } from "react";
import { find } from "lodash";
import { connect } from "react-redux";
import Alert from "../../../components/Alert";
import Table from "../../../components/Table";
import Edit from "../../../assets/svgs/edit.svg";
import Delete from "../../../assets/svgs/delete.svg";
import AddReward from "../../../components/AddReward";
import DeleteItem from "../../../components/DeleteItem";
import { Actions } from "../../../internals/app/Actions";
import RewardHeader from "../../../components/RewardHeader";

const tableHeader = [
  "REWARD TITLE",
  "TYPE",
  "STAMPS",
  "CUSTOMER",
  "PHONE NUMBER",
  "REWARD",
  "ACTIONS",
];

const RewardView = (props: any) => {
  const { alertStatus, openAlertBox, employeeList, updateEmployee } = props;
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [reward, setReward] = useState(null);

  useEffect(() => {
    if (employeeList.employees) {
      const tableData = getData(employeeList.employees);
      // setTableData(tableData);
    }
  }, [employeeList]);

  const getData = (data: any) => {
    return data.map((item: any) => {
      return {
        id: item.empId,
        "EMPLOYEE NAME": `${item.firstName} ${item.lastName}`,
        EMAIL: item.email,
        "PHONE NUMBER": item.mobileNumber,
        "ASSIGNED SERVICES": "",
        ACTIONS: [Edit, Delete],
      };
    });
  };

  const onClickItem = (data: any, index: number) => {
    const employee = find(
      employeeList.employees,
      (item) => item.empId === data.id
    );
    setReward(employee);
    if (index === 0) {
      toggleReward();
    } else if (index === 1) {
      toggleDelete();
    }
  };

  const toggleDelete = () => {
    setIsOpenDelete(!isOpenDelete);
  };

  const toggleReward = () => {
    setIsOpen(!isOpen);
  };

  const onDeleteReward = (data: any) => {
    toggleDelete();
  };

  return (
    <div className="flex flex-col w-full p-6 justify-start">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="pb-6">
        <RewardHeader
          openReward={() => {
            setReward(null);
            toggleReward();
          }}
        />
      </div>
      <Table {...{ tableHeader, tableData }} onClickItem={onClickItem} />
      <AddReward
        isOpen={isOpen}
        reward={reward}
        closeModal={() => toggleReward()}
      />
      <DeleteItem
        isOpen={isOpenDelete}
        closeModal={() => toggleDelete()}
        onDelete={() => onDeleteReward(reward)}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    categories: state.product.get("categories"),
    alertStatus: state.common.get("alertStatus"),
    employeeList: state.employee.get("employeeList"),
  }),
  {
    openAlertBox: Actions.common.openAlertBox,
    updateEmployee: Actions.employee.updateEmployee,
  }
)(RewardView);
