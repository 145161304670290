import { AiOutlinePlus } from "react-icons/ai";

const UserHeader = (props: any) => {
  const { openUser } = props;

  return (
    <div className="flex flex-row w-full justify-end">
      <div className="inline-flex rounded-md shadow-sm" role="group">
        <button
          type="button"
          onClick={() => openUser()}
          className="inline-flex gap-2 items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-md hover:bg-gray-100 hover:text-medium-slate-blue"
        >
          <AiOutlinePlus size={20} />
          Add User
        </button>
      </div>
    </div>
  );
};
export default UserHeader;
