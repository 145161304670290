import Swal from "sweetalert2";
import { router } from "../../App";
import { ModuleEvents } from "./Actions";
import { delay, find, isEmpty } from "lodash";
import { Actions } from "../../internals/app/Actions";
import withReactContent from "sweetalert2-react-content";

export default {
  [ModuleEvents.FETCH_USER_CLAIMS]: ({ dispatch, payload, appState }) => {
    if(isEmpty(payload)){
      router.navigate("/business-manage");
    }else{
      const claim = find(payload, (claim) => claim.claimType === 'INVITED')
      if (!isEmpty(claim)) {
        dispatch(Actions.user.setInviteClaim(claim));
        dispatch(Actions.user.setInviteStatus(true));
      }
    }
  },
  [ModuleEvents.CREATE_SHOP_CLAIM]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      dispatch(Actions.common.openAlertBox("CREATE", true, "SUCCESS"));
      delay(function (text) {
        dispatch(Actions.common.openAlertBox("CREATE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning("Shop claim created unsuccessfully!");
    }
  },

  [ModuleEvents.UPDATE_SHOP_CLAIM]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      dispatch(Actions.common.openAlertBox("UPDATE", true, "SUCCESS"));
      delay(function (text) {
        dispatch(Actions.common.openAlertBox("UPDATE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning("Shop claim updated unsuccessfully!");
    }
  },

  [ModuleEvents.UPDATE_INVITE_SHOP_CLAIM]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      if (payload.claimType === "INACTIVE") {
        router.navigate("/business-manage");
      } else {
        const currentUser = appState.setting.get("currentUser");
        dispatch(Actions.user.fetchUserClaims(currentUser));
        dispatch(Actions.dashboard.fetchAdminShops(currentUser.uid));
        dispatch(Actions.common.openAlertBox("UPDATE", true, "SUCCESS"));
        delay(function (text) {
          dispatch(Actions.common.openAlertBox("UPDATE", false, "SUCCESS"));
        }, 3000);
      }
    } else {
      return warning("Shop claim updated unsuccessfully!");
    }
  },

  [ModuleEvents.DELETE_SHOP_CLAIM]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      const selectedShop = appState.dashboard.get("selectedShop");
      dispatch(Actions.user.fetchClaims(selectedShop.shopId));
      dispatch(Actions.common.openAlertBox("DELETE", true, "SUCCESS"));
      delay(function (text) {
        dispatch(Actions.common.openAlertBox("DELETE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning("Shop claim deleted unsuccessfully!");
    }
  },
  [ModuleEvents.FETCH_CLAIMS]: ({ dispatch, payload, appState }) => {
    if (payload && payload.items && !payload.items.errors) {
      const shopClaims = payload.items;
      const currentUser = appState.setting.get("currentUser");
      const invitedUser = find(shopClaims, (item) => item.requestedUserId === currentUser.email)
      const inactiveUser = find(shopClaims, (item) => item.requestedUserId === currentUser.uid)
      if (!isEmpty(invitedUser) && invitedUser.claimType != "ACTIVE") {
        dispatch(Actions.user.setInviteClaim(invitedUser));
        dispatch(Actions.user.setInviteStatus(true));
      } else if (!isEmpty(inactiveUser) && inactiveUser.claimType === "INACTIVE") {
        router.navigate("/business-manage");
      }
    }
  },
};

const warning = (alert) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    icon: "error",
    text: alert,
    title: "Oops...",
    showCancelButton: true,
    showConfirmButton: false,
    cancelButtonColor: "#FF3333",
  });
};
