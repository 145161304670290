import {sortBy} from 'lodash';
import {connect} from 'react-redux';
import 'react-phone-input-2/lib/style.css';
import Modal from 'react-modal';
import Success from '../../../assets/svgs/subscription/success.svg';
import {Actions} from '../../../internals/app/Actions';
import {useEffect, useState} from 'react';
import {formatPrice} from '../../../internals/managers/PaymentManager';
import {useNavigate} from 'react-router-dom';

const SignUpSubscriptionView = (props: any) => {
  const {
    createTrial,
    currentUser,
    fetchPackages,
    paymentPackages,
    logout,
    selectedShop,
    trialDetails,
  } = props;
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPackages();
  }, [fetchPackages]);

  const onClickBuy = (values: any, item: any) => {
    navigate('/subscription');
  };

  const onStartTrial = () => {
    createTrial({shopId: selectedShop.shopId, createdBy: currentUser.uid});
  };

  const sortedPackages = sortBy(paymentPackages || [], ['amount']).filter(({isVisible}) => isVisible);

  return (
    <section className=" bg-white flex flex-row">
      <div className="max-w-5xl w-full h-full flex flex-col items-center justify-center p-6 mx-auto md:h-screen lg:py-0">
        <div className="flex flex-col items-center justify-center mb-6 ">
          <h1 className="text-4xl font-semibold text-midnight-express">
            Start Subscription
          </h1>
          {trialDetails ? null : (
            <>
              <p className="mt-8 text-ship-cove text-center max-w-sm">
                Get to know about Nelise platform services and subscribe later
              </p>
              <button
                onClick={() => setOpen(true)}
                className="bg-midnight-express rounded-lg mt-8 text-white px-24 py-3 font-inter"
              >
                Start 14 Days Trial
              </button>
            </>
          )}
        </div>
        <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 xl:p-0`}>
          {sortedPackages.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className={
                  'w-[310px] h-[438px] relative cursor-pointer border border-gray-200 rounded-lg p-1 bg-valhalla -ml-2 mb-6'
                }
              >
                <div
                  style={{
                    background: index > 0 ? '#54C1FB' : '#F8F9FD',
                  }}
                  className={
                    'w-[310px] h-[438px] absolute top-2 left-2 first-line:max-w-xs cursor-pointer border border-gray-200 rounded-lg p-4 sm:p-8 bg-ghost-white'
                  }
                >
                  <h5 className="flex items-center justify-center mb-4 text-lg font-medium text-midnight-express">
                    {item.title}
                  </h5>

                  <button
                    onClick={(e) => onClickBuy(e, item)}
                    className="flex w-full max-w-xs py-2 items-baseline justify-center text-white bg-medium-slate-blue rounded-lg"
                  >
                    <span className="text-base font-semibold"></span>
                    <span className="text-3xl font-bold tracking-tight">
                      {formatPrice(item.amount, 'USD')}
                    </span>
                    <span className="ml-1 text-base font-normal">/ month</span>
                  </button>

                  <ul className="space-y-5 my-7">
                    {item.features?.map((title: string, index: number) => {
                      return (
                        <li key={index} className="flex space-x-3 items-center">
                          {/* <img alt="step-2" className="w-5 h-5" src={checked} /> */}
                          <div className="w-3 h-3 bg-medium-slate-blue rounded-full border border-valhalla" />
                          <span className="text-base font-normal leading-tight text-midnight-express ">
                            {title}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
        <a
          onClick={logout}
          className="text-waikawa-grey underline mt-2"
          href="#"
        >
          Or sign in with a different account
        </a>
        <Modal
          isOpen={isOpen}
          style={customStyles}
          contentLabel="Category Modal"
          onRequestClose={() => setOpen(false)}
        >
          <div className="flex flex-col w-full h-full px-6 py-10 selection:overflow-hidden shadow rounded-lg items-center justify-center">
            <img src={Success} alt="altimage" />
            <h1 className="mt-7 text-lg font-bold text-paua">
              Start Free Trial!
            </h1>
            <p className="mt-2 text-xs text-waikawa-grey">
              Your trial will start now and will last for 14 days
            </p>
            <div className="flex justify-around w-full">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="mt-7 w-[135px] text-midnight-express bg-dismiss hover:bg-gray-200 font-medium rounded-lg text-sm px-5 py-2.5  focus:outline-none"
              >
                Dismiss
              </button>
              <button
                type="button"
                onClick={() => onStartTrial()}
                className="mt-7 w-[135px] text-white bg-medium-slate-blue hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5  focus:outline-none"
              >
                Start Trial
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </section>
  );
};

const customStyles: any = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    position: 'fixed',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    height: 'auto',
    padding: 0,
    overflow: 'auto',
    maxWidth: '374px',
    maxHeight: '369px',
    background: '#fff',
    borderRadius: '8px',
    position: 'absolute',
    top: 'calc(50vh - 20rem)',
    left: 'calc(50vw - 170px)',
    WebkitOverflowScrolling: 'touch',
    boxShadow: '0 4px 8px 1px rgb(0 0 0 / 0.8)',
  },
};

export default connect(
  (state: any) => ({
    selectedPlan: state.setting.get('selectedPlan'),
    selectedShop: state.dashboard.get('selectedShop'),
    paymentPackages: state.setting.get('paymentPackages'),
    currentUser: state.setting.get('currentUser'),
    trialDetails: state.setting.get('trialDetails'),
  }),
  {
    createShop: Actions.dashboard.createShop,
    fetchPackages: Actions.setting.fetchPackages,
    logout: Actions.login.logout,
    createTrial: Actions.setting.createTrial,
  }
)(SignUpSubscriptionView);
