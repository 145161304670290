import { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import Modal from "react-modal";
import { nanoid } from "nanoid";
import Switch from "react-switch";
import { Modifier } from "../Types";
import { connect } from "react-redux";
import { get, isEmpty } from "lodash";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { Actions } from "../../../internals/app/Actions";
import IconClose from "../../../assets/svgs/icon-close.svg";
import FormInput from "../../../components/inputs/FormInput";
import DeleteItem from "../../../components/Card/DeleteItem";
import { Formik, FieldArray, useFormikContext } from "formik";
import { formatPrice } from "../../../internals/managers/PaymentManager";

type ModifierProps = {
  modifier?: any;
  modifiers?: any;
  isOpen: boolean;
  currentUser: any;
  selectedShop: any;
  loadingAction: any;
  closeModal: () => void;
  createModifier: (params: any) => void;
  updateModifier: (params: any) => void;
  deleteModifier: (id: string, shopId: string) => void;
};

const customStyles: any = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    padding: 0,
    height: "auto",
    border: "none",
    overflow: "auto",
    maxWidth: "624px",
    background: "none",
    marginLeft: "20px",
    marginRight: "20px",
    left: "calc(50vw - 292px)",
    WebkitOverflowScrolling: "touch",
  },
};

const InitialValues = {
  title: "",
  setName: "",
  id: nanoid(8),
  isMandatory: false,
  items: [
    {
      price: 0,
      itemName: "",
      itemId: nanoid(8),
    },
  ],
};

const validationSchema = Yup.object({
  setName: Yup.string()
    .min(1, "At least 1 characters or more")
    .required("Please enter modifier title"),
  title: Yup.string()
    .min(1, "At least 1 characters or more")
    .required("Please enter modifier title"),
  isMandatory: Yup.boolean(),
  items: Yup.array().of(
    Yup.object().shape({
      itemName: Yup.string()
        .min(1, "At least 1 characters or more")
        .required("Please enter modifier item name"),
      price: Yup.number()
        .typeError("Please enter a valid price")
        .required("Please enter item price")
        .positive("Price must be more than 0"),
    })
  ),
});

const AddModifier = (props: ModifierProps) => {
  const {
    isOpen,
    modifier,
    modifiers,
    closeModal,
    currentUser,
    selectedShop,
    createModifier,
    updateModifier,
    deleteModifier,
  } = props;
  const formRef: any = useRef();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [initial, setInitial] = useState<any>(InitialValues);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    setIsEdit(!isEmpty(modifier));
    if (!isEmpty(modifier)) {
      let initial = {
        id: modifier.id,
        title: modifier.title,
        items: modifier.items,
        setName: modifier.setName,
        isMandatory: modifier.isMandatory,
      };
      setInitial(initial);
    } else {
      setInitial(InitialValues);
    }
  }, [isOpen, modifier]);

  const onSaveModifier = (values: any) => {
    const params: any = {
      id: nanoid(8),
      title: values.title,
      setName: values.setName,
      updatedTime: Date.now(),
      createdTime: Date.now(),
      shopId: selectedShop.shopId,
      createdUser: currentUser.uid,
      isMandatory: values.isMandatory,
    };
    const items = values?.items.map((item: any) => {
      item.price = parseFloat(item.price);
      return item;
    });
    params["items"] = items;
    if (isEdit) {
      params.id = modifier.id;
      params.shopId = modifier.shopId;
      params.updatedTime = Date.now();
      params.createdUser = modifier.createdUser;
      updateModifier(params);
    } else {
      createModifier(params);
    }
    closeModal();
  };

  const onModifierDelete = () => {
    deleteModifier(modifier.id, modifier.shopId);
    setIsOpenDelete(false);
    closeModal();
  };

  const isValidOnClose = () => {
    const formikValues = formRef.current?.values;
    return formikValues?.title === "" && isEmpty(formikValues?.items?.itemName);
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Product Modal"
      onRequestClose={() => {
        const isBackgroundClosed = isValidOnClose();
        if (isBackgroundClosed) {
          setIsOpenDelete(false);
          closeModal();
        }
      }}
    >
      <div className="relative shadow bg-white rounded-lg">
        <div className="flex flex-row h-14 justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-t-lg">
          <h3>{isEdit ? "Edit Modifier" : "Create Modifier"}</h3>
          <button
            type="button"
            onClick={() => closeModal()}
            className="top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
          >
            <AiOutlineClose size={20} />
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <Formik
          innerRef={formRef}
          validateOnBlur={true}
          validateOnChange={true}
          initialValues={initial}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => onSaveModifier(values)}
        >
          {({ errors, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <>
                  <RenderComponent />
                  <div className="flex flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
                    {isEdit ? (
                      <button
                        type="button"
                        onClick={() => setIsOpenDelete(true)}
                        className="inline-flex outline-none justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-red-700"
                      >
                        {"Delete"}
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => closeModal()}
                        className="inline-flex outline-none justify-center rounded-md border border-gray-300 bg-ghost-white-2 py-2 px-4 text-sm font-medium text-valhalla shadow hover:bg-ghost-white"
                      >
                        {"Close"}
                      </button>
                    )}
                    <button
                      type="submit"
                      disabled={isSubmitting || !isEmpty(errors)}
                      className="disabled:opacity-25 inline-flex justify-center rounded-md border border-transparent bg-medium-slate-blue py-2 px-4 text-sm font-medium text-white shadow hover:bg-slate-blue"
                    >
                      {isEdit ? "Update Modifier" : "Add Modifier"}
                    </button>
                  </div>
                </>
              </form>
            );
          }}
        </Formik>
        {isOpenDelete ? (
          <div className="absolute top-1/4 inset-14 w-full max-w-lg z-50 justify-center items-center">
            <DeleteItem
              onDelete={() => onModifierDelete()}
              closeModal={() => setIsOpenDelete(false)}
              title="Are you sure you want to delete this modifier?"
            />
          </div>
        ) : null}
      </div>
    </Modal>
  );
};
export default connect(
  (state: any) => ({
    modifiers: state.modifier.get("modifiers"),
    currentUser: state.setting.get("currentUser"),
    loadingAction: state.common.get("loadingAction"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    createModifier: Actions.modifier.createModifier,
    updateModifier: Actions.modifier.updateModifier,
    deleteModifier: Actions.modifier.deleteModifier,
  }
)(AddModifier);

const RenderComponent = () => {
  const { values, errors, touched, setFieldValue } =
    useFormikContext<Modifier>();
  const inputRef: any = useRef(null);
  const [editIndex, setEditIndex] = useState<number>(-1);

  return (
    <div className="overflow-auto bg-white px-4 py-5 sm:p-6">
      <div className="grid grid-cols-6 gap-6 flex-row">
        <div className="col-span-6 sm:col-span-3">
          <FormInput
            id="setName"
            value={values.setName}
            label="Modifier set name*"
            placeholder="Modifier set name"
            error={get(errors, "setName", null) as any}
            touched={get(touched, "setName", false) as any}
            onChange={(text: string) => {
              setFieldValue(`setName`, text);
            }}
          />
        </div>
        {/* <div className="col-span-6 sm:col-span-3">
      <label
          htmlFor={"optionName"}
          className="block mb-2 text-sm font-medium text-gray-700"
        >
          {"Modifier Set name*"}
        </label>
        <CreatableSelect
          isClearable
          options={options}
          styles={customStyles}
          placeholder={"Modifier set name*"}
          value={{
            label: values.setName,
            value: values.id,
          }}
          onChange={(newValue: any, actionMeta: any) => {
            setFieldValue(
              `setName`,
              actionMeta.action === "clear" ? "" : newValue.label
            );
          }}
        />
        {!isEmpty(errors?.setName) && submitCount > 0 && (
          <span className="mb-1 first-line:block text-xs font-normal text-rose-400">
            {errors.setName}
          </span>
        )}
      </div> */}
        <div className="col-span-6 sm:col-span-3">
          <FormInput
            id="title"
            value={values.title}
            label="Modifier Title"
            placeholder="Modifier Title"
            error={get(errors, "title", null) as any}
            touched={get(touched, "title", false) as any}
            onChange={(text: string) => {
              setFieldValue(`title`, text);
            }}
          />
        </div>
        <div className="col-span-6">
          <div className="flex flex-row justify-between items-center sm:w-1/2 w-full">
            <label
              htmlFor={"modifierStatus"}
              className="block mb-2 text-sm font-medium text-gray-700 mr-3"
            >
              Mandatory?
            </label>
            <Switch
              width={48}
              height={20}
              id="isMandatory"
              onColor="#6D71F9"
              checkedIcon={false}
              handleDiameter={30}
              uncheckedIcon={false}
              className="react-switch"
              onHandleColor="#F8F9FD"
              checked={values.isMandatory}
              boxShadow="0px 1px 5px rgba(109, 113, 249, 0.8)"
              activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
              onChange={(value: boolean) => setFieldValue(`isMandatory`, value)}
            />
          </div>
        </div>
        <FieldArray name={`items`}>
          {({ form, remove, push }) => {
            return (
              <div className="col-span-6 mb-4">
                <label
                  htmlFor={"modifierItems"}
                  className="block mb-2 text-sm font-medium text-gray-700"
                >
                  {"Modifier Items"}
                </label>

                <div className="flex flex-col w-full px-4 pt-3 bg-gray-50 rounded-md">
                  {values?.items?.map((item: any, index: number) => {
                    const itemErrors: any = get(
                      errors,
                      `items[${index}]`,
                      null
                    );
                    return (
                      <div
                        className={`flex flex-col w-full items-end border-b mb-2 pb-2`}
                      >
                        <div
                          className={`flex flex-row w-full items-center justify-between`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            value={item.itemName}
                            placeholder="Item Name"
                            name={`items[${index}].itemName`}
                            onChange={(e) => {
                              setFieldValue(
                                `items[${index}].itemName`,
                                e.target.value
                              );
                            }}
                            className="p-0 h-6 block w-4/5 bg-gray-50 outline-none focus:bg-transparent text-start text-sm font-medium text-valhalla"
                          />
                          <div className="w-2/5 h-6 gap-4 flex flex-row justify-center items-center">
                            {editIndex === index ? (
                              <input
                                ref={inputRef}
                                value={`${item.price}`}
                                onBlur={() => setEditIndex(-1)}
                                onChange={(e) => {
                                  setFieldValue(
                                    `items[${index}].price`,
                                    e.target.value
                                  );
                                }}
                                className="h-6 px-2 block w-5/6 rounded-md outline-none border-gray-300 border shadow sm:text-sm"
                              />
                            ) : (
                              <div
                                onClick={(e) => {
                                  e.preventDefault();
                                  setEditIndex(index);
                                }}
                                className="flex justify-end w-5/6 h-6 text-center items-center text-sm font-medium text-valhalla"
                              >
                                {formatPrice(item.price)}
                              </div>
                            )}
                            {values?.items.length > 1 ? (
                              <button
                                type="button"
                                onClick={() => remove(index)}
                                className="flex w-6 h-6 justify-center items-center rounded-lg bg-transparent"
                              >
                                <img
                                  alt="actions"
                                  src={IconClose}
                                  className="text-deactivate w-3 h-3"
                                />
                              </button>
                            ) : (
                              <div className="w-6 h-6" />
                            )}
                          </div>
                        </div>
                        <div
                          className={`flex flex-row w-full items-center justify-between`}
                        >
                          {!isEmpty(itemErrors?.itemName) && (
                            <span className="first-line:block text-xs font-normal text-rose-400	mr-8 mt-1">
                              {itemErrors.itemName}
                            </span>
                          )}
                          {!isEmpty(itemErrors?.price) && (
                            <span className="first-line:block text-xs font-normal text-rose-400	mr-8 mt-1">
                              {itemErrors.price}
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div
                  className="flex flex-row w-36 justify-start items-center gap-4 cursor-pointer mt-4"
                  onClick={() => {
                    const options: any = {
                      price: 0,
                      itemName: "",
                      itemId: nanoid(8),
                    };
                    push(options);
                  }}
                >
                  <BsFillPlusCircleFill color="#6D71F9" size={25} />
                  <label
                    htmlFor={"AddModofier"}
                    className="block text-sm text-medium-slate-blue cursor-pointer font-medium"
                  >
                    Add Modifier
                  </label>
                </div>
              </div>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};
