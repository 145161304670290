import ClientRepository from "../../internals/repository/ClientRepository";

export const ModuleEvents = {
  FETCH_CLIENTS: "FETCH_CLIENTS",
  UPDATE_CLIENT: "UPDATE_CLIENT",
  CREATE_CLIENT: "CREATE_CLIENT",
  DELETE_CLIENT: "DELETE_CLIENT",
  SEARCH_CLIENT: "SEARCH_CLIENT",
  RECEIVED_CLIENT: "RECEIVED_CLIENT",
  FETCH_PAGINATE_CLIENT: "FETCH_PAGINATE_CLIENT",
  FETCH_ASSIGN_APPOINTMENTS: "FETCH_ASSIGN_APPOINTMENTS",
  RECEIVED_ASSIGN_APPOINTMENT: "RECEIVED_ASSIGN_APPOINTMENT",
};

const createAction =
  (type, action = () => {}, meta) =>
  (...args) => ({
    type,
    payload: action(...args),
    meta,
  });

const fetchClients = createAction(
  ModuleEvents.FETCH_CLIENTS,
  async (shopId, limit = 10, lastKey = null) => {
    const result = await ClientRepository.fetchClients(shopId, limit, lastKey);
    return result;
  },
  { loading: true }
);

const createClient = createAction(
  ModuleEvents.CREATE_CLIENT,
  async (params) => {
    const result = await ClientRepository.createClient(params);
    return result;
  },
  { loading: true }
);

const updateClient = createAction(
  ModuleEvents.UPDATE_CLIENT,
  async (params) => {
    const result = await ClientRepository.updateClient(params);
    return result;
  },
  { loading: true }
);

const deleteClient = createAction(
  ModuleEvents.DELETE_CLIENT,
  async (id, shopId) => {
    const result = await ClientRepository.deleteClient(id, shopId);
    return result;
  },
  { loading: true }
);

const searchClient = createAction(
  ModuleEvents.SEARCH_CLIENT,
  async (shopId, text, limit = 30, lastKey = null) => {
    const result = await ClientRepository.searchClients(
      shopId,
      text,
      limit,
      lastKey
    );
    return result;
  },
  { loading: true }
);

const fetchAssignAppointments = createAction(
  ModuleEvents.FETCH_ASSIGN_APPOINTMENTS,
  async (id) => await ClientRepository.fetchUserAppointments(id),
  { loading: true }
);

const fetchPaginateClient = createAction(
  ModuleEvents.FETCH_PAGINATE_CLIENT,
  async (shopId, limit = 10, lastKey = null) => {
    const result = await ClientRepository.fetchClients(shopId, limit, lastKey);
    return result;
  },
  { loading: true }
);

const receivedClient = createAction(
  ModuleEvents.RECEIVED_CLIENT,
  async (data, type) => ({ data, type }),
  { loading: true }
);

const receivedAssignAppointment = createAction(
  ModuleEvents.RECEIVED_ASSIGN_APPOINTMENT,
  async (data, type) => ({ data, type }),
  { loading: true }
);

const exportedFuction = {
  fetchClients,
  updateClient,
  createClient,
  deleteClient,
  searchClient,
  receivedClient,
  fetchPaginateClient,
  fetchAssignAppointments,
  receivedAssignAppointment,
};

export default exportedFuction;
