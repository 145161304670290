import { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import Modal from "react-modal";
import { nanoid } from "nanoid";
import Select from "react-select";
import { connect } from "react-redux";
import FormInput from "../inputs/FormInput";
import DeleteItem from "../Card/DeleteItem";
import { AiOutlineClose } from "react-icons/ai";
import FormTextarea from "../inputs/FormTextarea";
import { Formik, useFormikContext } from "formik";
import { Actions } from "../../internals/app/Actions";
import { WarrantyDuration, WarrantyType } from "./Types";
import { get, isEmpty, capitalize, isNumber } from "lodash";

const style = {
  control: (base: any, state: any) => ({
    ...base,
    height: "40px",
    maxWidth: "144px",
    paddingRight: "10px",
    border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    "&:hover": {
      border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    },
    fontWeight: 500,
    fontSize: "14px",
    color: "#434343",
    paddingLeft: "5px",
    borderRadius: "6px",
    fontFamily: "Montserrat",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 10px 5px #c4d3f020",
  }),
};

type ModifierProps = {
  warranty?: any;
  isOpen: boolean;
  currentUser: any;
  selectedShop: any;
  loadingAction: any;
  closeModal: () => void;
  createWarranty: (params: any) => void;
  updateWarranty: (params: any) => void;
  deleteWarranty: (params: any) => void;
};

const AddWarranty = (props: ModifierProps) => {
  const {
    isOpen,
    warranty,
    closeModal,
    selectedShop,
    createWarranty,
    updateWarranty,
    deleteWarranty,
  } = props;

  const initials = {
    title: "",
    duration: 0,
    wrid: nanoid(8),
    description: "",
    durationType: "MONTHS",
    createdTime: Date.now(),
    updatedTime: Date.now(),
  };

  const formRef: any = useRef();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<WarrantyType>(initials);

  useEffect(() => {
    setIsEdit(!isEmpty(warranty));
    if (isEmpty(warranty)) {
      setInitialValues(initials);
    }
  }, [isOpen, warranty]);

  const customStyles: any = {
    overlay: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      position: "fixed",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      padding: 0,
      height: "auto",
      border: "none",
      overflow: "auto",
      maxWidth: "624px",
      background: "none",
      left: "calc(50vw - 292px)",
      WebkitOverflowScrolling: "touch",
    },
  };

  const onSaveModifier = (values: any) => {
    const params: any = {
      wrid: values.wrid,
      title: values.title,
      updatedTime: Date.now(),
      createdTime: Date.now(),
      shopId: selectedShop.shopId,
      description: values.description,
      durationType: values.durationType,
      duration: parseInt(values.duration),
    };
    if (isEdit) {
      params.wrid = warranty.wrid;
      params.shopId = warranty.shopId;
      params.createdTime = warranty.createdTime;
      updateWarranty(params);
    } else {
      createWarranty(params);
    }
    closeModal();
  };

  const WarrantySchema = Yup.object({
    title: Yup.string()
      .min(2, "Must be 2 characters or more")
      .required("Please enter warranty title"),
    durationType: Yup.string().required("Please enter warranty duration type"),
    duration: Yup.number()
      .min(1, "Please enter a valid duration")
      .typeError("Please enter a valid duration")
      .required("Please enter warranty duration"),
    // .when("durationType", {
    //   is: "DAYS",
    //   then: Yup.number().test("Days", "Please enter valid day count", (val) => {
    //     if (val == undefined) {
    //       return true;
    //     }
    //     return parseInt(val) != 0 || parseInt(val) <= 31;
    //   }),
    // }),
  });

  const onDelete = () => {
    deleteWarranty({ wrid: warranty.wrid, shopId: warranty.shopId });
    setIsOpenDelete(false);
    closeModal();
  };

  const onBackgroundClosed = () => {
    const formikValues = formRef.current?.values;
    return (
      !isEmpty(formikValues?.title) &&
      (!isEmpty(formikValues.duration) || isNumber(formikValues.duration))
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Product Modal"
      onRequestClose={() => {
        const isBackgroundClosed = onBackgroundClosed();
        if (isBackgroundClosed) {
          closeModal();
        }
        // closeModal();
      }}
    >
      <div className="relative shadow bg-white rounded-lg">
        <div className="flex flex-row h-14 justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-t-lg">
          <h3>{isEdit ? "Edit Warranty" : "Create Warranty"}</h3>
          <button
            type="button"
            onClick={() => closeModal()}
            className="top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
          >
            <AiOutlineClose size={20} />
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={WarrantySchema}
          onSubmit={(values) => onSaveModifier(values)}
        >
          {({ values, errors, handleSubmit, isSubmitting, submitCount }) => {
            return (
              <form onSubmit={handleSubmit}>
                <>
                  <div className="overflow-auto bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6 flex-row">
                      <RenderComponent warranty={warranty} />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
                    {isEdit ? (
                      <button
                        type="button"
                        onClick={() => setIsOpenDelete(true)}
                        className="inline-flex outline-none justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-red-700"
                      >
                        {"Delete"}
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => closeModal()}
                        className="inline-flex outline-none justify-center rounded-md border border-gray-300 bg-ghost-white-2 py-2 px-4 text-sm font-medium text-valhalla shadow hover:bg-ghost-white"
                      >
                        {"Close"}
                      </button>
                    )}
                    <button
                      type="submit"
                      disabled={isSubmitting || !isEmpty(errors)}
                      className="disabled:opacity-25 inline-flex justify-center rounded-md border border-transparent bg-medium-slate-blue py-2 px-4 text-sm font-medium text-white shadow hover:bg-slate-blue"
                    >
                      {isEdit ? "Update Warranty" : "Add Warranty"}
                    </button>
                  </div>
                </>
              </form>
            );
          }}
        </Formik>
        {isOpenDelete ? (
          <div className="absolute top-1/4 inset-14 w-full max-w-lg z-50 justify-center items-center bg-[rgba(0, 0, 0, 0.5)]">
            <DeleteItem
              onDelete={() => onDelete()}
              closeModal={() => setIsOpenDelete(false)}
              title="Are you sure you want to delete this modifier?"
            />
          </div>
        ) : null}
      </div>
    </Modal>
  );
};
export default connect(
  (state: any) => ({
    warranties: state.warranty.get("warranties"),
    currentUser: state.setting.get("currentUser"),
    loadingAction: state.common.get("loadingAction"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    createWarranty: Actions.warranty.createWarranty,
    updateWarranty: Actions.warranty.updateWarranty,
    deleteWarranty: Actions.warranty.deleteWarranty,
  }
)(AddWarranty);

const RenderComponent = ({ warranty }: any) => {
  const { values, errors, touched, submitCount, setFieldValue, handleChange } =
    useFormikContext<WarrantyType>();
  useEffect(() => {
    if (!isEmpty(warranty)) {
      setFieldValue("wrid", warranty.wrid);
      setFieldValue("title", warranty.title);
      setFieldValue("duration", warranty.duration);
      setFieldValue("description", warranty.description);
      setFieldValue("createdTime", warranty.createdTime);
      setFieldValue("durationType", warranty.durationType);
    }
  }, [warranty]);

  return (
    <>
      <div className="col-span-6">
        <FormInput
          id="title"
          value={values.title}
          label="Warranty Title"
          placeholder="Warranty title"
          error={get(errors, "title", null) as any}
          touched={get(touched, "title", false) as any}
          onChange={(text: string) => {
            setFieldValue(`title`, text);
          }}
        />
      </div>
      <div className="flex flex-row gap-6 col-span-6 sm:col-span-4">
        <FormInput
          id="duration"
          value={`${values.duration}`}
          placeholder="Duration"
          label="Warranty Duration"
          error={get(errors, "duration", null) as any}
          touched={get(touched, "duration", false) as any}
          onChange={(text: string) => {
            setFieldValue(`duration`, text);
          }}
        />
        <div className="w-full mt-[28px]">
          <Select
            styles={style}
            name="durationType"
            className="basic-single"
            classNamePrefix="select"
            options={WarrantyDuration}
            value={{ label: capitalize(values.durationType) }}
            placeholder={<div className="text-sm">durationType</div>}
            onChange={(data: any) => setFieldValue(`durationType`, data.value)}
          />
          {!isEmpty(errors?.durationType) && submitCount > 0 && (
            <span>{errors.durationType}</span>
          )}
        </div>
      </div>
      <div className="col-span-6 sm:col-span-6">
        <FormTextarea
          id="description"
          label="Description"
          error={errors.description}
          value={values.description}
          touched={touched.description}
          onChange={handleChange("description")}
        />
      </div>
    </>
  );
};
