import gql from 'graphql-tag';

export const CreateModifier = gql`
  mutation CreateModifier($input: CreateModifierInput!) {
    createModifier(input: $input) {
      id
      title
      shopId
      setName
      createdUser
      isMandatory
      createdTime
      updatedTime
      items {
        itemName
        itemId
        price
      }
    }
  }
`;

export const UpdateModifier = gql`
  mutation UpdateModifier($input: UpdateModifierInput!) {
    updateModifier(input: $input) {
      id
      title
      shopId
      setName
      isMandatory
      createdUser
      createdTime
      updatedTime
      items {
        itemId
        itemName
        price
      }
    }
  }
`;

export const DeleteModifier = gql`
  mutation DeleteModifier($input: DeleteModifierInput!) {
    deleteModifier(input: $input) {
      id
      shopId
    }
  }
`;

export const FetchModifiers = gql`
  query FetchModifiers($shopId: String!, $limit: Int, $nextToken: String) {
    fetchModifiers(shopId: $shopId, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        shopId
        setName
        isMandatory
        createdUser
        createdTime
        updatedTime
        items {
          itemId
          itemName
          price
        }
      }
      nextToken
    }
  }
`;