import ShopRepository from "../../internals/repository/ShopRepository";

export const ModuleEvents = {
  FETCH_CLAIMS: "FETCH_CLAIMS",
  SET_INVITE_CLAIM: "SET_INVITE_CLAIM",
  CREATE_SHOP_CLAIM: "CREATE_SHOP_CLAIM",
  UPDATE_SHOP_CLAIM: "UPDATE_SHOP_CLAIM",
  DELETE_SHOP_CLAIM: "DELETE_SHOP_CLAIM",
  FETCH_USER_CLAIMS: "FETCH_USER_CLAIMS",
  INVITE_MODAL_STATUS: "INVITE_MODAL_STATUS",
  UPDATE_INVITE_SHOP_CLAIM: "UPDATE_INVITE_SHOP_CLAIM"
};

const createAction =
  (type, action = () => { }, meta) =>
    (...args) => ({
      type,
      payload: action(...args),
      meta,
    });

const createShopClaim = createAction(
  ModuleEvents.CREATE_SHOP_CLAIM,
  async (param) => {
    const result = await ShopRepository.inviteShopClaim(param);
    return result;
  },
  { loading: true }
);

const updateShopClaim = createAction(
  ModuleEvents.UPDATE_SHOP_CLAIM,
  async (param) => {
    const result = await ShopRepository.updateShopClaim(param);
    return result;
  },
  { loading: true }
);

const updateInviteShopClaim = createAction(
  ModuleEvents.UPDATE_INVITE_SHOP_CLAIM,
  async (param, isCreate) => {
    const result = await ShopRepository.updateInviteShopClaim(param, isCreate);
    return result;
  },
  { loading: true }
);

const deleteShopClaim = createAction(
  ModuleEvents.DELETE_SHOP_CLAIM,
  async (param) => {
    const result = await ShopRepository.deleteShopClaim(param);
    return result;
  },
  { loading: true }
);

const setInviteClaim = createAction(
  ModuleEvents.SET_INVITE_CLAIM,
  async (claim) => {
    return claim;
  }
);

const setInviteStatus = createAction(
  ModuleEvents.INVITE_MODAL_STATUS,
  async (status) => {
    return status;
  }
);

const fetchClaims = createAction(
  ModuleEvents.FETCH_CLAIMS,
  async (shopId, limit = 30, nextToken = null) => {
    const result = await ShopRepository.fetchClaims({
      shopId,
      limit,
      nextToken
    });
    return result;
  },
  { loading: true }
);

const fetchUserClaims = createAction(
  ModuleEvents.FETCH_USER_CLAIMS,
  async (user) => {
    const result = await ShopRepository.fetchUserClaims(user);
    return result;
  },
  { loading: true }
);


const exportedFuction = {
  fetchClaims,
  setInviteClaim,
  createShopClaim,
  deleteShopClaim,
  setInviteStatus,
  updateShopClaim,
  fetchUserClaims,
  updateInviteShopClaim,
};

export default exportedFuction;
