import AuthRepository from "../../internals/repository/AuthRepository";
import MixpanelService, { MP_EVENTS } from "../../internals/service/MixpanelService";

export const ModuleEvents = {
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
  LOG_OUT: "LOG_OUT",
  SIGNUP_USER: "SIGNUP_USER",
  RESEND_CODE: "RESEND_CODE",
  CONFIRM_SIGN_UP: "CONFIRM_SIGN_UP",
  FORGET_PASSWORD: "FORGET_PASSWORD",
  NAVIGATE_OTP_VIEW: "NAVIGATE_OTP_VIEW",
  AUTHENTICATED_USER: "AUTHENTICATED_USER",
  FORGET_PASSWORD_SUBMIT: "FORGET_PASSWORD_SUBMIT",
};

const createAction =
  (type, action = () => {}, meta) =>
  (...args) => ({
    type,
    payload: action(...args),
    meta,
  });

const login = createAction(
  ModuleEvents.LOGIN,
  async (username, password) => {
    MixpanelService.setProfile({uid: username});
    MixpanelService.track(MP_EVENTS.SIGN_IN, {username})
    const data = await AuthRepository.login(username, password);
    return data;
  },
  { loading: true }
);

const authenticatedUser = createAction(
  ModuleEvents.AUTHENTICATED_USER,
  async () => {
    const user = await AuthRepository.authenticatedUser();
    return user;
  }
);

const logout = createAction(
  ModuleEvents.LOG_OUT,
  async (user) => await AuthRepository.logout()
);

const signupUser = createAction(ModuleEvents.SIGNUP_USER, async (user) => user);

const resendAuthCode = createAction(ModuleEvents.RESEND_CODE, async (email) => {
  await AuthRepository.resendAuthCode(email);
});

const signUp = createAction(
  ModuleEvents.SIGNUP,
  async (params, password) => {
    const result = await AuthRepository.signUp(params, password);
    return { result, params, password };
  },
  { loading: true }
);

const confirmSignUp = createAction(
  ModuleEvents.CONFIRM_SIGN_UP,
  async (payload) => {
    const data = await AuthRepository.confirmSignUp(payload);
    return data;
  },
  { loading: true }
);

const navigateOtpView = createAction(
  ModuleEvents.NAVIGATE_OTP_VIEW,
  async (data) => data
);

const forgotPassword = createAction(
  ModuleEvents.FORGET_PASSWORD,
  async (email) => {
    const data = await AuthRepository.forgotPassword(email);
    return { data, email };
  },
  { loading: true }
);

const forgotPasswordSubmit = createAction(
  ModuleEvents.FORGET_PASSWORD_SUBMIT,
  async (username, code, new_password) => {
    const data = await AuthRepository.forgotPasswordSubmit(
      username,
      code,
      new_password
    );
    return { data, new_password };
  },
  { loading: true }
);

const exportedFuction = {
  login,
  logout,
  signUp,
  signupUser,
  confirmSignUp,
  navigateOtpView,
  authenticatedUser,
  forgotPassword,
  forgotPasswordSubmit,
  resendAuthCode,
};

export default exportedFuction;
