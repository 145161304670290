import { Map } from "immutable";
import { ModuleEvents } from "./Actions";
import { orderBy, findIndex, isEmpty, filter } from "lodash";

const initialState = Map({
  warranties: [],
});

export const Reducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ModuleEvents.CREATE_WARRANTY: {
      if (payload && !payload.error) {
        let warranties = state.get("warranties");
        if (isEmpty(warranties)) {
          warranties = [payload];
        }
        warranties.push(payload);
        const sortResult = orderBy(warranties, ["updatedTime"], ["desc"]);
        return state.set("modifiers", [...sortResult]);
      }
      return state;
    }
    case ModuleEvents.UPDATE_WARRANTY: {
      if (payload && !payload.error) {
        const warranties = state.get("warranties");
        const index = findIndex(
          warranties,
          ({ wrid }) => wrid === payload.wrid
        );
        warranties[index] = payload;
        const sortResult = orderBy(warranties, ["updatedTime"], ["desc"]);
        return state.set("warranties", [...sortResult]);
      }
      return state;
    }
    case ModuleEvents.DELETE_WARRANTY: {
      if (payload && !payload.error) {
        const warranties = state.get("warranties");
        const newWarranties = filter(
          warranties,
          ({ wrid }) => wrid != payload.wrid
        );
        const sortResult = orderBy(
          newWarranties,
          ["updatedTime"],
          ["desc"]
        );
        return state.set("warranties", [...sortResult]);
      }
      return state;
    }
    case ModuleEvents.FETCH_WARRANTIES: {
      if (payload && !payload.error) {
        const sortResult = orderBy(
          payload.items,
          ["updatedTime"],
          ["desc"]
        );
        return state.set("warranties", [...sortResult]);
      }
      return state;
    }

    default:
      return state;
  }
};

export default Reducer;
