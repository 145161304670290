import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Offers } from "./Types";
import Switch from "react-switch";
import { get, isEmpty } from "lodash";
import { connect } from "react-redux";
import FormInput from "../inputs/FormInput";
import { TimePicker, DatePicker } from "antd";
import Plus from "../../assets/svgs/icon-plus.svg";
import Watch from "../../assets/svgs/watch-icon.svg";
import { useFormikContext, FieldArray } from "formik";
import Close from "../../assets/svgs/close-red-icon.svg";
import Calendar from "../../assets/svgs/calendar-icon.svg";
import { formatPrice } from "../../internals/managers/PaymentManager";

const OfferForm = (props: any) => {
  const { openOfferItem, removeTimePeriod, offer } = props;
  const { values, errors, touched, submitCount, handleChange, setFieldValue } =
    useFormikContext<Offers>();
  const [timeRanges, setTimeRanges] = useState<Array<any>>([]);
  const [timeRange, setTimeRange] = useState<Array<any>>([null, null]);

  useEffect(() => {
    if (!isEmpty(values)) {
      if (values.isEveryDay) {
        if (!isEmpty(values.timePeriod)) {
          const time = [
            !isEmpty(values.timePeriod.startTime)
              ? dayjs(values.timePeriod.startTime, "hh:mm A")
              : "",
            !isEmpty(values.timePeriod.startTime)
              ? dayjs(values.timePeriod.endTime, "hh:mm A")
              : "",
          ];
          setTimeRange(time);
        }
      } else {
        if (!isEmpty(values.timePeriods)) {
          const times = values.timePeriods.map((time: any) => {
            return [
              !isEmpty(time.startTime) ? dayjs(time.startTime, "hh:mm A") : "",
              !isEmpty(time.endTime) ? dayjs(time.endTime, "hh:mm A") : "",
            ];
          });
          setTimeRanges([...times]);
        }
      }
    }
  }, [values]);

  useEffect(() => {
    if (!isEmpty(offer)) {
      setFieldValue("title", offer.title);
      setFieldValue("isEveryDay", offer.isEveryDay);
      setFieldValue("displayTitle", offer.displayTitle);
      if (offer.isEveryDay) {
        const time = [
          dayjs(offer.timePeriod.startTime, "hh:mm A"),
          dayjs(offer.timePeriod.endTime, "hh:mm A"),
        ];
        setTimeRange(time);
        setFieldValue("timePeriods", []);
        setFieldValue("timePeriod", offer.timePeriod);
      } else {
        setFieldValue("timePeriod", {});
        setFieldValue("timePeriods", offer.timePeriods);
        const times = offer.timePeriods.map((time: any) => {
          return [
            dayjs(time.startTime, "hh:mm A"),
            dayjs(time.endTime, "hh:mm A"),
          ];
        });
        setTimeRanges([...times]);
      }
      const updatedData =
        offer.offerItems.length > values.offerItems.length
          ? offer.offerItems
          : values.offerItems;
      setFieldValue("offerItems", updatedData);
    }
  }, [offer]);

  useEffect(() => {
    if (!values.isEveryDay) {
      removeTimePeriod();
    }
  }, [values]);

  return (
    <>
      <div className="col-span-6 sm:col-span-3">
        <FormInput
          id="title"
          label="Offer title"
          error={errors?.title}
          value={values.title}
          touched={touched?.title}
          onChange={handleChange("title")}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <FormInput
          id="displayTitle"
          label="Offer display title(prints on the bill)"
          error={errors?.displayTitle}
          value={values.displayTitle}
          touched={touched?.displayTitle}
          onChange={handleChange("displayTitle")}
        />
      </div>
      <div className="col-span-3">
        <div className="flex flex-row justify-between">
          <label
            htmlFor={"isEveryDay"}
            className="block text-sm font-medium text-gray-700"
          >
            Every day offer?
          </label>
          <Switch
            width={46}
            height={24}
            onColor="#6D71F9"
            offColor="#9DABAC"
            checkedIcon={false}
            uncheckedIcon={false}
            className="w-10 mr-4"
            checked={values.isEveryDay}
            boxShadow={"0px 0px 2px 3px #00000016"}
            onChange={(status) => {
              if (status) {
                setFieldValue("timePeriod", {
                  startTime: "",
                  endTime: "",
                });
                setFieldValue("timePeriods", []);
              } else {
                setFieldValue("timePeriod", {});
                setFieldValue("timePeriods", [
                  {
                    date: "",
                    endTime: "",
                    startTime: "",
                  },
                ]);
              }
              setFieldValue(`isEveryDay`, status);
            }}
          />
        </div>
      </div>
      <div className="col-span-3" />
      {!values.isEveryDay ? (
        <div className="col-span-6">
          <FieldArray name="timePeriods">
            {({ insert, remove, push }) => {
              return (
                <div className="flex flex-col gap-4">
                  {!isEmpty(values.timePeriods) ? (
                    <div className="flex flex-col w-full px-4 pt-3 bg-ghost-white rounded-md">
                      <div className="w-full flex flex-row justify-between mb-4">
                        {["Date", "Time Period"]?.map(
                          (item: string, index: number) => {
                            return (
                              <div
                                key={index}
                                className={`flex text-center text-sm font-bold text-valhalla cursor-pointer justify-start ${
                                  index === 1 ? "mr-10" : ""
                                }`}
                              >
                                {item}
                              </div>
                            );
                          }
                        )}
                      </div>
                      <div className="w-full flex flex-col">
                        {values.timePeriods.map(
                          (period: any, index: number) => {
                            const error: any = !isEmpty(errors)
                              ? get(errors, `timePeriods.[${index}]`, null)
                              : null;

                            return (
                              <div
                                className={`flex flex-col w-full items-end border-b mb-3`}
                              >
                                <div
                                  className={`flex flex-row w-full items-center justify-between mb-2`}
                                >
                                  <div className="flex flex-row w-2/5 gap-3">
                                    <img
                                      alt="actions"
                                      src={Calendar}
                                      className="w-4 h-4 cursor-pointer"
                                    />
                                    <DatePicker
                                      name="date"
                                      bordered={false}
                                      suffixIcon={null}
                                      format={"YYYY/MM/DD"}
                                      className={"p-0 h-4"}
                                      value={
                                        period.date ? dayjs(period.date) : null
                                      }
                                      onChange={(
                                        value,
                                        dateString,
                                      ) => {
                                        setFieldValue(
                                          `timePeriods[${index}].date`,
                                          dateString
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="flex flex-row justify-end gap-6 w-3/5">
                                    <div className="flex flex-row gap-3">
                                      <TimePicker.RangePicker
                                        bordered={false}
                                        clearIcon={null}
                                        suffixIcon={null}
                                        format={"hh:mm A"}
                                        // value={
                                        //   period.startTime
                                        //     ? [
                                        //         moment(
                                        //           period.startTime,
                                        //           "hh:mm A"
                                        //         ) as any,
                                        //         moment(
                                        //           period.endTime,
                                        //           "hh:mm A"
                                        //         ) as any,
                                        //       ]
                                        //     : null
                                        // }
                                        value={timeRanges[index] as any}
                                        className={"p-0 h-3 items-center w-56"}
                                        placeholder={["Start time", "End time"]}
                                        onChange={(
                                          values: any,
                                          dateString: any
                                        ) => {
                                          setFieldValue(
                                            `timePeriods[${index}].startTime`,
                                            dateString[0]
                                          );
                                          setFieldValue(
                                            `timePeriods[${index}].endTime`,
                                            dateString[1]
                                          );
                                          timeRanges[index] = values;
                                          setTimeRanges([...timeRanges]);
                                        }}
                                      />
                                      <img
                                        src={Watch}
                                        alt="actions"
                                        className="w-4 h-4 cursor-pointer"
                                      />
                                    </div>
                                    {values.timePeriods.length > 1 && (
                                      <img
                                        src={Close}
                                        alt="actions"
                                        onClick={() => remove(index)}
                                        className="w-4 h-4  cursor-pointer"
                                      />
                                    )}
                                  </div>
                                </div>
                                {!isEmpty(error) && submitCount > 0 && (
                                  <span className="mb-1 text-xs font-normal text-rose-400 mr-10">
                                    {"Please enter time period*"}
                                  </span>
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  ) : null}
                  <button
                    className="flex flex-row w-40 text-sm font-medium text-gray-700"
                    onClick={() => {
                      const options: any = {
                        date: "",
                        startTime: "",
                        endTime: "",
                      };
                      removeTimePeriod();
                      push(options);
                    }}
                  >
                    {"Add Time Periods"}
                    <img className="ml-2" src={Plus} />
                  </button>
                </div>
              );
            }}
          </FieldArray>
        </div>
      ) : (
        <>
          <div className="col-span-3">
            <label
              htmlFor={"timePeriod"}
              className="block text-sm mb-2 font-medium text-gray-700"
            >
              Time period
            </label>
            <div className="flex flex-row gap-3 p-2.5 h-10 w-full rounded-md outline-none border-gray-300 border shadow sm:text-sm">
              <TimePicker.RangePicker
                bordered={false}
                clearIcon={null}
                suffixIcon={null}
                format={"hh:mm A"}
                value={timeRange as any}
                placeholder={["Start time", "End time"]}
                className={"p-0 h-4 items-center justify-between w-56"}
                onChange={(values: any, dateString: any) => {
                  setFieldValue(`timePeriod.startTime`, dateString[0]);
                  setFieldValue(`timePeriod.endTime`, dateString[1]);
                  setTimeRange(values);
                }}
                // value={[
                //   values.timePeriod?.startTime
                //     ? (moment(values.timePeriod.startTime, "hh:mm A") as any)
                //     : "",
                //   values.timePeriod?.endTime
                //     ? (moment(values.timePeriod.endTime, "hh:mm A"))
                //     : "",
                // ]}
              />
              <img
                src={Watch}
                alt="actions"
                className="w-4 h-4 cursor-pointer"
              />
            </div>
          </div>
          <div className="col-span-3" />
        </>
      )}
      <div className="col-span-6">
        {!isEmpty(values.offerItems) ? (
          <>
            <label
              htmlFor={"offerItems"}
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Offer items
            </label>
            <FieldArray name="offerItems">
              {({ insert, remove, push }) => {
                return (
                  <div className="flex flex-col">
                    {!isEmpty(values.offerItems) ? (
                      <div className="flex flex-col w-full px-4 pt-3 bg-ghost-white rounded-md">
                        <div className="w-full flex flex-row justify-between mb-4">
                          {["Item Name", "Offer"]?.map(
                            (item: string, index: number) => {
                              return (
                                <div
                                  key={index}
                                  className={`flex text-center text-sm font-bold text-valhalla cursor-pointer justify-start ${
                                    index === 1 ? "mr-10" : ""
                                  }`}
                                >
                                  {item}
                                </div>
                              );
                            }
                          )}
                        </div>
                        <div className="w-full flex flex-col">
                          {values.offerItems.map((item: any, index: number) => {
                            const offer =
                              item.type === "DISCOUNT"
                                ? item.discount.amountType === "PERCENTAGE"
                                  ? `${item.discount.amount}% Off`
                                  : `${formatPrice(item.discount.amount)} Off`
                                : item.products
                                    .map((product: any) => product.name)
                                    .join(", ");
                            return (
                              <div
                                className={`flex flex-row w-full items-center justify-between border-b pb-3 mb-3`}
                              >
                                <div className="flex flex-row w-2/5 text-start text-sm font-medium text-valhalla">
                                  {item.name}
                                </div>
                                <div className="flex flex-row justify-end items-center gap-6 w-3/5">
                                  <div className="text-start text-sm font-medium text-valhalla">
                                    {offer}
                                  </div>
                                  <img
                                    src={Close}
                                    alt="actions"
                                    onClick={() => remove(index)}
                                    className="w-3 h-3 cursor-pointer"
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                    <button
                      className="flex w-40 flex-row text-sm font-medium text-gray-700 mt-4"
                      onClick={() => openOfferItem()}
                    >
                      {"Add Offer Items"}
                      <img className="ml-2" src={Plus} />
                    </button>
                  </div>
                );
              }}
            </FieldArray>
          </>
        ) : (
          <button
            className="flex flex-row text-sm font-medium text-gray-700 mt-4"
            onClick={() => {
              openOfferItem(values.offerItems.length);
            }}
          >
            {"Add Offer Items"}
            <img className="ml-2" src={Plus} />
          </button>
        )}
        {!isEmpty(errors.offerItems) && submitCount > 0 && (
          <span className="mb-1 text-xs font-normal text-rose-400 mr-10">
            {errors?.offerItems as string}
          </span>
        )}
      </div>
    </>
  );
};
export default connect((state: any) => ({}), {})(OfferForm);
