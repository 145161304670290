import ProductRepository from '../../internals/repository/ProductRepository';
import MixpanelService, {
  MP_EVENTS,
} from '../../internals/service/MixpanelService';

export const ModuleEvents = {
  SET_IMAGE: 'SET_IMAGE',
  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  REMOVE_IMAGE: 'REMOVE_IMAGE',
  FETCH_BRANDS: 'FETCH_BRANDS',
  CREATE_BRAND: 'CREATE_BRAND',
  UPDATE_BRAND: 'UPDATE_BRAND',
  DELETE_BRAND: 'DELETE_BRAND',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  CREATE_PRODUCT_TAG: 'CREATE_PRODUCT_TAG',
  FETCH_TAGS: 'FETCH_TAGS',
  FETCH_PRODUCTS: 'FETCH_PRODUCTS',
  CREATE_CATEGORY: 'CREATE_CATEGORY',
  UPDATE_CATEGORY: 'UPDATE_CATEGORY',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  FETCH_CATEGORIES: 'FETCH_CATEGORIES',
  SELECTED_PRODUCT: 'SELECTED_PRODUCT',
  UPDATE_PRODUCT_OPTIONS: 'UPDATE_PRODUCT_OPTIONS',
  CREATE_PRODUCT_OPTIONS: 'CREATE_PRODUCT_OPTIONS',
  FETCH_PRODUCT_OPTION_SET: 'FETCH_PRODUCT_OPTION_SET',
};

const createAction =
  (type, action = () => {}, meta) =>
  (...args) => ({
    type,
    payload: action(...args),
    meta,
  });

const fetchBrands = createAction(ModuleEvents.FETCH_BRANDS, async (shopId) => {
  const result = await ProductRepository.fetchBrands(
    shopId,
    'FETCH_SHOP_BRANDS'
  );
  return result;
});

const fetchCategories = createAction(
  ModuleEvents.FETCH_CATEGORIES,
  async (shopId) => {
    const result = await ProductRepository.fetchCategories(shopId);
    return result;
  }
);
const fetchProducts = createAction(
  ModuleEvents.FETCH_PRODUCTS,
  async (shopId, limit = 200, lastKey = null) => {
    const result = await ProductRepository.fetchProducts(
      shopId,
      null,
      limit,
      lastKey
    );
    return result;
  }
);
const createCategory = createAction(
  ModuleEvents.CREATE_CATEGORY,
  async (param) => {
    const result = await ProductRepository.createCategory(param);
    return result;
  }
);
const updateCategory = createAction(
  ModuleEvents.UPDATE_CATEGORY,
  async (param) => {
    const result = await ProductRepository.updateCategory(param);
    return result;
  }
);
const deleteCategory = createAction(
  ModuleEvents.DELETE_CATEGORY,
  async (categoryId, sid) => {
    const result = await ProductRepository.deleteCategory(categoryId, sid);
    return result;
  }
);

const createProductTag = createAction(
  ModuleEvents.CREATE_PRODUCT_TAG,
  async (param) => {
    const result = await ProductRepository.createProductTag(param);
    return result;
  },
  {loading: true}
);

const fetchProductTags = createAction(
  ModuleEvents.FETCH_TAGS,
  async (shopId) => {
    const result = await ProductRepository.fetchProductTags(shopId);
    return result;
  },
  {loading: true}
);

const createProduct = createAction(
  ModuleEvents.CREATE_PRODUCT,
  async (param, type) => {
    MixpanelService.track(MP_EVENTS.CREATE_PRODUCT, param);
    const result = await ProductRepository.createProduct(param);
    return result;
  },
  {loading: true}
);
const updateProduct = createAction(
  ModuleEvents.UPDATE_PRODUCT,
  async (param) => {
    const result = await ProductRepository.updateProduct(param);
    return result;
  },
  {loading: true}
);
const deleteProduct = createAction(
  ModuleEvents.DELETE_PRODUCT,
  async (productId, shopId) => {
    const result = await ProductRepository.deleteProduct(productId, shopId);
    return result;
  }
);
const uploadImage = createAction(
  ModuleEvents.UPLOAD_IMAGE,
  async (file, type) => {
    const result = await ProductRepository.uploadProductImage(file);
    return {type, result};
  },
  {loading: true}
);
const setImage = createAction(ModuleEvents.SET_IMAGE, async (image) => image);
const removeImage = createAction(ModuleEvents.REMOVE_IMAGE, async () => true);

const createBrand = createAction(ModuleEvents.CREATE_BRAND, async (param) => {
  const result = await ProductRepository.createBrand(param);
  return result;
});
const updateBrand = createAction(ModuleEvents.UPDATE_BRAND, async (param) => {
  const result = await ProductRepository.updateBrand(param);
  return result;
});
const deleteBrand = createAction(
  ModuleEvents.DELETE_BRAND,
  async (shopId, id) => {
    const result = await ProductRepository.deleteBrand(shopId, id);
    return result;
  }
);

const fetchProductOptionSets = createAction(
  ModuleEvents.FETCH_PRODUCT_OPTION_SET,
  async (shopId, limit = 100, nextToken = null) => {
    const result = await ProductRepository.fetchProductOptionSets(
      shopId,
      limit,
      nextToken
    );
    return result;
  }
);

const createProductOptions = createAction(
  ModuleEvents.CREATE_PRODUCT_OPTIONS,
  async (param, type) => {
    const result = await ProductRepository.createProductOptions(param);
    return result;
  },
  {loading: true}
);
const updateProductOptions = createAction(
  ModuleEvents.UPDATE_PRODUCT_OPTIONS,
  async (param) => {
    const result = await ProductRepository.updateProductOptions(param);
    return result;
  },
  {loading: true}
);

const exportedFuction = {
  setImage,
  fetchBrands,
  uploadImage,
  removeImage,
  createBrand,
  updateBrand,
  deleteBrand,
  fetchProducts,
  createProduct,
  updateProduct,
  deleteProduct,
  createCategory,
  updateCategory,
  deleteCategory,
  fetchCategories,
  createProductOptions,
  updateProductOptions,
  fetchProductOptionSets,

  createProductTag,
  fetchProductTags,
};

export default exportedFuction;
