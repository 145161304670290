import { Map } from "immutable";
import { ModuleEvents } from "./Actions";
import { findIndex, orderBy, filter } from "lodash";

const initialState = Map({
  menus: [],
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (type) {
    case ModuleEvents.FETCH_MENU: {
      if (payload && !payload.error) {
        const sortResult = orderBy(payload, ["position"], ["asc"]);
        return state.set("menus", [...sortResult]);
      }
      return state;
    }
    case ModuleEvents.CREATE_MENU: {
      if (payload && !payload.error) {
        const menus = state.get('menus');
        menus.push(payload);
        const sortedModifiers = orderBy(menus, ['position'], ['asc']);
        return state.set('menus', [...sortedModifiers])
      }
      return state;
    }
    case ModuleEvents.UPDATE_MENU: {
      if (payload && !payload.error) {
        const menus = state.get('menus');
        const index = findIndex(menus, ({ menuId }) => menuId === payload.menuId)
        menus[index] = payload;
        const sortedModifiers = orderBy(menus, ['position'], ['asc']);
        return state.set('menus', [...sortedModifiers])
      }
      return state;
    }
    case ModuleEvents.DELETE_MODIFIER: {
      if (payload && !payload.error) {
        const modifiers = state.get('modifiers');
        const filtermodifiers = filter(modifiers, ({ id }) => id !== payload.id)
        const sortedModifiers = orderBy(filtermodifiers, ['updatedTime'], ['desc']);
        return state.set('modifiers', [...sortedModifiers])
      }
      return state;
    }

    default:
      return state;
  }
};

export default Reducer;
