import moment from "moment";
import { router } from "../../App";
import Swal from "sweetalert2";
import { ModuleEvents } from "./Actions";
import { Actions } from "../../internals/app/Actions";
import withReactContent from "sweetalert2-react-content";
import { delay, isEmpty, find, filter, get } from "lodash";
import MixpanelService, { MP_EVENTS } from "../../internals/service/MixpanelService";

export default {
  [ModuleEvents.FETCH_ADMIN_SHOPS]: async ({ dispatch, payload, pathname, appState }) => {
    if (payload && !payload.error) {

      const invitedUser = find(payload, (shop) => shop.claim.claimType === 'INVITED')
      if (!isEmpty(invitedUser)) {
        dispatch(Actions.user.setInviteClaim(invitedUser.claim));
        dispatch(Actions.user.setInviteStatus(true));
      }

      const activeShop = filter(payload, (shop) => get(shop, 'claim.claimType', null) === 'ACTIVE' || get(shop, 'claim.claimType', null) === null)
      if (!isEmpty(activeShop)) {
        const currentUser = appState.setting.get("currentUser");
        const userShop = find(activeShop, (shop) => shop.createdBy === currentUser.uid);
        dispatch(Actions.setting.fetchTrial(userShop ? userShop.shopId : activeShop[0].shopId));
        if (pathname === "/admin/recurring-loading") {
          const search = window.location.search;
          const params = new URLSearchParams(search);
          const pathId = params.get("id");
          const isUpdate = params.get("is-update");
          if (pathId) {
            dispatch(
              Actions.setting.checkSuccessRecurringPayment(pathId, isUpdate)
            );
          } else {
            dispatch(Actions.setting.openRecurringStatusModal("ERROR"));
          }
          if (userShop) {
            dispatch(Actions.dashboard.selectedAdminShop(userShop));
          } else {
            dispatch(Actions.dashboard.selectedAdminShop(activeShop[0]));
          }
        } else {
          // router.navigate("/dashboard");
          // dispatch(Actions.setting.fetchTrial(shop.shopId));
          if (userShop) {
            dispatch(Actions.dashboard.selectedAdminShop(userShop));
          } else {
            dispatch(Actions.dashboard.selectedAdminShop(activeShop[0]));
          }
        }
      }
      // else {
      //   router.navigate("/business-manage");
      // }
    }
  },

  [ModuleEvents.SELECTED_ADMIN_SHOP]: ({ dispatch, payload, appState }) => {
    const currentYear = moment().format('YYYY');
    const currentMonthName = moment().format('MMMM');
    const endDateOfYear = moment().endOf('year').valueOf();
    const startDateOfYear = moment().startOf('year').valueOf();

    if (payload) {
      const date = new Date();
      const shopId = payload.shopId;
      const currentUser = appState.setting.get("currentUser");
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();

      const todayTimestamp = moment(
        new Date(Date.now()).toDateString()
      ).valueOf();
      const reportMonth = `${year}_${month}`;

      dispatch(Actions.setting.fetchTrial(shopId));
      dispatch(Actions.setting.fetchSubscriptions(currentUser.uid));
      dispatch(Actions.user.fetchClaims(shopId));

      dispatch(Actions.employee.fetchEmployeesForShopId(shopId));
      
      dispatch(Actions.table.fetchTables(shopId));
      dispatch(Actions.table.fetchQRTables(shopId));
      dispatch(Actions.customer.fetchClients(shopId));

      dispatch(Actions.product.fetchBrands(shopId));
      dispatch(Actions.product.fetchProducts(shopId));
      dispatch(Actions.service.fetchCategories(shopId));
      dispatch(Actions.product.fetchCategories(shopId));
      dispatch(Actions.discount.fetchDiscounts(shopId));
      dispatch(Actions.modifier.fetchModifiers(shopId));
      dispatch(Actions.warranty.fetchWarranties(shopId));
      
      dispatch(Actions.transaction.fetchOrderTransactions(shopId));
      dispatch(Actions.dashboard.fetchSummaryForMonth(shopId, reportMonth));
      dispatch(Actions.dashboard.fetchOrdersForDate(shopId, todayTimestamp));
      dispatch(Actions.dashboard.fetchSummaryForDate(shopId, todayTimestamp));
      dispatch(Actions.transaction.fetchBatchSummary(shopId, currentUser.uid));
 
      MixpanelService.setProfile({ ...currentUser, shopId });
      MixpanelService.track(MP_EVENTS.SELECT_SHOP, payload);
      dispatch(Actions.report.fetchSummaryForMonthRange(shopId, startDateOfYear, endDateOfYear));
      dispatch(Actions.report.fetchSummaryForMonth(shopId, `${currentYear}_${currentMonthName}`));
      router.navigate("/dashboard");
    }
  },

  [ModuleEvents.CREATE_SHOP]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      dispatch(Actions.dashboard.fetchAdminShops(payload.createdBy));
    } else {
      return warning(`shop created failed!`);
    }
  },

  [ModuleEvents.UPDATE_SHOP]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      dispatch(Actions.common.openAlertBox("UPDATE", true, "SUCCESS"));
      delay(() => {
        dispatch(Actions.common.openAlertBox("UPDATE", false, "SUCCESS"));
      }, 3000);
    } else {
      return warning(`Shop updated failed!`);
    }
  },
};

const warning = (alert) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    icon: "error",
    text: alert,
    title: "Oops...",
    showCancelButton: true,
    showConfirmButton: false,
    cancelButtonColor: "#FF3333",
  });
};
