import gql from 'graphql-tag';

export const CreateUser = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      uid
      phoneNumber
      firstName
      lastName
      userName
      medicareNo
      healthFund
      healthFundId
      dob
      email
      image
      createdTime
      updatedTime
    }
  }
`;

export const UpdateUser = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      uid
      firstName
      lastName
      email
      image
      address
      custId
      phoneNumber
      updatedTime
      createdTime
      currency
      didDownload
      cardDetails {
        cardId
        brand
        country
        customer
        funding
        fingerprint
        last4
        expMonth
        expYear
        cardNumber
        isDefault
        name
      }
      notificationSettings {
        enablePushNotification
        enableChatNotification
      }
    }
  }
`;

export const GetUser = gql`
  query getUser($uid: String!) {
    getUser(uid: $uid) {
      uid
      firstName
      lastName
      email
      image
      address
      custId
      phoneNumber
      updatedTime
      createdTime
      currency
      didDownload
      cardDetails {
        cardId
        brand
        country
        customer
        funding
        fingerprint
        last4
        expMonth
        expYear
        cardNumber
        isDefault
        name
      }
      notificationSettings {
        enablePushNotification
        enableChatNotification
      }
    }
  }
`;
