import { useEffect, useState } from "react";
import * as Yup from "yup";
import Modal from "react-modal";
import { nanoid } from "nanoid";
import "../../layouts/Styles.css";
import { connect } from "react-redux";
import { filter, isEmpty } from "lodash";
import FormInput from "../inputs/FormInput";
import { AiOutlineClose } from "react-icons/ai";
import { CgClose } from "react-icons/cg";
import FormTextarea from "../inputs/FormTextarea";
import Delete from "../../assets/svgs/delete.svg";
import { Actions } from "../../internals/app/Actions";
import { Formik, Field, useFormikContext } from "formik";

type ProductProps = {
  discount?: any;
  isOpen: boolean;
  currentUser: any;
  selectedShop: any;
  loadingAction: any;
  selctedDiscount: any;
  closeModal: () => void;
  createDiscount: (params: any) => void;
  updateDiscount: (params: any, status: string) => void;
};

const AddDiscount = (props: ProductProps) => {
  const {
    isOpen,
    discount,
    closeModal,
    selectedShop,
    updateDiscount,
    createDiscount,
    selctedDiscount,
  } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    setIsEdit(!isEmpty(selctedDiscount));
  }, [selctedDiscount]);

  const customStyles: any = {
    overlay: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      position: "fixed",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      padding: 0,
      height: "auto",
      border: "none",
      overflow: "auto",
      maxWidth: "624px",
      background: "none",
      marginLeft: "20px",
      marginRight: "20px",
      left: "calc(50vw - 292px)",
      WebkitOverflowScrolling: "touch",
    },
  };

  const onSaveDiscount = (values: any) => {
    const data = {
      desc: values.desc,
      inId: values.inId,
      title: values.title,
      amount: values.amount,
      discType: values.discType,
      amountType: values.amountType,
      discId: isEdit ? selctedDiscount.discId : nanoid(),
    };
    const param: any = {
      updatedAt: `${Date.now()}`,
      shopId: selectedShop.shopId,
    };

    if (isEmpty(discount)) {
      param["discounts"] = [data];
      createDiscount(param);
    } else {
      const index = discount.discounts.findIndex(
        (discount: any) => discount.discId === data.discId
      );
      if (index > -1) {
        discount.discounts[index] = data;
        param["discounts"] = [...discount.discounts];
        updateDiscount(param, "UPDATE");
      } else {
        param["discounts"] = [...discount.discounts, data];
        updateDiscount(param, "CREATE");
      }
    }
    closeModal();
  };

  const validationSchema = Yup.object({
    inId: Yup.string().required("Internal ID is required"),
    title: Yup.string().required("Title is required"),
    amount: Yup.number()
      .typeError("Please enter a valid amount")
      .positive("Amount must be more than 0")
      .required("Amount is required"),
    amountType: Yup.string().required("Amount Type is required"),
    discType: Yup.string().required("Discount Type is required"),
  });

  const initialValues = {
    inId: "",
    desc: "",
    title: "",
    amount: "",
    amountType: "",
    discType: "ORDER_AMOUNT",
  };

  const onDeleteDiscount = () => {
    const values = filter(
      discount.discounts,
      (discount: any) => discount.discId !== selctedDiscount.discId
    );
    discount.discounts = [...values];
    updateDiscount(discount, "DELETE");
    setIsOpenDelete(false);
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Product Modal"
      onRequestClose={() => closeModal()}
    >
      <div className="relative shadow bg-white rounded-lg">
        <div className="flex flex-row h-14 justify-between items-center bg-gray-50 px-4 py-3 text-right text-xl font-semibold sm:px-6 rounded-t-lg">
          <h3>{isEdit ? "Edit Discount" : "Add Discount"}</h3>
          <button
            type="button"
            onClick={() => closeModal()}
            className=" top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
          >
            <CgClose size={20} />
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => onSaveDiscount(values)}
        >
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="overscroll-contain overflow-auto h-auto bg-white px-4 py-5 sm:p-6 rounded-lg">
                  <RenderComponent discount={selctedDiscount} />
                </div>

                <div className="flex h-16 flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
                  {isEdit ? (
                    <button
                      type="button"
                      onClick={() => setIsOpenDelete(true)}
                      className="inline-flex outline-none justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-red-700"
                    >
                      {"Delete Discount"}
                    </button>
                  ) : (
                    <div />
                  )}
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-indigo-700"
                  >
                    {isEdit ? "Save Changes" : "Add Discount"}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
        {isOpenDelete ? (
          <div className="absolute top-1/4 inset-14 w-full max-w-lg z-50 justify-center items-center">
            <div className="relative h-full md:h-auto">
              <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                <button
                  type="button"
                  onClick={() => setIsOpenDelete(false)}
                  className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <AiOutlineClose size={20} />
                  <span className="sr-only">Close modal</span>
                </button>
                <img
                  className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                  src={Delete}
                  alt="actions"
                />

                <p className="mb-4 text-gray-500 dark:text-gray-300">
                  Are you sure you want to delete this discount?
                </p>
                <div className="flex justify-center items-center space-x-4">
                  <button
                    type="button"
                    onClick={() => setIsOpenDelete(false)}
                    className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:outline-none hover:text-gray-900"
                  >
                    No, cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => onDeleteDiscount()}
                    className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none  "
                  >
                    Yes, I'm sure
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};
export default connect(
  (state: any) => ({
    discount: state.discount.get("discount"),
    currentUser: state.setting.get("currentUser"),
    loadingAction: state.common.get("loadingAction"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    createDiscount: Actions.discount.createDiscount,
    updateDiscount: Actions.discount.updateDiscount,
  }
)(AddDiscount);

const RenderComponent = ({ discount }: any) => {
  const { values, errors, touched, handleChange, setFieldValue } =
    useFormikContext<{
      inId: string;
      desc: string;
      title: string;
      amount: string;
      discType: string;
      amountType: string;
    }>();

  useEffect(() => {
    if (!isEmpty(discount)) {
      setFieldValue("inId", discount.inId);
      setFieldValue("desc", discount.desc);
      setFieldValue("title", discount.title);
      setFieldValue("amount", discount.amount);
      setFieldValue("discType", discount.discType);
      setFieldValue("amountType", discount.amountType);
    }
  }, [discount]);

  return (
    <div className="overflow-auto grid grid-cols-6 gap-6">
      <div className="col-span-6 md:col-span-3">
        <FormInput
          id="title"
          value={values.title}
          error={errors.title}
          label="Discount Title"
          touched={touched.title}
          placeholder="Discount name to identify"
          onChange={handleChange("title")}
        />
      </div>
      <div className="col-span-6 md:col-span-3">
        <FormInput
          id="inId"
          label="Internal ID"
          value={values.inId}
          error={errors.inId}
          touched={touched.inId}
          placeholder="Identifier for internal purposes"
          onChange={handleChange("inId")}
        />
      </div>
      <div className="col-span-6">
        <label
          htmlFor={"discType"}
          className="block mb-2 text-sm font-medium text-gray-700"
        >
          Discount Type
        </label>
        <div
          role="group"
          aria-labelledby="my-radio-group"
          className="w-full col-span-6 flex flex-row gap-6"
        >
          <label className="flex items-center text-sm font-medium text-gray-700">
            <Field
              type="radio"
              name="discType"
              value="ORDER_AMOUNT"
              className="mr-3 mw-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            Order Amount
          </label>
        </div>
        {!isEmpty(errors.discType) && touched.discType && (
          <span className="mt-1 first-line:block text-xs font-normal text-rose-400">
            {errors.discType}
          </span>
        )}
      </div>

      <div className="col-span-6 md:col-span-3">
        <label
          htmlFor={"amountType"}
          className="block text-sm mb-2 font-medium text-gray-700"
        >
          Discount Amount
        </label>
        <div
          role="group"
          aria-labelledby="my-radio-group"
          className="w-full col-span-6 flex flex-row gap-6"
        >
          <label className="flex items-center text-sm font-medium text-gray-700">
            <Field
              type="radio"
              value="PERCENTAGE"
              name="amountType"
              className="mr-3 mw-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            Percentage
          </label>
          <label className="flex items-center text-sm font-medium text-gray-700">
            <Field
              type="radio"
              value="FIXED_AMOUNT"
              name="amountType"
              className="mr-3 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            Fixed Amount
          </label>
        </div>
        {!isEmpty(errors.amountType) && touched.amountType && (
          <span className="mt-1 first-line:block text-xs font-normal text-rose-400">
            {errors.amountType}
          </span>
        )}
      </div>
      <div className="col-span-6 md:col-span-3">
        <FormInput
          id="amount"
          value={values.amount}
          error={errors.amount}
          label="Discount Amount"
          touched={touched.amount}
          placeholder="Discount name to identify"
          onChange={handleChange("amount")}
        />
      </div>
      <div className="col-span-6">
        <FormTextarea
          id="desc"
          label="Description"
          value={values.desc}
          error={errors.desc}
          touched={touched.desc}
          onChange={handleChange("desc")}
        />
      </div>
    </div>
  );
};
