import CheckIcon from '../../assets/svgs/check-icon.svg';
import CheckEmptyIcon from '../../assets/svgs/check-empty.svg';
import OpenLinkIcon from '../../assets/svgs/open-link.svg';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

const HelpGuide = ({products, employeeList, currentUser}: any) => {
  return (
    <div className="shadow-lg rounded p-6">
      <h2 className="text-base mb-1 font-semibold text-gray-600">
        Getting started
      </h2>
      <div>
        <div className="flex mt-4 mr-4">
          <img
            alt=""
            src={products?.products?.length > 0 ? CheckIcon : CheckEmptyIcon}
          />
          <label htmlFor="red-checkbox" className="ml-2 flex text-sm w-full">
            Create products/services for your shop
          </label>
          <Link to="/product?guide=true">
            <img src={OpenLinkIcon} className="min-w-[24px]" />
          </Link>
        </div>
        <div className="flex mt-4 mr-4">
          <img
            alt=""
            src={
              employeeList?.employees?.length > 0 ? CheckIcon : CheckEmptyIcon
            }
          />
          <label
            htmlFor="red-checkbox"
            className="ml-2 flex justify-between w-full  text-sm"
          >
            Create employees for the shop
          </label>
          <Link to="/employees?guide=true">
            <img src={OpenLinkIcon} className="min-w-[24px]" />
          </Link>
        </div>
        <div className="flex mt-4 mr-4 justify-between">
          <img src={currentUser.didDownload > 0 ? CheckIcon : CheckEmptyIcon} />
          <label htmlFor="red-checkbox" className="ml-2 mr-2 text-sm w-full">
            Download the point of sale app or HandPad app
          </label>
          <Link to="/download?guide=true">
            <img src={OpenLinkIcon} className="min-w-[24px]" />
          </Link>
        </div>

        <div className="flex mt-4 mr-4 justify-between">
          <img
            alt=""
            src={currentUser.didPinToken > 0 ? CheckIcon : CheckEmptyIcon}
          />
          <label htmlFor="red-checkbox" className="ml-2 flex text-sm w-full">
            Generate PIN and token for the onboarding{' '}
          </label>
          <Link to="/register-info?guide=true">
            <img src={OpenLinkIcon} className="min-w-[24px]" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default connect((state: any) => ({
  currentUser: state.setting.get('currentUser'),
  products: state.product.get('products'),
  employeeList: state.employee.get('employeeList'),
}))(HelpGuide);
