import {useEffect, useState, useRef} from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Switch from 'react-switch';
import {ProductTag, Products} from '../Types';
import {connect} from 'react-redux';
import {capitalize, get, isEmpty, find, kebabCase} from 'lodash';
import ItemDesc from '../../../components/Label/ItemDesc';
import IconClose from '../../../assets/svgs/icon-close.svg';
import FormInput from '../../../components/inputs/FormInput';
import {Field, useFormikContext, FieldArray} from 'formik';
import FormTextarea from '../../../components/inputs/FormTextarea';
import CustomSelect from '../../../components/inputs/CustomSelect';
import ItemPlusLabel from '../../../components/Label/ItemPlusLabel';
import {formatPrice} from '../../../internals/managers/PaymentManager';
import MDXEditorInput from '../../../components/inputs/MDXEditorInput';
import {MDXEditorMethods} from '@mdxeditor/editor';
import {Actions} from '../../../internals/app/Actions';
import {nanoid} from 'nanoid';

const selectStyles = {
  control: (base: any) => ({
    ...base,
    width: '367px',
    height: '100%',
    boxShadow: 'none',
    color: '#F0F1FA',
    borderColor: '#F0F1FA',
    '&:hover': {
      borderColor: '#F0F1FA',
    },
  }),
  container: (base: any) => ({
    ...base,
    width: '367px',
  }),
};

const MainForm = (props: any) => {
  const {
    tags,
    shop,
    product,
    openBrand,
    brandList,
    warranties,
    openWarranty,
    categoryList,
    warrantyList,
    openCategory,
    createProductTag,
    addProductOption,
    onSelectWarranty,
    editPriceVarients,
    addProductModifier,
    onSelectedModifier,
  } = props;
  const ref = useRef<MDXEditorMethods>(null);
  const {values, errors, touched, submitCount, handleChange, setFieldValue} =
    useFormikContext<Products>();
  const inputRef: any = useRef(null);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [selectedWarranty, setSelectedWarranty] = useState<any>(null);
  const [modifierEditIndex, setModifierEditIndex] = useState<Array<number>>([]);

  useEffect(() => {
    if (!isEmpty(product)) {
      const warannty = product?.warranty;
      setSelectedWarranty(
        !isEmpty(warannty)
          ? {
              value: warannty.wrid,
              label: capitalize(warannty.title),
            }
          : {}
      );
    }
  }, [product]);

  useEffect(() => {
    if (!values?.slug && values.productName?.length > 0) {
      setFieldValue('slug', kebabCase(values.productName));
    }
  }, [values, setFieldValue]);

  // const tableHeader = ["OPTION NAME", "UNIT", "PRICE"];
  const tableHeader = ['OPTION NAME', 'PRICE'];
  const tableModifierHeader = ['MODIFIER TITLE', 'PRICE'];

  const onChangeOption = (option: any) => {
    let result = find(
      warranties,
      (warranty: any) => warranty.wrid === option.value
    );
    onSelectWarranty(result);
    setSelectedWarranty(option);
  };

  const onChangeTags = (option: any) => {
    console.log(option);
    setFieldValue('tags', option);
  };

  const handleCreateTag = (value: string) => {
    createProductTag({shopId: shop.shopId, tagId: nanoid(8), tagName: value});
  };

  return (
    <>
      <div
        className={`col-span-6 ${
          isEmpty(values?.productOptions) ? 'sm:col-span-3' : 'sm:col-span-6'
        }`}
      >
        <FormInput
          id="productName"
          label="Product name"
          error={errors.productName}
          value={values.productName}
          touched={touched.productName}
          onChange={handleChange('productName')}
        />
      </div>
      {isEmpty(values.productOptions) && (
        <div className="col-span-6 sm:col-span-3">
          <FormInput
            id="price"
            label="Item price"
            error={errors.itemPrice}
            value={values.itemPrice}
            touched={touched.itemPrice}
            onChange={handleChange('itemPrice')}
          />
        </div>
      )}
      {isEmpty(values.productOptions) && (
        <div className="col-span-6 sm:col-span-3">
          <FormInput
            id="actualPrice"
            label="Price before discount"
            error={errors.actualPrice}
            value={values.actualPrice}
            touched={touched.actualPrice}
            onChange={handleChange('actualPrice')}
          />
        </div>
      )}
      {shop.shopType === 'E_COMMERCE' && (
        <div className="col-span-12 sm:col-span-6">
          <FormInput
            id="slug"
            label="Product slug"
            error={errors.slug}
            value={values.slug}
            touched={touched.slug}
            onChange={handleChange('slug')}
          />
        </div>
      )}

      <div className="col-span-6 sm:col-span-3">
        <Field
          id="category"
          name="category"
          isMulti={false}
          label="Category"
          options={categoryList}
          error={errors.category}
          component={CustomSelect}
          touched={touched.category}
          buttonOption={'Add Category'}
          placeholder="Select category"
          onClickOption={() => openCategory()}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <Field
          id="brand"
          name="brand"
          label="Brand"
          isMulti={false}
          options={brandList}
          error={errors.brand}
          touched={touched.brand}
          component={CustomSelect}
          buttonOption={'Add Brand'}
          placeholder="Select brand"
          onClickOption={() => openBrand()}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <CreatableSelect
          styles={selectStyles}
          isMulti
          options={tags.map(({tagName, tagId, shopId}: ProductTag) => ({
            value: tagId,
            label: tagName,
            shopId,
          }))}
          value={values.tags}
          placeholder={'Select or create new tags'}
          className={
            'block w-full h-10 shadow rounded-lg outline-none text-sm text-ship-cove border-gray-300'
          }
          isClearable
          onCreateOption={handleCreateTag}
          // onChange={handleChange('tag')}
          onChange={(option) => {
            onChangeTags(option);
          }}
        />
      </div>

      <div className="flex flex-row items-center col-span-6 sm:col-span-6 mb-4">
        <label
          htmlFor={'showMenu'}
          className="block text-sm font-medium text-gray-700 mr-4 "
        >
          Display in Menu App
        </label>
        <Switch
          width={48}
          height={20}
          id="showMenu"
          onColor="#6D71F9"
          checkedIcon={false}
          handleDiameter={30}
          uncheckedIcon={false}
          className="react-switch"
          onHandleColor="#F8F9FD"
          checked={values.showMenu}
          boxShadow="0px 1px 5px rgba(109, 113, 249, 0.8)"
          activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
          onChange={(value: boolean) => setFieldValue('showMenu', value)}
        />
      </div>
      <div className="col-span-6">
        <ItemPlusLabel
          title="Add Price Options"
          addItem={() => addProductOption()}
        />
        {!isEmpty(values.priceVariants) ? (
          <FieldArray name="priceVariants">
            {({insert, remove, push}) => {
              return (
                <div className="flex flex-col gap-4">
                  <div
                    onClick={() => editPriceVarients()}
                    className="flex flex-col w-full px-4 pt-3 bg-ghost-white rounded-md"
                  >
                    <div className="w-full flex flex-row justify-between mb-4">
                      {tableHeader?.map((item: string, index: number) => {
                        const isPrice = item === 'PRICE';
                        return (
                          <div
                            key={index}
                            onClick={() => {}}
                            className={`flex text-center text-sm font-bold text-valhalla justify-start cursor-pointer ${
                              isPrice ? 'justify-end pr-10' : 'justify-start'
                            } ${index === 1 ? 'w-1/5' : 'w-2/5'}`}
                          >
                            {capitalize(item)}
                          </div>
                        );
                      })}
                    </div>
                    <div className="w-full flex flex-col">
                      {values.priceVariants.map(
                        (variants: any, index: number) => {
                          return (
                            <div
                              className={`flex flex-row w-full items-center justify-between border-b mb-3 pb-1`}
                            >
                              <div className="w-2/5 text-start text-sm font-medium text-valhalla">
                                {variants.optionUnit}
                              </div>
                              {/* <Select
                                onChange={() => {}}
                                options={productOptions}
                                styles={selectCustomStyles}
                                className={
                                  "w-[120px] text-sm text-ship-cove bg-ghost-white"
                                }
                              /> */}
                              <div className="w-2/5 gap-4 flex flex-row items-end justify-around">
                                {editIndex === index ? (
                                  <input
                                    ref={inputRef}
                                    value={`${variants.price}`}
                                    onBlur={() => setEditIndex(null)}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `priceVariants[${index}].price`,
                                        !isEmpty(e.target.value)
                                          ? parseFloat(e.target.value)
                                          : e.target.value
                                      );
                                    }}
                                    className="p-2 h-8 block w-5/6 rounded-md justify-end outline-none border-gray-300 border shadow sm:text-sm"
                                  />
                                ) : (
                                  <div
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setEditIndex(index);
                                    }}
                                    className="w-5/6 py-2 h-8 text-end text-sm font-medium text-valhalla"
                                  >
                                    {formatPrice(variants.price)}
                                  </div>
                                )}
                                {values?.priceVariants.length > 1 && (
                                  <button
                                    type="button"
                                    onClick={() => remove(index)}
                                    className="flex items-center rounded-lg p-1.5 mb-1 justify-center bg-transparent"
                                  >
                                    <img
                                      alt="actions"
                                      src={IconClose}
                                      className="text-deactivate w-3 h-3"
                                    />
                                  </button>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              );
            }}
          </FieldArray>
        ) : (
          <ItemDesc
            desc="Add your product options to differentiate price for products. For
         example Large, Medium and Small Coffee"
          />
        )}
      </div>
      <div className="col-span-6">
        <ItemPlusLabel
          title="Add Modifier Set"
          addItem={() => addProductModifier()}
        />
        {!isEmpty(values?.modifiers) ? (
          <FieldArray name={`modifiers`}>
            {({form, remove, push}) => {
              return (
                <>
                  {values?.modifiers.map((modifier: any, index: number) => {
                    const modifierErrors: any = !isEmpty(errors)
                      ? get(errors, `modifiers.[${index}]`, null)
                      : null;
                    return (
                      <div className="flex flex-col w-full gap-3 mt-2">
                        <div className="w-full flex items-center px-4 flex-row justify-between">
                          <div className="block text-sm font-medium text-gray-700">
                            {modifier.title}
                          </div>
                          <img
                            alt="actions"
                            src={IconClose}
                            onClick={() => {
                              remove(index);
                            }}
                            className="cursor-pointer text-deactivate w-3 h-3"
                          />
                        </div>
                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            onSelectedModifier(modifier);
                          }}
                          className="flex flex-col cursor-pointer w-full px-4 pt-3 bg-ghost-white rounded-md"
                        >
                          <div className="w-full flex flex-row justify-between mb-3">
                            {tableModifierHeader?.map(
                              (item: string, index: number) => {
                                const isPrice = item === 'PRICE';
                                return (
                                  <div
                                    key={index}
                                    onClick={() => {}}
                                    className={`flex text-center text-sm font-bold text-valhalla justify-start cursor-pointer ${
                                      isPrice
                                        ? 'justify-end pr-10'
                                        : 'justify-start'
                                    } ${index === 1 ? 'w-1/5' : 'w-2/5'}`}
                                  >
                                    {capitalize(item)}
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <FieldArray name={`modifiers.${index}.items`}>
                            {({form, remove: childRemove, push: childPush}) => {
                              return (
                                <>
                                  {modifier?.items.map(
                                    (item: any, subIndex: number) => {
                                      const itemErrors: any = get(
                                        errors,
                                        `modifiers.[${index}].items[${subIndex}]`,
                                        null
                                      );

                                      return (
                                        <div
                                          className={`flex flex-col w-full items-end border-b mb-2 pb-2`}
                                        >
                                          <div
                                            className={`flex flex-row w-full items-center justify-between`}
                                          >
                                            <input
                                              type="text"
                                              autoComplete="off"
                                              value={item.itemName}
                                              placeholder="Item Name"
                                              name={`modifiers[${index}].items[${subIndex}].itemName`}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `modifiers[${index}].items[${subIndex}].itemName`,
                                                  e.target.value
                                                );
                                              }}
                                              className="p-0 h-6 block w-4/5 bg-gray-50 outline-none focus:bg-transparent text-start text-sm font-medium text-valhalla"
                                            />
                                            <div className="w-2/5 h-6 mr-6 gap-4 flex flex-row justify-center items-center">
                                              {modifierEditIndex[0] === index &&
                                              modifierEditIndex[1] ===
                                                subIndex ? (
                                                <input
                                                  ref={inputRef}
                                                  value={`${item.price}`}
                                                  onBlur={() =>
                                                    setModifierEditIndex([])
                                                  }
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      `modifiers[${index}].items[${subIndex}].price`,
                                                      e.target.value
                                                    );
                                                  }}
                                                  className="h-6 px-2 block w-5/6 rounded-md outline-none border-gray-300 border shadow sm:text-sm"
                                                />
                                              ) : (
                                                <div
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setModifierEditIndex([
                                                      index,
                                                      subIndex,
                                                    ]);
                                                  }}
                                                  className="flex justify-end w-5/6 h-6 text-center items-center text-sm font-medium text-valhalla"
                                                >
                                                  {formatPrice(item.price)}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div
                                            className={`flex flex-row w-full items-center justify-between`}
                                          >
                                            {!isEmpty(itemErrors?.itemName) &&
                                            submitCount > 0 ? (
                                              <span className="first-line:block text-xs font-normal text-rose-400	mr-8 mt-1">
                                                {itemErrors.itemName}
                                              </span>
                                            ) : !isEmpty(
                                                modifierErrors?.setName
                                              ) &&
                                              !isEmpty(modifierErrors?.title) &&
                                              submitCount > 0 ? (
                                              <span className="first-line:block text-xs font-normal text-rose-400	mr-8 mt-1">
                                                {
                                                  'Please enter valid modifier details*'
                                                }
                                              </span>
                                            ) : (
                                              <div />
                                            )}
                                            {!isEmpty(itemErrors?.price) &&
                                              submitCount > 0 && (
                                                <span className="first-line:block text-xs font-normal text-rose-400	mr-8 mt-1">
                                                  {itemErrors.price}
                                                </span>
                                              )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              );
                            }}
                          </FieldArray>
                        </div>
                      </div>
                    );
                  })}
                </>
              );
            }}
          </FieldArray>
        ) : (
          <ItemDesc desc="Add your product modifier to custormize for products." />
        )}
      </div>
      {shop.shopCategory === 'RETAILS' && (
        <div className="flex flex-row gap-6 items-center col-span-6">
          <div>
            <label
              htmlFor={'warranty'}
              className="block text-sm text-gray-700 mb-2 cursor-pointer font-bold"
            >
              Add Product Warranty
            </label>
            <Select
              styles={selectStyles}
              options={warrantyList}
              value={selectedWarranty}
              placeholder={'Warranty information to print on the bill'}
              className={
                'block w-full h-10 shadow rounded-lg outline-none text-sm text-ship-cove border-gray-300'
              }
              onChange={(option) => {
                onChangeOption(option);
              }}
            />
          </div>
          <div
            onClick={() => openWarranty()}
            className="inline-flex cursor-pointer justify-center mt-6 text-sm font-medium text-medium-slate-blue underline hover:text-slate-blue"
          >
            {'Add Warranty'}
          </div>
        </div>
      )}
      <div className="flex flex-row items-center col-span-6 sm:col-span-3">
        <label
          htmlFor={'sendToKitchen'}
          className="block mb-2 text-sm font-medium text-gray-700 mr-3"
        >
          Send to Kitchen(KOT)
        </label>
        <Switch
          width={48}
          height={20}
          id="sendToKitchen"
          onColor="#6D71F9"
          checkedIcon={false}
          handleDiameter={30}
          uncheckedIcon={false}
          className="react-switch"
          onHandleColor="#F8F9FD"
          checked={values.sendToKitchen}
          boxShadow="0px 1px 5px rgba(109, 113, 249, 0.8)"
          activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
          onChange={(value: boolean) => setFieldValue('sendToKitchen', value)}
        />
      </div>
      <div className="flex flex-row items-center col-span-6 sm:col-span-3">
        <label
          htmlFor={'sendToBar'}
          className="block text-sm font-medium text-gray-700 mr-3"
        >
          Send to Bar(BOT)
        </label>
        <Switch
          width={48}
          height={20}
          id="sendToBar"
          onColor="#6D71F9"
          checkedIcon={false}
          handleDiameter={30}
          uncheckedIcon={false}
          className="react-switch"
          onHandleColor="#F8F9FD"
          checked={values.sendToBar}
          boxShadow="0px 1px 5px rgba(109, 113, 249, 0.8)"
          activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
          onChange={(value: boolean) => setFieldValue('sendToBar', value)}
        />
      </div>
      <div className="col-span-6 sm:col-span-6">
        {/* <FormTextarea
          id="description"
          label="Description"
          error={errors.description}
          value={values.description}
          touched={touched.description}
          onChange={handleChange('description')}
        /> */}
        <MDXEditorInput
          ref={ref}
          id="description"
          label="Description"
          error={errors.description}
          value={values.description}
          touched={touched.description}
          onChange={handleChange('description')}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <FormInput
          id="stockCount"
          type="number"
          label="Stock Count"
          error={errors.stockCount}
          value={`${values.stockCount}`}
          touched={touched.stockCount}
          onChange={(val) => setFieldValue('stockCount', parseInt(val))}
        />
      </div>
    </>
  );
};
export default connect(
  (state: any) => ({
    warranties: state.warranty.get('warranties'),
    shop: state.dashboard.get('selectedShop'),
    tags: state.product.get('tags'),
  }),
  {
    createProductTag: Actions.product.createProductTag,
  }
)(MainForm);
