import { router } from "../../App";
import { connect } from "react-redux";
import { Actions } from "../../internals/app/Actions";
import { MdOutlineSubscriptions } from "react-icons/md";

const SubscriptionHeader = ({
  list,
  toggleList,
}: {
  list: string;
  toggleList: (status: string) => void;
}) => {
  
  return (
    <div className="flex flex-row w-full justify-between">
      <div className="flex flex-row flex-wrap gap-8 rounded-md" role="group">
        <div
          onClick={() => toggleList("SUBSCRIPTION")}
          className={
            "text-sm font-medium cursor-pointer" +
            (list === "SUBSCRIPTION"
              ? " text-midnight-express border-b border-valhalla"
              : " text-ship-cove")
          }
        >
          Subscription Plans
        </div>
        <div
          onClick={() => toggleList("PAYMENT")}
          className={
            "text-sm font-medium cursor-pointer" +
            (list === "PAYMENT"
              ? " text-midnight-express border-b border-valhalla"
              : " text-ship-cove")
          }
        >
          {"Past Payments"}
        </div>
      </div>

      <button
        type="button"
        onClick={() => router.navigate("/subscription")}
        className="inline-flex gap-2 items-center px-4 py-2 text-sm font-medium text-white bg-[#6D71F9] border border-gray-200 rounded-md hover:bg-gray-100 hover:text-blue-700"
      >
        <MdOutlineSubscriptions size={20} />
        Purchase Plan
      </button>
    </div>
  );
};
export default connect((state: any) => ({}), {
  setImage: Actions.product.setImage,
})(SubscriptionHeader);
