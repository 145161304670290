import { useEffect, useState } from "react";
import {
  Title,
  Filler,
  Legend,
  Tooltip,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale,
  Chart as ChartJS,
} from "chart.js";
import { connect } from "react-redux";
import { Line } from "react-chartjs-2";
import {
  getMonthSales,
  getMonthLabels,
} from "../../internals/managers/ReportsManager";

ChartJS.register(
  Title,
  Filler,
  Legend,
  Tooltip,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale
);

const AreaChart = (props: any) => {
  const { yearReports, monthRange } = props;
  const [labels, setLabels] = useState<any>([]);
  const [revenue, setRevenue] = useState<any>([]);

  useEffect(() => {
    const labels = getMonthLabels(monthRange);
    const details = getMonthSales(monthRange, labels, yearReports);
    setLabels(labels);
    setRevenue(details);
  }, [yearReports]);

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          font: {
            size: 12,
            align: "start",
            family: "Inter",
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          padding: 5,
          font: "Inter",
          color: "#272848",
          crossAlign: "start",
          callback: (v: any) => `Rs: ${v}`,
        },
      },
      x: {
        ticks: {
          padding: 5,
          font: "Inter",
          color: "#272848",
        },
        grid: {
          display: false,
        },
      },
    },
    interaction: {
      intersect: false,
    },
  };

  let width: any, height: any, gradient: any;
  const getGradient = (ctx: any, chartArea: any) => {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.top
      );
      gradient.addColorStop(0, "rgb(109, 113, 249, 0.1)");
      gradient.addColorStop(0.5, "rgb(109, 113, 249, 0.7)");
      gradient.addColorStop(1, "rgb(109, 113, 249)");
    }

    return gradient;
  };

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        data: revenue,
        label: "Revenue",
        borderColor: (context: any) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return;
          }
          return getGradient(ctx, chartArea);
        },
        backgroundColor: (context: any) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return;
          }
          return getGradient(ctx, chartArea);
        },
      },
    ],
  };

  return (
    <div className="flex flex-row items-start gap-6 mb-4">
      <Line height={"80px"} options={options} data={data} />
    </div>
  );
};
export default connect(
  (state: any) => ({
    yearReports: state.report.get("yearReports"),
  }),
  () => ({})
)(AreaChart);
