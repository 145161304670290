import Repository from "./Repository";
import { get, isArray, isEmpty } from 'lodash';
import { generateFileId } from "../managers/ImageManager";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import { isNetworkError } from "../managers/SecurityManager";
import { getProductOptionSets } from '../managers/ProductManager';
import { CreateBrand, CreateOption, DeleteBrand, UpdateBrand, UpdateOption, fetchOptionSets } from "./schema/Product.schema";
import { MENU_BUCKET } from "../config/aws_config";
import { CreateMenu, GetMenus, UpdateMenu } from "./schema/Menu.schema";
import { CreateProductTag, FetchProductTags } from "./schema/ShopOrder.schema";
class ProductRepository extends Repository {
  async fetchBrands(shopId, endPointName) {
    const message = this.buildMessage({ shopId, endPointName });
    try {
      const result = await this.apiPost({
        apiName: this.API_ADMIN_DASHBOARD,
        path: "/brands",
        message,
      });

      return result.data.brandResult;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async fetchCategories(shopId) {
    const message = this.buildMessage({ shopId });
    try {
      const result = await this.apiPost({
        apiName: this.API_ADMIN_DASHBOARD,
        path: "/products/category",
        message,
      });
      return result.data.categoryResult;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async fetchProducts(sid, categoryId = null, limit = 2, lastKey = null) {
    const message = this.buildMessage({ sid, categoryId, limit, lastKey });
    try {
      const result = await this.apiPost({
        apiName: this.API_ADMIN_DASHBOARD,
        path: "/products",
        message,
      });
      return result.data.productResult;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async createCategory(param) {
    const message = this.buildMessage(param);
    try {
      const result = await this.apiPost({
        apiName: this.API_ADMIN_DASHBOARD,
        path: "/product/category",
        message,
      });
      return result.data.categoryResult;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async updateCategory(param) {
    const message = this.buildMessage(param);
    try {
      const result = await this.apiPut({
        apiName: this.API_ADMIN_DASHBOARD,
        path: "/productCategory",
        message,
      });
      return result.data.productCategoryResult;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async deleteCategory(categoryId, sid) {
    const message = this.buildMessage({ categoryId, sid });
    try {
      const result = await this.apiDelete({
        apiName: this.API_ADMIN_DASHBOARD,
        path: '/productCategory',
        message,
      });

      return result.data.status;
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  }

  updateProductOptions = async (data) => {
    try {
      data['updatedTime'] = Date.now();
      await this.API.graphql(
        graphqlOperation(UpdateOption, { input: data })
      );
    } catch (error) {
      console.warn("update product options error", error);
    }
  };

  createProductOptions = async (data) => {
    const productOptions = await getProductOptionSets(data);
    await Promise.all(
      productOptions.map(async (productOption) => {
        try {
          await this.API.graphql(
            graphqlOperation(CreateOption, { input: productOption })
          );
        } catch (error) {
          console.warn("create product options error", error);
          if (isArray(error?.errors)) {
            const conditional = error.errors.find(
              ({ errorType }) =>
                errorType === "DynamoDB:ConditionalCheckFailedException"
            );
            if (conditional) {
              this.updateProductOptions(productOption);
            } else {
              throw error;
            }
          }
        }
      })
    );
  };

  async createProduct(param) {
    const message = this.buildMessage(param);
    try {
      const result = await this.apiPost({
        apiName: this.API_ADMIN_DASHBOARD,
        path: "/product",
        message,
      });
      return result.data.productResult;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async updateProduct(param) {
    const message = this.buildMessage(param);
    try {
      const result = await this.apiPut({
        apiName: this.API_ADMIN_DASHBOARD,
        path: "/product",
        message,
      });
      return result.data.productResult;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async deleteProduct(productId, shopId) {
    const message = this.buildMessage({ productId, shopId });
    try {
      const result = await this.apiDelete({
        apiName: this.API_ADMIN_DASHBOARD,
        path: "/product",
        message,
      });
      return result.data.status;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async createProductTag(param) {
    try {
      const result = await this.API.graphql(
        graphqlOperation(CreateProductTag, { input: param })
      );
      return result.data.createProductTag;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async fetchProductTags(shopId) {
    try {
      const result = await this.API.graphql(
        graphqlOperation(FetchProductTags, { shopId })
      );
      return result.data.fetchProductTags;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async uploadProductImage(file, type) {
    try {
      const bucket = type === 'menuImage' ? MENU_BUCKET : null;
      const fileId = generateFileId(file);
      const result = await this.uploadImage(file, fileId, bucket);
      return result.key;
    } catch (error) {
      console.warn("uploading error", error);
      return { error };
    }
  }

  async createMenu(param) {
    try {
      const result = await this.API.graphql(
        graphqlOperation(CreateMenu, { input: param })
      );
      return result.data.createMenu;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async updateMenu(param) {
    try {
      const result = await this.API.graphql(
        graphqlOperation(UpdateMenu, { input: param })
      );
      return result.data.updateMenu;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async fetchMenu(shopId) {
    try {
      const result = await this.API.graphql(
        graphqlOperation(GetMenus, { shopId })
      );
      return result.data.getMenus;
    } catch (error) {
      console.warn("fetch menu error", error);
    }
  }


  async createBrand(param) {
    try {
      const result = await this.API.graphql(
        graphqlOperation(CreateBrand, { input: param })
      );
      return result.data.createBrand;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async updateBrand(param) {
    try {
      const result = await this.API.graphql(
        graphqlOperation(UpdateBrand, { input: param })
      );
      return result.data.updateBrand;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async deleteBrand(shopId, id) {
    try {
      const result = await this.API.graphql(
        graphqlOperation(DeleteBrand, { input: { shopId, id } })
      );
      return result.data.deleteBrand;
    } catch (error) {
      console.warn('error', error);
      return { error };
    }
  }

  fetchProductOptionSets = async (
    shopId,
    limit,
    nextToken,
    retryCount = 1
  ) => {
    try {
      const params = {
        shopId,
        limit,
        nextToken,
      };
      const result = await this.API.graphql(
        graphqlOperation(fetchOptionSets, params)
      );
      return get(result, "data.fetchProductOptionSets", {});
    } catch (error) {
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchProductOptionSets(
          shopId,
          limit,
          nextToken,
          ++retryCount
        );
      }
      console.warn("fetch option sets error", error);
      return { error };
    }
  };


}
export default new ProductRepository();
