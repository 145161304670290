import Modal from "react-modal";
import "../../layouts/Styles.css";
import { connect } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import Delete from "../../assets/svgs/delete.svg";

const customStyles: any = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    padding: 0,
    border: "none",
    maxWidth: "512px",
    position: "absolute",
    backgroundColor: "unset",
    top: "calc(50vh - 20rem)",
    left: "calc(50vw - 256px)",
  },
};

type ProductProps = {
  isOpen: boolean;
  onDelete: () => void;
  closeModal: () => void;
};

const DeleteItem = (props: ProductProps) => {
  const { isOpen, closeModal, onDelete } = props;

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Delete Modal"
      onRequestClose={() => closeModal()}
    >
      <div className="relative h-full md:h-auto">
        <div className="relative p-4 text-center bg-white rounded-lg shadow sm:p-5">
          <button
            type="button"
            onClick={() => closeModal()}
            className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
          >
            <AiOutlineClose size={20} />
            <span className="sr-only">Close modal</span>
          </button>
          <img
            className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
            src={Delete}
            alt="actions"
          />

          <p className="mb-4 text-gray-500 ">
            Are you sure you want to delete this item?
          </p>
          <div className="flex justify-center items-center space-x-4">
            <button
              type="button"
              onClick={() => onDelete()}
              className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none  "
            >
              Yes, I'm sure
            </button>
            <button
              type="button"
              onClick={() => closeModal()}
              className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:outline-none hover:text-gray-900"
            >
              No, cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default connect((state: any) => ({}), {})(DeleteItem);
