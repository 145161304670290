import React, { useState } from "react";
import "../../layouts/Styles.css";
import { connect } from "react-redux";
import { capitalize, get } from "lodash";

const CategoryList = (props: any) => {
  const { categories } = props;
  return (
    <div className="flex flex-col justify-start">
      <h2 className="text-base mb-8 font-semibold text-valhalla">
        All Categories
      </h2>
      <ul className="mb-8 ml-2 space-y-4 text-left text-gray-500 ">
        {categories.map((item: any, index: number) => {
          return (
            <li key={index} className="flex items-center space-x-3">
              <div className="w-2 h-2 rounded bg-gray-300" />
              <div className="flex flex-row gap-1">
                <span className="text-sm">{capitalize(item.categoryName)}</span>
                <span className="text-sm">{`(${get(item, 'productCount', 0)})`}</span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default connect(
  (state: any) => ({
    categories: state.product.get("categories"),
  }),
  {}
)(CategoryList);
