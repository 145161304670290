import mixpanel from "mixpanel-browser";
import { isProdEnv } from "../config/aws_config";

const MixpanelService = () => {
  return {
    init: () => {
      mixpanel.init("02811da09c435c809ba28bb78510e4d3", { debug: !isProdEnv });
    },
    setProfile: ({ uid, firstName, lastName, email, shopId }) => {
      try {
        mixpanel.identify(uid, {
          firstName, lastName, email, shopId
        });
      } catch (e) {
        console.log('Mixpanel Error')
      }

      // mixpanel.people.set({
      //   ...merchant,
      // });
    },
    // setProfile: (merchant) => {
    //   mixpanel.identify(merchant.shopId);
    //   mixpanel.people.set({
    //     ...merchant,
    //   });
    // },
    // setEmployee: (employee) => {
    //   mixpanel.people.set({
    //     ...employee,
    //   });
    // },
    track: (tag, data) => {
      try {
        mixpanel.track(tag, { ...data });
      } catch (e) {
        console.log('Mixpanel error')
      }
    },
  };
};

export default MixpanelService();


export const MP_EVENTS = {
  SIGN_IN: 'SIGN_IN',
  SIGN_UP_EMAIL: 'SIGN_UP_EMAIL',
  SIGN_UP_GOOGLE: 'SIGN_UP_GOOGLE',
  CREATE_SHOP: 'CREATE_SHOP',
  SELECT_SHOP: 'SELECT_SHOP',
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  CREATE_EMPLOYEE: 'CREATE_EMPLOYEE',
  GENERATE_TOKEN: 'GENERATE_TOKEN',
  SUCCESS_SUBSCRIPTION: 'SUCCESS_SUBSCRIPTION',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
}