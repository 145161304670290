import { Map } from "immutable";
import { findIndex } from "lodash";
import { ModuleEvents } from "./Actions";

const initialState = Map({
  discount: {},
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.FETCH_DISCOUNTS: {
      if (payload && !payload.error) {
        return state.set("discount", payload);
      }
      return state;
    }
    case ModuleEvents.CREATE_DISCOUNT: {
      if (payload && !payload.error) {
        return state.set("discount", payload);
      }
      return state;
    }
    case ModuleEvents.UPDATE_DISCOUNT: {
      const { result } = payload;
      if (result && !result.error) {
        return state.set("discount", result);
      }
      return state;
    }
    default:
      return state;
  }
};

export default Reducer;
