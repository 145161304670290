import { get, isEmpty } from "lodash";
import { connect } from "react-redux";
import { Actions } from "../../internals/app/Actions";
import defaultImage from "../../assets/images/default-store.jpeg";
import location from "../../assets/svgs/subscription/location.svg";
import { formatPrice } from "../../internals/managers/PaymentManager";
import { getShopCtaegory, getShopType } from "../../modules/login/Constants";

const PaymentCard = (props: any) => {
  const {
    onClickBack,
    currentUser,
    selectedPlan,
    createCustomer,
    selectedLocation,
    createHostedSubscription,
  } = props;

  const onClickPayment = () => {
    if (isEmpty(currentUser.custId)) {
      const name = `${currentUser.firstName} ${currentUser.lastName}`;
      createCustomer({
        name,
        userId: currentUser.uid,
        email: currentUser.email,
        phone: currentUser.phoneNumber,
        shopId: selectedLocation.shopId,
      });
    }
    createHostedSubscription({
      planId: selectedPlan.planId,
      userId: currentUser.uid,
      shopId: selectedLocation.shopId,
      priceId: selectedPlan.priceId,
    });
  };

  return (
    <div className="flex flex-row flex-wrap gap-2">
      <div className="flex w-[50%] flex-col p-6 shadow rounded-lg">
        <div className="mb-4">{"Choose Location"}</div>
        <div className="max-w-[230px] mb-10 p-1 bg-white border border-gray-200 rounded-lg shadow">
          <img
            alt=""
            src={defaultImage}
            className="w-full h-[119px] object-cover overflow-hidden rounded-t-lg"
          />
          <div className="p-3">
            <h5 className="text-[11px] font-bold tracking-tight text-medium-slate-blue">
              {getShopCtaegory(get(selectedLocation, "shopCategory", ""))}
            </h5>
            <h5 className="mb-2 text-[10px] font-bold tracking-tight text-midnight-express">
              {getShopType(get(selectedLocation, "shopType", ""))}
            </h5>
            <p className="mb-2 text-sm font-normal text-midnight-express">
              {selectedLocation.shopName}
            </p>
            <div className="inline-flex items-start text-xs font-medium text-midnight-express opacity-40">
              <img className="mr-2" src={location} alt="" />
              {selectedLocation.address}
            </div>
          </div>
        </div>
        <div className="flex flex-row">
          <button
            type="button"
            onClick={() => onClickBack()}
            className="w-[104px] py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-medium-slate-blue focus:outline-none bg-white rounded-lg border border-medium-slate-blue hover:bg-gray-100 hover:text-blue-700 focus:z-10"
          >
            Back
          </button>
        </div>
      </div>

      <div className="flex h-auto flex-col gap-7 p-6 shadow rounded-lg items-center">
        <div className="flex flex-row gap-32 justify-between">
          <p>{selectedPlan.title} Package</p>
          <p>{formatPrice(selectedPlan.amount, "USD")}</p>
        </div>
        {/* <div className="flex w-full flex-row justify-between">
          <p>{"Enter Coupon"}</p>
          <input type="text" /> 
          <button>Add</button>
        </div> */}
        <div className="flex w-full flex-row justify-between font-bold">
          <p>{"Total"}</p>
          <p>{formatPrice(selectedPlan.amount, "USD")}</p>
        </div>
        <div className="border border-dark-gray border-dashed"></div>
        <button
          type="button"
          onClick={() => onClickPayment()}
          className="w-[175px] text-white bg-medium-slate-blue hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none"
        >
          Make Payment
        </button>
      </div>
    </div>
  );
};
export default connect(
  (state: any) => ({
    currentUser: state.setting.get("currentUser"),
    selectedPlan: state.setting.get("selectedPlan"),
    selectedLocation: state.setting.get("selectedLocation"),
  }),
  {
    createCustomer: Actions.setting.createCustomer,
    createHostedSubscription: Actions.setting.createHostedSubscription,
  }
)(PaymentCard);
