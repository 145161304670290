import { connect } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";

const DiscountHeader = (props: any) => {
  const { openDiscount } = props;

  return (
    <div className="flex flex-row w-full justify-end">
      <div className="inline-flex rounded-md shadow-sm" role="group">
        <button
          type="button"
          onClick={() => openDiscount()}
          className="inline-flex gap-2 items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-md hover:bg-gray-100 hover:text-medium-slate-blue"
        >
          <AiOutlinePlus size={20} />
          Add Discount
        </button>
      </div>
    </div>
  );
};
export default connect((state: any) => ({}), {})(DiscountHeader);
