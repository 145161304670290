import { List, Map } from "immutable";
import { ModuleEvents } from "./Actions";
import _, { filter, findIndex, orderBy } from "lodash";

const initialState = Map({
  shopClaims: [],
  userClaims: [],
  inviteClaim: null,
  inviteStatus: false,
  claimUserNextToken: null,
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.CREATE_SHOP_CLAIM: {
      const shopClaims = state.get("shopClaims");
      if (payload && !payload.error) {
        shopClaims.push(payload);
        const sortResult = orderBy(
          shopClaims,
          ["updatedTime"],
          ["desc"]
        );
        return state.set("shopClaims", [...sortResult]);
      }
      return state;
    }

    case ModuleEvents.UPDATE_SHOP_CLAIM: {
      const shopClaims = state.get("shopClaims");
      if (payload && !payload.error) {
        const index = findIndex(
          shopClaims,
          (user) => user.email === payload.email
        );
        shopClaims[index] = payload
        const sortResult = orderBy(
          shopClaims,
          ["updatedTime"],
          ["desc"]
        );
        return state.set("shopClaims", [...sortResult]);
      }
      return state;
    }

    case ModuleEvents.DELETE_SHOP_CLAIM: {
      // const shopClaims = state.get("shopClaims");
      // if (payload && !payload.error) {
      //   const filterResult = filter(
      //     shopClaims,
      //     (user) => user.requestedUserId != payload.requestedUserId
      //   );
      //   return state.set("shopClaims", [...filterResult]);
      // }
      return state;
    }

    case ModuleEvents.FETCH_CLAIMS: {
      if (payload && !payload.error) {
        const nextToken = payload.nextToken;
        const sortResult = orderBy(payload.items, ["updatedTime"], ["desc"]);
        return state
          .set("shopClaims", [...sortResult])
          .set("claimUserNextToken", nextToken);
      }
      return state;
    }

    case ModuleEvents.FETCH_USER_CLAIMS: {
      if (payload && !payload.error) {
        return state.set("userClaims", [...payload])
      }
      return state;
    }
    case ModuleEvents.SET_INVITE_CLAIM: {
      return state.set("inviteClaim", payload);
    }
    case ModuleEvents.INVITE_MODAL_STATUS: {
      return state.set("inviteStatus", payload);
    }

    default:
      return state;
  }
};

export default Reducer;
