import { List, Map } from "immutable";
import _, { filter, findIndex, get, orderBy } from "lodash";
import { ModuleEvents } from "./Actions";

const initialState = Map({
  clients: [],
  clientsLastKey: {},
  assignAppointments: [],
  clientList: {
    clients: [],
    nextLastKey: null,
    lastKeyList: [],
  },
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.FETCH_CLIENTS: {
      const clientList = state.get("clientList");
      if (payload && !payload.error) {
        const clientsLastKey = get(payload, "lastKey", {});
        const newClient = orderBy(get(payload, "items", []), ["name"], ["asc"]);
        clientList.clients = [...newClient];
        clientList.nextLastKey = clientsLastKey;
        return state.set("clientList", { ...clientList });
      }
      return state;
    }

    case ModuleEvents.SEARCH_CLIENT: {
      if (payload && !payload.error) {
        const clientList = state.get("clientList");
        clientList.clients = [...payload];
        clientList.nextLastKey = null;
        clientList.lastKeyList = [];
        return state.set("clientList", { ...clientList });
      }
      return state;
    }

    case ModuleEvents.CREATE_CLIENT: {
      if (
        payload &&
        !payload.error &&
        payload.code === "SUCCESSFULLY_CREATED"
      ) {
        const clientList = state.get("clientList");
        const { data } = payload;
        clientList.clients.push(data);
        clientList.clients = orderBy(clientList.clients, ["name"], ["asc"]);
        return state.set("clientList", { ...clientList });
      }
      return state;
    }

    case ModuleEvents.DELETE_CLIENT: {
      if (payload && !payload.error) {
        const clientList = state.get("clientList");
        const filterClients = filter(
          clientList.clients,
          (item) => item.id !== payload.id
        );
        clientList.clients = orderBy(filterClients, ["name"], ["asc"]);
        return state.set("clientList", { ...clientList });
      }
      return state;
    }

    case ModuleEvents.UPDATE_CLIENT: {
      if (payload && !payload.error) {
        const { data } = payload;
        const clientList = state.get("clientList");
        const index = findIndex(
          clientList.clients,
          (item) => item.id === data.id
        );
        clientList.clients[index] = data;
        clientList.clients = orderBy(clientList.clients, ["name"], ["asc"]);
        return state.set("clientList", { ...clientList });
      }
      return state;
    }

    case ModuleEvents.RECEIVED_CLIENT: {
      if (payload && !payload.error) {
        let clientList = state.get("clientList");
        if (payload.type === "CREATED") {
          clientList.clients.push(payload.data);
          clientList.clients = _.sortBy(clientList.clients, "name");
          return state.set("clientList", { ...clientList });
        } else {
          const index = _.findIndex(
            clientList.clients,
            (item) => item.id === payload.data.id
          );
          clientList.clients[index] = payload.data;
          clientList.clients = _.sortBy(clientList.clients, "name");
          return state.set("clientList", { ...clientList });
        }
      }
      return state;
    }

    default:
      return state;
  }
};

export default Reducer;
