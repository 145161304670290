import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
// import AddModifier from "./AddModifier";
import Alert from '../../../components/Alert';
import {Actions} from '../../../internals/app/Actions';
import ComponentHeader from '../../../components/ComponentHeader';
import MenuGrid from './MenuGrid';
import AddMenuModal from './AddMenuModal';

const MenuView = (props: any) => {
  const {alertStatus, openAlertBox, shop, fetchMenu, menus} = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editingMenu, setEditingMenu] = useState<any>(null);

  useEffect(() => {
    if (shop?.shopId) {
      fetchMenu(shop?.shopId);
    }
  }, [shop, fetchMenu]);

  return (
    <div className="flex flex-col w-full p-6 justify-start h-full">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="pb-6">
        <ComponentHeader
          createTitle={'Add Modifiers'}
          openCreate={() => {
            setIsOpen(true);
          }}
        />
      </div>

      <MenuGrid
        onClick={() => {
          setIsOpen(true);
          setEditingMenu(null);
        }}
        onPressEditMenu={(menu: any) => {
          setEditingMenu(menu);
          setIsOpen(true);
        }}
      />
      <AddMenuModal
        editingMenu={editingMenu}
        closeModal={() => setIsOpen(false)}
        isOpen={isOpen}
        position={menus.length}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    alertStatus: state.common.get('alertStatus'),
    menus: state.menu.get('menus'),
    shop: state.dashboard.get('selectedShop'),
  }),
  {
    openAlertBox: Actions.common.openAlertBox,
    deleteModifier: Actions.modifier.deleteModifier,
    fetchMenu: Actions.menu.fetchMenu,
  }
)(MenuView);
