import Repository from "./Repository";
import {
  CreateServiceCategory,
  UpdateServiceCategory,
} from "./schema/Service.schema";
import { API, graphqlOperation } from "@aws-amplify/api";

class ServiceRepository extends Repository {
  async fetchCategories(shopId) {
    const message = this.buildMessage({ shopId });
    try {
      const result = await this.apiPost({
        apiName: this.API_ADMIN_DASHBOARD,
        path: "/categories",
        message,
      });
      return result.data.categoryResult;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async createCategory(param) {
    try {
      const data = await API.graphql(
        graphqlOperation(CreateServiceCategory, { input: param })
      );
      return data.data.createServiceCategory;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async updateCategory(param) {
    try {
      const data = await API.graphql(
        graphqlOperation(UpdateServiceCategory, { input: param })
      );
      return data.data.updateServiceCategory;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }
}

export default new ServiceRepository();
