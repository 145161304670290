import { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { router } from "../../../App";
import { connect } from "react-redux";
import { find, truncate } from "lodash";
import AddStockItem from "./AddStockItem";
import Table from "../../../components/Table";
import Alert from "../../../components/Alert";
import Edit from "../../../assets/svgs/edit.svg";
import {
  mapStockItemText,
  getStockTotalPrice,
} from "../../../internals/managers/StockManager";
import Delete from "../../../assets/svgs/delete.svg";
import DeleteItem from "../../../components/DeleteItem";
import { Actions } from "../../../internals/app/Actions";
import ComponentHeader from "../../../components/ComponentHeader";

const headerData = ["CREATED DATE", "ITEMS", "TOTAL", "ACTIONS"];
const StockView = (props: any) => {
  const {
    shop,
    user,
    alertStatus,
    fetchStocks,
    stockRecords,
    openAlertBox,
    setStockRecord,
    fetchStockRecords,
    deleteStockRecord,
  } = props;
  const [record, setRecord] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tableItems, setTableItems] = useState<Array<any>>([]);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    if (shop) {
      fetchStocks(shop.shopId);
      fetchStockRecords(shop.shopId);
    }
  }, []);

  const getData = (data: any) => {
    return data?.map((item: any) => {
      const items = mapStockItemText(item.stockRecords);
      const total = getStockTotalPrice(item.stockRecords);
      const itemText = truncate(items, { length: 70 });
      return {
        id: item.id,
        TOTAL: total,
        ITEMS: itemText,
        ACTIONS: [Edit, Delete],
        "CREATED DATE": item.createdTime,
      };
    });
  };

  useEffect(() => {
    if (stockRecords) {
      const tableData = getData(stockRecords);
      setTableItems(tableData);
    }
  }, [stockRecords]);

  const onClickItem = (data: any, index: number) => {
    const selectedItem = find(stockRecords, (item) => item.id === data.id);
    setRecord(selectedItem);
    if (index === 0) {
      selectedItem.stockRecords.push({
        qty: 0,
        unitPrice: 0,
        id: nanoid(),
        totalPrice: 0,
        itemData: null,
      });
      setStockRecord({ record: selectedItem });
    } else if (index === 1) {
      toggleDelete();
    }
  };

  const toggleDelete = () => {
    setIsOpenDelete(!isOpenDelete);
  };

  const toggleStock = () => {
    setIsOpen(!isOpen);
  };

  const onDeleteStockRecord = () => {
    deleteStockRecord({ id: record.id, shopId: shop.shopId });
    toggleDelete();
  };

  const onCreateStock = () => {
    const param: any = {
      id: nanoid(8),
      shopId: shop.shopId,
      createdtim: Date.now(),
      updatedTime: Date.now(),
      createdUserId: user.uid,
      stockRecords: [
        {
          qty: 0,
          unitPrice: 0,
          id: nanoid(),
          totalPrice: 0,
          itemData: null,
        },
      ],
    };
    setStockRecord({ record: param });
    router.navigate("/purchase-stock");
  };

  return (
    <div className="flex flex-col w-full p-6 justify-start">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="flex flex-row justify-end gap-4 pb-6">
        <ComponentHeader
          createTitle={"Add Stock Item"}
          openCreate={() => {
            toggleStock();
          }}
        />
        <ComponentHeader
          createTitle={"Add Purchase Order"}
          openCreate={() => onCreateStock()}
        />
      </div>
      <Table
        {...{ tableHeader: headerData, tableData: tableItems }}
        onClickItem={onClickItem}
      />
      <AddStockItem isOpen={isOpen} closeModal={() => toggleStock()} />
      <DeleteItem
        isOpen={isOpenDelete}
        closeModal={() => toggleDelete()}
        onDelete={() => onDeleteStockRecord()}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    user: state.setting.get("currentUser"),
    shop: state.dashboard.get("selectedShop"),
    alertStatus: state.common.get("alertStatus"),
    stockRecords: state.stock.get("stockRecords"),
  }),
  {
    fetchStocks: Actions.stock.fetchStocks,
    openAlertBox: Actions.common.openAlertBox,
    setStockRecord: Actions.stock.setStockRecord,
    deleteStockRecord: Actions.stock.deleteStockRecord,
    fetchStockRecords: Actions.stock.fetchStockRecords,
  }
)(StockView);
