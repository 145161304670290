import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import QRCodesView from "./QRCodesView";
import Alert from "../../../components/Alert";
import { Actions } from "../../../internals/app/Actions";
import { nanoid } from "nanoid";

const TableView = (props: any) => {
  const { alertStatus, openAlertBox, selectedShop, updateShop } = props;
  const [selectedScreen, setSelectedScreen] = useState<string>("QR_CODES");

  useEffect(() => {
    if (selectedShop && isEmpty(selectedShop?.shortCode)) {
      let shortCode = selectedShop.shopName
        ?.replace(/[^a-z\ A-Z0-9]/g, '').trim().replace(/\s+/g, "-")
        .toLowerCase();
      shortCode = `${shortCode}-${nanoid(3)}`;
      const param = {
        shortCode,
        updatedTime: Date.now(),
        shopId: selectedShop.shopId,
      };
      updateShop(param);
    }
  }, [selectedShop]);

  const renderScreen = () => {
    switch (selectedScreen) {
      case "TABLES":
        return <div />;
      case "QR_CODES":
        return <QRCodesView />;
    }
  };

  return (
    <div className="flex flex-col w-full p-6 justify-start">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="flex flex-row gap-10 mb-9">
        {/* <h3
          onClick={() => setSelectedScreen("TABLES")}
          className={`${
            selectedScreen === "TABLES"
              ? "text-midnight-express"
              : "text-echo-Blue"
          } + cursor-pointer text-md font-semibold`}
        >
          {"Tables"}
        </h3> */}
        <h3
          onClick={() => setSelectedScreen("QR_CODES")}
          className={`${
            selectedScreen === "QR_CODES" || selectedScreen === "DETAILS"
              ? "text-midnight-express"
              : "text-echo-Blue"
          } + cursor-pointer text-md font-semibold`}
        >
          {"QR Codes"}
        </h3>
      </div>
      <>{renderScreen()}</>
    </div>
  );
};
export default connect(
  (state: any) => ({
    alertStatus: state.common.get("alertStatus"),
    loadingAction: state.common.get("loadingAction"),
    selectedShop: state.dashboard.get("selectedShop"),
    transactions: state.transaction.get("transactions"),
    batchSummary: state.transaction.get("batchSummary"),
    selectedBatch: state.transaction.get("selectedBatch"),
  }),
  {
    updateShop: Actions.dashboard.updateShop,
    openAlertBox: Actions.common.openAlertBox,
    fetchBatchSummaryById: Actions.transaction.fetchBatchSummaryById,
    fetchOrderTransactions: Actions.transaction.fetchOrderTransactions,
  }
)(TableView);
