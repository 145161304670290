import React from "react";
import { connect } from "react-redux";

type Props = {
  desc: string;
};

const ItemDesc = (props: Props) => {
  const { desc } = props;

  return (
    <div className="flex flex-row gap-4 mb-2 w-full cursor-pointer">
      <label
        htmlFor={"amountType"}
        className="block text-xs text-gray-400 cursor-pointer font-medium"
      >
        {desc}
      </label>
    </div>
  );
};
export default connect((state: any) => ({}), {})(ItemDesc);
