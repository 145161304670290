import { useEffect, useState } from "react";
import Table from "../Table";
import "../../layouts/Styles.css";
import { connect } from "react-redux";
import DeleteItem from "../DeleteItem";
import Edit from "../../assets/svgs/edit.svg";
import { find, get, isEmpty, orderBy, startCase } from "lodash";
import { Actions } from "../../internals/app/Actions";
import AddProduct from "../../modules/product/views/AddProduct";

const tableHeader = [
  "PRODUCT NAME",
  "CATEGORY",
  "BRAND",
  "QTY",
  "PRICE",
  "COST",
  "WHL PRICE",
  "SUPPLIER",
  "ACTIONS",
];

const ProductList = (props: any) => {
  const { products, brands, deleteProduct, setImage } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    if (products.products) {
      let items = products.products.map((product: any) => {
        // product.productName = product.productName.toLowerCase();
        return product;
      });
      const sortedData = orderBy(items, ["productName"], ["asc"]);
      const tableData = getData(sortedData);
      setTableData(tableData);
    }
  }, [products]);

  const getData = (data: any) => {
    return data.map((item: any) => {
      const brand = find(brands, (brand) => brand.id === item.brandId);
      let price = get(item, "itemPrice", 0);
      if (!isEmpty(item?.priceVariants)) {
        price = item.priceVariants.reduce(
          (min: any, obj: any) => (obj.price < min ? obj.price : min),
          item.priceVariants[0].price
        );
      }
      return {
        id: item.pid,
        PRICE: price,
        ACTIONS: [Edit],
        QTY: get(brand, "quantity", 0),
        COST: get(item, "itemCost", 0),
        "PRODUCT NAME": startCase(item.productName),
        BRAND: get(brand, "brandName", ""),
        "WHL PRICE": get(item, "whlPrice", 0),
        SUPPLIER: get(item, "supplier.supplierName", ""),
        CATEGORY: get(item, "categoryResult.categoryName", ""),
      };
    });
  };

  const toggleProduct = () => {
    setIsOpen(!isOpen);
  };

  const toggleDelete = () => {
    setIsOpenDelete(!isOpenDelete);
  };

  const onClickItem = (data: any, index: number) => {
    const product = find(products.products, (item) => item.pid === data.id);
    setSelectedProduct(product);
    if (index === 0) {
      setImage(product.productImage);
      toggleProduct();
    } else if (index === 1) {
      toggleDelete();
    }
  };

  const onDeleteProduct = (data: any) => {
    deleteProduct(data.pid, data.shopId);
    toggleDelete();
  };

  return (
    <div>
      <Table
        {...{ tableHeader, tableData, type: "PRODUCT" }}
        onClickItem={(data: any, index: number) => onClickItem(data, index)}
      />
      <AddProduct
        isOpen={isOpen}
        product={selectedProduct}
        closeModal={() => toggleProduct()}
      />
      <DeleteItem
        isOpen={isOpenDelete}
        closeModal={() => toggleDelete()}
        onDelete={() => onDeleteProduct(selectedProduct)}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    brands: state.product.get("brands"),
    products: state.product.get("products"),
  }),
  {
    setImage: Actions.product.setImage,
    deleteProduct: Actions.product.deleteProduct,
    selectedProduct: Actions.product.selectedProduct,
  }
)(ProductList);
