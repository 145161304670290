import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { find, capitalize } from "lodash";
import Alert from "../../../components/Alert";
import Table from "../../../components/Table";
import Edit from "../../../assets/svgs/edit.svg";
import Delete from "../../../assets/svgs/delete.svg";
import DeleteItem from "../../../components/DeleteItem";
import { Actions } from "../../../internals/app/Actions";
import AddWarranty from "../../../components/Modal/AddWarranty";
import ComponentHeader from "../../../components/ComponentHeader";

const tableHeader = ["TITLE", "WARRANTY PERIOD", "DESCRIPTION", "ACTIONS"];

const WarrantyView = (props: any) => {
  const { alertStatus, openAlertBox, warranties, deleteWarranty } = props;
  const [warranty, setWarranty] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    if (warranties) {
      const tableData = getData(warranties);
      setTableData(tableData);
    }
  }, [warranties]);

  const getData = (data: any) => {
    return data.map((item: any) => {
      // const isNewWarranty = isEmpty(item?.durationType);
      // const duration = !isNewWarranty
      //   ? `${item.duration} ${capitalize(item.durationType)}`
      //   : item?.description;
      const duration = `${item?.duration} ${capitalize(item?.durationType)}`;

      return {
        id: item.wrid,
        TITLE: item.title,
        "WARRANTY PERIOD": duration,
        DESCRIPTION: item?.description,
        ACTIONS: [Edit, Delete],
      };
    });
  };

  const onClickItem = (data: any, index: number) => {
    const selectedItem = find(warranties, ({ wrid }) => wrid === data.id);
    setWarranty(selectedItem);
    if (index === 0) {
      toggleAdd();
    } else if (index === 1) {
      toggleDelete();
    }
  };

  const toggleDelete = () => {
    setIsOpenDelete(!isOpenDelete);
  };

  const toggleAdd = () => {
    setIsOpen(!isOpen);
  };

  const onDelete = () => {
    deleteWarranty({ wrid: warranty.wrid, shopId: warranty.shopId });
    toggleDelete();
  };

  return (
    <div className="flex flex-col w-full p-6 justify-start">
      <Alert
        alertStatus={alertStatus}
        onCloseAlert={(status: any) =>
          openAlertBox(status, false, alertStatus.successStatus)
        }
      />
      <div className="pb-6">
        <ComponentHeader
          createTitle={"Add Warranty"}
          openCreate={() => {
            setWarranty(null);
            toggleAdd();
          }}
        />
      </div>
      <Table {...{ tableHeader, tableData }} onClickItem={onClickItem} />
      <AddWarranty
        isOpen={isOpen}
        warranty={warranty}
        closeModal={() => toggleAdd()}
      />
      <DeleteItem
        isOpen={isOpenDelete}
        onDelete={() => onDelete()}
        closeModal={() => toggleDelete()}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    alertStatus: state.common.get("alertStatus"),
    warranties: state.warranty.get("warranties"),
  }),
  {
    openAlertBox: Actions.common.openAlertBox,
    deleteWarranty: Actions.warranty.deleteWarranty,
  }
)(WarrantyView);
