import { v4 as uuid } from 'uuid';

export const generateImageUrl = imageId =>
  `https://netlise-image-bucket.s3.ap-southeast-1.amazonaws.com/public/${imageId}`;

export const generateFileId = file => {
  const fileKey = uuid();
  let fileId;
  if (file) {
    fileId = `${fileKey}.${file.name.split('.').pop()}`;
    return fileId;
  }
  return null;
};

export const generateCloudImageUrl = imageId =>
  `https://salon-pos-bucket.s3.ap-southeast-1.amazonaws.com/public/${imageId}`;
