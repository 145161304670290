import React from "react";
import { connect } from "react-redux";
import Chart from "../../../components/Chart/barChart";
import RevenueIcon from "../../../assets/svgs/revenueIcon.svg";
import OrderMonthIcon from "../../../assets/svgs/orderMonthIcon.svg";
import SaleSummaryCard from "../../../components/Card/saleSummaryCard";
import { formatPrice } from "../../../internals/managers/PaymentManager";
import { getMonthSummaryReport } from "../../../internals/managers/ProductManager";

const SalesSummaryView = (props: any) => {
  const { monthSummaryReport, selectedShop } = props;
  const summaryData = getMonthSummaryReport(monthSummaryReport);

  return (
    <div>
      <div className="flex flex-row items-center">
        <SaleSummaryCard
          mainText={"Revenue Per Month"}
          icon={RevenueIcon}
          price={`${formatPrice(summaryData.revenue, selectedShop.currency)}`}
          type={"REVENUE"}
        />
        <SaleSummaryCard
          mainText={"Orders/Month"}
          icon={OrderMonthIcon}
          price={summaryData.orderCount}
          type={"ORDERS"}
        />
      </div>
      <h5 className="font-inter font-bold leading-tight text-base mt-[31px] mb-[7px] dark-black">
        Revenue
      </h5>
      <p className="font-inter font-normal leading-tight text-sm gray-6c">
        Past 12 months
      </p>
      <div className="h-[166px]">
        <Chart />
      </div>
    </div>
  );
};

export default connect(
  (state: any) => ({
    selectedShop: state.dashboard.get("selectedShop"),
    monthSummaryReport: state.report.get("monthSummaryReport"),
  }),
  {}
)(SalesSummaryView);
