import { List, Map } from "immutable";
import { ModuleEvents } from "./Actions";
import _, { filter, findIndex, orderBy } from "lodash";

const initialState = Map({
  employeeList: {
    employees: [],
    nextLastKey: null,
    lastKeyList: [],
    totalCount: 0,
  },
  adminEmployees: {
    employees: [],
    lastKey: null,
  },
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.CREATE_EMPLOYEE: {
      const employeeList = state.get("employeeList");
      if (payload && !payload.error) {
        employeeList.employees.push(payload);
        const sortResult = orderBy(
          employeeList.employees,
          ["updatedTime"],
          ["desc"]
        );
        employeeList.employees = sortResult;
        return state.set("employeeList", { ...employeeList });
      }
      return state;
    }

    case ModuleEvents.UPDATE_EMPLOYEE: {
      const employeeList = state.get("employeeList");
      if (payload && !payload.error) {
        if (payload.employeeStatus === "DELETED") {
          const filterEmployees = filter(
            employeeList.employees,
            (employee) => employee.empId !== payload.empId
          );
          const sortResult = orderBy(
            filterEmployees,
            ["updatedTime"],
            ["desc"]
          );
          employeeList.employees = sortResult;
          return state.set("employeeList", { ...employeeList });
        } else {
          const index = findIndex(
            employeeList.employees,
            (employee) => employee.empId === payload.empId
          );
          employeeList.employees[index] = payload
          const sortResult = orderBy(
            employeeList.employees,
            ["updatedTime"],
            ["desc"]
          );
          employeeList.employees = sortResult;
          return state.set("employeeList", { ...employeeList });
        }
      }
      return state;
    }

    case ModuleEvents.FETCH_EMPLOYEES_FOR_SHOPID: {
      const employeeList = state.get("employeeList");
      if (payload && !payload.error) {
        const sortResult = orderBy(payload.items, ["updatedTime"], ["desc"]);
        employeeList.employees = sortResult;
        employeeList.nextLastKey = payload.lastKey;
        employeeList.totalCount = payload.totalCount;
      }
      employeeList.employees = _.uniqBy(
        _.flattenDeep(employeeList.employees),
        "empId"
      );
      return state.set("employeeList", { ...employeeList });
    }

    case ModuleEvents.SEARCH_STYLIST_FOR_SALONID: {
      let employeeList = state.get("employeeList");
      if (payload && !payload.error) {
        employeeList.employees = payload;
        employeeList.nextLastKey = null;
        employeeList.lastKeyList = [];
      }
      return state.set("employeeList", { ...employeeList });
    }

    case ModuleEvents.FETCH_ADMIN_STYLIST: {
      let adminEmployees = state.get("adminEmployees");
      if (payload) {
        adminEmployees.employees = payload.items;
        adminEmployees.lastKey = payload.lastKey;
      }

      return state.set("adminEmployees", { ...adminEmployees });
    }

    case ModuleEvents.FETCH_PAGINATE_STYLIST: {
      let employeeList = state.get("employeeList");
      if (payload && !payload.error) {
        employeeList.nextLastKey = payload.lastKey;
        const newStylist = _.sortBy(_.get(payload, "items", []), "name");
        employeeList.employees = [...employeeList.employees, ...newStylist];
        employeeList.employees = _.uniqBy(
          _.flattenDeep(employeeList.employees),
          "empId"
        );
      }
      return state.set("employeeList", { ...employeeList });
    }
    default:
      return state;
  }
};

export default Reducer;
