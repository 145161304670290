import { Map } from "immutable";
import { ModuleEvents } from "./Actions";
import _, { get, isEmpty, orderBy } from "lodash";

const initialState = Map({
  category: {},
  selectedService: null,
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.FETCH_SERVICE_CATEGORIES: {
      if (payload && !payload.error) {
        const categories = get(payload, "[0].categories", null);
        if (!isEmpty(categories)) {
          let sortResult = orderBy(categories, ["category"], ["asc"]);
          payload[0].categories = sortResult;
          return state.set("category", { ...payload[0] });
        }
      }
      return state;
    }
    case ModuleEvents.SEARCH_CATEGORIES: {
      if (payload && !payload.error) {
        return state.set("category", { ...payload });
      }
      return state;
    }
    case ModuleEvents.CREATE_SERVICE_CATEGORY: {
      if (payload && !payload.error) {
        return state.set("category", { ...payload });
      }
      return state;
    }
    case ModuleEvents.UPDATE_SERVICE_CATEGORY: {
      const { result } = payload;
      if (result && !result.error) {
        return state.set("category", { ...result });
      }
      return state;
    }
    default:
      return state;
  }
};

export default Reducer;
