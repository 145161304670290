import { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import Modal from "react-modal";
import { nanoid } from "nanoid";
import { connect } from "react-redux";
import FormInput from "../inputs/FormInput";
import PhoneInput from "react-phone-input-2";
import { AiOutlineClose } from "react-icons/ai";
import Delete from "../../assets/svgs/delete.svg";
import { includes, isEmpty, toLower } from "lodash";
import { Actions } from "../../internals/app/Actions";
import { Formik, Field, useFormikContext } from "formik";

type CustomerProps = {
  employee?: any;
  customer?: any;
  isOpen: boolean;
  currentUser: any;
  selectedShop: any;
  loadingAction: any;
  closeModal: () => void;
  createClient: (params: any) => void;
  updateClient: (params: any) => void;
  deleteClient: (id: string, shopId: string) => void;
};

const InitialValues = {
  email: "",
  address: "",
  fullName: "",
  mobileNumber: "",
  isContactApp: false,
  isContactText: false,
};

const AddCustomer = (props: CustomerProps) => {
  const {
    isOpen,
    customer,
    closeModal,
    currentUser,
    selectedShop,
    createClient,
    updateClient,
    deleteClient,
  } = props;
  const formRef: any = useRef();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>(InitialValues);
  const [emailOrMobileError, setEmailOrMobileError] = useState<string>("");

  useEffect(() => {
    setIsEdit(!isEmpty(customer));
  }, [customer]);

  const customStyles: any = {
    overlay: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      position: "fixed",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      padding: 0,
      height: "auto",
      border: "none",
      overflow: "auto",
      maxWidth: "624px",
      background: "none",
      left: "calc(50vw - 312px)",
      WebkitOverflowScrolling: "touch",
    },
  };

  const onSaveCustomer = (values: any) => {
    const spliceName = values.fullName.split(" ");
    let firstName = spliceName[0],
      lastName = "";
    let nameLength = 0;
    while (nameLength < spliceName.length - 1) {
      nameLength++;
      if (isEmpty(lastName)) {
        lastName = `${spliceName[nameLength]}`;
      } else {
        lastName = `${lastName} ${spliceName[nameLength]}`;
      }
    }
    const params: any = {
      lastName,
      firstName,
      email: values.email,
      address: values.address,
      name: toLower(values.fullName),
      isContactApp: values.isContactApp,
      isContactText: values.isContactText,
      mobileNumber: includes(values.mobileNumber, "+")
        ? values.mobileNumber
        : `+${values.mobileNumber}`,
      createdUser: currentUser.uid,
    };
    if (values.mobileNumber.length < 9) {
      params["mobileNumber"] = "+94";
    }
    if (isEdit) {
      params["id"] = customer.id;
      params["shopId"] = customer.shopId;
      updateClient(params);
    } else {
      params["id"] = nanoid();
      params["shopId"] = selectedShop.shopId;
      createClient(params);
    }
    closeModal();
  };

  const validationSchema = Yup.object({
    fullName: Yup.string()
      .min(2, "Must be 2 characters or more")
      .required("Customer name is required"),
    isContactApp: Yup.boolean(),
    isContactText: Yup.boolean(),
  });

  const onProductDelete = () => {
    deleteClient(customer.id, customer.shopId);
    setIsOpenDelete(false);
    closeModal();
  };

  const isValidOnClose = () => {
    const formikValues = formRef.current?.values;
    return (
      formikValues?.fullName === "" &&
      formikValues?.mobileNumber === "" &&
      formikValues?.isContactApp === false &&
      formikValues?.isContactText === false
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Product Modal"
      // onRequestClose={() => {
      //   const isBackgroundClosed = isValidOnClose();
      //   if (isBackgroundClosed) {
      //     closeModal();
      //   }
      // }}
    >
      <div className="relative shadow bg-white rounded-lg">
        <div className="flex flex-row h-14 justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-t-lg">
          <h3>{isEdit ? "Edit Customer" : "Create Customer"}</h3>
          <button
            type="button"
            onClick={() => closeModal()}
            className="top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
          >
            <AiOutlineClose size={20} />
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => onSaveCustomer(values)}
          validate={(values) => {
            if (!isEmpty(values.email) || values.mobileNumber.length > 7) {
              return setEmailOrMobileError("");
            } else {
              return setEmailOrMobileError(
                "Please enter valid email or phone number"
              );
            }
          }}
        >
          {({ values, errors, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="overscroll-contain overflow-auto h-auto bg-white px-4 py-5 sm:p-6 rounded-lg">
                  <RenderComponent
                    customer={customer}
                    emailOrMobileError={emailOrMobileError}
                  />
                </div>

                <div className="flex h-16 flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
                  {isEdit ? (
                    <button
                      type="button"
                      onClick={() => setIsOpenDelete(true)}
                      className="inline-flex outline-none justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-red-700"
                    >
                      {"Delete Customer"}
                    </button>
                  ) : (
                    <div />
                  )}
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-indigo-700"
                  >
                    {isEdit ? "Save Changes" : "Add Customer"}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
        {isOpenDelete ? (
          <div className="absolute top-1/4 inset-14 w-full max-w-lg z-50 justify-center items-center">
            <div className="relative h-full md:h-auto">
              <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                <button
                  type="button"
                  onClick={() => setIsOpenDelete(false)}
                  className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <AiOutlineClose size={20} />
                  <span className="sr-only">Close modal</span>
                </button>
                <img
                  className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                  src={Delete}
                  alt="actions"
                />

                <p className="mb-4 text-gray-500 dark:text-gray-300">
                  Are you sure you want to delete this employee?
                </p>
                <div className="flex justify-center items-center space-x-4">
                  <button
                    type="button"
                    onClick={() => setIsOpenDelete(false)}
                    className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:outline-none hover:text-gray-900"
                  >
                    No, cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => onProductDelete()}
                    className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none  "
                  >
                    Yes, I'm sure
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};
export default connect(
  (state: any) => ({
    currentUser: state.setting.get("currentUser"),
    loadingAction: state.common.get("loadingAction"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    createClient: Actions.customer.createClient,
    updateClient: Actions.customer.updateClient,
    deleteClient: Actions.customer.deleteClient,
  }
)(AddCustomer);

const RenderComponent = ({ customer, emailOrMobileError }: any) => {
  const { values, errors, touched, handleChange, setFieldValue } =
    useFormikContext<{
      email: string;
      address: string;
      fullName: string;
      mobileNumber: string;
      isContactApp: boolean;
      isContactText: boolean;
    }>();

  useEffect(() => {
    if (!isEmpty(customer)) {
      setFieldValue("email", customer.email);
      setFieldValue("address", customer.address);
      setFieldValue("mobileNumber", customer.mobileNumber);
      setFieldValue("isContactApp", customer.isContactApp);
      setFieldValue("isContactText", customer.isContactText);
      setFieldValue("fullName", `${customer.firstName} ${customer.lastName}`);
    }
  }, [customer]);

  return (
    <div className="grid grid-cols-6 gap-6">
      <div className="col-span-6 md:col-span-3">
        <FormInput
          id="fullName"
          label="Full Name"
          value={values.fullName}
          error={errors.fullName}
          placeholder="Full Name"
          touched={touched.fullName}
          onChange={handleChange("fullName")}
        />
      </div>
      <div className="col-span-6 md:col-span-3">
        <FormInput
          id="email"
          label="Your email"
          value={values.email}
          touched={touched.email}
          error={emailOrMobileError}
          placeholder="name@company.com"
          onChange={handleChange("email")}
        />
      </div>
      <div className="col-span-6 md:col-span-3">
        <div className="w-full relative flex flex-col">
          <label
            htmlFor="mobile-number"
            className="mb-2 block text-sm font-medium text-gray-700"
          >
            Mobile Number
          </label>
          <PhoneInput
            country={"lk"}
            value={values.mobileNumber}
            onChange={handleChange("mobileNumber")}
            inputStyle={{
              border: "none",
              height: "100%",
              width: "100%",
              borderRadius: "8px",
            }}
            buttonStyle={{
              border: "none",
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
            }}
            containerClass="h-10 block w-full rounded-md outline-none border-gray-300 border shadow sm:text-sm mt-0"
          />
          {!isEmpty(emailOrMobileError) && (
            <span className="mt-2 first-line:block text-xs font-normal text-rose-400	">
              {emailOrMobileError}
            </span>
          )}
        </div>
      </div>

      <div className="col-span-6 md:col-span-3">
        <label
          htmlFor={"amountType"}
          className="block text-sm mb-2 font-medium text-gray-700"
        >
          Preferred Contact Method
        </label>
        <div
          role="group"
          aria-labelledby="my-radio-group"
          className="w-full h-10 col-span-6 flex flex-row gap-6"
        >
          <label className="flex items-center text-sm font-medium text-gray-700">
            <Field
              type="checkbox"
              name="isContactApp"
              className="mr-3 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            Mobile App
          </label>
          <label className="flex items-center text-sm font-medium text-gray-700">
            <Field
              type="checkbox"
              name="isContactText"
              className="mr-3 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            Text Messages
          </label>
        </div>
        {!values.isContactText &&
          !values.isContactApp &&
          (touched.isContactText || touched.isContactApp) && (
            <span className="text-xs font-normal text-rose-400">
              {"Please select contact method"}
            </span>
          )}
      </div>

      <div className="col-span-6">
        <FormInput
          id="address"
          label="Address"
          value={values.address}
          error={errors.address}
          placeholder="Address"
          touched={touched.address}
          onChange={handleChange("address")}
        />
      </div>
    </div>
  );
};
