import { isProd } from '../config/aws_config';
import {Storage} from '@aws-amplify/storage';

export const generateImageUrl = async (imageId: string) => {
  if (isProd) {
    const url = `https://netlise-image-bucket.s3.ap-southeast-1.amazonaws.com/public/${imageId}`;
    try {
      const signed = await Storage.get(imageId);
      return signed;
    } catch (e) {}
    return null;
  }
  return `https://netlise-image-bucket.s3.ap-southeast-1.amazonaws.com/public/${imageId}`;
};