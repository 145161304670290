import { useRef, useState, useEffect } from "react";
import { nanoid } from "nanoid";
import Switch from "react-switch";
import { Products } from "../Types";
import { connect } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useFormikContext, FieldArray } from "formik";
import { find, findIndex, get, isEmpty } from "lodash";
import IconClose from "../../../assets/svgs/icon-close.svg";
import FormInput from "../../../components/inputs/FormInput";
import { formatPrice } from "../../../internals/managers/PaymentManager";

const customStyles = {
  container: (base: any) => ({
    ...base,
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: 0,
  }),
  singleValue: (base: any) => ({
    ...base,
    marginLeft: 0,
    marginRight: 0,
  }),
  placeholder: (base: any) => ({
    ...base,
    marginLeft: 0,
    marginRight: 0,
    color: "#979797",
  }),
  input: (base: any) => ({
    ...base,
    margin: 0,
  }),
  control: (base: any, state: any) => ({
    ...base,
    height: 40,
    // width: "100%",
    fontSize: 14,
    fontWeight: 400,
    paddingLeft: 10,
    borderRadius: 8,
    paddingRight: 10,
    color: "#434343",
    boxShadow: "0px 0px 10px 5px #c4d3f020",
    border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    "&:hover": {
      border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    },
  }),
};

const ModifierForm = (props: any) => {
  const { modifiers, editModifier, removeEditModifier } = props;
  const { values, errors, touched, submitCount, setFieldValue, isValid } =
    useFormikContext<Products>();
  const inputRef: any = useRef(null);
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [options, setOptions] = useState<Array<any>>([]);

  useEffect(() => {
    if (!isEmpty(modifiers)) {
      const modifierOptions = modifiers.map((item: any) => {
        return {
          value: item.id,
          label: item.setName,
        };
      });
      setOptions([...modifierOptions]);
    } else {
      setOptions([]);
    }
  }, [modifiers]);

  return (
    <div className="overflow-auto bg-white px-4 py-5 sm:p-6">
      <div className="grid grid-cols-6 gap-6 flex-row">
        <FieldArray name="modifiers">
          {({ insert, remove, push, replace }) => {
            return (
              <>
                {values?.modifiers.map((modifier: any, index: number) => {
                  const modifierErrors: any = !isEmpty(errors)
                    ? get(errors, `modifiers.[${index}]`, null)
                    : null;
                  const modifierTouched: any = get(
                    touched,
                    `modifiers.[${index}]`,
                    null
                  );
                  const validIndex = !isEmpty(editModifier)
                    ? findIndex(
                        values?.modifiers,
                        (modifier: any) => modifier.id === editModifier.id
                      )
                    : values.modifiers.length - 1;

                  return (
                    <>
                      {validIndex === index ? (
                        <>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor={"modifierSetName"}
                              className="block mb-2 text-sm font-medium text-gray-700"
                            >
                              {"Modifier Set name*"}
                            </label>
                            <CreatableSelect
                              isClearable
                              options={options}
                              styles={customStyles}
                              placeholder={"Modifier set name*"}
                              value={{
                                label: modifier.setName,
                                value: modifier.id,
                              }}
                              onChange={(newValue: any, actionMeta: any) => {
                                setFieldValue(
                                  `modifiers[${index}].setName`,
                                  actionMeta.action === "clear"
                                    ? ""
                                    : newValue.label
                                );
                                if (actionMeta.action === "select-option") {
                                  const option = find(
                                    modifiers,
                                    (item: any) => item.id === newValue.value
                                  );
                                  removeEditModifier();
                                  replace(index, option);
                                } else if (actionMeta.action === "clear") {
                                  const clearData = {
                                    title: "",
                                    setName: "",
                                    id: nanoid(8),
                                    isMandatory: false,
                                    items: [
                                      {
                                        price: 0,
                                        itemName: "",
                                      },
                                    ],
                                  };
                                  removeEditModifier();
                                  replace(index, clearData);
                                }
                              }}
                            />
                            {!isEmpty(modifierErrors?.setName) &&
                              submitCount > 0 && (
                                <span className="mb-1 first-line:block text-xs font-normal text-rose-400">
                                  {modifierErrors?.setName}
                                </span>
                              )}
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <FormInput
                              id="title"
                              label="Modifier Title"
                              value={modifier.title}
                              error={modifierErrors?.title}
                              touched={modifierTouched?.title}
                              onChange={(text: string) => {
                                setFieldValue(
                                  `modifiers[${index}].title`,
                                  text
                                );
                              }}
                            />
                          </div>
                          <div className="col-span-6">
                            <div className="flex flex-row justify-between items-center sm:w-1/2 w-full">
                              <label
                                htmlFor={"modifierStatus"}
                                className="block mb-2 text-sm font-medium text-gray-700 mr-3"
                              >
                                Mandatory?
                              </label>
                              <Switch
                                width={48}
                                height={20}
                                id="isMandatory"
                                onColor="#6D71F9"
                                checkedIcon={false}
                                handleDiameter={30}
                                uncheckedIcon={false}
                                className="react-switch"
                                onHandleColor="#F8F9FD"
                                checked={modifier.isMandatory}
                                boxShadow="0px 1px 5px rgba(109, 113, 249, 0.8)"
                                activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                                onChange={(value: boolean) =>
                                  setFieldValue(
                                    `modifiers[${index}].isMandatory`,
                                    value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <FieldArray name={`modifiers.${index}.items`}>
                            {({
                              form,
                              remove: childRemove,
                              push: childPush,
                            }) => {
                              return (
                                <div className="col-span-6 mb-4">
                                  <label
                                    htmlFor={"modifierItems"}
                                    className="block mb-2 text-sm font-medium text-gray-700"
                                  >
                                    {"Modifier Items"}
                                  </label>

                                  <div className="flex flex-col w-full px-4 pt-3 bg-gray-50 rounded-md">
                                    {modifier?.items?.map(
                                      (item: any, subIndex: number) => {
                                        const itemErrors: any = get(
                                          errors,
                                          `modifiers.[${index}].items[${subIndex}]`,
                                          null
                                        );
                                        const itemTouched: any = get(
                                          touched,
                                          `modifiers.[${index}].items[${subIndex}]`,
                                          null
                                        );

                                        return (
                                          <div
                                            className={`flex flex-col w-full items-end border-b mb-2 pb-2`}
                                          >
                                            <div
                                              className={`flex flex-row w-full items-center justify-between`}
                                            >
                                              <input
                                                type="text"
                                                autoComplete="off"
                                                value={item.itemName}
                                                placeholder="Item Name"
                                                name={`modifiers[${index}].items[${subIndex}].itemName`}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `modifiers[${index}].items[${subIndex}].itemName`,
                                                    e.target.value
                                                  );
                                                }}
                                                className="p-0 h-6 block w-4/5 bg-gray-50 outline-none focus:bg-transparent text-start text-sm font-medium text-valhalla"
                                              />
                                              <div className="w-2/5 h-6 gap-4 flex flex-row justify-center items-center">
                                                {editIndex === subIndex ? (
                                                  <input
                                                    ref={inputRef}
                                                    value={`${item.price}`}
                                                    onBlur={() =>
                                                      setEditIndex(-1)
                                                    }
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        `modifiers[${index}].items[${subIndex}].price`,
                                                        e.target.value
                                                      );
                                                    }}
                                                    className="h-6 px-2 block w-5/6 rounded-md outline-none border-gray-300 border shadow sm:text-sm"
                                                  />
                                                ) : (
                                                  <div
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      setEditIndex(subIndex);
                                                    }}
                                                    className="flex justify-end w-5/6 h-6 text-center items-center text-sm font-medium text-valhalla"
                                                  >
                                                    {formatPrice(item.price)}
                                                  </div>
                                                )}
                                                {modifier?.items.length > 1 ? (
                                                  <button
                                                    type="button"
                                                    onClick={() =>
                                                      childRemove(subIndex)
                                                    }
                                                    className="flex w-6 h-6 justify-center items-center rounded-lg bg-transparent"
                                                  >
                                                    <img
                                                      alt="actions"
                                                      src={IconClose}
                                                      className="text-deactivate w-3 h-3"
                                                    />
                                                  </button>
                                                ) : (
                                                  <div className="w-6 h-6" />
                                                )}
                                              </div>
                                            </div>
                                            <div
                                              className={`flex flex-row w-full items-center justify-between`}
                                            >
                                              {!isEmpty(itemErrors?.itemName) &&
                                              submitCount > 0 ? (
                                                <span className="first-line:block text-xs font-normal text-rose-400	mr-8 mt-1">
                                                  {itemErrors.itemName}
                                                </span>
                                              ) : (
                                                <div />
                                              )}
                                              {!isEmpty(itemErrors?.price) &&
                                                submitCount > 0 && (
                                                  <span className="first-line:block text-xs font-normal text-rose-400	mr-8 mt-1">
                                                    {itemErrors.price}
                                                  </span>
                                                )}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>

                                  <div
                                    className="flex flex-row w-36 justify-start items-center gap-4 cursor-pointer mt-4"
                                    onClick={() => {
                                      const options: any = {
                                        price: 0,
                                        itemName: "",
                                      };
                                      childPush(options);
                                    }}
                                  >
                                    <BsFillPlusCircleFill
                                      size={25}
                                      color="#6D71F9"
                                    />
                                    <label
                                      htmlFor={"AddModofier"}
                                      className="block text-sm text-medium-slate-blue cursor-pointer font-medium"
                                    >
                                      Add Modifier
                                    </label>
                                  </div>
                                </div>
                              );
                            }}
                          </FieldArray>
                        </>
                      ) : null}
                    </>
                  );
                })}
              </>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};

export default connect(
  (state: any) => ({
    modifiers: state.modifier.get("modifiers"),
    optionSets: state.product.get("optionSets"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {}
)(ModifierForm);
