export const TransactionTableHeader = [
  "ORDER ID",
  "DATE",
  "EMPLOYEE",
  "RECEIPT ID",
  "AMOUNT",
  "ACTIONS",
];

export const CloseCashTableHeader = [
  "TIME PERIOD",
  "CLOSE CASH BY",
  "TOTAL PRODUCTS",
  "TOTAL SERVICES",
  "TOTAL ITEMS",
  "NET TOTAL",
];

export const DateRangeTableHeader = [
  "TIME PERIOD",
  "CLOSE CASH BY",
  "TOTAL PRODUCTS",
  "STARTING CASH",
  "CASH SALES",
  "CARD SALES",
  "REFUNDS",
  "CASH IN/OUT",
  "DRAWER AMOUNT",
  "NET AMOUNT",
];
