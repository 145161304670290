import OfferRepository from "../../internals/repository/OfferRepository";

export const ModuleEvents = {
  FETCH_OFFERS: "FETCH_OFFERS",
  CREATE_OFFER: "CREATE_OFFER",
  UPDATE_OFFER: "UPDATE_OFFER",
  DELETE_OFFER: "DELETE_OFFER",
};

const createAction =
  (type, action = () => { }, meta) =>
    (...args) => ({
      type,
      payload: action(...args),
      meta,
    });

const fetchOffers = createAction(
  ModuleEvents.FETCH_OFFERS,
  async (shopId, limit = 30, nextToken = null) => {
    const result = await OfferRepository.fetchOffers(
      shopId,
      limit,
      nextToken
    );
    return result;
  }
);

const createOffer = createAction(
  ModuleEvents.CREATE_OFFER,
  async (param) => {
    const result = await OfferRepository.createOffer(param);
    return result;
  }
);
const updateOffer = createAction(
  ModuleEvents.UPDATE_OFFER,
  async (param) => {
    const result = await OfferRepository.updateOffer(param);
    return result;
  }
);
const deleteOffer = createAction(
  ModuleEvents.DELETE_OFFER,
  async (shopId, id) => {
    const result = await OfferRepository.deleteOffer(shopId, id);
    return result;
  }
);

const exportedFuction = {
  createOffer,
  updateOffer,
  deleteOffer,
  fetchOffers,
};

export default exportedFuction;

// if (isArray(data)) {
//   const productOptions: any = await getProductOptionSets(data);
//   await Promise.all(
//     productOptions.map(async (productOption: any) => {
//       try {
//         await this.API.graphql(
//           graphqlOperation(UpdateOption, { input: productOption })
//         );
//       } catch (error) {
//         console.warn("update product options error", error);
//       }
//     })
//   );
// } else {
//   try {
//     data['updatedTime'] = Date.now();
//     await this.API.graphql(
//       graphqlOperation(UpdateOption, { input: data })
//     );
//   } catch (error) {
//     console.warn("update product options error", error);
//   }
// }